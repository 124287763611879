// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ButtonV2 as Button,
  CropIcon,
  RotateIcon,
  UploadIcon,
  Stack,
  styled,
  Grid,
  Box,
} from '@livingpackets/design-system-react-next';
import imgBgTablet from 'assets/img/shipment/import/tablet/TABLET.png';
import imgBgTablet2x from 'assets/img/shipment/import/tablet/TABLET@2x.png';
import imgBgTablet3x from 'assets/img/shipment/import/tablet/TABLET@3x.png';
import ShipmentParcelForm from 'components/molecules/ShipmentParcelForm';
import { IOcrLabel, ShipmentParcelData } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';

import { LabelCropState, LabelCropStateNameEnum } from './ShipmentLabelForm';
import ShipmentLabelImage from './ShipmentLabelImage';

const ImageTabletContainer = styled(Box)`
  width: 29.375rem;
  height: 14rem;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${imgBgTablet});
  background-image: -webkit-image-set(
    url(${imgBgTablet2x}) 1x,
    url(${imgBgTablet3x}) 2x
  );
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0.8125rem 1.6875rem;
`;

type Props = {
  labelCropState: LabelCropState;
  handleLabelReUpload: () => void;
  handleRedirectToCroppingPage: () => void;
  label: IOcrLabel;
  currentLabel: IOcrLabel;
  setIsParcelDetailsBlockConfirmed: (value: boolean) => void;
};

const ShippingDetailsForm = ({
  labelCropState,
  handleLabelReUpload,
  handleRedirectToCroppingPage,
  label,
  currentLabel,
  setIsParcelDetailsBlockConfirmed,
}: Props) => {
  const { t } = useTranslation(['shipments', 'general']);

  return (
    <Grid container direction="column" gap="1.5rem">
      <Grid item>
        <Stack direction="row" justifyContent="space-between">
          <ImageTabletContainer>
            {(labelCropState.name ===
              LabelCropStateNameEnum.shipmentCreationFormDisplay ||
              labelCropState.name ===
                LabelCropStateNameEnum.showingManualCropResult ||
              labelCropState.name ===
                LabelCropStateNameEnum.showingAutoCropResult) && (
              <ShipmentLabelImage
                labelUrl={labelCropState.previewUrl}
                onManualCropStarted={() => {}}
                enableManualCrop={false}
                displayInTabletImg={true}
              />
            )}
          </ImageTabletContainer>
          <Stack justifyContent="center">
            <Button
              fullWidth
              variant="secondary"
              onClick={handleLabelReUpload}
              icon={UploadIcon}
            >
              {t('shipments:importLabel.confirmInformation.button.changeLabel')}
            </Button>
            <Button
              disabled
              fullWidth
              variant="secondary"
              onClick={handleLabelReUpload}
              icon={RotateIcon}
              sx={{ marginTop: '1rem' }}
            >
              {t('shipments:importLabel.confirmInformation.button.rotation')}
            </Button>
            <Button
              fullWidth
              variant="secondary"
              onClick={handleRedirectToCroppingPage}
              icon={CropIcon}
              sx={{ marginTop: '1rem' }}
            >
              {t('shipments:importLabel.confirmInformation.button.cropAgain')}
            </Button>
          </Stack>
        </Stack>
      </Grid>
      <Grid item>
        <ShipmentParcelForm
          label={label}
          onChange={(shipmentParcelData?: ShipmentParcelData) => {
            if (!shipmentParcelData) {
              setIsParcelDetailsBlockConfirmed(false);

              return;
            }

            currentLabel.weightInGrams = shipmentParcelData.weightInGrams;
            currentLabel.carrierTrackingCode =
              shipmentParcelData.carrierTrackingCode;
            currentLabel.referenceNumber = shipmentParcelData.referenceNumber;

            setIsParcelDetailsBlockConfirmed(true);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ShippingDetailsForm;
