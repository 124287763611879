// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  ButtonV2 as Button,
  Grid,
  Text,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';
import { disabledContent } from 'utils/disabledElementCss';

interface Props {
  prefix: string;
  alwaysShowActions?: boolean;
  disabled?: boolean;
  isDirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  onSubmit: () => void;
  onCancel?: () => void;
  textOnLeftSide?: string;
}
/**
 * Reset and Submit button that appears throught animation when the form is dirty
 */

export const ExpandableSubmitButton = ({
  prefix,
  alwaysShowActions,
  disabled,
  isDirty,
  isValid,
  isSubmitting,
  onSubmit,
  onCancel,
  textOnLeftSide,
}: Props) => {
  const { t } = useTranslation(['general']);

  return (
    <Grid item mobile={12}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        gap="1.5rem"
        sx={{
          width: '100%',
          maxHeight: alwaysShowActions || isDirty ? 'max-content' : '0rem',
          overflow: 'hidden',
          transition: 'max-height 0.25s ease-in-out',
        }}
      >
        <Grid
          item
          mobile={12}
          tablet="auto"
          flexGrow={1}
          sx={{
            ...(disabled && disabledContent),
          }}
        >
          <Text variant="bodyTextS">{textOnLeftSide}</Text>
        </Grid>
        <Grid item mobile={12} tablet="auto">
          <Box sx={{ pt: '1rem', display: 'flex', alignItems: 'center' }}>
            <Grid container justifyContent="flex-end" gap="1.5rem">
              <Grid item>
                <Button
                  data-testid={`${prefix}-cancel-button`}
                  variant="tertiary"
                  onClick={onCancel}
                  disabled={disabled}
                >
                  {t('general:cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  data-testid={`${prefix}-save-button`}
                  disabled={!isValid || !isDirty || isSubmitting || disabled}
                  onClick={onSubmit}
                >
                  {t('general:save')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
