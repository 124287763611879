// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useEffect, useState } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';

import { INITIAL_RENT, IRent } from '../models/boxContract';

const useGetRents = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpAccountV3');
  const [rents, setRents] = useState<IRent>(INITIAL_RENT);
  const getRents = useCallback(async () => {
    const { data, error } = await makeRequest({
      path: `/me/rents`,
      method: 'get',
    });

    if (error) {
      return { success: false };
    }

    return data;
  }, [makeRequest]);

  useEffect(() => {
    getRents().then(r => setRents(r));
  }, [getRents]);

  return { loading, rents };
};

export default useGetRents;
