// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  ShareIcon,
  ButtonV2 as Button,
  ModalV2 as Modal,
} from '@livingpackets/design-system-react-next';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';

import { TrackingLinkForm } from './TrackingLinkForm';

interface IShareTrackingLink {
  shipmentId: string;
  shipment: IShipment;
}

export const ShareTrackingLink = ({
  shipmentId,
  shipment,
}: IShareTrackingLink) => {
  const { t } = useTranslation('shipments');

  const [modalOpen, setModalOpen] = useState(false);
  const [showSearchContact, setShowSearchContact] = useState<boolean>(false);

  const handleClose = () => {
    setModalOpen(false);
    setShowSearchContact(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Button
        icon={ShareIcon}
        onClick={handleOpen}
        data-testid="open-share-tracking"
      >
        {shipment.opening_code
          ? t('detail.tab.shareTrackingCodeButton')
          : t('detail.tab.shareTrackingLinkButton')}
      </Button>
      <Modal
        hideCloseIcon={!showSearchContact}
        open={modalOpen}
        onDismiss={() => setShowSearchContact(false)}
        setOpen={setModalOpen}
        maxWidth="sm"
        sx={{
          '.MuiDialog-paper': {
            padding: 0,
          },
        }}
      >
        <TrackingLinkForm
          shipment={shipment}
          shipmentId={shipmentId}
          handleClose={handleClose}
          displaySearchContact={setShowSearchContact}
        />
      </Modal>
    </>
  );
};
