// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Stack, Box, Text } from '@livingpackets/design-system-react-next';
import { DetailsCard, DetailsSpan } from 'components/atoms/ShipmentAtoms';
import { ProvidedWeightTooltip } from 'components/molecules/ShipmentWeightTooltips';
import isCancelledState from 'helpers/isCancelledState';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { formatLpui } from 'utils/product';

import CopyToClipboard from '../atoms/CopyToClipboard';

const DataValue = ({
  label,
  value,
  icon,
  dataTestId,
  displayCopyToClipboardButton,
  ...rest
}: {
  label: string;
  value: string | number | null;
  icon?: React.ReactNode;
  dataTestId?: string;
  displayCopyToClipboardButton?: boolean;
}) => (
  <Stack>
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack>
        <Stack direction="row" alignItems="center">
          <DetailsSpan variant="titleXS" color="custom.neutral.black.50">
            {label}
          </DetailsSpan>
          {icon}
        </Stack>
        <Text
          variant="bodyTextS"
          color="custom.neutral.black.100"
          mt="4px"
          data-testid={dataTestId}
          {...rest}
        >
          {value || '- - - - -'}
        </Text>
      </Stack>
      {displayCopyToClipboardButton && value && (
        <CopyToClipboard
          data={typeof value === 'number' ? value.toString() : value}
        />
      )}
    </Stack>
  </Stack>
);

interface IShipmentIdData {
  shipment: IShipment;
  isLinked?: boolean;
}

const toKg = (weight: number) => (weight / 1000).toString() + 'kg';

const ShipmentIdData = ({ shipment, isLinked = false }: IShipmentIdData) => {
  const { t } = useTranslation('shipments');

  const formatTimestamp = useIntlFormatTimestamp();

  const isCancelled = isCancelledState(shipment);

  return (
    <>
      <DetailsCard
        padding="20px"
        mt="12px"
        data-testid="shipment-id-card"
        data-testvalue={isLinked ? 'linked' : 'current'}
        disabled={isCancelled}
      >
        <Stack>
          <DataValue
            label={t('detail.summary.information.importDate')}
            value={formatTimestamp(shipment.created_at, {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              hour: undefined,
              minute: undefined,
            })}
            displayCopyToClipboardButton={false}
            dataTestId="shipment-importDate-card"
          />
          {shipment.shipment_name && (
            <Box marginTop="24px !important">
              <DataValue
                label={t('detail.summary.information.shipmentName')}
                value={shipment.shipment_name}
                displayCopyToClipboardButton={false}
                dataTestId="shipment-shipmentName-card"
              />
            </Box>
          )}
          {shipment.product?.lp_ui && (
            <Box marginTop="24px !important">
              <DataValue
                label={t('detail.summary.information.lpUi')}
                value={formatLpui(shipment.product.lp_ui)}
                displayCopyToClipboardButton={true}
                dataTestId="shipment-lpui-card"
              />
            </Box>
          )}
          {shipment.contract_state !== 'DRAFT' && (
            <>
              <Box marginTop="24px !important">
                <DataValue
                  label={t('detail.summary.information.providedWeight')}
                  dataTestId="providedWeight"
                  value={
                    shipment.weight_in_grams
                      ? toKg(shipment.weight_in_grams)
                      : '--'
                  }
                  icon={<ProvidedWeightTooltip />}
                />
              </Box>
            </>
          )}
        </Stack>
      </DetailsCard>
    </>
  );
};

export default ShipmentIdData;
