// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Text,
  ButtonV2 as Button,
  Box,
  styled,
} from '@livingpackets/design-system-react-next';
import TextSkeleton from 'components/atoms/loadingSkeletons/TextSkeleton';
import {
  IconCard,
  Info,
  InfoBar,
  StyledDivider,
  Wrapper,
  WrapperAddress,
  WrapperText,
} from 'components/molecules/ShipmentSumUpCard';
import { useTranslation } from 'react-i18next';

const StyledTextSkeleton = styled(TextSkeleton)`
  border-radius: 3px;
  height: 0.75rem;
  width: ${({ width }) => (width ? width : '10.125rem')};
  margin-bottom: 0.5rem;
`;

const ShipmentSumUpSkeleton = ({
  onClickAddReturn,
}: {
  onClickAddReturn: () => void;
}) => {
  const { t } = useTranslation('shipments');

  return (
    <Wrapper>
      <Text variant="titleXS" color="custom.neutral.black.50" mb={2}>
        {t('returnLabel')}
      </Text>
      <WrapperAddress>
        <WrapperAddress>
          <IconCard />
          <WrapperText>
            <StyledTextSkeleton width="8.5rem" />
            <StyledTextSkeleton />
            <StyledTextSkeleton />
            <StyledTextSkeleton />
          </WrapperText>
        </WrapperAddress>

        <WrapperAddress>
          <IconCard />
          <WrapperText>
            <StyledTextSkeleton width="8.5rem" />
            <StyledTextSkeleton />
            <StyledTextSkeleton />
            <StyledTextSkeleton />
          </WrapperText>
        </WrapperAddress>
        <Box marginTop="-1rem">
          <Button
            variant="tertiary"
            onClick={onClickAddReturn}
            sx={{ maxWidth: '200px' }}
            data-testid="shipmentSumUp-add-return"
          >
            {t('addReturnLabel')}
          </Button>
        </Box>
      </WrapperAddress>
      <StyledDivider direction="horizontal" />
      <InfoBar>
        <Info>
          <StyledTextSkeleton width="2.625rem" />
          <StyledTextSkeleton width="4.125rem" />
        </Info>
        <Info>
          <StyledTextSkeleton width="2.625rem" />
          <StyledTextSkeleton width="4.125rem" />
        </Info>
        <Info>
          <StyledTextSkeleton width="2.625rem" />
          <StyledTextSkeleton width="4.125rem" />
        </Info>
        <Info>
          <StyledTextSkeleton width="2.625rem" />
          <StyledTextSkeleton width="4.125rem" />
        </Info>
      </InfoBar>
    </Wrapper>
  );
};

export default ShipmentSumUpSkeleton;
