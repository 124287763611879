// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Text, Stack, styled } from '@livingpackets/design-system-react-next';
import { Carriers } from 'configs/carriers';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WrapperTile = styled('div')<{
  img: string;
  selected: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 14px;
  outline-offset: -2px;
  outline: ${({ theme, selected }) =>
    `solid 2px ${
      selected ? theme.palette.custom.primary[100] : 'transparent'
    }`};
  background-image: ${({ img }) => img};
  background-size: cover;
  background-repeat: no-repeat;
  width: 11.625rem;
  height: 7rem;
  padding: 0.5rem;
  cursor: pointer;
  position: relative;
  -webkit-transition: outline 0.1s 0s ease-in;
  -moz-transition: outline 0.1s 0s ease-in;
  -o-transition: outline 0.1s 0s ease-in;
  transition: outline 0.1s 0s ease-in;

  &:hover {
    outline: ${({ theme }) => `solid 2px ${theme.palette.custom.primary[100]}`};
  }
`;

const Description = styled('div')<{ textColor: string }>`
  position: absolute;
  color: ${props => props.textColor};
  bottom: -0.2rem;
  width: 100%;
  text-align: center;
  padding-left: 1rem;
`;

const CarrierTile = ({
  carrier,
  carrierLabel,
  onClick,
  selected = false,
  logoPath,
  description = '',
  textColor = null,
}: {
  carrier: Carriers;
  carrierLabel: string;
  onClick: (carrier: Carriers) => void;
  selected: boolean;
  logoPath: string;
  description?: string | null;
  textColor?: string | null;
}) => (
  <Wrapper>
    <WrapperTile
      data-testid={carrier}
      selected={selected}
      img={logoPath}
      onClick={() => onClick(carrier)}
    >
      {description && (
        <Description textColor={textColor as string}>
          <Text my={4} variant="titleXS">
            {description}
          </Text>
        </Description>
      )}
    </WrapperTile>
    <Stack direction="row" alignItems="center" marginTop="9px" spacing={1}>
      <Text variant="titleXS">{carrierLabel}</Text>
    </Stack>
  </Wrapper>
);

export default CarrierTile;
