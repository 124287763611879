// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect } from 'react';

import {
  Grid,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import { PhoneNumber } from 'components/form/phoneNumber';
import useFormTemplate from 'hooks/useFormTemplate';
import { set } from 'lodash';
import { IContact, IContactInformationForm } from 'models/contactAddress';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { contactBasicInformationSchema } from 'schemas/contactAddressSchema';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const inputConfig = {
  shouldValidate: true,
  shouldDirty: true,
  shouldTouch: true,
};

interface IContactInformationType {
  defaultValues?: IContact;
  onValuesChanged: (values: IContactInformationForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  triggerResetForm?: boolean;
}

const ContactForm = ({
  defaultValues,
  onValuesChanged,
  isFormValidChanged,
  triggerResetForm,
}: IContactInformationType) => {
  const { t } = useTranslation(['contactAddress', 'profileSettings']);

  const { smsEnabled } = useMyPartnersStore(activePartnerSelector);

  const {
    formState: { touchedFields, isValid },
    getValues,
    setValue,
    reset,
    control,
  } = useFormTemplate<IContactInformationForm>({
    resolver: contactBasicInformationSchema({ smsEnabled }),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    },
  });

  useEffect(() => {
    if (defaultValues) {
      setValue('firstName', defaultValues.firstName, inputConfig);
      setValue('lastName', defaultValues.lastName, inputConfig);
      setValue('email', defaultValues.email, inputConfig);
      setValue('phoneNumber', defaultValues.phoneNumber, inputConfig);

      // Manually set the fields as touched
      set(touchedFields, `firstName`, true);
      set(touchedFields, `lastName`, true);
      set(touchedFields, `email`, true);
      set(touchedFields, `phoneNumber`, true);

      onValuesChanged(getValues() as IContactInformationForm);
    }
  }, [defaultValues, onValuesChanged, touchedFields, setValue, getValues]);

  useEffect(() => {
    isFormValidChanged(isValid);
  }, [isValid, isFormValidChanged]);

  useEffect(() => {
    if (triggerResetForm) {
      reset({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      });
    }
  }, [triggerResetForm, reset]);

  const handleInputChange = (
    event?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onValuesChanged(getValues() as IContactInformationForm);
  };

  return (
    <Grid container component="form" columnSpacing={2}>
      <Grid item mobile={12} tablet={6} mb="1.25rem">
        <Controller
          name="firstName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('contactAddress:form.firstName.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} mb="1.25rem">
        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('contactAddress:form.lastName.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} mb="1.25rem">
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('contactAddress:form.email.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} mb="1.25rem">
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field, fieldState }) => (
            <PhoneNumber
              label={t('contactAddress:form.phoneNumber.placeholder')}
              defaultCountryCode="FR"
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={value => {
                field.onChange(value);
                handleInputChange();
              }}
              ref={field.ref}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ContactForm;
