// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export type Action<K> =
  | {
      type: 'updateRowData';
      args: {
        rowData: K[];
      };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { offset: number; pageSize: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: keyof K;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateActiveId';
      args: { activeId: string | undefined };
    }
  | {
      type: 'removeEntry';
      args: { id: string };
    }
  | {
      type: 'removePartnerMemberEntry';
      args: { accountId: string };
    }
  | {
      type: 'searchByTerm';
      args: { searchTerm: string | undefined };
    }
  | {
      type: 'reset';
    };

export const reducer = <K>(
  state: IPaginatedStore<K>,
  action: Action<K>,
  INITIAL_STATE: any
) => {
  switch (action.type) {
    case 'updateRowData':
      return { ...state, rowData: action.args.rowData };
    case 'updateError':
      return { ...state, error: action.args.error };
    case 'updateLoading':
      return { ...state, loading: action.args.loading };
    case 'updatePagination':
      return {
        ...state,
        offset: action.args.offset,
        pageSize: action.args.pageSize,
      };
    case 'updateSorting':
      return { ...state, sortBy: action.args.sortBy, order: action.args.order };
    case 'updateCount':
      return { ...state, count: action.args.count };
    case 'updateTotal':
      return { ...state, total: action.args.total };
    case 'updateActiveId':
      return { ...state, activeId: action.args.activeId };
    case 'removeEntry':
      const filteredRowData = state.rowData?.filter(
        (entry: any) => entry.id !== action.args.id
      );

      return { ...state, rowData: filteredRowData };
    case 'removePartnerMemberEntry':
      return {
        ...state,
        rowData: state.rowData?.filter(
          (entry: any) => entry.accountId !== action.args.accountId
        ),
      };
    case 'searchByTerm':
      return {
        ...state,
        // Reset pagination when searching
        offset: 0,
        searchTerm: action.args.searchTerm,
      };
    case 'reset':
      return INITIAL_STATE;
  }
};

export interface IPaginatedStore<K> {
  rowData: K[];
  pageSize: number;
  offset: number;
  sortBy: keyof K;
  order: 'ASC' | 'DESC';
  error: string | boolean;
  loading: boolean;
  count: number;
  dispatch: (args: Action<K>) => void;
  total: number;
  activeId?: string;
  searchTerm?: string;
}

export const getInitialState = <K>(
  params: { sortBy: keyof K; order: 'ASC' | 'DESC' } & Partial<
    Omit<IPaginatedStore<K>, 'dispatch'>
  >
) => ({
  rowData: [],
  error: '',
  loading: false,
  pageSize: 10,
  offset: 0,
  count: 0,
  total: 0,
  ...params,
});

export const storeSelector = <K>(state: IPaginatedStore<K>) => ({
  rowData: state.rowData,
  pageSize: state.pageSize,
  offset: state.offset,
  sortBy: state.sortBy,
  order: state.order,
  error: state.error,
  loading: state.loading,
  count: state.count,
  dispatch: state.dispatch,
  total: state.total,
  activeId: state.activeId,
  searchTerm: state.searchTerm,
});

export const dispatchSelector = <K>(state: IPaginatedStore<K>) =>
  state.dispatch;
