// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { IShipment } from 'models/shipment';

import {
  GetUserShipmentsParams,
  useGetUserShipments,
} from './useGetUserShipments';
import {
  Paginated,
  UseFetchResource,
  useFetchResource,
  UseFetchResourceOptions,
} from '../shared-components';

export function useUserShipments(
  params: GetUserShipmentsParams,
  { enabled = true }: UseFetchResourceOptions = {}
): UseFetchResource<Paginated<IShipment>> {
  const getUserShipments = useGetUserShipments();

  const fetchFunction = useCallback(
    () => getUserShipments(params),
    [getUserShipments, params]
  );

  return useFetchResource(fetchFunction, { enabled });
}
