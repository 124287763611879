// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Select, SelectProps } from '@livingpackets/design-system-react-next';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type RHFSelectCountryProps = {
  name?: string;
  control: any;
} & Omit<SelectProps, 'options'>;

export const RHFSelectCountry = ({
  name = 'countryCode',
  control,
  ...rest
}: RHFSelectCountryProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => {
      const { ref, ...restField } = field;

      return (
        <SelectCountry
          {...rest}
          {...restField}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
        />
      );
    }}
  />
);

const SelectCountry = ({ label, ...rest }: Omit<SelectProps, 'options'>) => {
  const { t } = useTranslation(['forms', 'account']);
  const [countryCodes] = useCountryCodesWrapper();

  if (countryCodes.loading) {
    return <></>;
  }

  return (
    <Select
      label={label || t('forms:country.label')}
      {...rest}
      options={countryCodes.data?.map((country: any) => ({
        label: country.name,
        value: country.alpha2Code,
      }))}
    />
  );
};
