// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Grid, Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import { InsuranceCard } from './InsuranceCard';
import { OfferSensorCard } from './OfferSensorCard';

export const RightPart = () => {
  const { t } = useTranslation('partnerSettings');

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container direction="column" spacing="1.5rem">
          <Grid item mobile={12}>
            <Grid container direction="column" gap=".75rem">
              <Text variant="titleS">{t('offer.sensor.title')}</Text>
              <OfferSensorCard />
            </Grid>
          </Grid>
          <Grid item mobile={12}>
            <Grid container direction="column" gap=".75rem">
              <Text variant="titleS">{t('offer.insurance.title')}</Text>
              <InsuranceCard />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
