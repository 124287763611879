// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { lightTheme } from '@livingpackets/design-system-react-next';
import { Carriers, ENABLED_CARRIERS } from 'configs/carriers';

import chronopostTile from '../assets/chronopost-tile.png';
import chronopostLogo from '../assets/chronopost.png';
import colissimoTile from '../assets/colissimo-tile.png';
import colissimoLogo from '../assets/colissimo.png';
import dhlTile from '../assets/dhl-tile.png';
import dhlLogo from '../assets/dhl.png';
import fedexTile from '../assets/fedex-tile.png';
import fedexLogo from '../assets/fedex.png';
import miistTile from '../assets/miist-tile.png';
import miistLogo from '../assets/miist.png';
import otherCarrierLogoBig from '../assets/other-carrier-logo-big.png';
import otherCarrierLogo from '../assets/other-carrier-logo.png';
import tntTile from '../assets/tnt-tile.png';
import tntLogo from '../assets/tnt.png';
import upsTile from '../assets/ups-tile.png';
import upsLogo from '../assets/ups.png';

export interface CarrierInfo {
  logoPath: string;
  logo: string;
  code: Carriers;
  textColor: string;
}

// Used in shipments creation
const CARRIER_CONFIGURATIONS: Record<Carriers, CarrierInfo> = {
  [Carriers.ups]: {
    logoPath: `url(${upsTile})`,
    logo: upsLogo,
    code: Carriers.ups,
    textColor: lightTheme.palette.custom.neutral.white.pure,
  },
  [Carriers.fedex]: {
    logoPath: `url(${fedexTile})`,
    logo: fedexLogo,
    code: Carriers.fedex,
    textColor: lightTheme.palette.custom.neutral.black[100],
  },
  [Carriers.tnt]: {
    logoPath: `url(${tntTile})`,
    logo: tntLogo,
    code: Carriers.tnt,
    textColor: lightTheme.palette.custom.neutral.white.pure,
  },
  [Carriers.chronopost]: {
    logoPath: `url(${chronopostTile})`,
    logo: chronopostLogo,
    code: Carriers.chronopost,
    textColor: lightTheme.palette.custom.neutral.black[100],
  },
  [Carriers.colissimo]: {
    logoPath: `url(${colissimoTile})`,
    logo: colissimoLogo,
    code: Carriers.colissimo,
    textColor: lightTheme.palette.custom.neutral.white.pure,
  },
  [Carriers.dhl]: {
    logoPath: `url(${dhlTile})`,
    logo: dhlLogo,
    code: Carriers.dhl,
    textColor: lightTheme.palette.custom.neutral.black[100],
  },
  [Carriers.miist]: {
    logoPath: `url(${miistTile})`,
    logo: miistLogo,
    code: Carriers.miist,
    textColor: lightTheme.palette.custom.neutral.white.pure,
  },
  [Carriers.other]: {
    logoPath: `url(${otherCarrierLogoBig})`,
    logo: otherCarrierLogo,
    code: Carriers.other,
    textColor: lightTheme.palette.custom.neutral.white.pure,
  },
};

const CARRIERS_LIST = ENABLED_CARRIERS.map(
  carrierCode => CARRIER_CONFIGURATIONS[carrierCode]
);

export default CARRIERS_LIST;
