// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  GeolocationIcon,
  Grid,
  HumidityIcon,
  ShockIcon,
  Stack,
  TemperatureIcon,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import boxShowcaseImg from 'assets/img/shipment/product/box-showcase.png';
import boxShowcaseImg2x from 'assets/img/shipment/product/box-showcase@2x.png';
import boxShowcaseImg3x from 'assets/img/shipment/product/box-showcase@3x.png';
import { useTranslation } from 'react-i18next';

import { useOffer } from '../../../api/offer/getOffer';

const SensorRow = ({ icon: Icon, title, value, isEnabled = true }: any) => (
  <tr style={{ opacity: isEnabled ? '1' : '0.5' }}>
    <td>
      <Stack direction="row" spacing="0.5rem" alignItems="center">
        <Icon />
        <Text variant="titleS">{title}</Text>
      </Stack>
    </td>
    <td>
      <Text variant="bodyTextXS">{value}</Text>
    </td>
  </tr>
);

const Root = styled(Grid)`
  padding: 2rem;
  padding-left: 6.9619rem;
  border-radius: 1.25rem;
  background-repeat: no-repeat;
  background-size: 6.2119rem 9.25rem;
  background-position: left center;
  background-image: url(${boxShowcaseImg});
  background-image: -webkit-image-set(
    url(${boxShowcaseImg2x}) 1x,
    url(${boxShowcaseImg3x}) 2x
  );
`;

const StyledTable = styled('table')({
  width: 'fit-content',
  '& tr': {
    '& td': {
      verticalAlign: 'middle',
      paddingBottom: '1rem',
    },
    '& td:last-child': {
      paddingLeft: '1.8125rem',
    },
  },
  '& tr:last-child': {
    '& td': {
      paddingBottom: 0,
    },
  },
});

export const OfferSensorCard = () => {
  const { t } = useTranslation('partnerSettings');

  const { data: offer } = useOffer();

  const getTrackingFrequencyText = (trackingFrequency: number) => {
    if (trackingFrequency > 60) {
      const trackingFrequencyInHours = Math.round(trackingFrequency / 60);

      if (trackingFrequencyInHours === 1) {
        return t('offer.sensor.info.trackingFrequency.info.hours.singular');
      }

      return t('offer.sensor.info.trackingFrequency.info.hours.plural', {
        hourNumber: trackingFrequencyInHours,
      });
    }

    if (trackingFrequency === 1) {
      return t('offer.sensor.info.trackingFrequency.info.minutes.singular');
    }

    return t('offer.sensor.info.trackingFrequency.info.minutes.plural', {
      minuteNumber: trackingFrequency,
    });
  };

  return (
    <Root container direction="column" bgcolor="custom.neutral.black.2">
      <StyledTable>
        <SensorRow
          icon={GeolocationIcon}
          title={t('offer.sensor.info.trackingFrequency.title')}
          value={getTrackingFrequencyText(
            offer!.paas_configuration.product_communication_frequency
          )}
        />
        <SensorRow
          icon={ShockIcon}
          title={t('offer.sensor.info.shocks.title')}
          value={t('offer.sensor.info.shocks.info', {
            threshold: offer!.sensor_configuration.shock_threshold.height,
          })}
          isEnabled={
            offer!.paas_configuration.product_sensors.shock.is_activated
          }
        />
        <SensorRow
          icon={TemperatureIcon}
          title={t('offer.sensor.info.temperature.title')}
          value={t('offer.sensor.info.temperature.info', {
            min: offer!.sensor_configuration.temperature_threshold.celsius_min,
            max: offer!.sensor_configuration.temperature_threshold.celsius_max,
          })}
          isEnabled={
            offer!.paas_configuration.product_sensors.temperature.is_activated
          }
        />
        <SensorRow
          icon={HumidityIcon}
          title={t('offer.sensor.info.humidity.title')}
          value={t('offer.sensor.info.humidity.info', {
            min: offer!.sensor_configuration.humidity_threshold.percent_min,
            max: offer!.sensor_configuration.humidity_threshold.percent_max,
          })}
          isEnabled={
            offer!.paas_configuration.product_sensors.humidity.is_activated
          }
        />
      </StyledTable>
    </Root>
  );
};
