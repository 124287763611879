// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useMemo } from 'react';

import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
  Box,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import TextSkeleton from 'components/atoms/loadingSkeletons/TextSkeleton';
import {
  Header,
  StyledTable,
  TableDesignTypeEnum,
  TableRow,
  TableWrapper,
  Wrapper,
} from 'components/atoms/TableComponents';
import TablePagination from 'components/molecules/TablePagination';
import pick from 'lodash/pick';
import {
  Column,
  HeaderGroup,
  usePagination,
  useSortBy,
  UseSortByColumnProps,
  useTable,
  Row,
  useExpanded,
} from 'react-table';

export type TPagination = {
  offset: number;
  pageSize: number;
  total: number;
};

export type TColumn<T extends {}> = Column<T> & {
  sortable?: boolean;
  headerEmphasis?: boolean;
  cellEmphasis?: 'high' | 'low';
  Header: any;
  hasClickable?: boolean;
  skeleton?: React.ReactNode;
  dataTestId?: string;
  displayHasText?: boolean;
};

const defaultColumn = {
  width: '100%',
  displayHasText: true,
};

export interface ITableHeaderCard {
  recordName: string;
  statusLabel?: React.ReactNode;
  showCounter?: boolean;
  showPageSizeChanger?: boolean;
}

type TReactTableTable<T extends object> = {
  columns: Array<TColumn<T>>;
  data: Array<any>;
  activeRowId?: string;
  pagination?: TPagination;
  onPaginationChange?: (input: { offset: number; pageSize: number }) => void;
  onSortingChange?: (input: { sortBy: string; order: 'ASC' | 'DESC' }) => void;
  loading?: boolean;
  sorting?: {
    sortBy: string;
    order: 'ASC' | 'DESC';
  };
  style?: any;
  onRowClick?: (row: T) => any;
  maxHeight?: string;
  dataTestId?: string;
  searchTerm?: string;
  tableDesignType?: TableDesignTypeEnum;
  rowHeight?: string;
  hiddenColumns?: Array<string>;
  renderSubComponent?: (props: { row: Row<Array<any>> }) => React.ReactElement;
  getRowCanExpand?: (row: Row<Array<any>>) => boolean;
  renderRowSubComponent?: any;
};

const parseColumns = <T extends {}>(columns: TColumn<T>[]) =>
  columns.map(column =>
    column.sortable ? column : { ...column, disableSortBy: true }
  );

const getSkeletonOpacity = (index: number) => Math.exp(-index / 2);

const StyledWrapper = styled(TableWrapper)`
  padding-right: 0.5rem;
  min-height: 7rem;
  overflow-y: hidden;
  overflow-x: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: ${({ theme }) => theme.palette.custom.neutral.black[10]};
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-track-piece {
    border-radius: 2px;
    background: ${({ theme }) => theme.palette.custom.neutral.black[4]};
  }

  ::-webkit-scrollbar-button {
    height: 1.5rem;
    width: 2rem;
  }
`;

const StyledTdExpanded = styled('td')`
  position: relative;
  top: -8px;
`;

function ReactTableTable<T extends object>({
  columns,
  data,
  activeRowId,
  pagination,
  onPaginationChange,
  onSortingChange,
  sorting,
  style = {},
  onRowClick,
  maxHeight = 'auto',
  loading,
  dataTestId,
  tableDesignType = TableDesignTypeEnum.default,
  rowHeight,
  hiddenColumns = [],
  renderRowSubComponent,
}: TReactTableTable<T>) {
  const theme = useTheme();

  const tableData = useMemo(
    () => (loading ? Array(3).fill({}) : data),
    [loading, data]
  );

  const tableColumns = useMemo(
    () =>
      loading
        ? columns.map((column, index) => ({
            ...pick(column, ['Header', 'width', 'headerEmphasis']),
            id: index.toString(),
            Cell: ({ row }: { row: Row<T> }) => (
              <div
                key={index}
                style={{ opacity: getSkeletonOpacity(row.index) }}
              >
                {column.skeleton || <TextSkeleton />}
              </div>
            ),
            disableSortBy: true,
          }))
        : parseColumns(columns),
    [loading, columns]
  );

  const paginationSettings = useMemo(
    () =>
      !!pagination
        ? {
            manualPagination: true,
            pageCount: Math.ceil(pagination.total / pagination.pageSize),
            initialState: {
              pageSize: pagination.pageSize,
              pageIndex: pagination.offset / pagination.pageSize,
              hiddenColumns: hiddenColumns,
            },
          }
        : {
            initialState: {
              hiddenColumns: hiddenColumns,
            },
          },
    [pagination, hiddenColumns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    previousPage,
    nextPage,
    setSortBy,
    gotoPage,
    visibleColumns,
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      defaultColumn: defaultColumn,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortRemove: true,
      ...paginationSettings,
    } as any,
    useSortBy,
    useExpanded,
    usePagination
  ) as any;

  const dataSource = pagination ? page : rows;

  useEffect(() => {
    if (!sorting) {
      return;
    }
    setSortBy([{ id: sorting.sortBy, desc: sorting.order === 'DESC' }]);
  }, [sorting, setSortBy]);

  const handleSorting = useCallback(
    (column: TColumn<T> & UseSortByColumnProps<any>) => {
      if (!onSortingChange || !column.sortable || !column.id) {
        return;
      }
      onSortingChange({
        sortBy: column.id,
        order: column.isSortedDesc ? 'ASC' : 'DESC',
      });
    },
    [onSortingChange]
  );

  return (
    <Wrapper style={style} tableDesignType={tableDesignType}>
      <StyledWrapper maxHeight={maxHeight} data-testid={dataTestId}>
        <StyledTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup: HeaderGroup, index: number) => {
              const { key, ...props } = headerGroup.getHeaderGroupProps();

              return (
                <tr key={index} {...props}>
                  {headerGroup.headers.map((column: any, index: number) => {
                    const { key, ...props } = column.getHeaderProps(
                      column.getSortByToggleProps()
                    );

                    return (
                      <Header
                        key={index}
                        {...props}
                        headerEmphasis={column.headerEmphasis}
                        canSort={column.canSort && pagination?.total !== 0}
                        onClick={() => {
                          if (pagination?.total !== 0) {
                            handleSorting(column);
                          }
                        }}
                        style={{
                          width: column.width,
                        }}
                        data-testid={
                          column.dataTestId
                            ? column.dataTestId
                            : 'reactTable-header-' + column.id
                        }
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {column.displayHasText === true ? (
                            <Box
                              component="div"
                              sx={{
                                fontFamily: 'TT-Norms-Pro',
                                fontWeight: 500,
                                fontSize: '.75rem',
                                lineHeight: '.875rem',
                              }}
                            >
                              {column.render('Header')}
                            </Box>
                          ) : (
                            column.render('Header')
                          )}
                          {column.canSort &&
                            (column.isSorted ? (
                              column.isSortedDesc ? (
                                <Box display="inline-flex" marginLeft={1}>
                                  <ChevronDownIcon
                                    size="24px"
                                    color={theme.palette.custom.primary[100]}
                                  />
                                </Box>
                              ) : (
                                <Box display="inline-flex" marginLeft={1}>
                                  <ChevronUpIcon
                                    size="24px"
                                    color={theme.palette.custom.primary[100]}
                                  />
                                </Box>
                              )
                            ) : (
                              <Box display="inline-flex" marginLeft={1}>
                                <ChevronUpDownIcon
                                  size="24px"
                                  color={theme.palette.custom.primary[100]}
                                />
                              </Box>
                            ))}
                        </Box>
                      </Header>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {dataSource.map((row: any, index: number) => {
              prepareRow(row);

              return (
                <React.Fragment key={index}>
                  <TableRow
                    row={row}
                    activeRowId={activeRowId}
                    onRowClick={onRowClick}
                    tableDesignType={tableDesignType}
                    rowHeight={rowHeight}
                    dataTestId={`shipment-row-${index}`}
                    isExpanded={row.isExpanded}
                  />
                  {row.isExpanded && (
                    <tr>
                      <StyledTdExpanded colSpan={visibleColumns.length}>
                        {renderRowSubComponent({ row })}
                      </StyledTdExpanded>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </StyledTable>
      </StyledWrapper>
      {pagination && page.length > 0 && (
        <TablePagination
          currentPage={pageIndex}
          pageOptions={pageOptions}
          gotoPage={(n: number) => {
            gotoPage(n);
            onPaginationChange &&
              onPaginationChange({
                offset: n * pagination.pageSize,
                pageSize: pagination.pageSize,
              });
          }}
          nextPage={() => {
            onPaginationChange &&
              onPaginationChange({
                offset: pagination.offset + pagination.pageSize,
                pageSize: pagination.pageSize,
              });
            nextPage();
          }}
          previousPage={() => {
            onPaginationChange &&
              onPaginationChange({
                offset: pagination.offset - pagination.pageSize,
                pageSize: pagination.pageSize,
              });
            previousPage();
          }}
        />
      )}
    </Wrapper>
  );
}

export default ReactTableTable;
