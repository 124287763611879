// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import ImportShipment from 'components/organisms/ImportShipment';
import PartnerShipmentsDetail from 'components/views/lpVillage/PartnerShipmentsDetail';
import { ROUTE_PATHS } from 'configs';
import { shipmentDeployReturnRoutes } from 'features/deploy-return';
import { shipmentRemoteControlRoutes } from 'features/remoteControl';
import { useRoutes } from 'react-router-dom';

import PartnerShipmentsOverview from './PartnerShipmentsOverview';

const PartnerShipments = () => {
  const element = useRoutes([
    //  SHIPMENT OVERVIEW
    {
      path: '/',
      element: <PartnerShipmentsOverview />,
      children: [],
    },
    {
      // CREATE SHIPMENT
      path: `/${ROUTE_PATHS.PARTNER.CHILDREN.SHIPMENTS.CHILDREN.LABELS.ROOT}`,
      children: [
        {
          path: ROUTE_PATHS.PARTNER.CHILDREN.SHIPMENTS.CHILDREN.LABELS.CHILDREN
            .IMPORT.ROOT,
          element: <ImportShipment />,
        },
      ],
    },
    {
      // SHIPMENT
      path: ROUTE_PATHS.PARTNER.CHILDREN.SHIPMENTS.CHILDREN.ID.ROOT,
      children: [
        // SHIPMENT DETAIL
        {
          path: ROUTE_PATHS.PARTNER.CHILDREN.SHIPMENTS.CHILDREN.ID.CHILDREN
            .DETAIL.ROOT,
          element: <PartnerShipmentsDetail />,
        },
        // SHIPMENT DETAIL ALERT
        {
          path: ROUTE_PATHS.PARTNER.CHILDREN.SHIPMENTS.CHILDREN.ID.CHILDREN
            .DETAIL_WITH_ALERT.ROOT,
          children: [
            {
              // SHIPMENT DETAIL ALERT DETAILS
              path: ROUTE_PATHS.PARTNER.CHILDREN.SHIPMENTS.CHILDREN.ID.CHILDREN
                .DETAIL_WITH_ALERT.CHILDREN.ID.ROOT,
              element: <PartnerShipmentsDetail />,
            },
          ],
        },
        //REMOTE
        ...shipmentRemoteControlRoutes,
        ...shipmentDeployReturnRoutes,
      ],
    },
  ]);

  return element;
};

export default PartnerShipments;
