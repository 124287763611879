// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import { REACT_APP_KOALENDAR_IFRAME_INFO } from 'configs';
import useActiveLanguage from 'hooks/useGetActiveLanguage';
import { KoalendarIframeInfo } from 'models/koalendarIframeInfo';

import Modal, { IModalWrapperType } from '../../atoms/Modal';

interface IViewKoalendarContactRequestModal {
  visible: boolean;
  onDismiss: () => void;
}

const ViewKoalendarContactRequestModal = ({
  visible,
  onDismiss,
}: IViewKoalendarContactRequestModal) => {
  const activeLanguage = useActiveLanguage();

  const getKoalendarIframeUrl = useCallback((): string | undefined => {
    const koalendarIframeInfoList =
      REACT_APP_KOALENDAR_IFRAME_INFO as KoalendarIframeInfo[];

    const koalendarIframeInfoDefault = koalendarIframeInfoList.find(
      (koalendarIframeInfo: KoalendarIframeInfo) =>
        koalendarIframeInfo.locale === 'en'
    );

    const koalendarIframeInfo = koalendarIframeInfoList.find(
      (koalendarIframeInfo: KoalendarIframeInfo) =>
        koalendarIframeInfo.locale === activeLanguage
    );

    return koalendarIframeInfo
      ? koalendarIframeInfo.url
      : koalendarIframeInfoDefault?.url;
  }, [activeLanguage]);

  return (
    <Modal
      displayCloseButton
      open={visible}
      handleClose={onDismiss}
      wrapperType={IModalWrapperType.koalendarContactRequest}
    >
      {getKoalendarIframeUrl() && (
        <iframe
          id="koalendarContactRequestIframe"
          title="Koalendar Contact Request Form"
          src={getKoalendarIframeUrl()}
          width="100%"
          height="700px"
        />
      )}
    </Modal>
  );
};

export default ViewKoalendarContactRequestModal;
