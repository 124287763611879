// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

interface ImportShipmentPdfSpecificPageSelectedManuallyCroppingActionType {
  isLabelPdfMultiPage: boolean;
  handleChangePage: () => void;
  handlePdfPageSelectedCropped: () => void;
  isButtonActionDisabled: boolean;
}

const ImportShipmentPdfSpecificPageSelectedManuallyCroppingAction = ({
  isLabelPdfMultiPage,
  handleChangePage,
  handlePdfPageSelectedCropped,
  isButtonActionDisabled,
}: ImportShipmentPdfSpecificPageSelectedManuallyCroppingActionType) => {
  const { t } = useTranslation('shipments');

  return (
    <Box
      display="flex"
      justifyContent={isLabelPdfMultiPage ? 'space-between' : 'flex-end'}
      width="100%"
    >
      {isLabelPdfMultiPage && (
        <Button variant="secondary" onClick={handleChangePage}>
          {t('changePage')}
        </Button>
      )}
      <Button
        onClick={handlePdfPageSelectedCropped}
        disabled={isButtonActionDisabled}
      >
        {t('cropLabel')}
      </Button>
    </Box>
  );
};

export default ImportShipmentPdfSpecificPageSelectedManuallyCroppingAction;
