// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Text, styled, Stack } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import livingPacketsLogo from '../../../assets/img/authentication/lp-logo.png';
import livingPacketsLogo2x from '../../../assets/img/authentication/lp-logo@2x.png';
import livingPacketsLogo3x from '../../../assets/img/authentication/lp-logo@3x.png';

const ContributionRewardInProgressContainer = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 20px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  padding: 12px 14px;
`;

const ContributionRewardInProgress = () => {
  const { t } = useTranslation('contribution');

  return (
    <ContributionRewardInProgressContainer>
      <Stack direction="row" alignItems="center">
        <img
          alt="The Box by LivingPackets"
          width="48px"
          height="48px"
          src={livingPacketsLogo}
          srcSet={`${livingPacketsLogo2x} 2x, ${livingPacketsLogo3x} 3x`}
        />
        <Stack paddingLeft="24px">
          <Text variant="titleXS" color="custom.neutral.black.90">
            {t('contributionsTab.contributionsList.detail.inProgress.title')}
          </Text>
          <Text variant="bodyTextS" color="custom.neutral.black.90">
            {t('contributionsTab.contributionsList.detail.inProgress.text')}
          </Text>
        </Stack>
      </Stack>
    </ContributionRewardInProgressContainer>
  );
};

export default ContributionRewardInProgress;
