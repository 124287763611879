// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useState } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from '@livingpackets/design-system-react';
import {
  ArrowLeftIcon,
  ButtonV2 as Button,
  Stack,
  styled,
  Text,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import { usePartnership, useUpdatePartnership } from 'features/account';
import { isEmpty } from 'lodash/fp';
import {
  IContactForm,
  INITIAL_STATE_CONTACT_ADDRESS_COMPLETE_FORM,
} from 'models/contactAddress';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import useContactAddress from '../../../hooks/useContactAddress';
import useMyPartnersStore, {
  activePartnerSelector,
} from '../../../stores/useMyPartnersStore';
import ContactAddressCreateForm from '../Form/ContactAddressCreateForm';
import { NEW_DEFAULT_ADDRESS } from '../Form/DefaultAddresses';

// Create react context
export const DefaultAddressesContext = React.createContext<any>(null);

const CardContact = styled(Card)`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    width: auto;
  }
`;

const PartnerContactAddressAdd = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['general', 'contactAddress', 'messages']);

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const {
    loading,
    addContact,
    addAddressToContact,
    getContactAddressPostFromContactAddressFormData,
  } = useContactAddress(activePartner.id);

  const { data: partnership } = usePartnership();
  const { mutateAsync: updatePartnership } = useUpdatePartnership({
    showSuccessToast: false,
  });

  const [isContactFormValid, setIsContactFormValid] = useState<boolean>(false);
  const [isDismissTriggered, setIsDismissTriggered] = useState<boolean>(false);

  const [contactData, setContactData] = useState<IContactForm>(
    INITIAL_STATE_CONTACT_ADDRESS_COMPLETE_FORM
  );

  const [defaultSenderAddress, setDefaultSenderAddress] = useState<
    string | undefined
  >();
  const [defaultRecipientAddress, setDefaultRecipientAddress] = useState<
    string | undefined
  >();

  const updatePartnershipDefaultAddress = useCallback(
    async ({ addressId, prefix }: any) => {
      // Update default sender/recipient if any
      let newDefaultAddresses: any = {};
      if (defaultSenderAddress?.startsWith(`${NEW_DEFAULT_ADDRESS}${prefix}`))
        newDefaultAddresses['default_sender_address_id'] = addressId;
      if (
        defaultRecipientAddress?.startsWith(`${NEW_DEFAULT_ADDRESS}${prefix}`)
      )
        newDefaultAddresses['default_recipient_address_id'] = addressId;

      if (!isEmpty(newDefaultAddresses)) {
        try {
          await updatePartnership({
            values: newDefaultAddresses,
            partnershipId: partnership!.id,
          });
        } catch (_) {
          // TODO: Toast error ?
        }
      }
    },
    [
      partnership,
      updatePartnership,
      defaultRecipientAddress,
      defaultSenderAddress,
    ]
  );

  const onSubmit = useCallback(async () => {
    const { success: addContactSuccess, data } = await addContact(
      contactData.basicInformation
    );

    if (addContactSuccess) {
      const contactAddressFirst =
        getContactAddressPostFromContactAddressFormData(
          contactData.basicInformation,
          contactData.address
        );

      const { success: addFirstContactAddressFirstSuccess, data: newAddress } =
        await addAddressToContact(contactAddressFirst, data.id);

      if (addFirstContactAddressFirstSuccess) {
        // Save any default address
        await updatePartnershipDefaultAddress({
          addressId: newAddress.id,
          prefix: 'address.',
        });

        if (contactData.address2) {
          const contactAddressSecond =
            getContactAddressPostFromContactAddressFormData(
              contactData.basicInformation,
              contactData.address2
            );

          const { success: addContactAddressSecondSuccess, data: newAddress2 } =
            await addAddressToContact(contactAddressSecond, data.id);

          if (addContactAddressSecondSuccess) {
            await updatePartnershipDefaultAddress({
              addressId: newAddress2.id,
              prefix: 'address2.',
            });

            navigate(
              generatePath(PATHS.CONTACT_ADDRESS.LIST, {
                partnerId: activePartner.id,
              })
            );
          }
        } else {
          navigate(
            generatePath(PATHS.CONTACT_ADDRESS.LIST, {
              partnerId: activePartner.id,
            })
          );
        }
      } else {
        navigate(
          generatePath(PATHS.CONTACT_ADDRESS.LIST, {
            partnerId: activePartner.id,
          })
        );
      }
    } else {
      navigate(
        generatePath(PATHS.CONTACT_ADDRESS.LIST, {
          partnerId: activePartner.id,
        })
      );
    }
  }, [
    addContact,
    addAddressToContact,
    contactData,
    activePartner,
    navigate,
    getContactAddressPostFromContactAddressFormData,
    updatePartnershipDefaultAddress,
  ]);

  const backToContactList = useCallback(() => {
    navigate(
      generatePath(PATHS.CONTACT_ADDRESS.LIST, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  const handleOnContactValuesChanged = useCallback((values: IContactForm) => {
    setContactData(values);
    setIsDismissTriggered(false);
  }, []);

  const handleOnFormValidChanged = useCallback((isFormValid: boolean) => {
    setIsContactFormValid(isFormValid);
  }, []);

  const handleDismiss = useCallback(() => {
    if (isDismissTriggered) {
      backToContactList();
    } else {
      setIsDismissTriggered(true);
    }
  }, [isDismissTriggered, backToContactList]);

  return (
    <CardContact>
      <React.Fragment key=".0">
        <CardHeader backgroundColor="black.100" color="white" padding={5}>
          <Stack direction="row" alignItems="center" spacing=".75rem">
            <Button
              variant="tertiary"
              icon={ArrowLeftIcon}
              onClick={backToContactList}
            />
            <Text variant="bodyTextXS">
              {t('contactAddress:create.breadcrumb')}
            </Text>
          </Stack>
          <Text variant="titleXS" mt={6}>
            {t('contactAddress:create.title')}
          </Text>
          <Text variant="titleM">{t('contactAddress:create.subTitle')}</Text>
        </CardHeader>
        <CardBody paddingLeft="32px" paddingRight="32px" paddingTop="36px">
          <DefaultAddressesContext.Provider
            value={{
              setDefaultSenderAddress,
              setDefaultRecipientAddress,
            }}
          >
            <ContactAddressCreateForm
              onValuesChanged={handleOnContactValuesChanged}
              isFormValidChanged={handleOnFormValidChanged}
              isDismissTriggered={isDismissTriggered}
            />
          </DefaultAddressesContext.Provider>
        </CardBody>
        <CardFooter backgroundColor="transparent">
          <Stack direction="row" justifyContent="space-between" marginTop={3}>
            <Button
              data-testid="dismissBtn"
              variant="secondary"
              onClick={handleDismiss}
            >
              {t('contactAddress:form.dismiss.label')}
            </Button>
            <Button
              data-testid="submitBtn"
              variant="primary"
              disabled={!isContactFormValid || loading}
              onClick={onSubmit}
            >
              {t('contactAddress:form.submit.label')}
            </Button>
          </Stack>
        </CardFooter>
      </React.Fragment>
    </CardContact>
  );
};

export default PartnerContactAddressAdd;
