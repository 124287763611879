// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

/**
 * Address
 */
export interface Address {
  id: string;
  addressTypes: AddressTypesKeys[];
  building: string;
  city: string;
  company: string;
  countryCode: string;
  displayName: string;
  email: string;
  firstName: string;
  /**
   * @deprecated
   */
  houseNumber: string;
  lastName: string;
  phoneNumber: string;
  postalCode: string;
  state: string;
  street: string;
  street2: string;
  /**
   * @deprecated
   */
  type: AddressTypesKeys;
  position: {
    latitude: number;
    longitude: number;
  };
}

/**
 * Editable address
 */
export interface EditableAddress {
  addressTypes: AddressTypesKeys[];
  building: string;
  city: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
  postalCode: string;
  street: string;
  street2: string;
  // Not present if addressTypes contains MAIN
  company?: string;
}

export const EditableAddressKeys = [
  'addressTypes',
  'building',
  'city',
  'email',
  'countryCode',
  'phoneNumber',
  'postalCode',
  'street',
  'street2',
  'company',
];

/**
 * Miscellanous
 */

export const AddressTypes = {
  MAIN: 'MAIN',
  BILLING: 'BILLING',
  DELIVERY: 'DELIVERY',
  SENDER: 'SENDER',
  PROFESSIONAL: 'PROFESSIONAL',
  PERSONAL: 'PERSONAL',
} as const;

export type AddressTypesKeys = (typeof AddressTypes)[keyof typeof AddressTypes];

// Iniatial states
export const INITIAL_ADDRESS: EditableAddress = {
  addressTypes: [AddressTypes.MAIN],
  building: '',
  city: '',
  email: '',
  countryCode: '',
  phoneNumber: '',
  postalCode: '',
  street: '',
  street2: '',
};
