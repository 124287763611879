// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useEffect } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';

import useToastMessages from './useToastMessages';
import useSocialMediaArticlesStore, {
  setSmArticleSelector,
} from '../stores/useSocialMediaArticlesStore';

const useSocialMediaArticles = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpAccountV3');
  const setSmArticles = useSocialMediaArticlesStore(setSmArticleSelector);
  const { error: toastError } = useToastMessages();
  const getSmArticles = useCallback(async () => {
    const { data, error } = await makeRequest({
      path: '/me/sm_articles',
      method: 'get',
    });
    if (error) {
      toastError('messagesV3:noSocialMediaArticleFound');

      return { success: false };
    }
    Array.isArray(data?.sm_articles) && setSmArticles(data.sm_articles);

    return { success: true };
  }, [makeRequest, setSmArticles, toastError]);

  useEffect(() => {
    getSmArticles();
  }, [getSmArticles]);

  return { loading };
};

export default useSocialMediaArticles;
