// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useState } from 'react';

import {
  ButtonV2 as Button,
  Grid,
  Paper,
  RefreshIcon,
  ShopIcon,
  Text,
} from '@livingpackets/design-system-react-next';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { disabledContent } from 'utils/disabledElementCss';

import { useCreateAddress } from '../../../api/addresses/createAddress';
import { useAddresses } from '../../../api/addresses/getAddresses';
import { usePartnership } from '../../../api/partnership/getPartnership';
import { useAddressByType } from '../../../hooks/useAddressByType';
import { INITIAL_ADDRESS } from '../../../types/address';
import { PartnerBusinessStatusEnum } from '../../../types/partnership';
import { Card } from '../../commons/addresses/Card';
import { AddressForm } from '../../commons/addresses/Form';
import { ErrorEmptyState } from '../../commons/ErrorEmptyState';
import { Loading } from '../../commons/Loading';

const RegisteredOffice = () => {
  const { t } = useTranslation('account');
  const {
    data,
    isPending: isLoadingAddresses,
    error,
    refetch,
  } = useAddresses();

  const { mutate, isPending } = useCreateAddress();
  const { data: partnership } = usePartnership();

  const isPartnerBlocked =
    partnership?.business_information?.status ===
    PartnerBusinessStatusEnum.BLOCKED;

  const [editionMode, setEditionMode] = useState(false);

  // Loop through addresses and find the main one
  // Because we only display the address with the type MAIN
  const mainAddress = useAddressByType({ addresses: data });

  const renderContent = useCallback(() => {
    if (isLoadingAddresses) {
      return (
        <Paper sx={{ gap: '1.5rem' }}>
          <Loading height="4rem" />
        </Paper>
      );
    }

    if (error) {
      return <ErrorEmptyState />;
    }

    // If no address or no main address
    if (isEmpty(data?.items) || !mainAddress) {
      return (
        <AddressForm
          alwaysShowActions
          initialValues={INITIAL_ADDRESS}
          disabled={isPartnerBlocked}
          mutate={mutate}
          isPending={isPending}
        />
      );
    }

    return (
      <Grid
        container
        direction="column"
        gap=".875rem"
        sx={{
          ...(isPartnerBlocked && disabledContent),
        }}
      >
        <Card
          canEdit
          address={mainAddress}
          editionMode={editionMode}
          setEditionMode={setEditionMode}
        />
        {!editionMode && (
          <Text variant="bodyTextS">
            {t('partnership.information.address.bottomHelper')}
          </Text>
        )}
      </Grid>
    );
  }, [
    isLoadingAddresses,
    error,
    data,
    mutate,
    isPending,
    t,
    mainAddress,
    editionMode,
    setEditionMode,
    isPartnerBlocked,
  ]);

  return (
    <Grid item>
      <Paper
        headerIcon={ShopIcon}
        headerTitle={t('partnership.information.address.title')}
        headerSubtitle={t('partnership.information.address.subtitle')}
        headerInfo={
          error && (
            <Button
              variant="tertiary"
              size="small"
              icon={RefreshIcon}
              onClick={refetch as any}
              data-testid="refresh-addresses-button"
            />
          )
        }
        sx={{ pt: '24px', gap: '1.5rem' }}
        LeftHeaderProps={{
          sx: {
            opacity: error ? 0.3 : 1,
          },
        }}
      >
        {renderContent()}
      </Paper>
    </Grid>
  );
};

export default RegisteredOffice;
