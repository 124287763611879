// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ButtonV2 as Button,
  CopyIcon,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';
import useClipboard from 'react-use-clipboard';

interface ICopyToClipboard {
  data: string;
}

const CopyToClipboard = ({ data }: ICopyToClipboard) => {
  const theme = useTheme();
  const { t } = useTranslation('general');

  const [isCopied, setCopied] = useClipboard(data, {
    successDuration: 1000,
  });

  return (
    <Button
      variant="flat"
      aria-label={t('copyToClipboard')}
      onClick={setCopied}
    >
      <CopyIcon
        color={
          isCopied
            ? theme.palette.custom.neutral.black[30]
            : theme.palette.custom.primary[100]
        }
        style={{ transition: '500ms' }}
        size="1.1875rem"
      />
    </Button>
  );
};

export default CopyToClipboard;
