// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useState } from 'react';

export type OrderDirection = 'DESC' | 'ASC';

export interface PaginationParams {
  page: number;
  pageSize: number;
  orderDirection: OrderDirection;
  orderColumn: string;
}

export interface Paginated<Type> extends PaginationParams {
  items: Type[];
  totalPages: number;
}

export interface UsePaginationValues {
  params: PaginationParams;
  setParams: (params: Partial<PaginationParams>) => void;
}

export function usePagination({
  page = 1,
  pageSize = 100,
  orderDirection = 'DESC',
  orderColumn = 'updatedAt',
}: Partial<PaginationParams> = {}): UsePaginationValues {
  const [params, setParams] = useState<PaginationParams>({
    page,
    pageSize,
    orderColumn,
    orderDirection,
  });

  const setPartialParams = useCallback(
    (newParams: Partial<PaginationParams>) =>
      setParams(prevParams => ({ ...prevParams, ...newParams })),
    [setParams]
  );

  return {
    params,
    setParams: setPartialParams,
  };
}
