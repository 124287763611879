// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { create } from 'zustand';

import { NewsletterItem } from '../models/newsletter';

export interface INewslettersStore {
  newsletters: NewsletterItem[];
  setNewsletters: (newsletters: NewsletterItem[]) => void;
}

export const newsletterSelector = (state: INewslettersStore) =>
  state.newsletters;
export const setNewsletterSelector = (state: INewslettersStore) =>
  state.setNewsletters;

const useNewsletterStore = create<INewslettersStore>(set => ({
  newsletters: [],
  setNewsletters: newsletters => set(() => ({ newsletters: newsletters })),
}));

export default useNewsletterStore;
