// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect } from 'react';

import { FinancialCard } from '@livingpackets/design-system-react';
import {
  BuyRentIcon,
  ContributionIcon,
  TooltipIcon,
  NewTooltip as Tooltip,
  Box,
  Text,
  styled,
  Stack,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import useGetContribution from 'hooks/useGetContribution';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import useFormatTimestamp from '../../hooks/useFormatTimestamp';
import useGetRents from '../../hooks/useGetRents';
import useBoxContractsStore, {
  boxContractSelector,
} from '../../stores/useBoxContractsStore';
import useContributionsStore, {
  contributionsSelector,
  contributionOverviewSelector,
} from '../../stores/useContributionsStore';
import {
  getNewsletterDateFormat,
  getSMArticleDateFormat,
} from '../commonFunctions/DateCalculation';
import BalanceCard from '../molecules/BalanceCard';
import NewsletterCardList from '../molecules/dashboard/NewsletterCardList';
import SocialMediaCardList from '../molecules/dashboard/SocialMediaCardList';
import WebsiteLinkCard from '../molecules/dashboard/WebsiteLinkCard';
import DashboardHeader from '../molecules/DashboardHeader';

const CardWrapper = styled('div')<{ width?: string }>`
  display: flex;
  z-index: 2;
  width: ${({ width }) => width};
`;
const ProfileDashboard = () => {
  const theme = useTheme();
  const {
    t,
    i18n: { language },
  } = useTranslation(['contribution', 'buyAndRent']);
  const navigate = useNavigate();

  const contracts = useBoxContractsStore(boxContractSelector);
  const contributions = useContributionsStore(contributionsSelector);
  const formatTimestamp = useFormatTimestamp();
  const { rents } = useGetRents();
  const { getContributionOverview } = useGetContribution();

  const contributionOverview = useContributionsStore(
    contributionOverviewSelector
  );

  useEffect(() => {
    getContributionOverview();
  }, [getContributionOverview]);

  return (
    <Stack>
      <DashboardHeader />
      <Stack direction="row" justifyContent="space-between" sx={{ zIndex: 1 }}>
        {contributions.length > 0 && (
          <CardWrapper
            width={contracts.length === 0 ? '100%' : '49%'}
            style={{ zIndex: 3 }}
          >
            <FinancialCard
              handleClick={() => {
                navigate(PATHS.ACCOUNT.PROFILE);
              }}
              headerBackgroundColor="primary.140"
              titleCard={
                <>
                  <ContributionIcon size="24px" />
                  <Text variant="titleL">
                    {t('contribution:financialCard.dashboardTitle')}
                  </Text>
                </>
              }
              totalLabelInit={
                <Text style={{ textAlign: 'center' }}>
                  {t('contribution:financialCard.investmentsTotalLabel')}
                </Text>
              }
              totalLabelInitAmount={
                <Text variant="bodyTextXL">
                  {t('contribution:financialCard.investmentsTotalAmount', {
                    investmentsTotalAmount:
                      contributionOverview.investments_total_amount,
                  })}
                </Text>
              }
              totalLabelwin={
                <Box display="flex" sx={{ gap: '5px' }} alignItems="center">
                  <Text sx={{ textAlign: 'center' }}>
                    {t('contribution:financialCard.rewardLabel')}
                  </Text>

                  <Tooltip
                    id="reward-label"
                    title={
                      <>
                        <Text variant="titleL">
                          {t('contribution:financialCard.rewardTooltip.title')}
                        </Text>
                        <Text>
                          {t(
                            'contribution:financialCard.rewardTooltip.content'
                          )}
                        </Text>
                      </>
                    }
                    sx={{ width: '30rem' }}
                  >
                    <TooltipIcon data-tooltip-id="reward-label" size="13.5px" />
                  </Tooltip>
                </Box>
              }
              totalLabelwinAmount={
                <Text variant="bodyTextXL">
                  {t('contribution:financialCard.rewardAmount', {
                    rewardAmount: contributionOverview.reward_amount,
                  })}
                </Text>
              }
              summaryTotalLabel={
                <Text variant="titleL">
                  {t('contribution:financialCard.summaryLabel')}
                </Text>
              }
              summaryTotalAmount={
                <Text variant="titleL">
                  {t('contribution:financialCard.summaryAmount', {
                    summaryAmount:
                      contributionOverview.investments_total_amount,
                  })}
                  {}
                </Text>
              }
              summaryLabelDetail1={
                <Text variant="bodyTextL">
                  {t('contribution:financialCard.contributionNumberLabel', {
                    contributionNumber:
                      contributionOverview.contributions_number,
                  })}
                </Text>
              }
              summaryLabelDetailAmount1={
                <Text variant="bodyTextL">
                  {t('contribution:financialCard.contributionAmount', {
                    contributionAmount:
                      contributionOverview.contributions_amount,
                  })}
                </Text>
              }
              summaryLabelDetail2={
                <Text variant="bodyTextL">
                  {t('contribution:financialCard.sponsorshipsNumberLabel', {
                    sponsorshipsNumberLabel:
                      contributionOverview.sponsorships_number,
                  })}
                </Text>
              }
              summaryLabelDetailAmount2={
                <Text variant="bodyTextL">
                  {t('contribution:financialCard.sponsorshipsAmount', {
                    sponsorshipsAmount:
                      contributionOverview.sponsorships_amount,
                  })}
                </Text>
              }
              IsCompleted={false}
              IsEmpty={contributionOverview.reward_amount <= 0}
            />
          </CardWrapper>
        )}
        {contracts.length > 0 && (
          <CardWrapper
            width={contributions.length === 0 ? '100%' : '49%'}
            onClick={() => {
              navigate(PATHS.ACCOUNT.BUY_RENT);
            }}
          >
            <BalanceCard
              payoutAvailable={false}
              title={t('buyAndRent:buyAndRent')}
              icon={
                <BuyRentIcon color={theme.palette.custom.neutral.white.pure} />
              }
              payoutAvailability={t(
                'buyAndRent:buyNRent.balanceCard.availableFunds',
                {
                  date: rents.available_at
                    ? formatTimestamp(
                        new Date(rents.available_at),
                        getNewsletterDateFormat()
                      )
                    : null,
                  defaultValue: '',
                }
              )}
              amount={t('buyAndRent:buyNRent.balanceCard.reward', {
                reward: rents.total,
              })}
              investments={t(
                'buyAndRent:buyNRent.balanceCard.numberBoxesPurchased'
              )}
              investmentAmount={rents.product_count}
              firstLine={
                rents.shipment_count > 1
                  ? t('buyAndRent:buyNRent.balanceCard.shipmentsCount', {
                      shipmentCount: rents.shipment_count,
                      defaultValue: '',
                    })
                  : t(
                      'buyAndRent:buyNRent.balanceCard.shipmentsCountSingular',
                      {
                        shipmentCount: rents.shipment_count,
                        defaultValue: '',
                      }
                    )
              }
              initialDate={
                !!rents.activated_at &&
                t('buyAndRent:buyNRent.balanceCard.activationDate', {
                  activationDate: rents.activated_at
                    ? formatTimestamp(
                        new Date(rents.activated_at),
                        getSMArticleDateFormat(language)
                      )
                    : null,
                  defaultValue: '',
                })
              }
              secondLine={
                rents.contract_count > 1
                  ? t('buyAndRent:buyNRent.balanceCard.contractCount', {
                      contractCount: rents.contract_count,
                    })
                  : t('buyAndRent:buyNRent.balanceCard.contractCountSingular', {
                      contractCount: rents.contract_count,
                    })
              }
              cardHeaderColor={theme.colors.secondary.pantone['328C']}
              cardBalanceColor={theme.colors.primary[150]}
            />
          </CardWrapper>
        )}
        {contributions.length === 0 && contracts.length === 0 && (
          <Stack direction="row" alignItems="flex-end" width="100%">
            <WebsiteLinkCard />
          </Stack>
        )}
      </Stack>
      <Stack direction="row">
        <SocialMediaCardList />
        <NewsletterCardList />
      </Stack>
    </Stack>
  );
};

export default ProfileDashboard;
