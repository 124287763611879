// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  CountriesArray,
  PhoneNumber as DSPhoneNumber,
  PhoneNumberProps,
} from '@livingpackets/design-system-react-next';
import { LanguageTypes } from '@livingpackets/design-system-react-next/dist/components/PhoneNumber/PhoneNumber';
import { useTranslation } from 'react-i18next';

import { countriesData } from './countriesData';

/**
 * Intermediate component to wrap the PhoneNumber component from the design system
 * with the i18n translation function for inputPlaceholderLabel
 * and the countries data.
 * and the language.
 */
export const PhoneNumber = React.forwardRef(
  (
    {
      language,
      inputPlaceholderLabel,
      countries,
      ...rest
    }: Omit<
      PhoneNumberProps,
      'language' | 'inputPlaceholderLabel' | 'countries'
    > & {
      language?: LanguageTypes;
      inputPlaceholderLabel?: string;
      countries?: CountriesArray;
    },
    ref: React.Ref<any> | undefined
  ) => {
    const { t, i18n } = useTranslation('forms');

    return (
      <DSPhoneNumber
        ref={ref}
        language={language || (i18n.language as LanguageTypes)}
        inputPlaceholderLabel={
          inputPlaceholderLabel || t('phoneNumber.inputPlaceholderLabel')
        }
        countries={countries || countriesData}
        {...rest}
      />
    );
  }
);

PhoneNumber.displayName = 'PhoneNumber';
