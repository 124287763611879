// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { Dispatch, SetStateAction } from 'react';

import {
  Box,
  ButtonV2 as Button,
  Chip,
  EditIcon,
  Grid,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import useIsMobile from 'hooks/useIsMobile';
import { isEmpty } from 'lodash';

import { Delete } from './Delete';
import { AddressForm } from './Form';
import { useUpdateAddress } from '../../../api/addresses/updateAddress';
import { usePartnership } from '../../../api/partnership/getPartnership';
import { Address } from '../../../types/address';
import { PartnerBusinessStatusEnum } from '../../../types/partnership';

export const Card = ({
  address,
  showIdentificationName = false,
  canEdit = false,
  editionMode,
  setEditionMode,
}: {
  address: Address;
  showIdentificationName?: boolean;
  canEdit?: boolean;
  editionMode: boolean;
  setEditionMode: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isMobile } = useIsMobile();

  const { data: partnership } = usePartnership();
  const { mutate, isPending } = useUpdateAddress({ initialValues: address });

  const [, getCountryNameFromCode] = useCountryCodesWrapper();

  const canDelete = !address.addressTypes?.includes('MAIN');

  const isPartnerBlocked =
    partnership?.business_information?.status ===
    PartnerBusinessStatusEnum.BLOCKED;

  const editAndDeleteButtons = (
    <Grid container justifyContent="flex-end">
      {canEdit && (
        <Grid item>
          <Button
            data-testid="edit-address-button"
            disabled={isPartnerBlocked}
            icon={EditIcon}
            variant="tertiary"
            sx={{ color: 'custom.neutral.black.100' }}
            onClick={() => setEditionMode(true)}
          />
        </Grid>
      )}
      {canDelete && (
        <Grid item>
          <Delete addressId={address.id} disabled={isPartnerBlocked} />
        </Grid>
      )}
    </Grid>
  );

  if (editionMode) {
    return (
      <AddressForm
        alwaysShowActions
        showIdentificationName={showIdentificationName}
        initialValues={address}
        disabled={isPartnerBlocked}
        mutate={mutate}
        isPending={isPending}
        onSave={() => setEditionMode(false)}
        onDismiss={() => setEditionMode(false)}
      />
    );
  }

  return (
    <Grid
      data-testid="address-card"
      container
      flexWrap={{ mobile: 'wrap', laptop: 'nowrap' }}
      justifyContent={{
        mobile: 'flex-start',
        laptop: 'space-between',
      }}
      alignItems="center"
      rowSpacing={{ mobile: '1rem', laptop: '0' }}
      columnSpacing={{ mobile: '0' }}
      columnGap={{ laptop: '1rem' }}
      sx={{
        p: '1.5rem',
        borderRadius: '.75rem',
        border: '1px solid',
        borderColor: 'custom.neutral.black.10',
        ...(canDelete && { boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.08)' }),
      }}
    >
      <Grid
        item
        mobile={6}
        laptop="auto"
        sx={{ mt: { mobile: '-1.125rem', laptop: '0' } }}
      >
        <Chip label={address.company} state="default" type="tags" />
      </Grid>
      {isMobile && (
        <Grid item mobile={6} laptop="auto" sx={{ mt: '-18px' }}>
          {editAndDeleteButtons}
        </Grid>
      )}
      <Grid item flex={1} ml={{ mobile: 0, laptop: '7.125rem' }}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          gap="1rem"
        >
          <Box flexGrow={1}>
            <Stack>
              <Text variant="bodyTextS">
                {address.street}
                {address.street2 ? `, ${address.street2}` : ''}
                {address.building ? `, ${address.building}` : ''}
              </Text>
              <Text variant="bodyTextS">
                {address.postalCode} {address.city},{' '}
                {getCountryNameFromCode(address.countryCode)}
              </Text>
            </Stack>
          </Box>
          {!isEmpty(address.phoneNumber) && (
            <Box flexGrow={1}>
              <Text variant="bodyTextS">{address.phoneNumber}</Text>
            </Box>
          )}
          <Box flexGrow={1}>
            <Text variant="bodyTextS" sx={{ wordBreak: 'break-all' }}>
              {address.email}
            </Text>
          </Box>
          {isEmpty(address.phoneNumber) && <Box flexGrow={1}>&nbsp;</Box>}
        </Grid>
      </Grid>
      {!isMobile && (
        <Grid item mobile={12} tablet="auto">
          {editAndDeleteButtons}
        </Grid>
      )}
    </Grid>
  );
};
