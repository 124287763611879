// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  LinkIcon,
  NoneIcon,
  Text,
  Stack,
  styled,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import figuralLogo from 'assets/img/insurance/figural-logo.svg';
import { DetailsCard } from 'components/atoms/ShipmentAtoms';
import isCancelledState from 'helpers/isCancelledState';
import CamelCase from 'lodash/camelCase';
import { IShipment, ShipmentContractStateEnum } from 'models/shipment';
import { Trans, useTranslation } from 'react-i18next';

import { PATHS } from '../../../configs';

const Wrapper = styled('div')`
  margin-top: 1.5rem;
`;

const DetailsSpan = styled(Text)``;

DetailsSpan.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.50',
};

const InsuranceFiguralLogo = styled('div')<{
  figuralLogo: string;
}>`
  min-width: 60px;
  height: 52px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ figuralLogo }) => figuralLogo});
  margin-right: 17px;
`;

const InsuredStatusText = styled(Text, {
  shouldForwardProp: prop => prop !== 'isShipmentInsured',
})<{
  isShipmentInsured: boolean;
}>`
  color: ${({ theme, isShipmentInsured }) =>
    isShipmentInsured
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.black[50]};
  background-color: ${({ theme, isShipmentInsured }) =>
    isShipmentInsured
      ? theme.palette.custom.primary[15]
      : theme.palette.custom.neutral.black[4]};
  border-radius: 30px;
  padding: 8px 25px 8px 25px;
  letter-spacing: 0.4px;
  text-align: center;
  text-transform: uppercase;
`;

InsuredStatusText.defaultProps = {
  variant: 'bodyTextXS',
};

const ClaimStatusPlaceholderText = styled(Text)`
  text-align: center;
`;

ClaimStatusPlaceholderText.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.50',
};

const ClaimStatusText = styled(Text)`
  text-align: center;
`;

ClaimStatusText.defaultProps = {
  variant: 'bodyTextS',
  color: 'custom.neutral.black.100',
};

const ShipmentDetailInsuranceCard = ({ shipment }: { shipment: IShipment }) => {
  const { t } = useTranslation(['shipments', 'messages']);

  const [currentShipment, setCurrentShipment] = useState<IShipment>(shipment);
  useEffect(() => {
    setCurrentShipment(shipment);
  }, [shipment]);

  const isCancelled = isCancelledState(currentShipment);

  const isShipmentInsured = useCallback(
    (): boolean => currentShipment.parcel_request_id !== '',
    [currentShipment]
  );

  const getClaimStatus = useCallback(() => {
    if (shipment.contract_state === ShipmentContractStateEnum.PURCHASED) {
      return <NoneIcon size="12px" />;
    }

    if (
      shipment.partnerParcelData &&
      shipment.partnerParcelData.secursus_claim_status
    ) {
      return (
        <ClaimStatusText>
          <Trans t={t}>
            {
              `shipments:detail.summary.insurance.claim.status.${CamelCase(
                shipment.partnerParcelData?.secursus_claim_status
              )}` as const
            }
          </Trans>
        </ClaimStatusText>
      );
    } else {
      return (
        <ClaimStatusText>
          {t('shipments:detail.summary.insurance.claim.status.noClaim')}
        </ClaimStatusText>
      );
    }
  }, [shipment, t]);

  return (
    <Wrapper>
      <DetailsSpan>{t('shipments:detail.summary.insurance.title')}</DetailsSpan>
      <DetailsCard padding="1.25rem" mt=".75rem" disabled={isCancelled}>
        <Stack direction="row" alignItems="center">
          <Stack direction="row" alignItems="center">
            <InsuranceFiguralLogo figuralLogo={figuralLogo} />
            <Stack alignItems="flex-start">
              <InsuredStatusText isShipmentInsured={isShipmentInsured()}>
                {isShipmentInsured()
                  ? t('shipments:detail.summary.insurance.insured')
                  : t('shipments:detail.summary.insurance.notInsured')}
              </InsuredStatusText>
              <Stack
                direction="row"
                alignItems="flex-start"
                style={{ paddingTop: '8px' }}
              >
                <ClaimStatusPlaceholderText>
                  {t(
                    'shipments:detail.summary.insurance.claim.claimStatusPlaceholder'
                  )}
                </ClaimStatusPlaceholderText>
                {getClaimStatus()}
              </Stack>
            </Stack>
          </Stack>
          {isShipmentInsured() && (
            <Button
              variant="links"
              sx={{
                textTransform: 'uppercase',
                '& .MuiTypography-titleM': { paddingRight: 0 },
              }}
              onClick={() => {
                const queryParams = new URLSearchParams();

                if (shipment.partnerParcelData?.secursus_id) {
                  queryParams.set(
                    'secursusId',
                    shipment.partnerParcelData.secursus_id
                  );
                }
                if (shipment.partnerParcelData?.email) {
                  queryParams.set('email', shipment.partnerParcelData.email);
                }
                const urlToOpen = `${
                  PATHS.FIGURAL_MONITORY_OUR_CLAIM_PAGE
                }${queryParams.toString()}`;

                window.open(urlToOpen, '_blank', 'noopener noreferrer');
              }}
              icon={LinkIcon}
            >
              {t('shipments:detail.summary.insurance.monitorYourClaims')}
            </Button>
          )}
        </Stack>
      </DetailsCard>
    </Wrapper>
  );
};

export default ShipmentDetailInsuranceCard;
