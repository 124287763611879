// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { ToastMessage } from 'stores/useToasts.types';
import { create } from 'zustand';

let id: number = 0;
const defaultCloseDuration = 400;

const toastSettings = {
  defaultDuration: 3500,
};

interface IToastItem {
  key: number;
  toast: ToastMessage;
  view: string;
  settings: {
    wide?: boolean;
    duration?: number;
    forever?: boolean;
    queued?: boolean;
    persist?: boolean;
  };
}

export type ToastSettings = {
  wide?: boolean;
  duration?: number;
  forever?: boolean;
  queueed?: boolean;
  persist?: boolean;
};

export interface IUseToasts {
  items: IToastItem[];
  cancelItems: Map<number, any>;
  queuedItems: IToastItem[];
  getViewItems: (view: string) => IToastItem[];
  getQueuedViewItem: (view: string) => IToastItem | undefined;
  markItems: (key: number) => void;
  removeItem: (key: number) => void;
  addItem: (
    toast: ToastMessage,
    view: string,
    settings: ToastSettings
  ) => number;
  _addItem: (item: IToastItem) => any;
  clearItem: (key: number) => void;
  clearItems: () => void;
}

export const itemsSelector = (state: IUseToasts) => state.items;
export const markItemsSelector = (state: IUseToasts) => state.markItems;
export const removeItemSelector = (state: IUseToasts) => state.removeItem;
export const addItemSelector = (state: IUseToasts) => state.addItem;
export const clearItemsSelector = (state: IUseToasts) => state.clearItems;

const useToasts = create<IUseToasts>((set, get) => ({
  items: [],
  cancelItems: new Map(),
  queuedItems: [],
  getViewItems: (view: string) =>
    get().items.filter((i: IToastItem) => i.view === view),
  getQueuedViewItem: (view: string) =>
    get().queuedItems.find((i: IToastItem) => i.view === view),
  markItems: (key: number) => {
    const item = get().items.find((c: IToastItem) => c.key === key);
    if (!item) {
      return;
    }
    set(state => ({
      items: state.items.map((i: IToastItem) => {
        if (i.key === key) {
          i.toast.cancel = true;
        }

        return i;
      }),
      cancelItems: item.settings?.forever
        ? new Map(
            state.cancelItems.set(
              key,
              setTimeout(() => {
                get().removeItem(key);
              }, defaultCloseDuration)
            )
          )
        : state.cancelItems,
    }));
  },
  removeItem: (key: number) => {
    get().clearItem(key);
    const item = get().items.find((c: IToastItem) => c.key === key);
    const updatedItems = get().items.filter((i: IToastItem) => i.key !== key);

    set(state => {
      state.cancelItems.delete(key);

      return {
        items: updatedItems,
        cancelItems: new Map(state.cancelItems),
      };
    });

    //add item from the queue
    if (!item) {
      return;
    }

    const queuedItem = get().getQueuedViewItem(item.view);
    if (updatedItems.length === 0 && queuedItem) {
      set(state => ({
        ...get()._addItem(queuedItem),
        queuedItems: state.queuedItems.filter(
          (i: IToastItem) => i.key !== queuedItem.key
        ),
      }));
    }
  },
  addItem: (toast, view, settings) => {
    const key = id++;
    const item: IToastItem = {
      key: key,
      toast: toast,
      view: view,
      settings: settings,
    };
    set(state =>
      settings.queueed && get().items.length > 0
        ? { queuedItems: [...state.queuedItems, item] }
        : get()._addItem(item)
    );

    return key;
  },
  _addItem: (item: IToastItem) =>
    item?.settings?.forever
      ? { items: [...get().items, item] }
      : {
          items: [...get().items, item],
          cancelItems: new Map(
            get().cancelItems.set(
              item.key,
              setTimeout(() => {
                get().markItems(item.key);
                setTimeout(
                  () => get().removeItem(item.key),
                  defaultCloseDuration
                );
              }, item.settings.duration || toastSettings.defaultDuration)
            )
          ),
        },
  clearItem: (key: number) => {
    clearTimeout(get().cancelItems.get(key));
  },
  clearItems: () => {
    let canceledMapCopy = get().cancelItems;
    for (const key of get().cancelItems.keys()) {
      const item = get().items.find((c: IToastItem) => c.key === key);
      if (!item?.settings.persist) {
        clearTimeout(key);
        canceledMapCopy.delete(key);
      }
    }
    set(_ => ({
      items: get().items.filter(item => item?.settings.persist),
      cancelItems: canceledMapCopy,
      queuedItems: [],
    }));
  },
}));

export default useToasts;
