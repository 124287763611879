// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  NoneIcon,
  ButtonV2 as Button,
  Text,
  styled,
  Stack,
  Box,
} from '@livingpackets/design-system-react-next';
import boxShowcaseClosedFoldImg from 'assets/img/products/showcase/box/box-showcase-closed-fold.png';
import boxShowcaseClosedFoldImg2x from 'assets/img/products/showcase/box/box-showcase-closed-fold@2x.png';
import boxShowcaseClosedFoldImg3x from 'assets/img/products/showcase/box/box-showcase-closed-fold@3x.png';
import boxShowcaseOpenImg from 'assets/img/products/showcase/box/box-showcase-open.png';
import boxShowcaseOpenImg2x from 'assets/img/products/showcase/box/box-showcase-open@2x.png';
import boxShowcaseOpenImg3x from 'assets/img/products/showcase/box/box-showcase-open@3x.png';
import boxShowcaseImg from 'assets/img/products/showcase/box/box-showcase.png';
import boxShowcaseImg2x from 'assets/img/products/showcase/box/box-showcase@2x.png';
import boxShowcaseImg3x from 'assets/img/products/showcase/box/box-showcase@3x.png';
import boxProShowcaseImg from 'assets/img/products/showcase/boxPro/box-pro-showcase.png';
import boxProShowcaseImg2x from 'assets/img/products/showcase/boxPro/box-pro-showcase@2x.png';
import boxProShowcaseImg3x from 'assets/img/products/showcase/boxPro/box-pro-showcase@3x.png';
import tabletShowcaseImg from 'assets/img/products/showcase/tablet/tablet-showcase.png';
import tabletShowcaseImg2x from 'assets/img/products/showcase/tablet/tablet-showcase@2x.png';
import tabletShowcaseImg3x from 'assets/img/products/showcase/tablet/tablet-showcase@3x.png';
import BatteryInfoIcon from 'components/molecules/box/BatteryInfoIcon';
import BatteryInfoText from 'components/molecules/box/BatteryInfoText';
import ContractStateInfoContainer from 'components/molecules/box/ContractStateInfo';
import LockingStateIcon from 'components/molecules/box/LockingStateIcon';
import LockingStateText from 'components/molecules/box/LockingStateText';
import ViewProductScreenModal from 'components/molecules/modals/ViewProductScreenModal';
import { PATHS } from 'configs';
import { ProductClosingStateEnum, ProductTypeEnum } from 'enums/ProductEnum';
import { STATE_ORIGIN_PRODUCT } from 'features/deploy-return';
import {
  TooltipRemoteControl,
  TooltipReturnProduct,
} from 'features/remoteControl';
import useIntlDistanceTimestamp from 'hooks/useIntlDistanceTimestamp';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import useToastMessages from 'hooks/useToastMessages';
import { ProductModel } from 'models/product';
import { IShipment, ShipmentContractStateEnum } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import useAppState, { tokenSelector } from 'stores/appState/useAppState';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import { formatLpui } from 'utils/product';

const ProductShowcaseImgContainer = styled('div')<{
  productShowcaseImg: string;
  productShowcaseImg2x: string;
  productShowcaseImg3x: string;
}>`
  height: 220px;
  width: 268px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ productShowcaseImg }) => productShowcaseImg});
  background-image: -webkit-image-set(
    url(${({ productShowcaseImg2x }) => productShowcaseImg2x}) 1x,
    url(${({ productShowcaseImg3x }) => productShowcaseImg3x}) 2x
  );
`;

const Wrapper = styled('div')`
  position: relative;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  height: 100%;
  overflow-x: auto;

  :: - webkit-scrollbar-track {
    border: 1px solid #f6f6f6;
    padding: 2px 0;
    background-color: #f6f6f6;
    border-radius: 2px;
  }

  :: -webkit-scrollbar {
    width: 4px;
  }

  :: -webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #cccccc;
  }
`;

export const ProductWrapperContainer = styled('div')`
  height: inherit;
  overflow: hidden;
`;

const BlockTitleText = styled(Text)``;

BlockTitleText.defaultProps = {
  variant: 'titleS',
  color: 'custom.neutral.black.50',
};

const ProductInformationContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  border-radius: 14px;
  padding: 16px;
`;

interface ProductDetailsProps {
  product: ProductModel;
  shipmentDetail?: IShipment;
}

const ProductDetails = ({ product, shipmentDetail }: ProductDetailsProps) => {
  const { t } = useTranslation('products');
  const appToken = useAppState(tokenSelector);
  const navigate = useNavigate();

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const formatTimestamp = useIntlFormatTimestamp();
  const intlDistanceTimestamp = useIntlDistanceTimestamp();

  const [productScreenImageBase64, setProductScreenImageBase64] = useState<
    string | undefined
  >(undefined);

  const [showProductScreenModal, setShowProductScreenModal] =
    useState<boolean>(false);
  const [disabledButtonLabel, setDisabledButtonLabel] =
    useState<boolean>(false);

  const { error: toastError } = useToastMessages();

  useEffect(() => {
    const fetchShipmentLabel = async () => {
      if (!product?.current_screen) {
        return;
      }

      try {
        const response = await fetch(product?.current_screen, {
          method: 'GET',
          headers: { Authorization: `Bearer ${appToken}` },
        });

        if (!response.ok) {
          throw new Error(`HTTP error: ${response.status}`);
        }

        const blob = await response.blob();
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          if (typeof reader.result === 'string') {
            setProductScreenImageBase64(reader.result);
          }
        };
      } catch {
        toastError(t('detail.errorLabel'));
        setDisabledButtonLabel(true);
      }
    };

    fetchShipmentLabel();
  }, [product, appToken, product.current_screen, toastError, t]);

  const getProductShowcaseImgContainer = useCallback(
    (product: ProductModel) => {
      let showcaseImg = undefined;
      let showcaseImg2x = undefined;
      let showcaseImg3x = undefined;

      if (product.productType === ProductTypeEnum.BOX) {
        switch (product.closing_state) {
          case ProductClosingStateEnum.CONFIG_OPEN:
            showcaseImg = boxShowcaseOpenImg;
            showcaseImg2x = boxShowcaseOpenImg2x;
            showcaseImg3x = boxShowcaseOpenImg3x;
            break;
          case ProductClosingStateEnum.CONFIG_32L:
            showcaseImg = boxShowcaseImg;
            showcaseImg2x = boxShowcaseImg2x;
            showcaseImg3x = boxShowcaseImg3x;
            break;
          case ProductClosingStateEnum.CONFIG_2L:
            showcaseImg = boxShowcaseClosedFoldImg;
            showcaseImg2x = boxShowcaseClosedFoldImg2x;
            showcaseImg3x = boxShowcaseClosedFoldImg3x;
            break;
          case ProductClosingStateEnum.CONFIG_CLOSED_FOLD:
            showcaseImg = boxShowcaseClosedFoldImg;
            showcaseImg2x = boxShowcaseClosedFoldImg2x;
            showcaseImg3x = boxShowcaseClosedFoldImg3x;
            break;
          default:
            showcaseImg = boxShowcaseImg;
            showcaseImg2x = boxShowcaseImg2x;
            showcaseImg3x = boxShowcaseImg3x;
        }
      }

      if (product.productType === ProductTypeEnum.BOX_PRO) {
        showcaseImg = boxProShowcaseImg;
        showcaseImg2x = boxProShowcaseImg2x;
        showcaseImg3x = boxProShowcaseImg3x;
      }

      if (product.productType === ProductTypeEnum.TABLET) {
        showcaseImg = tabletShowcaseImg;
        showcaseImg2x = tabletShowcaseImg2x;
        showcaseImg3x = tabletShowcaseImg3x;
      }

      if (showcaseImg && showcaseImg2x && showcaseImg3x) {
        return (
          <ProductShowcaseImgContainer
            productShowcaseImg={showcaseImg}
            productShowcaseImg2x={showcaseImg2x}
            productShowcaseImg3x={showcaseImg3x}
          />
        );
      } else {
        return <></>;
      }
    },
    []
  );

  const getProductDetailShowcaseTitleText = useCallback(
    (product: ProductModel) => {
      if (product.productType === ProductTypeEnum.BOX) {
        return t('detail.showcaseTitle.box');
      }

      if (product.productType === ProductTypeEnum.BOX_PRO) {
        return t('detail.showcaseTitle.boxPro');
      }
      if (product.productType === ProductTypeEnum.TABLET) {
        return t('detail.showcaseTitle.tablet');
      }

      return '';
    },
    [t]
  );

  const getProductDetailClosingStateText = useCallback(
    (product: ProductModel) => {
      if (
        product.productType === ProductTypeEnum.BOX ||
        product.productType === ProductTypeEnum.BOX_PRO
      ) {
        switch (product.closing_state) {
          case ProductClosingStateEnum.CONFIG_OPEN:
            return t('common.closingState.open');
          case ProductClosingStateEnum.CONFIG_32L:
            return t('common.closingState.32L');
          case ProductClosingStateEnum.CONFIG_2L:
            return t('common.closingState.2L');
          case ProductClosingStateEnum.CONFIG_CLOSED_FOLD:
            return t('common.closingState.closedFold');
        }
      }

      return <NoneIcon size="15px" />;
    },
    [t]
  );
  const handleClickGoToDeployReturn = () => {
    navigate(
      generatePath(PATHS.SHIPMENT.DEPLOY_RETURN, {
        partnerId: product.partner_id,
        shipmentId: product.last_shipment_id,
      }),
      { state: { origin: STATE_ORIGIN_PRODUCT, productId: product.product_id } }
    );
  };

  return (
    <ProductWrapperContainer>
      <Wrapper>
        <Stack direction="row" justifyContent="space-between">
          <Stack marginRight="32px">
            <Text variant="titleM">
              {getProductDetailShowcaseTitleText(product)}
            </Text>
            <Stack direction="row" alignItems="center" marginTop="8px">
              <Text variant="titleXS" color="custom.neutral.black.100">
                {t('detail.lastUpdate')}
              </Text>
              <Text variant="bodyTextXS">
                &nbsp;
                {formatTimestamp(product.updated_at, {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Text>
            </Stack>
            {getProductShowcaseImgContainer(product)}
            <Button
              onClick={() => setShowProductScreenModal(true)}
              variant="secondary"
              fullWidth
              disabled={disabledButtonLabel}
              sx={{
                marginTop: '1rem',
              }}
            >
              {t('detail.displayScreen')}
            </Button>
            {product.contract_state === ShipmentContractStateEnum.DEPLOYED &&
              activePartner.remoteControlEnabled && (
                <TooltipRemoteControl
                  fullWidth
                  shipmentId={product.last_shipment_id}
                />
              )}

            {shipmentDetail?.contract_state ===
              ShipmentContractStateEnum.ARRIVED &&
              shipmentDetail?.return_shipment_id &&
              shipmentDetail.return_shipment?.contract_state ===
                ShipmentContractStateEnum.TRANSFERRED && (
                <TooltipReturnProduct
                  fullWidth
                  onClick={handleClickGoToDeployReturn}
                />
              )}
          </Stack>
          <Box>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <BlockTitleText>{t('detail.contractState')}</BlockTitleText>
                <Stack
                  direction="row"
                  alignItems="center"
                  marginTop="12px !important"
                >
                  <ContractStateInfoContainer
                    contractState={product.contract_state}
                  />
                  <Text variant="bodyTextS" marginLeft="12px">
                    {t('detail.lastContractStateChange', {
                      lastContractStateChange: intlDistanceTimestamp(
                        product.contract_state_updated_at
                      ),
                    })}
                  </Text>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" marginTop="30px">
              <Stack marginRight="16px" minWidth="214px">
                <BlockTitleText>
                  {t('detail.productInformation.title')}
                </BlockTitleText>
                <ProductInformationContainer marginTop="12px !important">
                  <Stack>
                    <BlockTitleText>
                      {t('detail.productInformation.battery')}
                    </BlockTitleText>
                    <Stack
                      direction="row"
                      alignItems="center"
                      marginTop="12px !important"
                    >
                      <BatteryInfoIcon batteryLevel={product.battery_level} />
                      <BatteryInfoText
                        batteryLevel={Math.floor(product.battery_level)}
                        variant="titleXS"
                      />
                    </Stack>
                  </Stack>
                  {product.productType !== ProductTypeEnum.TABLET && (
                    <Stack marginTop="25px">
                      <BlockTitleText>
                        {t('detail.productInformation.lockingState')}
                      </BlockTitleText>
                      <Stack
                        direction="row"
                        alignItems="center"
                        marginTop="12px !important"
                      >
                        <LockingStateIcon lockState={product.lock_state} />
                        <Text variant="titleXS">
                          <LockingStateText lockState={product.lock_state} />
                        </Text>
                      </Stack>
                    </Stack>
                  )}
                  <Stack marginTop="25px">
                    <BlockTitleText>
                      {t('detail.productInformation.closingState')}
                    </BlockTitleText>
                    <Text variant="bodyTextS" marginTop="12px !important">
                      {getProductDetailClosingStateText(product)}
                    </Text>
                  </Stack>
                  <Stack marginTop="25px">
                    <BlockTitleText>
                      {t('detail.productInformation.firmwareVersion')}
                    </BlockTitleText>
                    {product.firmware_version ? (
                      <Text variant="bodyTextS" marginTop="12px !important">
                        {product.firmware_version}
                      </Text>
                    ) : (
                      <NoneIcon size="15px" />
                    )}
                  </Stack>
                </ProductInformationContainer>
              </Stack>
              <Stack>
                <BlockTitleText>
                  {t('detail.identificationInformation.title')}
                </BlockTitleText>
                <ProductInformationContainer marginTop="12px !important">
                  <Stack direction="row">
                    <Stack width="50%" marginRight="25px">
                      <Stack>
                        <BlockTitleText>
                          {t('detail.identificationInformation.holder')}
                        </BlockTitleText>
                        {product.holder_name ? (
                          <Text variant="bodyTextS" marginTop="12px !important">
                            {product.holder_name}
                          </Text>
                        ) : (
                          <NoneIcon size="15px" />
                        )}
                      </Stack>
                      <Stack marginTop="25px !important">
                        <BlockTitleText>
                          {t(
                            'detail.identificationInformation.partnershipAssignment'
                          )}
                        </BlockTitleText>
                        {product.partner_since ? (
                          <Text variant="bodyTextS" marginTop="12px !important">
                            {t(
                              'detail.identificationInformation.partnershipAssignmentInfo',
                              {
                                date: formatTimestamp(product.partner_since),
                              }
                            )}
                          </Text>
                        ) : (
                          <NoneIcon size="15px" />
                        )}
                      </Stack>
                    </Stack>
                    <Stack width="50%">
                      <Stack>
                        <BlockTitleText>
                          {t(
                            'detail.identificationInformation.productSerialNumber'
                          )}
                        </BlockTitleText>
                        <Text variant="bodyTextS" marginTop="12px !important">
                          {formatLpui(product.lp_ui)}
                        </Text>
                      </Stack>
                      <Stack marginTop="25px !important">
                        <BlockTitleText>
                          {t(
                            'detail.identificationInformation.shipmentsOperated'
                          )}
                        </BlockTitleText>
                        {product.shipments_operated ? (
                          <Text variant="bodyTextS" marginTop="12px !important">
                            {product.shipments_operated}
                          </Text>
                        ) : (
                          <NoneIcon size="15px" />
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </ProductInformationContainer>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Wrapper>

      {productScreenImageBase64 && (
        <ViewProductScreenModal
          visible={showProductScreenModal}
          onDismiss={() => setShowProductScreenModal(false)}
          productScreenUrl={productScreenImageBase64}
          hideLabelFullScreenModal={() => setShowProductScreenModal(false)}
          headerLabel={t('detail.productScreenHeaderLabel')}
        />
      )}
    </ProductWrapperContainer>
  );
};

export default ProductDetails;
