// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import {
  BatteryUndefinedIcon,
  BatteryLowIcon,
  BatteryMiddleIcon,
  BatteryFullIcon,
  useTheme,
} from '@livingpackets/design-system-react-next';

interface IBatteryInfoIcon {
  batteryLevel: number | undefined;
  updateColor?: boolean;
}

const BatteryInfoIcon = ({
  batteryLevel,
  updateColor = true,
}: IBatteryInfoIcon) => {
  const theme = useTheme();

  const getBatteryLevelIcon = useCallback(
    (batteryLevel: number | undefined) => {
      if (!batteryLevel) {
        return (
          <BatteryUndefinedIcon
            color={theme.palette.custom.neutral.black[100]}
          />
        );
      } else if (batteryLevel <= 33) {
        return (
          <BatteryLowIcon
            color={
              updateColor
                ? theme.palette.custom.error.red[100]
                : theme.palette.custom.neutral.black[100]
            }
          />
        );
      } else if (batteryLevel <= 66) {
        return (
          <BatteryMiddleIcon
            color={
              updateColor
                ? theme.palette.custom.neutral.black[100]
                : theme.palette.custom.neutral.black[100]
            }
          />
        );
      } else if (batteryLevel > 66) {
        return (
          <BatteryFullIcon
            color={
              updateColor
                ? theme.palette.custom.primary[100]
                : theme.palette.custom.neutral.black[100]
            }
          />
        );
      }
    },
    [theme, updateColor]
  );

  return <>{getBatteryLevelIcon(batteryLevel)}</>;
};

export default BatteryInfoIcon;
