// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useRef } from 'react';

import {
  AccountIcon,
  ButtonV2 as Button,
  NewCard as Card,
  Divider,
  Grid,
  RefreshIcon,
} from '@livingpackets/design-system-react-next';
import useAutoResizableModal from 'hooks/useAutoResizableModal';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { useTranslation } from 'react-i18next';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { useMe } from '../api/personal/getMe';
import { Avatar } from '../components/commons/Avatar';
import { MoreActionsMenu } from '../components/commons/delete/MoreActionsMenu';
import { ErrorEmptyState } from '../components/commons/ErrorEmptyState';
import { LastUpdateAt } from '../components/commons/LastUpdateAt';
import { Loading } from '../components/commons/Loading';
import { Layout } from '../components/Layout';
import { BasicInformation } from '../components/personal/BasicInformation';
import { GreenCardHeader } from '../components/personal/GreenCardHeader';
import { Help } from '../components/personal/Help';
import { Security } from '../components/personal/Security';

export const ModalSizeContext = React.createContext<any>(null);

export const Personal = ({ hideTabs }: any) => {
  const { t } = useTranslation(['general', 'profileSettings']);
  const format = useIntlFormatTimestamp();

  const { data: user, isPending, error, refetch } = useMe();

  const { role } = useMyPartnersStore(activePartnerSelector);

  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const modalOpt = useAutoResizableModal({
    topRef: topRef,
    bottomRef: bottomRef,
  });

  return (
    <ModalSizeContext.Provider value={modalOpt}>
      <Layout hideTabs={hideTabs} value="personal">
        <Grid container direction="column" gap="2rem">
          <Grid item>
            <Card
              ref={topRef}
              headerProps={{
                variant: 'green',
                children: (
                  <GreenCardHeader
                    avatar={
                      <Avatar>
                        {user
                          ? `${user.first_name[0]}${user.last_name[0]}`
                          : ''}
                      </Avatar>
                    }
                    title={user ? `${user.first_name} ${user.last_name}` : ''}
                    subtitle={role.name}
                    moreActions={<MoreActionsMenu />}
                  />
                ),
              }}
              bodyProps={{
                headerIcon: AccountIcon,
                headerTitle: t('profileSettings:basicInfo'),
                headerInfo: error ? (
                  <Button
                    variant="tertiary"
                    size="small"
                    icon={RefreshIcon}
                    onClick={refetch as any}
                    data-testid="refresh-basic-information-button"
                  />
                ) : (
                  user && <LastUpdateAt date={format(user?.updated_at || 0)} />
                ),
                LeftHeaderProps: {
                  sx: {
                    opacity: error ? 0.3 : 1,
                  },
                },
                children: error ? (
                  <ErrorEmptyState />
                ) : isPending ? (
                  <Loading height="8.25rem" />
                ) : (
                  <BasicInformation user={user} />
                ),
              }}
            />
          </Grid>
          <Grid item ref={bottomRef}>
            <Security />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item>
            <Help />
          </Grid>
        </Grid>
      </Layout>
    </ModalSizeContext.Provider>
  );
};
