// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import useImperativeAnonymousRequest from 'hooks/useImperativeAnonymousRequest';

export const useUpdateContractState = () => {
  const [, makeRequest] = useImperativeAnonymousRequest('shipmentV3');

  const updateContractState = async (
    shipmentId: string,
    commandType: string,
    contratState: string,
    LpUi: string
  ): Promise<ArrayBuffer | undefined> => {
    const { data, error } = await makeRequest({
      path: `/me/shipments/${shipmentId}/contract-state`,
      method: 'PATCH',
      body: {
        command_type: commandType,
        contract_state: contratState,
        lp_ui: LpUi,
      },
    });

    if (error) {
      console.error('ERROR => ', error);

      return;
    }

    if (data) {
      return data;
    }
  };

  return { updateContractState };
};
