// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { APIServices, TAPIService } from 'configs';
import useRemoteDataV2, { IUseRemoteDataV2 } from 'hooks/useRemoteDataV2';
import useAppState, { tokenSelector } from 'stores/appState/useAppState';

// TODO: implement new useRemoteData
const useRemoteData = (params: IUseRemoteDataV2, service: TAPIService) => {
  const token = useAppState(tokenSelector);
  const tokenAndApiUrl = {
    apiUrl: APIServices[service],
    token: token,
  };

  return useRemoteDataV2({ ...params, tokenAndApiUrl });
};

export default useRemoteData;
