// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  EditIcon,
  Box,
  Text,
  Stack,
  styled,
} from '@livingpackets/design-system-react-next';
import { IContact } from 'models/contactAddress';

const ContactBasicInformationContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'displayContainer',
})<{
  displayContainer: boolean;
}>`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  max-width: 100%;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 40px;
  cursor: pointer;
  display: ${({ displayContainer }) => (displayContainer ? 'block' : 'none')};
  transition: 200ms;
  outline: 1.5px solid transparent;
  &:hover {
    outline: 1.5px solid ${({ theme }) => theme.palette.custom.primary[100]};
  }
`;

interface IContactBasicInformationBlockType {
  contact: IContact;
  displayBasicInformationForm: boolean;
  onBlockClick?: () => void;
  smsEnabled?: boolean;
}

const ContactBasicInformationBlock = ({
  contact,
  displayBasicInformationForm,
  onBlockClick,
  smsEnabled,
}: IContactBasicInformationBlockType) => (
  <ContactBasicInformationContainer
    onClick={onBlockClick}
    displayContainer={!displayBasicInformationForm}
    data-testid="contactBasicInformationContainer"
  >
    <Stack direction="row" justifyContent="space-between">
      <Box>
        <Text variant="titleM" data-testid="shipment-name">
          {contact.firstName + ' ' + contact.lastName}
        </Text>
      </Box>
      <Box>
        <EditIcon />
      </Box>
    </Stack>
    <Box>
      <Text variant="bodyTextM">{contact.email}</Text>
      {smsEnabled && <Text variant="bodyTextM">{contact.phoneNumber}</Text>}
    </Box>
  </ContactBasicInformationContainer>
);

export default ContactBasicInformationBlock;
