// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  Select,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import { PhoneNumber } from 'components/form/phoneNumber';
import WarnBeforeQuit from 'components/form/WarnBeforeQuit';
import { isEmpty } from 'lodash/fp';
import { Language, PreferredLanguage } from 'models/user';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { accountPersonalBasicInformation } from 'schemas/accountPersonalBasicInformation';

import { useUpdateMe, FormValues } from '../../api/personal/updateMe';
import { User } from '../../types/user';
import { ExpandableSubmitButton } from '../commons/ExpandableSubmitButton';

export const BasicInformation = ({ user }: { user: User }) => {
  const { t } = useTranslation([
    'profileSettings',
    'general',
    'address',
    'forms',
  ]);

  const initialValues = useMemo(
    () => ({
      first_name: user?.first_name,
      last_name: user?.last_name,
      phone_number: user?.phone_number,
      preferred_language: user?.preferred_language,
    }),
    [user]
  );

  const { mutate: updateUserBasicInformation, isPending: updateInProgress } =
    useUpdateMe({
      initialValues,
    });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, errors },
  } = useForm<FormValues>({
    resolver: yupResolver(accountPersonalBasicInformation()),
    defaultValues: initialValues,
    mode: 'onTouched',
  });

  const onSubmit = handleSubmit((values: FormValues) =>
    updateUserBasicInformation(values)
  );

  // Reinitialize form after user changes
  useEffect(() => reset(initialValues), [reset, initialValues]);

  return (
    <Grid container spacing="1rem">
      <WarnBeforeQuit
        queue={{
          personal: {
            isDirty,
            onSubmit,
            reset,
          },
        }}
      />
      {/* FIRSTNAME  */}
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="first_name"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('forms:firstName.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      {/* LASTNAME  */}
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="last_name"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('forms:lastName.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      {/* PHONE_NUMBER  */}
      <Grid item mobile={12} tablet={4}>
        <Controller
          name="phone_number"
          control={control}
          render={({ field, fieldState: { error, invalid } }) => (
            <PhoneNumber
              label={t('profileSettings:phoneNumber')}
              defaultCountryCode="FR"
              error={invalid}
              helperText={error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      {/* EMAIL */}
      <Grid item mobile={12} tablet={4}>
        <TextField
          name="email"
          label={t('profileSettings:email')}
          value={user?.email}
          disabled
        />
      </Grid>
      {/* PREFERRED LANGUAGE */}
      <Grid item mobile={12} tablet={4}>
        <Controller
          name="preferred_language"
          control={control}
          render={({ field, fieldState: { invalid } }) => (
            <Select
              {...field}
              error={invalid}
              label={t('profileSettings:preferredLanguage')}
              options={Object.keys(Language).map(k => ({
                value: k,
                label: t(`general:${Language[k as PreferredLanguage]}`),
              }))}
            />
          )}
        />
      </Grid>
      <ExpandableSubmitButton
        prefix="personal"
        isDirty={isDirty}
        isValid={isEmpty(errors)}
        isSubmitting={updateInProgress}
        onSubmit={onSubmit}
        onCancel={() => reset(initialValues)}
      />
    </Grid>
  );
};
