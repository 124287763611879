// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Grid, Text, styled } from '@livingpackets/design-system-react-next';
import halfCircleBottomGrey from 'assets/half-circle-bottom-grey.svg';
import logoOnboarding from 'assets/logoOnboarding.svg';
import manageDE from 'assets/videos/onboarding/manageDE.mp4';
import manageEN from 'assets/videos/onboarding/manageEN.mp4';
import manageFR from 'assets/videos/onboarding/manageFR.mp4';
import overviewDE from 'assets/videos/onboarding/overviewDE.mp4';
import overviewEN from 'assets/videos/onboarding/overviewEN.mp4';
import overviewFR from 'assets/videos/onboarding/overviewFR.mp4';
import productsDE from 'assets/videos/onboarding/productsDE.mp4';
import productsEN from 'assets/videos/onboarding/productsEN.mp4';
import productsFR from 'assets/videos/onboarding/productsFR.mp4';
import shipmentDE from 'assets/videos/onboarding/shipmentDE.mp4';
import shipmentEN from 'assets/videos/onboarding/shipmentEN.mp4';
import shipmentFR from 'assets/videos/onboarding/shipmentFR.mp4';
import { get } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';
import useAppState, { userSelector } from 'stores/appState/useAppState';

import StepContent from './StepContent';

// Keep order in sync with translations array
const VIDEOS_LANG = [
  { fr: shipmentFR, en: shipmentEN, de: shipmentDE },
  { fr: overviewFR, en: overviewEN, de: overviewDE },
  { fr: manageFR, en: manageEN, de: manageDE },
  { fr: productsFR, en: productsEN, de: productsDE },
];

const HalfCircleBottomGrey = styled('img')`
  position: absolute;
  bottom: 0%;
  left: 24%;
`;

export type SecondPageProps = {
  setStepsOnboarding: React.Dispatch<React.SetStateAction<number>>;
  closeOnboarding: () => void;
};

const SecondPage = ({
  setStepsOnboarding,
  closeOnboarding,
}: SecondPageProps) => {
  const { t, i18n } = useTranslation('onboarding');

  const userInfos = useAppState(userSelector);

  const stepsTranslations = t('steps', { returnObjects: true });

  const stepsData = stepsTranslations.map((translation, index) => ({
    video: get(VIDEOS_LANG, [index, i18n.language]),
    title: translation.title,
    text: <Trans i18nKey={translation.text as any} />,
  }));

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      flexWrap="nowrap"
      zIndex="99999"
      height="100%"
      width="100%"
      position="fixed"
      top="0"
      left="0"
      sx={{ bgcolor: 'custom.neutral.black.4' }}
    >
      <HalfCircleBottomGrey src={halfCircleBottomGrey} alt="half-circle" />
      <Grid
        container
        height="100%"
        item
        tablet={3.75}
        justifyContent="center"
        alignItems="center"
        sx={{ bgcolor: 'custom.neutral.white.pure' }}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          gap="1.4187rem"
          padding="4.75rem"
        >
          <Grid item>
            <img src={logoOnboarding} alt="lplogo" />
          </Grid>
          <Grid item textAlign="center">
            <Text variant="titleM">
              {t('welcome')} {userInfos?.firstName} {userInfos?.lastName}!
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <StepContent closeOnboarding={closeOnboarding} stepsData={stepsData} />
    </Grid>
  );
};

export default SecondPage;
