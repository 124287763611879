// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useState } from 'react';

import {
  ArrowLeftIcon,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { Marker } from '@react-google-maps/api';
import Map from 'components/molecules/Map';

import ShowroomImage from '../../assets/img/showrooms/map_showroom@10x.png';
import InfoPointImage from '../../assets/img/showrooms/map_tooltip@10x.png';
import { centers, defaultCenter } from '../organisms/ProfileShowroom';

const IconWrapper = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.75rem;
  z-index: 1;
  position: absolute;
  left: 2rem;
  top: 2rem;
`;

type ShowroomMapProps = {
  center: google.maps.LatLngLiteral;
  zoom: number;
  onClick: () => void;
  onMarkerClick: (index: number) => void;
};

const ShowroomMap = ({
  center,
  zoom,
  onClick,
  onMarkerClick,
}: ShowroomMapProps) => {
  const theme = useTheme();

  const showroomIconWidth = 70;
  const showroomIconHeight = 74;
  const infoPointIconWidth = 70;
  const infoPointIconHeight = 70;
  const [, setMap] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);

  const updateIsMapLoaded = useCallback(
    (isMapLoadedInfo: boolean, mapInstance: any) => {
      setIsMapLoaded(isMapLoadedInfo);
      setMap(mapInstance);
    },
    []
  );

  return (
    <Map
      isFullScreen
      fullscreenControl={false}
      streetViewControl={false}
      minZoom={2}
      maxZoom={20}
      zoom={zoom}
      center={center}
      isMapLoadedAction={updateIsMapLoaded}
      width="67%"
      height="41.825rem"
      bounds={[
        { id: 'showroom', position: centers[0] },
        { id: 'infoPoint1', position: centers[1] },
        { id: 'infoPoint2', position: centers[2] },
      ]}
    >
      <>
        {isMapLoaded && window.google && (
          <>
            {center !== defaultCenter ? (
              <IconWrapper onClick={onClick}>
                <ArrowLeftIcon
                  color={theme.palette.custom.primary[100]}
                  cursor="pointer"
                />
              </IconWrapper>
            ) : (
              <></>
            )}
            <Marker
              icon={{
                url: ShowroomImage,
                scaledSize: new google.maps.Size(
                  showroomIconWidth,
                  showroomIconHeight
                ),
              }}
              position={centers[0]}
              onClick={() => onMarkerClick(0)}
            />
            <Marker
              icon={{
                url: InfoPointImage,
                scaledSize: new google.maps.Size(
                  infoPointIconWidth,
                  infoPointIconHeight
                ),
              }}
              position={centers[1]}
              onClick={() => onMarkerClick(1)}
            />
            <Marker
              icon={{
                url: InfoPointImage,
                scaledSize: new google.maps.Size(
                  infoPointIconWidth,
                  infoPointIconHeight
                ),
              }}
              position={centers[2]}
              onClick={() => onMarkerClick(2)}
            />
          </>
        )}
      </>
    </Map>
  );
};

export default ShowroomMap;
