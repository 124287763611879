// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useMutation } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import { MutationConfig, queryClient } from 'lib/react-query';
import useMyPartnersStore, {
  IPartnerEntry,
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { IShareLinkForm } from '../types/trackingCode';

const shareTrackingCode =
  ({
    makeRequest,
    activePartner,
    shipmentId,
  }: {
    makeRequest: any;
    activePartner: IPartnerEntry;
    shipmentId: string;
  }) =>
  async (values: IShareLinkForm): Promise<IShareLinkForm> => {
    const { data, error } = await makeRequest({
      path: `api/v2/partners/${activePartner.id}/shipments/${shipmentId}/tracking-codes`,
      method: 'post',
      body: { ...values, role: (values.role as any) * 1 },
    });

    if (error) {
      throw new Error(error);
    }

    return data;
  };

type UseSendTrackingCode = {
  config?: MutationConfig<ReturnType<typeof shareTrackingCode>>;
  shipmentId: string;
};

export const useShareTrackingCode = ({
  config,
  shipmentId,
}: UseSendTrackingCode) => {
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const { error: toastError, success: toastSuccess } = useToastMessages();
  const [, makeRequest] = useImperativeRequest('shipment');

  return useMutation({
    onError: () => {
      toastError('messages:shareTrackingLinkError.message');
    },

    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['trackingCodes', shipmentId],
      });
      toastSuccess('messages:shareTrackingLinkSuccess.message');
    },
    ...config,
    mutationFn: shareTrackingCode({ makeRequest, activePartner, shipmentId }),
  });
};
