// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { Fragment } from 'react';

import { NavigationMenu as Menu } from '@livingpackets/design-system-react-next';
import useIsMobile from 'hooks/useIsMobile';
import { IRouteEntry } from 'models/routing';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation } from 'react-router-dom';
import useAppState, {
  anonymousTokenSelector,
} from 'stores/appState/useAppState';
import useMyPartnersStore, {
  partnersSelector,
} from 'stores/useMyPartnersStore';

import Footer from './Footer';
import Header from './Header';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import MenuDivider from './MenuDivider';
import useNavigationMenu from '../../hooks/useNavigationMenu';

const ANONYMOUS_OFFSET = '3.175rem';
const ONE_PARTNER_OFFSETS = { open: '13.175rem', close: '9.875rem' };
const MANY_PARTNERS_OFFSETS = { open: '14.375rem', close: '9.875rem' };

const addDivider = (path: string) =>
  path.includes('settings') && path.includes('partner');

const NavigationMenu = () => {
  const { t } = useTranslation('navigation');
  const location = useLocation();

  const { isMobile } = useIsMobile();

  const {
    openNav,
    setOpenNav,
    menuRoutes,
    isShipmentAnonymousPage,
    showKoalendar,
  } = useNavigationMenu();

  const partners = useMyPartnersStore(partnersSelector);
  const anonymousToken = useAppState(anonymousTokenSelector);

  const closeMenuOnMobile = () => {
    if (isMobile) setOpenNav(false);
  };

  const mainItems = menuRoutes.map(
    ({ path, icon, labelI18nKey, testId }: IRouteEntry) => {
      const match = matchPath(path + '/*', location.pathname);
      const selected = match !== null && path === match.pathnameBase;

      return (
        <Fragment key={path}>
          {addDivider(path) && <MenuDivider />}
          <Menu.Item
            icon={icon}
            open={openNav}
            component={Link}
            to={path}
            selected={selected}
            onClick={closeMenuOnMobile}
            data-testid={testId}
          >
            {t(labelI18nKey as unknown as TemplateStringsArray) || ''}
          </Menu.Item>
        </Fragment>
      );
    }
  );

  // Three different offsets for the toggleButton
  const computeTopOffset = () => {
    // Anonymous view
    if (anonymousToken) {
      return ANONYMOUS_OFFSET;
    }

    // Only one partner
    if (partners?.length === 1) {
      return openNav ? ONE_PARTNER_OFFSETS.open : ONE_PARTNER_OFFSETS.close;
    }

    // More than one partner
    return openNav ? MANY_PARTNERS_OFFSETS.open : MANY_PARTNERS_OFFSETS.close;
  };

  return (
    <Menu
      showToggleButton
      toggleButtonTopOffset={computeTopOffset()}
      open={openNav}
      setOpen={setOpenNav}
      header={<Header isShipmentAnonymousPage={isShipmentAnonymousPage} />}
      footer={<Footer showKoalendar={showKoalendar} />}
    >
      {/* Main menu items links */}
      {mainItems}
      {/* Login/Logout buttons */}
      {isShipmentAnonymousPage ? (
        <LoginButton open={openNav} />
      ) : (
        <LogoutButton open={openNav} />
      )}
    </Menu>
  );
};

export default NavigationMenu;
