// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  DeleteIcon,
  Text,
  ButtonV2 as Button,
  Stack,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import Modal from '../../atoms/Modal';

const DeleteShipmentReturnModal = ({
  open,
  onSubmit,
  onCancel,
}: {
  open: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation('shipments');

  return (
    <Modal open={open} handleClose={onCancel}>
      <>
        <Text variant="titleM">{t('modal.deleteShipmentReturn.title')}</Text>
        <Text variant="bodyTextS" mt="1.5625rem">
          {t('modal.deleteShipmentReturn.warning')}
        </Text>
        <Stack direction="row" justifyContent="space-between" mt="2rem">
          <Button
            variant="secondary"
            onClick={onCancel}
            data-testid="delete-shipment-return-button-cancel"
          >
            {t('modal.deleteShipmentReturn.button.dismiss')}
          </Button>
          <Button
            color="critical"
            icon={DeleteIcon}
            onClick={onSubmit}
            data-testid="delete-shipment-return-button-submit"
          >
            {t('modal.deleteShipmentReturn.button.submit')}
          </Button>
        </Stack>
      </>
    </Modal>
  );
};

export default DeleteShipmentReturnModal;
