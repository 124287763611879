// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  Alert,
  Box,
  Stack,
  ButtonV2 as Button,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import { AuthenticationView } from 'components/containers/AuthenticationView';
import { PATHS } from 'configs';
import { createBrowserHistory } from 'history';
import useFormTemplate from 'hooks/useFormTemplate';
import usePublic from 'hooks/usePublic';
import { useShipmentAddByTrackingCode } from 'hooks/useShipmentAddByTrackingCode';
import {
  IDeliveryKeyForm,
  INITIAL_STATE_DELIVERY_KEY_FORM,
} from 'models/deliveryKey';
import { IShipment } from 'models/shipment';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import { retrieveDeliveryKeySchema } from 'schemas/retrieveDeliveryKeySchema';
import useAppState, {
  anonymousTokenSelector,
  dispatch,
} from 'stores/appState/useAppState';

import DeliveryKeyExpiredWarningModal from '../../molecules/modals/DeliveryKeyExpiredWarningModal';

const RetrieveDeliveryKeyPage = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let history = createBrowserHistory();
  const pageInfo = location.state || {};
  const displayDeliveryKeyExpiredWarningOnPageLoad =
    pageInfo.displayDeliveryKeyExpiredWarningOnPageLoad
      ? pageInfo.displayDeliveryKeyExpiredWarningOnPageLoad
      : false;

  history.replace(PATHS.AUTHENTICATION.RETRIEVE_DELIVERY_KEY, {});

  const { t } = useTranslation('authentication');
  const getShipmentAddByTrackingCode = useShipmentAddByTrackingCode();
  const anonymousToken = useAppState(anonymousTokenSelector);
  const { postSignup } = usePublic();

  const [trackingCode, setTrackingCode] = useState<string>();
  const [shipmentData, setShipmentData] = useState<IShipment | null>();
  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] =
    useState<boolean>(false);

  const [
    displayDeliveryKeyExpiredWarning,
    setDisplayDeliveryKeyExpiredWarning,
  ] = useState<boolean>(displayDeliveryKeyExpiredWarningOnPageLoad);

  const props = useSpring({
    to: { height: '0', opacity: 0 },
    from: { height: '6.125rem', opacity: 1 },
    reverse: isErrorMessageDisplayed,
  });

  useEffect(() => {
    postSignup().then(({ success, tokenResponse }) => {
      if (!success) {
        navigate(PATHS.PAGE_404);
      } else {
        if (tokenResponse) {
          dispatch({
            type: 'SET_ANONYMOUS_TOKEN',
            payload: tokenResponse.accessToken,
          });
        }
      }
    });
  }, [postSignup, navigate]);

  useEffect(() => {
    if (!shipmentData && anonymousToken) {
      if (trackingCode) {
        getShipmentAddByTrackingCode(trackingCode).then(
          async ({ success: successShipment, shipment }) => {
            if (!successShipment) {
              setIsErrorMessageDisplayed(true);
            } else {
              setShipmentData(shipment);
              navigate(
                generatePath(PATHS.SHIPMENT.ANONYMOUS_DETAIL, {
                  trackingCode: trackingCode,
                })
              );
            }
          }
        );
      }
    }
  }, [
    navigate,
    anonymousToken,
    getShipmentAddByTrackingCode,
    shipmentData,
    trackingCode,
  ]);

  const {
    formState: { isValid },
    getValues,
    control,
  } = useFormTemplate<IDeliveryKeyForm>({
    resolver: retrieveDeliveryKeySchema,
    defaultValues: INITIAL_STATE_DELIVERY_KEY_FORM,
  });

  const redirectToLandingPage = useCallback(() => {
    navigate(PATHS.AUTHENTICATION.LANDING);
  }, [navigate]);

  const submitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      const formValues = getValues();
      const deliveryKey = formValues.deliveryKey;
      setTrackingCode(deliveryKey);
    },
    [getValues]
  );

  return (
    <>
      <AuthenticationView
        title={t('retrieveDeliveryKey.title')}
        description={t('retrieveDeliveryKey.description')}
        customWidth="27.375rem"
      >
        <Stack
          component="form"
          onSubmit={submitHandler}
          data-testid="retrieve-delivery-key-container"
        >
          {trackingCode && (
            <animated.div style={props}>
              <Box>
                <Alert
                  state="error"
                  title={t(
                    'retrieveDeliveryKey.form.deliveryKey.validation.invalid'
                  )}
                />
              </Box>
            </animated.div>
          )}
          <Controller
            name="deliveryKey"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('retrieveDeliveryKey.form.deliveryKey.placeholder')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                fullWidth
                sx={{
                  mb: '2rem',
                }}
                {...field}
                onChange={e => {
                  field.onChange(e);
                  setIsErrorMessageDisplayed(false);
                }}
              />
            )}
          />
          <Button
            {...{ type: 'submit' }}
            disabled={!isValid}
            sx={{ mb: '1rem' }}
            data-testid="retrieve-delivery-key-confirm-button"
          >
            {t('retrieveDeliveryKey.buttons.confirm')}
          </Button>
          <Button
            variant="secondary"
            onClick={() => redirectToLandingPage()}
            sx={{ mt: 0 }}
          >
            {t('retrieveDeliveryKey.buttons.cancel')}
          </Button>
        </Stack>
      </AuthenticationView>
      <DeliveryKeyExpiredWarningModal
        isVisible={displayDeliveryKeyExpiredWarning}
        onDismiss={() => setDisplayDeliveryKeyExpiredWarning(false)}
      />
    </>
  );
};

export default RetrieveDeliveryKeyPage;
