// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useState } from 'react';

import {
  Stack,
  styled,
  useTheme,
  Theme,
} from '@livingpackets/design-system-react-next';
import Badge from 'components/containers/layout/components/sidebar/partnerCard/Badge';
import { PartnerBusinessStatusEnum } from 'enums/accountTypeEnum';
import { AccountSelectionDisplayMode } from 'enums/layout';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useIsMobile from 'hooks/useIsMobile';
import useSessionStorage from 'hooks/useSessionStorage';
import { IPartner } from 'models/partner';
import { useNavigate } from 'react-router-dom';
import { animated, config, useSpring } from 'react-spring';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import useLayoutStore, {
  openNavSelector,
  setOpenNavSelector,
} from 'stores/useLayoutStore';
import useMyPartnersStore, {
  activePartnerSelector,
  IPartnerEntry,
  partnersSelector,
} from 'stores/useMyPartnersStore';

import ButtonContent from './ButtonContent';

const getColor = ({
  theme,
  isHovered,
  isActive,
  isOpen,
}: {
  theme: Theme;
  isHovered: boolean;
  isActive: boolean;
  isOpen: boolean;
}) => {
  if (isActive) {
    return {
      bgColor: theme.palette.custom.neutral.white.pure,
    };
  }
  if (isHovered || isOpen) {
    return {
      bgColor: theme.palette.custom.neutral.black[8],
    };
  }

  return {
    bgColor: theme.palette.custom.neutral.black[3],
  };
};

const Container = styled(animated.div, {
  shouldForwardProp: prop =>
    prop !== '$canBeSelected' && prop !== '$displayMode',
})<{
  $canBeSelected: boolean;
  $displayMode: AccountSelectionDisplayMode;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  position: relative;
  pointer-events: ${({ $canBeSelected }) =>
    $canBeSelected ? 'initial' : 'none'};

  ${props =>
    props.$displayMode === AccountSelectionDisplayMode.SHORT &&
    `
  margin-left: .3125rem;
  width: 95%;
  transition: 200ms;
  border-radius: .5rem;
  padding: .3125rem;
  align-items: center;
  
  &:hover {
    background: ${props.theme.palette.custom.neutral.black[8]};
  }
  `}
`;

const NavButton = styled(animated.div, {
  shouldForwardProp: prop =>
    prop !== '$canBeSelected' && prop !== '$isOpen' && prop !== '$displayMode',
})<{
  color: string;
  $canBeSelected: boolean;
  $isOpen: boolean;
  $displayMode: AccountSelectionDisplayMode;
}>`
  ${props =>
    props.$displayMode === AccountSelectionDisplayMode.BIG &&
    `
  display: flex;
  padding: ${
    props.$canBeSelected && props.$isOpen
      ? '.75rem .5rem 0 .5rem'
      : '.75rem .5rem .75rem .5rem'
  };
  align-items: center;
  justify-content: center;
  z-index: 0;
  color: ${props.theme.palette.custom.neutral.black[100]};
  width: auto;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  `}

  ${props =>
    props.$displayMode === AccountSelectionDisplayMode.SHORT &&
    `
    width: 100%;
  `}
`;

interface IPartnerButton {
  accountName: string;
  typeName?: string;
  cityName?: string;
  to: string;
  isActive: boolean;
  canBeSelected?: boolean;
  displayMode: AccountSelectionDisplayMode;
  partner?: IPartner | IPartnerEntry;
}

const PartnerButton = ({
  accountName,
  typeName,
  cityName,
  to,
  isActive,
  canBeSelected = true,
  displayMode,
  partner,
}: IPartnerButton) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { resetSessionStorage } = useSessionStorage();

  const { isMobile } = useIsMobile();

  const currentUser = useAppState(userSelector);
  const currentPartner = useMyPartnersStore(activePartnerSelector);
  const partnerList = useMyPartnersStore(partnersSelector);
  const open = useLayoutStore(openNavSelector);
  const setOpenNav = useLayoutStore(setOpenNavSelector);

  const [isHovered, setIsHovered] = useState(false);
  // Expands the list of partners
  const [closeNavComplete, setCloseNavComplete] = useState(true);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const { bgColor } = getColor({ theme, isHovered, isActive, isOpen: open });

  const redirectToPartnerPage = useCallback(
    (to: string) => {
      // Only redirect list items (partner) (and not the current partner)
      if (displayMode !== AccountSelectionDisplayMode.BIG) {
        navigate(to);
      }
    },
    [displayMode, navigate]
  );

  /**
   * Hard coded height for the container when the side nav is open
   * depending on the user type and the number of partners
   */
  const getContainerBigHeightWhenSideNavOpen = useCallback(() => {
    // Personal account
    if (currentUser?.businessType === UserBusinessTypeEnum.PERSONAL) {
      return '7.1875rem';
    }

    // Multiple partners
    if (partnerList.length > 2) {
      // Multiple partners with validated or pending partner
      if (
        currentPartner.businessInformation.status ===
          PartnerBusinessStatusEnum.VALIDATED ||
        currentPartner.businessInformation.status ===
          PartnerBusinessStatusEnum.PENDING
      ) {
        return '7.1875rem';
      }

      return '8.8125rem';
    } else {
      // One partner
      return '7.1875rem';
    }
  }, [currentPartner, currentUser?.businessType, partnerList.length]);

  const containerBigStyles = useSpring({
    config: config.default,
    from: { width: '5rem', height: '4rem' },
    to: {
      width: open ? '14rem' : '5rem',
      height: open ? getContainerBigHeightWhenSideNavOpen() : '4rem',
    },
    onStart: (ds: any) => {
      setCloseNavComplete(ds.width === '5rem');
    },
  });

  return (
    <Container
      style={
        displayMode === AccountSelectionDisplayMode.BIG
          ? containerBigStyles
          : undefined
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        if (!isActive) {
          resetSessionStorage();
        }

        // Close nav on mobile
        if (isMobile) setOpenNav(false);
        redirectToPartnerPage(to);
      }}
      $canBeSelected={canBeSelected}
      $displayMode={displayMode}
    >
      <NavButton
        color={bgColor}
        $canBeSelected={canBeSelected}
        $isOpen={open}
        $displayMode={displayMode}
      >
        {displayMode === AccountSelectionDisplayMode.BIG ? (
          <Stack alignItems="center">
            <Badge isOpen={open} isActive={false} partner={partner} />
            {!closeNavComplete && (
              <ButtonContent
                isActive={isActive}
                accountName={accountName}
                typeName={typeName}
                cityName={cityName}
                displayMode={displayMode}
              />
            )}
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center">
            <Badge isOpen={false} isActive={false} partner={partner} />
            {!closeNavComplete && (
              <ButtonContent
                isActive={isActive}
                accountName={accountName}
                typeName={typeName}
                cityName={cityName}
                displayMode={displayMode}
              />
            )}
          </Stack>
        )}
      </NavButton>
    </Container>
  );
};

export default PartnerButton;
