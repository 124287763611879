// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  Grid,
  Stack,
  Switch,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import FiguralLogo from 'assets/img/insurance/figural-logo.svg';
import FiguralInformationModal from 'components/molecules/modals/FiguralInformationModal';
import { useTranslation } from 'react-i18next';

import { usePartnership } from '../../../api/partnership/getPartnership';
import { useInsurance } from '../../../api/partnership/useInsurance';

const FiguralImg = styled('img')`
  width: 3.25rem;
  height: 3.25rem;
`;

export const InsuranceCard = () => {
  const { t } = useTranslation('partnerSettings');

  const { data: partnership } = usePartnership();

  const { activateInsurance, deactivateInsurance } = useInsurance();

  const [
    isFiguralInsuranceInformationModalVisible,
    setIsFiguralInsuranceInformationModalVisible,
  ] = useState(false);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      bgcolor="custom.neutral.black.2"
      borderRadius="1.25rem"
      p="1rem"
    >
      <Grid item>
        <Stack direction="row" gap=".75rem">
          <FiguralImg src={FiguralLogo} alt="Figural logo" />
          <Stack justifyContent="center" gap="0.2rem">
            <Text variant="titleXS">{t('offer.insurance.brandName')}</Text>
            <Text
              variant="titleXS"
              color="primary.main"
              sx={{
                textDecoration: 'underline',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => setIsFiguralInsuranceInformationModalVisible(true)}
            >
              {t('offer.insurance.learnMoreLinkText')}
            </Text>
          </Stack>
        </Stack>
      </Grid>
      <Grid item>
        <Stack direction="row" spacing="0.5rem" alignItems="center">
          <Text variant="titleXS" sx={{ marginTop: '.375rem !important' }}>
            {t('offer.insurance.ensureParcel')}
          </Text>
          <Switch
            checked={partnership?.secursus_enabled}
            data-testid="insurance-switch"
            onChange={event =>
              event.target.checked ? activateInsurance() : deactivateInsurance()
            }
          />
        </Stack>
      </Grid>
      <FiguralInformationModal
        isVisible={isFiguralInsuranceInformationModalVisible}
        onDismiss={() => setIsFiguralInsuranceInformationModalVisible(false)}
      />
    </Grid>
  );
};
