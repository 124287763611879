// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useState } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from '@livingpackets/design-system-react';
import {
  Box,
  ButtonV2 as Button,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import SearchContactNameForm from 'components/molecules/Form/SearchContactNameForm';
import { IContact } from 'models/contactAddress';
import { useTranslation } from 'react-i18next';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import Modal, { IModalWrapperType } from '../../atoms/Modal';

interface IViewAddAddressToContact {
  visible: boolean;
  onContactSelect: (contactId: string) => void;
  onDismiss: () => void;
}

const AddAddressToContactModal = ({
  visible,
  onContactSelect,
  onDismiss,
}: IViewAddAddressToContact) => {
  const { t } = useTranslation('contactAddress');

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const [contactSelected, setContactSelected] = useState<IContact | undefined>(
    undefined
  );

  const handleDismiss = useCallback(async () => {
    onDismiss();
  }, [onDismiss]);

  const handleContactSelect = useCallback(
    (contact?: IContact) => {
      setContactSelected(contact);
    },
    [setContactSelected]
  );

  const handleContactSelectClick = useCallback(() => {
    if (contactSelected) {
      onContactSelect(contactSelected.id);
    }
  }, [onContactSelect, contactSelected]);

  return (
    <Modal
      open={visible}
      handleClose={onDismiss}
      wrapperType={IModalWrapperType.large}
    >
      <Card width="100%">
        <>
          <CardHeader backgroundColor="black.100" color="white" padding={5}>
            <Text variant="bodyTextXS">
              {t('modal.addAddressToContact.breadcrumb', {
                partnerName: activePartner.name,
              })}
            </Text>
            <Text variant="titleXS" mt="1.375rem">
              {t('modal.addAddressToContact.title')}
            </Text>
            <Text variant="titleXL">
              {t('modal.addAddressToContact.subTitle')}
            </Text>
          </CardHeader>
          <CardBody>
            <Box my="1rem">
              <SearchContactNameForm
                onContactSelect={handleContactSelect}
                onDismiss={() => {}}
                isFormDisplayed={true}
              />
            </Box>
          </CardBody>
          <CardFooter>
            <Stack direction="row" justifyContent="space-between">
              <Button
                data-testid="dismissBtn"
                variant="secondary"
                onClick={handleDismiss}
              >
                {t('modal.addAddressToContact.button.dismiss')}
              </Button>
              <Button
                data-testid="submitBtn"
                variant="secondary"
                disabled={!contactSelected}
                onClick={handleContactSelectClick}
              >
                {t('modal.addAddressToContact.button.select')}
              </Button>
            </Stack>
          </CardFooter>
        </>
      </Card>
    </Modal>
  );
};

export default AddAddressToContactModal;
