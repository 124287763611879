// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useState } from 'react';

import {
  Box,
  Grid,
  Text,
  TabsV2 as Tabs,
  TabPanelV2 as TabPanel,
  useTheme,
  Stack,
  BoxFoldedIcon,
  BoxDefaultIcon,
} from '@livingpackets/design-system-react-next';
import TheBoxRC4ADE from 'assets/img/remote/lotties/de/rc-the-box-4a-de.json';
import TheBoxRC4BDE from 'assets/img/remote/lotties/de/rc-the-box-4b-de.json';
import TheBoxPRORC4ADE from 'assets/img/remote/lotties/de/rc-the-box-pro-4a-de.json';
import TheTabletPRORC4DE from 'assets/img/remote/lotties/de/rc-the-tablet-4a-de.json';
import TheBoxRC4AEN from 'assets/img/remote/lotties/en/rc-the-box-4a-en.json';
import TheBoxRC4BEN from 'assets/img/remote/lotties/en/rc-the-box-4b-en.json';
import TheBoxPRORC4AEN from 'assets/img/remote/lotties/en/rc-the-box-pro-4a-en.json';
import TheTabletPRORC4EN from 'assets/img/remote/lotties/en/rc-the-tablet-4a-en.json';
import TheBoxRC4AFR from 'assets/img/remote/lotties/fr/rc-the-box-4a-fr.json';
import TheBoxRC4BFR from 'assets/img/remote/lotties/fr/rc-the-box-4b-fr.json';
import TheBoxPRORC4AFR from 'assets/img/remote/lotties/fr/rc-the-box-pro-4a-fr.json';
import TheTabletPRORC4FR from 'assets/img/remote/lotties/fr/rc-the-tablet-4a-fr.json';
import { ProductTypeEnum } from 'enums/ProductEnum';
import { Loading } from 'features/account';
import useShipmentDetail from 'hooks/useShipmentDetail';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { DeployReturnTab } from './DeployReturnTab';
import { getProductType } from '../../../utils/product';

const ressources: any = [
  {
    box: {
      en: { lottie: TheBoxRC4AEN },
      fr: { lottie: TheBoxRC4AFR },
      de: { lottie: TheBoxRC4ADE },
    },
    tablet: {
      en: { lottie: TheTabletPRORC4EN },
      fr: { lottie: TheTabletPRORC4FR },
      de: { lottie: TheTabletPRORC4DE },
    },
    boxPro: {
      en: { lottie: TheBoxPRORC4AEN },
      fr: { lottie: TheBoxPRORC4AFR },
      de: { lottie: TheBoxPRORC4ADE },
    },
  },
  {
    box: {
      en: { lottie: TheBoxRC4BEN },
      fr: { lottie: TheBoxRC4BFR },
      de: { lottie: TheBoxRC4BDE },
    },
  },
];

type Tab = {
  label: React.ReactNode; // ou le type de label que tu utilises
};

export const DeployReturnTabs = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation('deployReturn');
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const { getShipmentDetail } = useShipmentDetail();
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [tab, setTab] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentProductType, setCurrentProductType] = useState<any>(
    ProductTypeEnum.BOX
  );

  const currentLanguage = i18n.language;

  useEffect(() => {
    if (shipmentId) {
      getShipmentDetail(activePartner.id, shipmentId).then(
        ({ success, shipment }) => {
          if (success && shipment && shipment.product) {
            const productType = getProductType(shipment.product.article_code);
            setCurrentProductType(productType);

            const newTab = [
              {
                label: (
                  <Stack direction="row" alignItems="start">
                    <BoxDefaultIcon />
                    <Text variant="titleS">{t('defaultFormat.titleTab')}</Text>
                  </Stack>
                ),
              },
              ...(currentProductType === ProductTypeEnum.BOX
                ? [
                    {
                      label: (
                        <Stack direction="row" alignItems="start">
                          <BoxFoldedIcon />
                          <Text variant="titleS">
                            {t('foldedFormat.titleTab')}
                          </Text>
                        </Stack>
                      ),
                    },
                  ]
                : []),
            ];
            setTabs(newTab);
            setIsLoading(false);
          }
        }
      );
    }
  }, [activePartner.id, currentProductType, getShipmentDetail, shipmentId, t]);

  if (isLoading) return <Loading />;

  return (
    <Grid
      container
      direction="column"
      sx={{
        height: '100%',
        justifyContent: 'space-around',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        minHeight: '28.375rem',
      }}
    >
      <Stack pb="1rem" alignItems="center" gap=".625rem">
        <Text variant="titleXL"> {t('title')}</Text>
        <Text
          variant="bodyTextM"
          sx={{
            textAlign: 'center',
            paddingLeft: '3.125rem',
            paddingRight: '2.5rem',
          }}
        >
          {t('description')}
        </Text>
      </Stack>
      {ressources && (
        <Box
          sx={{
            backgroundColor: theme.palette.custom.neutral.black[2],
            borderRadius: '1.5rem',
            padding: '1.5rem',
            marginBottom: '2.5rem',
          }}
        >
          <Tabs value={tab} setValue={setTab} tabs={tabs} />
          <Box mt={1}>
            <TabPanel value={tab} index={0}>
              <DeployReturnTab
                ressource={
                  ressources[tab][currentProductType][currentLanguage].lottie
                }
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <DeployReturnTab
                ressource={
                  ressources[tab][currentProductType][currentLanguage].lottie
                }
              />
            </TabPanel>
          </Box>
        </Box>
      )}
    </Grid>
  );
};
