// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useEffect, useState } from 'react';

/**
 * Use the top ref for the modal top, left, right and width of the modal.
 * Use the bottom ref for the modal bottom and height.
 * If no bottom ref is provided, the top ref will be used.
 */
const useAutoResizableModal = ({ topRef, bottomRef = topRef }: any) => {
  const [modalOpt, setModalOpt] = useState<any>({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });

  const handleResize = useCallback(() => {
    if (topRef?.current && bottomRef?.current) {
      const { top, left, right, width } =
        topRef.current.getBoundingClientRect();
      const { bottom } = bottomRef.current.getBoundingClientRect();

      setModalOpt({
        // Position
        top,
        left,
        right: window.innerWidth - right,
        bottom: window.innerHeight - bottom,
        // Size
        width: width,
        height: bottom - top,
      });
    }
  }, [topRef, bottomRef]);

  // Redraw on window resize
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  return {
    top: modalOpt.top,
    left: modalOpt.left,
    right: modalOpt.right,
    bottom: modalOpt.bottom,
    width: modalOpt.width,
    height: modalOpt.height,
    handleResize,
  };
};

export default useAutoResizableModal;
