// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  SenderAddressIcon,
  RecipientAddressIcon,
  styled,
  Stack,
  StackProps,
} from '@livingpackets/design-system-react-next';
import { DetailsCard, DetailsSpan } from 'components/atoms/ShipmentAtoms';
// @ts-ignore
import { FlexDirectionProperty } from 'csstype';
import isCancelledState from 'helpers/isCancelledState';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';

import { Address, AddressName } from './Address';

interface IShipmentAddress {
  shipment: IShipment;
  titleTransKey?: string;
  blockDirection?: FlexDirectionProperty;
}

const IconCard = styled('div')`
  height: 45px;
  width: 45px;
  min-width: 45px;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  background: ${({ theme }) => theme.palette.custom.neutral.black[4]};
  margin-right: 16px;
  text-align: center;
  line-height: 60px;
`;

const ShipmentAddressCard = ({
  shipment,
  titleTransKey,
  blockDirection = 'column',
  ...rest
}: IShipmentAddress & StackProps) => {
  const { t } = useTranslation('shipments');

  const isCancelled = isCancelledState(shipment);

  return (
    <Stack {...rest}>
      <DetailsSpan>
        {titleTransKey
          ? t(titleTransKey as unknown as TemplateStringsArray)
          : t('detail.address.title')}
      </DetailsSpan>
      <DetailsCard
        padding="20px"
        mt="12px"
        fontSize="12px"
        lineHeight="19px"
        style={{
          borderRadius: '10px',
          flexDirection: blockDirection,
          justifyContent: 'space-between',
          gap: blockDirection === 'column' ? 0 : '2.5rem',
        }}
        disabled={isCancelled}
      >
        <Stack direction="row" data-testid="shipment-sender-adress">
          <IconCard>
            <SenderAddressIcon />
          </IconCard>
          <Stack>
            <DetailsSpan>{t('detail.address.sender')}</DetailsSpan>
            <AddressName address={shipment.sender.address} />
            <Address address={shipment.sender.address} inline />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          mt={blockDirection === 'column' ? '20px' : 0}
          data-testid="shipment-recipient-adress"
        >
          <IconCard>
            <RecipientAddressIcon />
          </IconCard>
          <Stack>
            <DetailsSpan>{t('detail.address.recipient')}</DetailsSpan>
            <AddressName address={shipment.recipient.address} />
            <Address address={shipment.recipient.address} inline />
          </Stack>
        </Stack>
      </DetailsCard>
    </Stack>
  );
};

export default ShipmentAddressCard;
