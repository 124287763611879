// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { IIbanForm } from '@livingpackets/design-system-react/src/components/IBANForm/IBANForm';
import { ISwiftForm } from '@livingpackets/design-system-react/src/components/SWIFTForm/SWIFTForm';
import useImperativeRequest from 'hooks/useImperativeRequest';

import useToastMessages from './useToastMessages';
import { HttpRequestMethod } from '../enums/httpRequest';
import { Wire } from '../models/Wallet';

const useMeWallet = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpAccountV3');
  const { error: toastError } = useToastMessages();

  const getWalletList = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/me/wallet`,
      method: HttpRequestMethod.GET,
    });

    if (error) {
      toastError('messages:getWalletListError.message', {
        wide: true,
      });

      return { success: false, data: null };
    }

    return { success: true, data };
  }, [makeRequest, toastError]);

  const createWallet = useCallback(
    async (wire: any) => {
      const { error, data } = await makeRequest({
        path: `/me/wallet/transaction-methods`,
        method: HttpRequestMethod.POST,
        body: { wire },
      });

      if (error) {
        return { success: false, data: null };
      }

      return { success: true, data };
    },
    [makeRequest]
  );

  const deleteWallet = useCallback(
    async (transactionMethodsId: string) => {
      const { error } = await makeRequest({
        path: `/me/wallet/transaction-methods/${transactionMethodsId}`,
        method: HttpRequestMethod.DELETE,
      });

      if (error) {
        toastError('messages:deleteWalletError.message', {
          wide: true,
        });

        return { success: false };
      }

      return { success: true };
    },
    [makeRequest, toastError]
  );

  const getWireFromBankAccountIbanForm = useCallback(
    (ibanFormData: IIbanForm): Wire => ({
      bank_title: ibanFormData.bankName,
      beneficiary_name: ibanFormData.beneficiaryName,
      bic: ibanFormData.bicCode,
      iban: ibanFormData.ibanCode,
    }),
    []
  );

  const getWireFromBankAccountSwiftForm = useCallback(
    (swiftFormData: ISwiftForm): Wire => ({
      bank_title: swiftFormData.bankName,
      beneficiary_name: swiftFormData.beneficiaryName,
      swift_code: swiftFormData.swiftCode,
      bank_account_number: swiftFormData.bankAccountNumber,
    }),
    []
  );

  return {
    loading,
    getWalletList,
    createWallet,
    deleteWallet,
    getWireFromBankAccountIbanForm,
    getWireFromBankAccountSwiftForm,
  };
};

export default useMeWallet;
