// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useState } from 'react';

import { Tag } from '@livingpackets/design-system-react';
import {
  Box,
  Text,
  Stack,
  styled,
  Grid,
} from '@livingpackets/design-system-react-next';
import MouseTooltip from 'components/atoms/MouseTooltip';
import {
  PartnerBusinessStatusEnum,
  PartnerBusinessTypeEnum,
} from 'enums/accountTypeEnum';
import { AccountSelectionDisplayMode } from 'enums/layout';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import { IPartner } from 'models/partner';
import { animated, config, useSpring } from 'react-spring';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import useCurrentPartnerStore, {
  currentPartnerSelector,
} from 'stores/useCurrentPartnerStore';
import useLayoutStore, { openNavSelector } from 'stores/useLayoutStore';

const Wrapper = styled(animated.div, {
  shouldForwardProp: prop => prop !== '$displayMode',
})<{
  $displayMode: AccountSelectionDisplayMode;
}>`
  display: flex;
  align-items: ${({ $displayMode }) =>
    $displayMode === AccountSelectionDisplayMode.BIG ? 'center' : 'flex-start'};
  flex-direction: column;
  height: 100%;
  margin-top: ${({ $displayMode }) =>
    $displayMode === AccountSelectionDisplayMode.BIG ? '.5rem' : 'initial'};
  margin-left: ${({ $displayMode }) =>
    $displayMode === AccountSelectionDisplayMode.BIG
      ? '0 !important'
      : '.3125rem !important'};
`;

const CustomButton = styled(Stack, {
  shouldForwardProp: prop => prop !== 'displayMode',
})<{
  displayMode: AccountSelectionDisplayMode;
}>`
  height: 100%;
  display: flex;
  align-items: ${({ displayMode }) =>
    displayMode === AccountSelectionDisplayMode.BIG ? 'center' : 'flex-start'};
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 12.5rem;
  white-space: nowrap;
`;

const AccountNameContainer = styled(Box)`
  height: 2.625rem;
  padding: 0.5rem;
  background: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border: 0.0313rem solid
    ${({ theme }) => theme.palette.custom.neutral.black[50]};

  box-shadow: 0 0.5rem 1.25rem rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;

  display: flex;
  align-items: center;

  white-space: nowrap;

  z-index: 2;
`;

interface ButtonContentProps {
  isActive: boolean;
  accountName: string;
  typeName?: string;
  cityName?: string;
  displayMode: AccountSelectionDisplayMode;
}

const ButtonContent = ({
  accountName,
  typeName,
  cityName,
  displayMode,
}: ButtonContentProps) => {
  const currentUser = useAppState(userSelector);
  const currentPartner: IPartner = useCurrentPartnerStore(
    currentPartnerSelector
  );
  const openNav = useLayoutStore(openNavSelector);

  const [
    isAccountNameMouseTooltipVisible,
    setIsAccountNameMouseTooltipVisible,
  ] = useState<boolean>(false);

  const getWrapperBigHeightWhenSideNavOpen = useCallback(() => {
    if (currentUser?.businessType === UserBusinessTypeEnum.PERSONAL) {
      return '3.3125rem';
    }

    if (typeName) {
      return '3rem';
    }

    return '2.25rem';
  }, [currentUser?.businessType, typeName]);

  const getTagState = useCallback(() => {
    if (currentUser?.businessType === UserBusinessTypeEnum.PERSONAL) {
      return 'info';
    }

    if (
      currentPartner.businessInformation.type === PartnerBusinessTypeEnum.TEST
    ) {
      return 'pending';
    }

    if (
      currentPartner.businessInformation.status ===
      PartnerBusinessStatusEnum.PENDING
    ) {
      return 'idle';
    }

    return 'info';
  }, [currentUser, currentPartner]);

  const wrapperBigStyles = useSpring({
    config: config.default,
    from: { width: '0', height: '0' },
    to: {
      opacity: openNav ? 1 : 0,
      width: openNav ? '12.5rem' : '0',
      height: openNav ? getWrapperBigHeightWhenSideNavOpen() : '0',
    },
  });

  return (
    <Wrapper
      style={
        displayMode === AccountSelectionDisplayMode.BIG
          ? wrapperBigStyles
          : undefined
      }
      $displayMode={displayMode}
    >
      <Grid alignItems="stretch" justifyContent="center">
        <CustomButton direction="column" displayMode={displayMode}>
          <Text
            variant="titleS"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign:
                displayMode === AccountSelectionDisplayMode.BIG
                  ? 'center'
                  : 'left',
              maxWidth:
                displayMode === AccountSelectionDisplayMode.BIG
                  ? '12.5rem'
                  : '9.0625rem',
            }}
            {...{
              onMouseEnter: () => setIsAccountNameMouseTooltipVisible(true),
              onMouseLeave: () => setIsAccountNameMouseTooltipVisible(false),
            }}
            data-testid="side-nav-accountName"
          >
            {accountName}
          </Text>
          {accountName.length > 20 && (
            <MouseTooltip
              visible={isAccountNameMouseTooltipVisible}
              offsetX={-35}
              offsetY={10}
            >
              <AccountNameContainer>{accountName}</AccountNameContainer>
            </MouseTooltip>
          )}
          {cityName && (
            <Text
              variant="bodyTextS"
              style={{
                textAlign:
                  displayMode === AccountSelectionDisplayMode.BIG
                    ? 'center'
                    : 'left',
                maxWidth:
                  displayMode === AccountSelectionDisplayMode.BIG
                    ? '12.5rem'
                    : '9.0625rem',
                marginTop: '.25rem !important',
              }}
            >
              {cityName}
            </Text>
          )}
          {typeName && (
            <Tag state={getTagState()} text={typeName.toUpperCase()} />
          )}
        </CustomButton>
      </Grid>
    </Wrapper>
  );
};

export default ButtonContent;
