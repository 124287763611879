// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  ButtonV2 as Button,
  DeleteIcon,
  Grid,
  ModalV2 as Modal,
  Text,
} from '@livingpackets/design-system-react-next';
import useToastMessages from 'hooks/useToastMessages';
import { useTranslation } from 'react-i18next';

import { useDeleteAddress } from '../../../api/addresses/deleteAddress';

export const Delete = ({
  addressId,
  disabled,
}: {
  addressId: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('account');
  const { error: toastError, success: toastSuccess } = useToastMessages();

  const { mutate, isPending } = useDeleteAddress();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        data-testid="delete-address-button"
        icon={DeleteIcon}
        variant="tertiary"
        disabled={disabled}
        sx={{ color: 'custom.neutral.black.100' }}
        onClick={() => setOpen(true)}
      />
      <Modal open={open} setOpen={setOpen} widthCustom="34.875rem">
        <Grid container direction="column" gap="2rem">
          <Grid item mobile={12}>
            <Text variant="titleM">{t('address.deleteTitle')}</Text>
          </Grid>
          <Grid item mobile={12}>
            <Grid container direction="column" gap=".25rem">
              <Text variant="bodyTextS">{t('address.deleteSubtitle')}</Text>
              <Text variant="bodyTextS">{t('address.deleteSubtitleBis')}</Text>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Button
              data-testid="delete-account-cancel-button"
              variant="secondary"
              onClick={() => setOpen(false)}
            >
              {t('address.cancelButton')}
            </Button>
            <Button
              data-testid="delete-account-confirm-button"
              disabled={isPending}
              variant="primary"
              color="critical"
              icon={DeleteIcon}
              onClick={async () => {
                mutate(addressId, {
                  onSettled: () => setOpen(false),
                  onError: () => {
                    toastError('messages:deleteAddressError.message');
                  },
                  onSuccess: () => {
                    toastSuccess('messages:deleteAddressSuccess.message');
                  },
                });
              }}
            >
              {t('address.deleteButton')}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
