// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ButtonV2 as Button,
  Paper,
  Stack,
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import NameCard from 'components/atoms/NameCard';
import { IBaseAddress } from 'models/address';
import { IUser } from 'models/user';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Address } from './Address';

interface IAccountInfoCard {
  user: IUser | null;
  address: IBaseAddress;
  subtitle: string;
  website: string;
  phoneNumber: string;
  editLink: string;
}

const InfomationWrapper = styled(Stack)`
  gap: 1.729rem;
`;

const AddressWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.75rem;
  justify-content: center;
`;

const HomeInfoCard = ({
  user,
  address,
  subtitle,
  website,
  phoneNumber,
  editLink,
}: IAccountInfoCard) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation('general');

  return (
    <Paper padding="1.5rem !important">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <InfomationWrapper
          direction="row"
          data-testid="partnerHome-homeInfoCard-nameCard"
        >
          <Stack
            direction="row"
            pr="1.727rem"
            style={{
              borderRight: `0.0625rem solid ${theme.palette.custom.neutral.black[20]}`,
            }}
          >
            <NameCard
              firstName={user?.firstName || ''}
              lastName={user?.lastName || ''}
              email={user?.email || ''}
              width="2.5rem"
              height="2.5rem"
              mr="1rem"
            />
            <Stack justifyContent="center">
              <Text variant="titleS" pb="0.5rem">
                {user?.firstName}&nbsp;{user?.lastName}
              </Text>
              <Text
                variant="bodyTextS"
                color="custom.neutral.black.50"
                sx={{ lineHeight: '0.875rem' }}
              >
                {subtitle}
              </Text>
            </Stack>
          </Stack>
          {address?.street && (
            <AddressWrapper>
              <Address address={address} countryInline />
            </AddressWrapper>
          )}
          <Stack justifyContent="center" gap="0.5rem">
            <Text variant="bodyTextS">{website}</Text>
            <Text variant="bodyTextS">{phoneNumber}</Text>
          </Stack>
        </InfomationWrapper>
        <Button
          variant="tertiary"
          onClick={() => navigate(editLink)}
          data-testid="partnerHome-homeInfoCard-button"
        >
          {t('edit')}
        </Button>
      </Stack>
    </Paper>
  );
};

export default HomeInfoCard;
