// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useRef, useState } from 'react';

import {
  ButtonV2 as Button,
  CropIcon,
  Stack,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import { getImageOrientation } from 'helpers/pdfHelper';
import useToastMessages from 'hooks/useToastMessages';
import { useTranslation } from 'react-i18next';

const WrapperImg = styled('img', {
  shouldForwardProp: prop =>
    prop !== 'displayInTabletImg' && prop !== 'imageOrientation',
})<{
  displayInTabletImg: boolean;
  imageOrientation: string;
}>`
  object-fit: contain;

  ${props =>
    props.imageOrientation === 'portrait'
      ? `transform: rotate(-90deg);  width: 12.0625rem; height: 16.125rem;`
      : ` height: 12.0625rem; width: 16.125rem;`}
`;

const CropOverlay = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 100ms ease-out;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 27%;
  margin-right: 27%;

  &:hover {
    transition: opacity 100ms ease-in;
    opacity: 1;
  }
`;

interface ShipmentLabelImageProps {
  labelUrl: string;
  enableManualCrop?: boolean;
  onManualCropStarted: () => void;
  displayInTabletImg?: boolean;
}

const ShipmentLabelImage = ({
  labelUrl,
  enableManualCrop = false,
  onManualCropStarted,
  displayInTabletImg = false,
}: ShipmentLabelImageProps) => {
  const { t } = useTranslation('shipments');
  const imageRef = useRef<HTMLImageElement>(null);
  const { info } = useToastMessages();
  useEffect(() => {
    if (!enableManualCrop) return;
  }, [enableManualCrop, info, t]);

  const [imageOrientation, setImageOrientation] = useState<string>();

  useEffect(() => {
    getImageOrientation(labelUrl)
      .then((imageOrientation: string) => {
        setImageOrientation(imageOrientation);
      })
      .catch(error => {
        setImageOrientation('portrait');
      });
  }, [labelUrl]);

  return imageOrientation ? (
    <>
      <WrapperImg
        ref={imageRef}
        data-testid="label-image"
        src={labelUrl}
        displayInTabletImg={displayInTabletImg}
        imageOrientation={imageOrientation}
      />
      {enableManualCrop && (
        <CropOverlay>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            onClick={onManualCropStarted}
            style={{ cursor: 'pointer' }}
            height="100%"
          >
            <Button aria-labelledby="crop-description" icon={CropIcon} />
            <Stack id="crop-description">
              <Text variant="titleS">{t('manualCrop.button.title')}</Text>
              <Text variant="bodyTextS">
                {t('manualCrop.button.description')}
              </Text>
            </Stack>
          </Stack>
        </CropOverlay>
      )}
    </>
  ) : (
    <></>
  );
};

export default ShipmentLabelImage;
