// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useMemo } from 'react';

import useToasts, {
  addItemSelector,
  removeItemSelector,
  ToastSettings,
} from 'stores/useToasts';
import { MessageType } from 'stores/useToasts.types';

type TMessage = string | { msg: string; title?: string };

const parseCopy = (copy: TMessage): { msg: string; title?: string } => {
  if (typeof copy === 'string') {
    return { msg: copy };
  }

  return copy;
};

const useToastMessages = () => {
  const addItem = useToasts(addItemSelector);
  const removeItem = useToasts(removeItemSelector);

  return useMemo(() => {
    const success = (copy: TMessage, settings: ToastSettings = {}) => {
      const { msg, title } = parseCopy(copy);
      addItem(
        { msg, title, type: MessageType.Success, close: true },
        'root',
        settings
      );
    };
    const error = (copy: TMessage, settings: ToastSettings = {}) => {
      const { msg, title } = parseCopy(copy);
      addItem(
        { title, msg, type: MessageType.Error, close: true },
        'root',
        settings
      );
    };
    const info = (copy: TMessage, settings: ToastSettings = {}) => {
      const { msg, title } = parseCopy(copy);
      addItem(
        { title, msg, type: MessageType.Info, close: true },
        'root',
        settings
      );
    };

    return {
      removeItem,
      addItem,
      success,
      error,
      info,
    };
  }, [addItem, removeItem]);
};

export default useToastMessages;
