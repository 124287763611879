// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useEffect, useState } from 'react';

import useAppState, {
  loadingSelector,
  userSelector,
} from 'stores/appState/useAppState';

const useLDReady = () => {
  const [ready, setReady] = useState(false);
  const appUser = useAppState(userSelector);
  const loading = useAppState(loadingSelector);

  useEffect(() => {
    // if auth is loading, we don't know which flags to serve
    // -> we cannot show the app
    if (loading) {
      setReady(false);

      return;
    }
    // if auth has loaded, and user is not authenticated, no need to identify
    // -> we can show the app
    if (!appUser) {
      setReady(true);

      return;
    }
    // if auth has loaded, and user is authenticated,
    // -> we need to wait for user to be identified before showing the app
    if (appUser) {
      setReady(true);

      return;
    }
  }, [loading, appUser]);

  return ready;
};

export default useLDReady;
