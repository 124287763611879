// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { create } from 'zustand';

interface ILayoutStore {
  openNav: boolean;
  setOpenNav: (openNav: boolean) => void;
  openAccountSelection: boolean;
  setOpenAccountSelection: (openAccountSelection: boolean) => void;
}

const useLayoutStore = create<ILayoutStore>(set => ({
  // Left navigation menu
  openNav: false,
  setOpenNav: (openNav: boolean) => set(state => ({ openNav })),
  // Partners selector
  openAccountSelection: false,
  setOpenAccountSelection: (openAccountSelection: boolean) =>
    set(state => ({ openAccountSelection })),
}));

export const openNavSelector = (state: ILayoutStore) => state.openNav;
export const setOpenNavSelector = (state: ILayoutStore) => state.setOpenNav;

export const openAccountSelectionSelector = (state: ILayoutStore) =>
  state.openAccountSelection;
export const setOpenAccountSelectionSelector = (state: ILayoutStore) =>
  state.setOpenAccountSelection;

export default useLayoutStore;
