// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Divider,
  styled,
  Text,
  Stack,
  Grid,
} from '@livingpackets/design-system-react-next';
import { DetailsCard, DetailsSpan } from 'components/atoms/ShipmentAtoms';
import isCancelledState from 'helpers/isCancelledState';
import useShipmentDetail from 'hooks/useShipmentDetail';
import { isEmpty } from 'lodash';
import { IShipment, IShipmentAnonymous, TCarrierCodes } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { DeliverySharingList } from './DeliverySharingList';
import { useGetTrackingCodes } from '../../api/getTrackingCodes';

const Wrapper = styled('div')`
  margin-top: 1.5rem;
`;

const LogoWrapper = styled('img')`
  height: 40px;
  width: 40px;
  background-color: transparent;
`;

export const TrackingInfoCard = ({
  shipment,
  recipientEmail,
  recipientPhone,
  shipmentCarrierCode,
  carrierTrackingCode,
  isShipmentAnonymous = false,
}: {
  shipment: IShipment | IShipmentAnonymous;
  recipientEmail: string | undefined;
  recipientPhone?: string;
  shipmentCarrierCode: TCarrierCodes;
  carrierTrackingCode?: string;
  isShipmentAnonymous?: boolean;
}) => {
  const { t } = useTranslation('shipments');

  const { data: trackingCodeData } = useGetTrackingCodes({
    shipmentId: shipment.shipment_id,
  });

  const { smsEnabled } = useMyPartnersStore(activePartnerSelector);

  const { getCarrierLogo } = useShipmentDetail();
  const isCancelled = isCancelledState(shipment);

  const logo = getCarrierLogo(shipmentCarrierCode);

  return (
    <Wrapper>
      <DetailsSpan>{t('detail.summary.tracking.title')}</DetailsSpan>
      <DetailsCard padding="1.25rem" mt=".75rem" disabled={isCancelled}>
        <Stack>
          <Grid container justifyContent="space-between" spacing="1rem">
            <Grid item mobile={12} tablet={6} flexGrow={1}>
              <Grid container gap="1.25rem" flexWrap="nowrap">
                {logo && <LogoWrapper src={logo} alt="carrier logo" />}
                <Stack justifyContent="center">
                  <DetailsSpan>
                    {t('detail.summary.tracking.trackingNumber')}
                  </DetailsSpan>
                  <Text
                    variant="bodyTextS"
                    marginTop="4px !important"
                    data-testid="carrier-tracking-code"
                    sx={{ wordBreak: 'break-all' }}
                  >
                    {carrierTrackingCode
                      ? carrierTrackingCode
                      : t('detail.summary.tracking.trackingCodeEmpty')}
                  </Text>
                </Stack>
              </Grid>
            </Grid>
            {!isShipmentAnonymous && (
              <Grid item mobile={12} tablet={6}>
                <Stack
                  alignItems={{ mobile: 'flex-start', tablet: 'flex-end' }}
                >
                  <DetailsSpan>{t('recipientInformation')}</DetailsSpan>
                  <Text variant="bodyTextS" sx={{ wordBreak: 'break-all' }}>
                    {recipientEmail ||
                      t('detail.summary.tracking.recipientEmailEmpty')}
                  </Text>
                  {/* Only display if smsEnabled */}
                  {!smsEnabled && !isEmpty(recipientPhone) && (
                    <Text variant="bodyTextS">{recipientPhone}</Text>
                  )}
                </Stack>
              </Grid>
            )}
          </Grid>
          {!isShipmentAnonymous && (
            <>
              <Divider sx={{ my: '1.25rem' }} />
              <DeliverySharingList
                code={(shipment as IShipment).opening_code}
                trackingCodeData={trackingCodeData}
              />
            </>
          )}
        </Stack>
      </DetailsCard>
    </Wrapper>
  );
};
