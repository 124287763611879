// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { useRoutes } from 'react-router-dom';

import { AnonymousShipmentDetails } from './AnonymousShipmentDetails';
import { Delivery } from './Delivery';

export const SHIPMENT_ANONYMOUS_ROOT =
  '/shipments/:trackingCode/anonymous-detail/*';

export const SHIPMENT_ANONYMOUS_SHORT_LINK = '/s/:trackingCode/*';

const routes = [
  // Routes
  {
    path: '',
    element: <AnonymousShipmentDetails />,
  },
  { path: '/delivery', element: <Delivery /> },
];

export const ShipmentAnonymous = () => {
  const element = useRoutes(routes);

  return <>{element}</>;
};
