// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ButtonV2,
  Chip,
  EditIcon,
  Paper,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import { normalizeKey } from 'helpers/i18n';
import { useTranslation } from 'react-i18next';

import useCountryCodes from '../../../shared-components/useCountryCodes';

type Props = {
  showForm: () => void;
  isSender: boolean;
  isReturnShipment: boolean;
  values: {
    firstName: string;
    lastName: string;
    street: string;
    postalCode: string;
    city: string;
    countryCode: string;
  };
};

const ReadOnlyAddress = ({
  showForm,
  isSender,
  isReturnShipment,
  values,
}: Props) => {
  const { t, i18n } = useTranslation('shipments');

  const [, getCountryNameFromCode] = useCountryCodes({
    includeTranslations: true,
    language: i18n.language,
  });

  return (
    <Paper
      sx={{ backgroundColor: 'custom.neutral.black.2', padding: '1.25rem' }}
    >
      <Stack>
        {!isReturnShipment && (
          <Stack direction="row" justifyContent="space-between">
            <Chip
              data-testid={`default-chip-${isSender ? 'sender' : 'recipient'}`}
              label={t(
                normalizeKey(
                  `form.${isSender ? 'sender' : 'recipient'}.defaultChip`
                )
              )}
            />
            <ButtonV2
              data-testid={`edit-default-${
                isSender ? 'sender' : 'recipient'
              }-address`}
              icon={EditIcon}
              size="small"
              onClick={showForm}
            />
          </Stack>
        )}
        <Stack>
          <Text variant="titleXS">
            {values.firstName} {values.lastName}
          </Text>
          <Text variant="bodyTextM">{values.street}</Text>
          <Text variant="bodyTextM">
            {values.postalCode} {values.city}
          </Text>
          <Text variant="bodyTextM">
            {getCountryNameFromCode(values.countryCode)}
          </Text>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ReadOnlyAddress;
