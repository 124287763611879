// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export const CommandControlStatus = {
  WRONG_SEQUENCE: -14,
  WRONG_LENGTH: -13,
  SHIPMENT_ID_NOK: -12,
  BOX_ID_NOK: -11,
  SIGNATURE_NOK: -10,
  TSA_ACTION_NOK: -9,
  ELABEL_DISPLAY_NOK: -8,
  ELABEL_NOT_PRESENT_NOK: -7,
  SET_SHIPMENT_NOK: -6,
  SHIPMENT_REQUEST_NOK: -5,
  SHIPMENT_IN_PROGRESS_NOK: -4,
  READ_FILE_NOK: -3,
  BATTERY_LOW: -2,
  WRONG_CONDITION_NOK: -1,
  UNKNOWN: 0,
  CMD_LOCK_OK: 1,
} as const;

export type CommandControlStatusKeys = keyof typeof CommandControlStatus;
export type CommandControlStatusValues =
  (typeof CommandControlStatus)[CommandControlStatusKeys];
