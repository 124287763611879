// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Box, styled } from '@livingpackets/design-system-react-next';
import ImportShipmentStepTextBlock from 'components/atoms/importShipment/ImportShipmentStepTextBlock';
import { Carriers } from 'configs/carriers';
import { normalizeKey } from 'helpers/i18n';
import { isEmpty } from 'lodash/fp';
import CARRIERS_LIST from 'models/carriers';
import { useTranslation } from 'react-i18next';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import CarrierTile from '../atoms/CarrierTile';

interface ICarrierImgSelector {
  selected: Carriers | undefined;
  setSelected: (carrier?: Carriers) => void;
  onClickNext: () => void;
}

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  border-radius: 20px;
  padding: 32px 40px;
`;

const TileWrapper = styled('div')`
  display: grid;
  gap: 33px 28px;
  grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
  grid-template-rows: auto;
  width: 100%;
`;

const CarrierImgSelector = ({
  selected,
  setSelected,
  onClickNext,
}: ICarrierImgSelector) => {
  const { t } = useTranslation(['shipments', 'general']);

  const { carriers } = useMyPartnersStore(activePartnerSelector);

  // Filter to avoid any non exising carrier
  let displayedCarriers = CARRIERS_LIST.filter(carrier =>
    carriers?.includes(carrier.code)
  );

  // Skip step if only one carrier
  if (displayedCarriers?.length === 1) {
    setSelected(displayedCarriers[0].code);
    onClickNext();

    return null;
    // If we have no carrier, we display all the available carriers
  } else if (isEmpty(displayedCarriers)) {
    displayedCarriers = CARRIERS_LIST;
  }

  return (
    <Wrapper>
      <Box mb="2.4375rem">
        <ImportShipmentStepTextBlock
          number="1"
          title={t('shipments:importLabel.header.steps.carrierSelector.title')}
          subTitle={t(
            'shipments:importLabel.header.steps.carrierSelector.subTitle'
          )}
        />
      </Box>
      <TileWrapper>
        {displayedCarriers.map(carrier => (
          <CarrierTile
            key={carrier.code}
            carrier={carrier.code}
            carrierLabel={t(
              normalizeKey(`shipments:${carrier.code.toLocaleLowerCase()}`)
            )}
            onClick={(carrier: Carriers) => {
              setSelected(carrier);
              onClickNext();
            }}
            logoPath={carrier.logoPath}
            selected={selected === carrier.code}
          />
        ))}
      </TileWrapper>
    </Wrapper>
  );
};

export default CarrierImgSelector;
