// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useState } from 'react';

import {
  EmptyState,
  AddIcon,
  Box,
  UserProfileIcon,
  ButtonV2 as Button,
  Text,
  ArrowIcon,
  Stack,
  QuickLaunch,
  ChevronDownIcon,
} from '@livingpackets/design-system-react-next';
import AddAddressToContactModal from 'components/molecules/modals/AddAddressToContactModal';
import { PATHS } from 'configs';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import useGetPartnerContacts from '../../../hooks/useGetPartnerContacts';
import PartnerContactsTable from '../../organisms/PartnerContactsTable';
import Title from '../Title';

const { CONTACT_ADDRESS } = PATHS;

const DropDownItem = ({
  label,
  icon,
}: {
  label: string;
  icon: React.ReactNode;
}) => (
  <Stack
    direction="row"
    width="100%"
    justifyContent="space-between"
    alignItems="center"
    gap="0.5rem"
  >
    {label}
    {icon}
  </Stack>
);

const PartnerContactAddressOverview = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['general', 'contactAddress']);

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const [isSearchMustBeCleared, setIsSearchMustBeCleared] = useState<number>(0);

  const { loading, onSortingChange, onPaginationChange, total, searchTerm } =
    useGetPartnerContacts();

  const [showAddAddressToContactModal, setShowAddAddressToContactModal] =
    useState<boolean>(false);

  const redirectToCreateContactPage = useCallback(() => {
    navigate(
      generatePath(PATHS.CONTACT_ADDRESS.CREATE, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  const handleSelectContact = useCallback(
    (contactId: string) => {
      let contactAddressEditUrl = generatePath(CONTACT_ADDRESS.EDIT, {
        partnerId: activePartner.id,
        contactId: contactId,
      });
      navigate(contactAddressEditUrl, {
        state: { displayAddAddressFormOnPageLoad: true },
      });
    },
    [navigate, activePartner]
  );

  return (
    <>
      <Title pageName={t('contactAddress:pageListTitle')} />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        mb="1.5rem"
      >
        {(total !== 0 || searchTerm) && (
          <Box sx={{ zIndex: 10 }}>
            <QuickLaunch
              elements={[
                {
                  children: (
                    <DropDownItem
                      label={t('contactAddress:list.dropdown.newContact')}
                      icon={<AddIcon size="1.5rem" />}
                    />
                  ),
                  onClick: redirectToCreateContactPage,
                },
                {
                  children: (
                    <DropDownItem
                      label={t('contactAddress:list.dropdown.existingContact')}
                      icon={<UserProfileIcon size="1.5rem" />}
                    />
                  ),
                  onClick: () => setShowAddAddressToContactModal(true),
                },
              ]}
              rootElement={({ handleClick }) => (
                <Button
                  data-testid="more-actions-button"
                  onClick={handleClick}
                  icon={ChevronDownIcon}
                >
                  {t('contactAddress:list.dropdown.addAddress')}
                </Button>
              )}
            />
          </Box>
        )}
      </Stack>
      <PartnerContactsTable
        loading={loading}
        onSortingChange={onSortingChange}
        onPaginationChange={onPaginationChange}
        actionIsSearchMustBeCleared={isSearchMustBeCleared}
      />
      {total === 0 && (
        <Box>
          {!searchTerm ? (
            <EmptyState type="Addresses">
              <Text variant="titleM" color="custom.neutral.black.50">
                {t('contactAddress:list.emptyState.emptyMessage')}
              </Text>
              <Text variant="bodyTextL" color="custom.neutral.black.50">
                {t('contactAddress:list.emptyState.emptySubMessage')}
              </Text>
              <Button
                icon={ArrowIcon}
                sx={{ marginTop: '1.625rem' }}
                data-testid="partner-contact-address-redirect-create-contact-button"
                onClick={() => redirectToCreateContactPage()}
              >
                {t('contactAddress:list.emptyState.cta')}
              </Button>
            </EmptyState>
          ) : (
            <EmptyState type="Addresses">
              <Text variant="titleM" color="custom.neutral.black.50">
                {t('contactAddress:list.noResult.emptyMessage')}
              </Text>
              <Text variant="bodyTextL" color="custom.neutral.black.50">
                {t('contactAddress:list.noResult.emptySubMessage')}
              </Text>
              <Button
                icon={ArrowIcon}
                sx={{ marginTop: '1.625rem' }}
                data-testid="partner-contact-address-clear-search-button"
                onClick={() =>
                  setIsSearchMustBeCleared(isSearchMustBeCleared + 1)
                }
              >
                {t('contactAddress:list.noResult.cta')}
              </Button>
            </EmptyState>
          )}
        </Box>
      )}
      <AddAddressToContactModal
        visible={showAddAddressToContactModal}
        onContactSelect={handleSelectContact}
        onDismiss={() => setShowAddAddressToContactModal(false)}
      />
    </>
  );
};

export default PartnerContactAddressOverview;
