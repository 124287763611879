// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { IBoxContract } from 'models/boxContract';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export interface IContractsStore {
  contracts: IBoxContract[];
  setContracts: (contracts: IBoxContract[]) => void;
  order: 'ASC' | 'DESC';
  setOrder: (order: 'ASC' | 'DESC') => void;
  partnerId?: string;
  pageSize?: number;
}

export const boxContractSelector = (state: IContractsStore) => state.contracts;
export const orderSelector = (state: IContractsStore) => state.order;
export const setOrderSelector = (state: IContractsStore) => state.setOrder;
export const setContractsSelector = (state: IContractsStore) =>
  state.setContracts;

const useBoxContractsStore = create<IContractsStore>()(
  devtools(
    persist(
      set => ({
        contracts: [],
        setContracts: contracts => set(() => ({ contracts: contracts })),
        order: 'DESC',
        setOrder: order => set(() => ({ order: order })),
      }),
      {
        name: 'contracts',
      }
    )
  )
);

export default useBoxContractsStore;
