// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useState } from 'react';

import axios, { ResponseType } from 'axios';

export interface IUseImperativeRequest {
  path: string;
  method?: 'get' | 'post';
  headers?: any;
  body?: { [key: string]: string };
  responseType?: ResponseType;
}

interface IUseImperativeRequestUrlToken {
  url: string;
  token: string;
}

const useImperativeRequest = ({
  url,
  token,
}: IUseImperativeRequestUrlToken): any => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  const makeRequest = useCallback(
    async ({
      path = '/',
      method = 'get',
      body,
      headers = {},
      responseType = 'json',
    }: IUseImperativeRequest) => {
      try {
        setLoading(true);
        const data = await axios({
          url: `${url}${path}`,
          method,
          data: body,
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
          responseType,
        }).then(res => res.data);
        setError('');
        setData(data);
        setLoading(false);

        return { data: data, error: '' };
      } catch (err: any) {
        setError(err);
        console.error(err);
        setLoading(false);

        return { data: null, error: err };
      }
    },
    [token, url]
  );

  return [{ error, loading, data }, makeRequest];
};

export default useImperativeRequest;
