// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Stack, Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import SocialMediaCard from './SocialMediaCard';
import useSocialMediaArticles from '../../../hooks/useSocialMediaArticles';
import useSocialMediaArticlesStore, {
  smArticleSelector,
} from '../../../stores/useSocialMediaArticlesStore';

const SocialMediaCardList = () => {
  const { t } = useTranslation('dashboard');

  const { loading } = useSocialMediaArticles();
  const smArticles = useSocialMediaArticlesStore(smArticleSelector);

  return (
    <Stack mt="3rem" width="53%">
      <Stack direction="row" justifyContent="space-between" width="95%">
        <Text variant="titleM" mb="1rem">
          {t('socialMediaList.title')}
        </Text>
        <Text color="custom.neutral.black.50" mb="1rem">
          {t('socialMediaList.published')}
        </Text>
      </Stack>
      {!loading &&
        smArticles.map((article: any, key: any) => (
          <SocialMediaCard key={key} article={article} />
        ))}
    </Stack>
  );
};

export default SocialMediaCardList;
