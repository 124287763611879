// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  Stack,
  Text,
  ButtonV2 as Button,
  styled,
  BLEBoxOpenIcon,
} from '@livingpackets/design-system-react-next';
import BoxProClose from 'assets/img/shipment/openning/ble/box_pro_close.png';
import BoxProOpen from 'assets/img/shipment/openning/ble/box_pro_open.png';
import TabletClose from 'assets/img/shipment/openning/ble/tablet_close.png';
import TabletOpen from 'assets/img/shipment/openning/ble/tablet_open.png';
import TheBoxClose from 'assets/img/shipment/openning/ble/the_box_close.png';
import TheBoxOpen from 'assets/img/shipment/openning/ble/the_box_open.png';
import UnlockGreen from 'assets/lotties/unlock-green.json';
import { ProductTypeEnum } from 'enums/ProductEnum';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import { getProductType } from 'utils/product';

const BoxDeleveryWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'isDelivered',
})<{ isDelivered: boolean }>(({ isDelivered, theme }) => ({
  height: '100vh',
  width: '100%',
  paddingBottom: '1.125rem',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflowY: 'auto',
  ':before': {
    content: '""',
    height: '856px',
    top: 'calc(-856px + 52vh)',
    width: '856px',
    left: 'calc(-428px + 50vw)',
    background: isDelivered
      ? `linear-gradient(${theme.palette.custom.primary[120]},${theme.palette.custom.primary[140]})`
      : theme.palette.custom.neutral.black[8],
    transition: 'background-color 1s ease-in-out, color 1s ease-in-out',
    position: 'absolute',
    borderRadius: '50%',
    zIndex: -1,
  },
}));

const productImages: any = {
  boxpro_close: BoxProClose,
  boxpro_open: BoxProOpen,
  tablet_close: TabletClose,
  tablet_open: TabletOpen,
  box_close: TheBoxClose,
  box_open: TheBoxOpen,
};

const BLEDelivery = ({
  isDelivered,
  articleCode,
}: {
  isDelivered: boolean;
  articleCode: string;
}) => {
  const { t } = useTranslation('shipments');
  const navigate = useNavigate();

  const getProductDisplayed = (articleCode: string) => {
    const productType = getProductType(articleCode);

    const product =
      productType === ProductTypeEnum.UNKNOWN
        ? 'box'
        : productType.toLocaleLowerCase();

    const ressourceName = `${product}${isDelivered ? '_open' : '_close'}`;

    return productImages[ressourceName];
  };

  return (
    <BoxDeleveryWrapper isDelivered={isDelivered}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        paddingLeft="1rem"
        paddingRight="1rem"
      >
        {isDelivered ? (
          <>
            <BLEBoxOpenIcon
              style={{
                width: '3rem',
                height: '3rem',
                color: 'custom.neutral.white.pure',
                marginBottom: '0.6875rem',
              }}
            />
            <Text variant="titleXL" sx={{ color: 'custom.neutral.white.pure' }}>
              {t('anonymous.mobile.success.title')}
            </Text>
            <Text
              variant="bodyTextL"
              sx={{
                color: 'custom.neutral.white.pure',
                textAlign: 'center',
              }}
            >
              {t('anonymous.mobile.success.message.text1')}
            </Text>
            <Text
              variant="bodyTextL"
              sx={{
                color: 'custom.neutral.white.pure',
                textAlign: 'center',
              }}
            >
              {t('anonymous.mobile.success.message.text2')}
            </Text>
          </>
        ) : (
          <>
            <Box
              sx={{
                backgroundColor: 'custom.neutral.white.pure',
                height: '3.25rem',
                width: '3.25rem',
                borderRadius: '0.75rem',
                marginBottom: '.5rem',
              }}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: UnlockGreen,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                  },
                }}
              />
            </Box>
            <Text variant="titleXL">
              {t('anonymous.mobile.waitDelivery.title')}
            </Text>
            <Text variant="bodyTextL">
              {t('anonymous.mobile.waitDelivery.message')}
            </Text>
          </>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <img
          src={getProductDisplayed(articleCode)}
          alt="box-delivery"
          style={{ transform: 'scale(1)' }}
        />
        {isDelivered && <Text> {t('anonymous.mobile.success.action')}</Text>}
      </Box>
      <Stack
        sx={{
          backgroundColor: 'custom.neutral.white.pure',
          position: 'fixed',
          bottom: 0,
          width: '100%',
          padding: '1rem',
        }}
      >
        <Button
          variant={isDelivered ? 'primary' : 'tertiary'}
          onClick={() => {
            navigate(-1);
          }}
        >
          {isDelivered
            ? t('anonymous.mobile.cta.close')
            : t('anonymous.mobile.cta.btnCancel')}
        </Button>
      </Stack>
    </BoxDeleveryWrapper>
  );
};

export default BLEDelivery;
