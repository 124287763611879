// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Divider,
  Grid,
  Stack,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import { differenceInYears, format, isEqual } from 'date-fns';
import { normalizeKey } from 'helpers/i18n';
import { i18n } from 'i18next';
import { useTranslation } from 'react-i18next';
import { getLocale } from 'utils/date/locale';

import { usePartnership } from '../../../api/partnership/getPartnership';
import { Offer } from '../../../types/offer';

const contactLP = 'contact@livingpackets.com';
const OFFER_TYPE_PREFIX = 'OFFER_TYPE_';

const Root = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 1.25rem;
`;

const TitleOffer = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding-left: 1.25rem;
  align-items: center;
`;

// Uncomment when we have the value from the API available
// const NumberOfDeliveriesBox = styled(Box)`
//   width: 3.0625rem;
//   height: 1.25rem;
//   border-radius: 0.25rem;
//   padding: 0.25rem 1rem 0.25rem 1rem;
//   background-color: ${({ theme }) => theme.palette.custom.neutral.black[3]};
//   justify-content: center;
//   align-items: center;
//   display: flex;
// `;

const ContactSalesContainer = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
  border-radius: 0.5rem;
`;

const getDuration = (offer: Offer, i18n: i18n, t: any) => {
  const start = new Date(offer.contract_information.start_time);
  const end = new Date(offer.contract_information.end_time);

  if (isEqual(start, end)) return '-';

  const formattedStartDate = format(start, 'P', {
    locale: getLocale(i18n.language),
  });

  const years = differenceInYears(end, start);

  return t('offer.contract.durationInformation', {
    count: years,
    fromDate: formattedStartDate,
  });
};

export const LeftPart = ({ offer }: { offer: Offer }) => {
  const { t, i18n } = useTranslation('partnerSettings');

  const { data: partnership } = usePartnership();

  let duration = getDuration(offer, i18n, t);

  return (
    <Root container direction="column" sx={{ height: '100%' }}>
      <TitleOffer item container>
        <Text variant="titleM" color="custom.neutral.white.pure">
          {offer.name}
        </Text>
      </TitleOffer>
      <Grid
        item
        p="1rem"
        flexGrow={1}
        sx={{
          border: `0.0625rem solid `,
          borderColor: `custom.neutral.black.10`,
          borderBottomLeftRadius: '1.25rem',
          borderBottomRightRadius: '1.25rem',
          borderTop: 'none',
        }}
      >
        <Grid container direction="column" spacing="0.75rem">
          {/* Cost per delivery */}
          <Grid item>
            <Text variant="titleL">
              {offer.contract_information.cost_per_shipment}{' '}
              {t('offer.costPerDelivery')}
            </Text>
          </Grid>
          {/* Deliveries per month */}
          {!/UNSPECIFIED/.test(offer.contract_information.type) && (
            <Grid item>
              <Grid container spacing="0.3rem">
                <Grid item>
                  <Text variant="titleXS">
                    {t(
                      normalizeKey(
                        `offer.deliveriesPerMonthInformation.${offer.contract_information.type.replace(
                          OFFER_TYPE_PREFIX,
                          ''
                        )}`
                      )
                    )}
                  </Text>
                </Grid>
                <Grid item>
                  <Text variant="bodyTextXS">
                    {t('offer.deliveriesPerMonth')}
                  </Text>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* Deliveries this month */}
          {/* <Grid
            item
            container
            direction="row"
            spacing="0.3rem"
            alignItems="center"
          >
            <Grid item>
              <NumberOfDeliveriesBox>
                <Text variant="bodyTextXS">267</Text>
              </NumberOfDeliveriesBox>
            </Grid>
            <Grid item>
              <Text variant="bodyTextXS">
                {t('offer.shipmentNumberThisMonth')}
              </Text>
            </Grid>
          </Grid> */}
          {/* Divider */}
          <Grid item container mt="0.5rem" mb="1.5rem">
            <Divider orientation="horizontal" sx={{ width: '100%' }} />
          </Grid>
          {/* Contract duration */}
          <Grid item container spacing="0.25rem" direction="column">
            <Grid item>
              <Text variant="bodyTextXS" color="custom.neutral.black.50">
                {t('offer.contract.duration')}
              </Text>
            </Grid>
            <Grid item>
              <Text variant="bodyTextXS">{duration}</Text>
            </Grid>
          </Grid>
          {/* Contact sales */}
          <Grid item mobile={12}>
            <ContactSalesContainer
              container
              direction="column"
              spacing="0.25rem"
            >
              <Grid item>
                <Text variant="titleXS">{t('offer.salesContact')}</Text>
              </Grid>
              <Grid item>
                <Stack>
                  <Text variant="bodyTextXS">
                    {partnership?.partner_id_card.internal_sales_name || ' '}
                  </Text>
                  <Text
                    component="a"
                    href={`mailto:${contactLP}`}
                    variant="bodyTextXS"
                    sx={{ fontWeight: '500' }}
                    color="custom.primary.100"
                  >
                    {contactLP}
                  </Text>
                </Stack>
              </Grid>
            </ContactSalesContainer>
          </Grid>
        </Grid>
      </Grid>
    </Root>
  );
};
