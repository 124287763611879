// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Text, styled } from '@livingpackets/design-system-react-next';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { useTranslation } from 'react-i18next';
import { space, SpaceProps } from 'styled-system';

const Wrapper = styled('div')<SpaceProps>`
  ${space}
  display:inline-flex
`;

const LastUpdatedAt = ({
  timestamp,
  dateFormat = {},
  ...rest
}: { timestamp: number; dateFormat?: any } & SpaceProps) => {
  const { t } = useTranslation('general');

  const format = useIntlFormatTimestamp();

  return (
    <Wrapper {...rest}>
      <Text marginRight="0.5rem" variant="bodyTextXS">
        {t('lastUpdate')}
      </Text>
      <Text color="custom.neutral.black.50" variant="bodyTextXS">
        {format(timestamp, dateFormat)}
      </Text>
    </Wrapper>
  );
};

export default LastUpdatedAt;
