// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useState } from 'react';

import { FormInputs } from 'components/molecules/ProfileSettingsForm';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import pick from 'lodash/pick';
import { ILPAccountAddress } from 'models/address';
import { IUser } from 'models/user';
import useAppState, {
  dispatch,
  userSelector,
} from 'stores/appState/useAppState';
import useMyAddressesStore, {
  primaryAddressSelector,
  setPrimaryAddressSelector,
} from 'stores/useMyAddressesStore';

export const addressValues = [
  'company',
  'city',
  'street',
  'houseNumber',
  'postalCode',
  'countryCode',
  'firstName',
  'lastName',
] as const;

const appUserValues = [
  'firstName',
  'lastName',
  'phoneNumber',
  'preferredLanguage',
] as const;

const dispatchUser = (data: { profile: IUser }) =>
  dispatch({ type: 'SET_USER', payload: data.profile });

interface IActions {
  appUser: null | ((values: FormInputs) => Promise<any>);
  address: null | ((values: FormInputs) => Promise<any>);
}

const handleActions = async (
  actions: IActions,
  values: FormInputs,
  onError: any,
  setPrimaryAddress: (input: ILPAccountAddress | null) => void
) => {
  if (actions.appUser && actions.address) {
    const [userResult, addressResult] = await Promise.all([
      actions.appUser(values),
      actions.address(values),
    ]);
    if (
      userResult.error ||
      addressResult.error ||
      !userResult.data ||
      !addressResult.data
    ) {
      onError(userResult.error || addressResult.error);

      return { success: false };
    }
    dispatchUser(userResult.data);
    setPrimaryAddress(addressResult.data);

    return { success: true };
  }
  if (actions.appUser) {
    const { data: userData, error: userError } = await actions.appUser(values);
    if (!userData || userError) {
      onError(userError);

      return { success: false };
    }
    dispatchUser(userData);

    return { success: true };
  }
  if (actions.address) {
    const { data: addressData, error: addressError } = await actions.address(
      values
    );
    if (!addressData || addressError) {
      onError(addressError);

      return { success: false };
    }
    setPrimaryAddress(addressData);

    return { success: true };
  }

  return { success: false };
};

export const handleSubmit = async (
  values: FormInputs,
  appUser: IUser,
  makeRequest: (input: any) => Promise<any>,
  setPrimaryAddress: (input: ILPAccountAddress | null) => void,
  primaryAddress: ILPAccountAddress | null,
  onError: (error: any) => void
) => {
  const updateAppUser = (values: FormInputs) =>
    makeRequest({
      path: 'api/v1/me',
      method: 'put',
      body: pick(values, [
        'firstName',
        'lastName',
        'phoneNumber',
        'preferredLanguage',
      ]),
    });

  const createPrimaryAddress = (values: FormInputs) =>
    makeRequest({
      path: 'api/v1/me/addresses',
      method: 'post',
      body: {
        ...pick(values, addressValues),
        isPrimary: true,
        types: ['billing'],
      },
    });

  const updatePrimaryAddress = async (values: FormInputs) => {
    if (primaryAddress === null) {
      throw new Error('primaryAddress must be defined');
    }

    return makeRequest({
      path: 'api/v1/me/addresses',
      method: 'put',
      body: {
        ...pick(values, addressValues),
        id: primaryAddress.id,
        versionHash: primaryAddress.versionHash,
        isPrimary: true,
        types: Array.from(new Set(['billing', ...primaryAddress.types])),
      },
    });
  };

  const actions: IActions = {
    appUser: null,
    address: null,
  };
  const sameAppUser = appUserValues.every(key => values[key] === appUser[key]);

  if (!sameAppUser) {
    actions.appUser = updateAppUser;
  }

  // This data is note modify on account screen for Business partner type
  if (appUser.businessType === UserBusinessTypeEnum.PERSONAL) {
    if (!primaryAddress) {
      actions.address = createPrimaryAddress;
    } else {
      const sameAddress = addressValues.every(
        key => values[key] === primaryAddress[key]
      );
      if (!sameAddress) {
        actions.address = updatePrimaryAddress;
      }
    }
  }

  return handleActions(actions, values, onError, setPrimaryAddress);
};

const useProfileSettings = () => {
  const [, makeRequest] = useImperativeRequest('lpAccount');
  const [loading, setLoading] = useState(false);
  const primaryAddress = useMyAddressesStore(primaryAddressSelector);
  const setPrimaryAddress = useMyAddressesStore(setPrimaryAddressSelector);
  const appUser = useAppState(userSelector);
  const { error: toastError, success: toastSuccess } = useToastMessages();
  const onError = useCallback(() => {
    toastError('messages:updateProfileError.message');
  }, [toastError]);
  const onSuccess = useCallback(() => {
    toastSuccess('messages:updateProfileSuccess.message');
  }, [toastSuccess]);

  const onSubmit = useCallback(
    async (values: FormInputs) => {
      if (!appUser) {
        dispatch({
          type: 'SET_ERROR',
          payload: new Error('Unable to get appUser in Profile Settings form'),
        });

        return;
      }
      setLoading(true);
      const { success } = await handleSubmit(
        values,
        appUser,
        makeRequest,
        setPrimaryAddress,
        primaryAddress,
        onError
      );
      if (success) {
        onSuccess();
      }
      setLoading(false);
    },
    [
      appUser,
      makeRequest,
      primaryAddress,
      setPrimaryAddress,
      onError,
      onSuccess,
    ]
  );

  return { onSubmit, loading };
};

export default useProfileSettings;
