// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import { INITIAL_STATE_PARTNER_R_ADDRESS } from 'models/address';
import { INITIAL_STATE_PARTNER } from 'models/partner';
import queryString from 'query-string';
import { dispatch } from 'stores/appState/useAppState';
import useCurrentPartnerStore, {
  setBillingAddressesSelector,
  setCurrentPartnerSelector,
  setRegistrationAddressSelector,
  setBillingAddressesPaginationSelector,
  billingAddressesPaginationSelector,
} from 'stores/useCurrentPartnerStore';

const useCurrentPartner = () => {
  const [{ loading }, makePartnersRequest] = useImperativeRequest('lpVillage');
  const [
    { loading: registrationAddressLoading },
    makeRegistationAddressRequest,
  ] = useImperativeRequest('lpVillage');
  const [{ loading: billingAddressesLoading }, makeBillingAddressesRequest] =
    useImperativeRequest('lpVillage');

  const setPartner = useCurrentPartnerStore(setCurrentPartnerSelector);
  const setBillingAddressesPagination = useCurrentPartnerStore(
    setBillingAddressesPaginationSelector
  );
  const billingAddressesPagination = useCurrentPartnerStore(
    billingAddressesPaginationSelector
  );

  const handleError = useCallback(
    (error: any) => dispatch({ type: 'SET_ERROR', payload: error }),
    []
  );

  const setRegistrationAddress = useCurrentPartnerStore(
    setRegistrationAddressSelector
  );
  const setBillingAddresses = useCurrentPartnerStore(
    setBillingAddressesSelector
  );

  const getCurrentPartner = useCallback(
    async (id: string) => {
      const { data, error } = await makePartnersRequest({
        path: `api/v1/me/partners/${id}`,
        method: 'get',
      });

      if (error) {
        dispatch({ type: 'SET_ERROR', payload: error });
        dispatch({ type: 'SET_API_ERROR', payload: error });

        return;
      }
      setPartner(data);
    },
    [setPartner, makePartnersRequest]
  );

  const getRegistrationAddress = useCallback(
    async (id: string) => {
      const { data, error } = await makeRegistationAddressRequest({
        path: `api/v1/me/partners/${id}/addresses?sortBy=updatedAt&order=DESC&pageSize=100&type=registration`,
        method: 'get',
      });
      if (error || !data?.items) {
        dispatch({ type: 'SET_API_ERROR', payload: error });

        handleError(
          error || new Error('Partner registration address request failed')
        );

        return;
      }
      const rAddress = data.items[0];
      rAddress && setRegistrationAddress(rAddress);
    },
    [makeRegistationAddressRequest, handleError, setRegistrationAddress]
  );

  const getBillingAddresses = useCallback(
    async (id: string) => {
      const query = queryString.stringify({
        sortBy: 'updatedAt',
        order: 'DESC',
        pageSize: billingAddressesPagination.pageSize,
        type: 'billing',
        offset: billingAddressesPagination.offset,
      });
      const { data, error } = await makeBillingAddressesRequest({
        path: `api/v1/me/partners/${id}/addresses?${query}`,
        method: 'get',
      });

      if (error || !data?.items) {
        dispatch({ type: 'SET_API_ERROR', payload: error });

        handleError(
          error || new Error('Partner billing addresses request failed')
        );

        return;
      }

      setBillingAddressesPagination({
        total: data.total,
      });

      setBillingAddresses(data.items);
    },
    [
      makeBillingAddressesRequest,
      handleError,
      setBillingAddresses,
      setBillingAddressesPagination,
      billingAddressesPagination.pageSize,
      billingAddressesPagination.offset,
    ]
  );

  const reset = useCallback(() => {
    setPartner(INITIAL_STATE_PARTNER);
    setRegistrationAddress(INITIAL_STATE_PARTNER_R_ADDRESS);
    setBillingAddresses([]);
  }, [setPartner, setRegistrationAddress, setBillingAddresses]);

  return {
    loading,
    billingAddressesLoading,
    registrationAddressLoading,
    getRegistrationAddress,
    getBillingAddresses,
    getCurrentPartner,
    reset,
  };
};

export default useCurrentPartner;
