// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Stack, Text, Divider } from '@livingpackets/design-system-react-next';
import { ShipmentEditionContext } from 'components/organisms/ImportShipmentFlow';
import { Partnership } from 'features/account';
import { getContacts } from 'features/addressBook';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { queryClient } from 'lib/react-query';
import { set } from 'lodash';

import AddressForm from './AddressForm';
import ReadOnlyAddress from './ReadOnlyAddress';
import getFieldNameWithPrefix from '../../../helpers/getFieldNameWithPrefix';
import {
  ContactAddressType,
  IContactAddress,
  ISearchContactAddress,
} from '../../../models/contactAddress';
import { INITIAL_STATE_OCR_LABEL } from '../../../models/shipment';

interface CreateShipmentAddressFormTemplateType {
  partnership?: Partnership;
  prefix?: string;
  smsEnabled: boolean;
  isSender: boolean;
  title: string;
  setValue: any;
  touchedFields: any;
  trigger: any;
  register: any;
  errors: any;
  watch: any;
  control: any;
  resetField: any;
  showReadOnlyAddress: boolean;
  setShowReadOnlyAddress: Dispatch<SetStateAction<boolean>>;
  isReturnShipment?: boolean;
}

const resetShippingAddress = (
  resetField: any,
  getFieldNameWithPrefix: any,
  isSender: boolean,
  prefix?: string
) => {
  resetField(prefix);
  // don't know why first reset don't work for countryCode
  resetField(getFieldNameWithPrefix(prefix, 'countryCode'));
  // Reset the phone number
  const name = isSender ? 'senderPhoneNumber' : 'recipientPhoneNumber';
  resetField(name);
};

const CreateShipmentAddressFormTemplate = ({
  partnership,
  prefix,
  smsEnabled,
  isSender,
  title,
  setValue,
  touchedFields,
  trigger,
  register,
  errors,
  watch,
  control,
  resetField,
  showReadOnlyAddress,
  setShowReadOnlyAddress,
  isReturnShipment = false,
}: CreateShipmentAddressFormTemplateType) => {
  const {
    senderAddress,
    recipientAddress,
    senderPhoneNumber,
    recipientPhoneNumber,
  } = watch();

  const hasDefaultAddress = isSender
    ? !!partnership?.default_sender_address_id
    : !!partnership?.default_recipient_address_id;

  const {
    isEditingCreationShipment,
    useDefautSenderCheckbox,
    setUseDefautSenderCheckbox,
    useDefautRecipientCheckbox,
    setUseDefautRecipientCheckbox,
  } = useContext(ShipmentEditionContext);

  let elseValue = hasDefaultAddress;
  if (isSender) {
    if (isEditingCreationShipment) {
      elseValue = useDefautSenderCheckbox;
    }
  } else {
    if (isEditingCreationShipment) {
      elseValue = useDefautRecipientCheckbox;
    }
  }

  const [isDefaultAddressChecked, setIsDefaultAddressChecked] = useState(
    isReturnShipment ? false : elseValue
  );

  useEffect(() => {
    if (!isReturnShipment && !isEditingCreationShipment) {
      setIsDefaultAddressChecked(hasDefaultAddress);
    }
  }, [hasDefaultAddress, isReturnShipment, isEditingCreationShipment]);

  // Update context level info base on isDefaultAddressChecked value
  useEffect(() => {
    if (isSender) {
      setUseDefautSenderCheckbox(isDefaultAddressChecked);
    } else {
      setUseDefautRecipientCheckbox(isDefaultAddressChecked);
    }
  }, [
    isDefaultAddressChecked,
    isSender,
    setUseDefautSenderCheckbox,
    setUseDefautRecipientCheckbox,
  ]);

  const [showSearchContactAddressModal, setShowSearchContactAddressModal] =
    useState<boolean>(false);

  const [, makeRequest] = useImperativeRequest('lpVillage');

  const handleSelectContact = useCallback(
    (contact: ISearchContactAddress) => {
      // Set the email
      setValue(getFieldNameWithPrefix(prefix, 'email'), contact.email);
      set(touchedFields, getFieldNameWithPrefix(prefix, 'email'), true);
      trigger(getFieldNameWithPrefix(prefix, 'email'));

      // Set the first and last name
      setValue(getFieldNameWithPrefix(prefix, 'firstName'), contact.firstName);
      set(touchedFields, getFieldNameWithPrefix(prefix, 'firstName'), true);
      trigger(getFieldNameWithPrefix(prefix, 'firstName'));

      setValue(getFieldNameWithPrefix(prefix, 'lastName'), contact.lastName);
      set(touchedFields, getFieldNameWithPrefix(prefix, 'lastName'), true);
      trigger(getFieldNameWithPrefix(prefix, 'lastName'));

      // Set the phone number
      const name = isSender ? 'senderPhoneNumber' : 'recipientPhoneNumber';

      setValue(name, contact.phoneNumber);
      set(touchedFields, name, true);
      trigger(name);
    },
    [prefix, setValue, touchedFields, trigger, isSender]
  );

  const handleInputTypeChange = useCallback(
    (addressType: ContactAddressType | undefined) => {
      const addressTypeData = addressType
        ? addressType
        : ContactAddressType.professional;

      setValue(getFieldNameWithPrefix(prefix, 'type'), addressTypeData);
      trigger(getFieldNameWithPrefix(prefix, 'type'));

      // Remove the company name when you select the personal type
      if (addressTypeData === ContactAddressType.personal) {
        setValue(
          getFieldNameWithPrefix(prefix, 'company'),
          INITIAL_STATE_OCR_LABEL.recipientAddress.company,
          {
            shouldValidate: false,
            shouldDirty: true,
          }
        );

        set(touchedFields, getFieldNameWithPrefix(prefix, 'company'), true);
      }
    },
    [setValue, prefix, trigger, touchedFields]
  );

  const updateAddressValuesWithContactAddress = useCallback(
    (address: IContactAddress) => {
      handleInputTypeChange(address.type);

      setValue(
        getFieldNameWithPrefix(prefix, 'company'),
        address.type === ContactAddressType.professional
          ? address.company
            ? address.company
            : ''
          : ''
      );
      set(touchedFields, getFieldNameWithPrefix(prefix, 'company'), true);

      setValue(getFieldNameWithPrefix(prefix, 'street'), address.street);
      set(touchedFields, getFieldNameWithPrefix(prefix, 'street'), true);

      setValue(
        getFieldNameWithPrefix(prefix, 'postalCode'),
        address.postalCode
      );
      set(touchedFields, getFieldNameWithPrefix(prefix, 'postalCode'), true);

      setValue(getFieldNameWithPrefix(prefix, 'city'), address.city);
      set(touchedFields, getFieldNameWithPrefix(prefix, 'city'), true);

      setValue(getFieldNameWithPrefix(prefix, 'state'), address.state);
      set(touchedFields, getFieldNameWithPrefix(prefix, 'state'), true);

      setValue(
        getFieldNameWithPrefix(prefix, 'countryCode'),
        address.countryCode
      );

      trigger(prefix);
    },
    [handleInputTypeChange, setValue, prefix, touchedFields, trigger]
  );

  const handleSelectAddress = useCallback(
    (address: IContactAddress) => {
      setShowSearchContactAddressModal(false);
      updateAddressValuesWithContactAddress(address);
      setIsDefaultAddressChecked(false);
    },
    [updateAddressValuesWithContactAddress]
  );

  const [defaultContact, setDefaultContact] = useState<any>();

  const loadDefaultAddress = useCallback(
    async (
      { fillTheForm }: { fillTheForm: boolean } = { fillTheForm: true }
    ) => {
      if (
        !partnership ||
        !partnership[
          isSender
            ? 'default_sender_address_id'
            : 'default_recipient_address_id'
        ]
      ) {
        return;
      }
      try {
        // Now get the contact from addressId
        const addressId = isSender
          ? partnership?.default_sender_address_id
          : partnership?.default_recipient_address_id;

        const contacts = await queryClient.fetchQuery({
          queryKey: ['partnership', partnership?.id, 'contact', addressId],
          queryFn: () =>
            getContacts({
              search: addressId,
              partnershipId: partnership?.id,
              makeRequest,
            }),
        });

        if (contacts.count === 0) {
          return;
        }

        if (fillTheForm) {
          handleSelectContact(
            contacts.items[0] as unknown as ISearchContactAddress
          );
        }

        const currentAddress = contacts.items[0].addresses.find(
          ({ id }: any) => id === addressId
        );

        if (fillTheForm) {
          updateAddressValuesWithContactAddress(currentAddress as any);
        }

        setDefaultContact({
          email: contacts.items[0].email,
          firstName: contacts.items[0].firstName,
          lastName: contacts.items[0].lastName,
          phoneNumber: contacts.items[0].phoneNumber,
          city: currentAddress?.city,
          company: currentAddress?.company,
          countryCode: currentAddress?.countryCode,
          postalCode: currentAddress?.postalCode,
          street: currentAddress?.street,
          type: currentAddress?.type,
        });
      } catch (_) {}
    },
    [
      handleSelectContact,
      isSender,
      makeRequest,
      partnership,
      updateAddressValuesWithContactAddress,
    ]
  );

  useEffect(() => {
    if (isReturnShipment) return;
    if (hasDefaultAddress && partnership) {
      if (isEditingCreationShipment) {
        loadDefaultAddress({ fillTheForm: false });
      } else {
        loadDefaultAddress();
      }
    }
  }, [
    hasDefaultAddress,
    partnership,
    loadDefaultAddress,
    isReturnShipment,
    isEditingCreationShipment,
  ]);

  return (
    <Stack pb="0.9375rem">
      <Stack direction="row" gap="0.25rem">
        <Text variant="titleXS" color="custom.neutral.black.50">
          {title}
        </Text>
      </Stack>
      <Divider sx={{ mt: '0.5rem', mb: '1.125rem' }} />
      {showReadOnlyAddress ? (
        <ReadOnlyAddress
          showForm={() => setShowReadOnlyAddress(false)}
          isSender={isSender}
          isReturnShipment={isReturnShipment}
          values={isSender ? senderAddress : recipientAddress}
        />
      ) : (
        <AddressForm
          defaultContact={defaultContact}
          isSender={isSender}
          isReturnShipment={isReturnShipment}
          isDefaultAddressChecked={isDefaultAddressChecked}
          setIsDefaultAddressChecked={setIsDefaultAddressChecked}
          hasDefaultAddress={hasDefaultAddress}
          loadDefaultAddress={loadDefaultAddress}
          resetShippingAddress={resetShippingAddress}
          getFieldNameWithPrefix={getFieldNameWithPrefix}
          resetField={resetField}
          prefix={prefix}
          register={register}
          watch={watch}
          control={control}
          errors={errors}
          touchedFields={touchedFields}
          senderAddress={senderAddress}
          recipientAddress={recipientAddress}
          senderPhoneNumber={senderPhoneNumber}
          recipientPhoneNumber={recipientPhoneNumber}
          smsEnabled={smsEnabled}
          handleOpenSearchContactAddressModal={() =>
            setShowSearchContactAddressModal(true)
          }
          showSearchContactAddressModal={showSearchContactAddressModal}
          setShowSearchContactAddressModal={setShowSearchContactAddressModal}
          handleSelectAddress={handleSelectAddress}
          handleSelectContact={handleSelectContact}
          handleInputTypeChange={handleInputTypeChange}
        />
      )}
    </Stack>
  );
};

export default CreateShipmentAddressFormTemplate;
