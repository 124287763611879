// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Theme } from '@livingpackets/design-system-react-next';
import { GlobalStyles } from '@mui/material';

const globalStyles = (theme: Theme) => ({
  '*': {
    boxSizing: 'border-box',
  },
  '*:before': {
    boxSizing: 'border-box',
  },
  '*:after': {
    boxSizing: 'border-box',
  },
  html: {
    height: '100%',
  },
  body: {
    ...theme.fontStyles.bodyTextM,
    color: theme.palette.custom.neutral.black[100],
    background: theme.palette.custom.neutral.white.pure,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    height: 'auto',
    margin: 0,
    minHeight: '100%',
    position: 'relative',
    '@media only screen and (min-width: 768px)': {
      overflow: 'hidden',
    },
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
  a: {
    textDecoration: 'none',
  },
  ul: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
});

const GlobalStyle = () => <GlobalStyles styles={globalStyles} />;

export default GlobalStyle;
