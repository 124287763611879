// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Text,
  ButtonV2,
  EmptyState,
  ArrowRightIcon,
  BulletWrongIcon,
  ChevronDownIcon,
  SearchIcon,
  ShipmentCompletedIcon,
  ShipmentOngoingIcon,
  ShipmentPurchasedIcon,
  ArrowIcon,
  Grid,
  Chip,
  styled,
  useTheme,
  Stack,
} from '@livingpackets/design-system-react-next';
import Input from 'components/atoms/Input';
import NavTab from 'components/atoms/NavTab';
import Tab from 'components/atoms/Tab';
import { DateTimeFilter } from 'components/form/DateTimeFilter';
import ActionModal from 'components/molecules/ActionModal';
import ArchiveShipmentModal from 'components/molecules/modals/ArchiveShipmentModal';
import NavTabs from 'components/molecules/NavTabs';
import Title from 'components/molecules/Title';
import PartnerShipmentsTable, {
  ShipmentDirection,
} from 'components/organisms/PartnerShipmentsTable';
import { PATHS } from 'configs';
import { STATE_ORIGIN_SHIPMENT } from 'features/deploy-return';
import { formatDateTime } from 'features/shipments';
import useCurrentPartner from 'hooks/useCurrentPartner';
import { useDeleteShipment } from 'hooks/useDeleteShipment';
import useEditShipment from 'hooks/useEditShipment';
import useFormTemplate from 'hooks/useFormTemplate';
import { GetPartnerShipmentsParams } from 'hooks/useGetPartnerShipments';
import useNewShipmentDisabled from 'hooks/useNewShipmentDisabled';
import { usePartnerShipments } from 'hooks/usePartnerShipments';
import useShipmentList from 'hooks/useShipmentList';
import useShipmentTab, {
  ShipmentListNavTabEnum,
  ShipmentListTabEnum,
} from 'hooks/useShipmentTab';
import i18next from 'i18next';
import { debounce, get } from 'lodash';
import { isEmpty } from 'lodash/fp';
import {
  INITIAL_STATE_SHIPMENT_SEARCH_FORM,
  IShipment,
  IShipmentSearchForm,
  ShipmentContractStateEnum,
  ShipmentSearchFieldEnum,
} from 'models/shipment';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { shipmentSearchSchema } from 'schemas/shipmentSchema';
import useCurrentPartnerStore, {
  currentPartnerSelector,
} from 'stores/useCurrentPartnerStore';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import { usePagination } from '../../../shared-components';

const WrapperHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const InputContainer = styled('div')`
  position: relative;
  width: 100%;
`;

const InputSearch = styled(Input, {
  shouldForwardProp: prop => prop !== 'searchFieldHighLighted',
})<{
  searchFieldHighLighted: boolean;
}>`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: ${({ theme, searchFieldHighLighted }) =>
    searchFieldHighLighted
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.white.pure};
  transition: 200ms;
  border-style: solid solid solid none;
`;

const SearchFieldIconContainer = styled(Box)`
  transition: 200ms;
  margin-left: 0;

  height: 25px;
  width: 25px;
`;

const SearchFieldSelector = styled(Box, {
  shouldForwardProp: prop => prop !== 'searchFieldHighLighted',
})<{
  searchFieldHighLighted: boolean;
}>`
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-width: 1px;
  border-style: solid none solid solid;
  border-color: ${({ theme, searchFieldHighLighted }) =>
    searchFieldHighLighted
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.white.pure};
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  transition: 200ms;
  height: 3rem;
  width: 11.75rem;
  min-width: 11.75rem;
  padding-left: 0.75rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding-right: 0.3125rem;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    height: 2rem;
    width: 0.0625rem;
    background-color: ${({ theme }) => theme.palette.custom.neutral.black[20]};
    margin-top: auto;
    margin-bottom: auto;
    top: 50%;
    bottom: 50%;
  }
`;

const ShipmentSearchFieldListContainer = styled(Box)`
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  border-width: 1px;
  border-style: solid none solid solid;
  border-color: ${({ theme }) => theme.palette.custom.neutral.black[10]};
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  position: absolute;
  left: 0;
  top: 3rem;
  width: 100%;
  padding-top: 12px;
  margin-top: -12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
`;

const ShipmentSearchFieldContainer = styled(Box)`
  padding: 10px;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom.primary[10]};
  }

  &:last-child {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
`;

const InputIconContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'displaySearchMagnifierIcon',
})<{
  displaySearchMagnifierIcon: boolean;
}>`
  position: absolute;
  right: 12px;
  top: ${({ displaySearchMagnifierIcon }) =>
    displaySearchMagnifierIcon ? '12px' : '15px'};
`;

const ClearButton = styled('button')`
  cursor: pointer;
  border: none;
  background: none;
`;

ClearButton.defaultProps = {
  children: <BulletWrongIcon size="1rem" />,
  type: 'button',
};

const SubTabContainer = styled(Box)`
  padding-left: 2rem;
  margin-top: 0;
`;

const TabContainer = styled(Grid)`
  flex-direction: row;
  justifycontent: space-between;
  gap: 1.5rem;
`;

const TabsPart = styled(Grid)`
  flex: 1;
  border-bottom: 0.0625rem;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.custom.neutral.black[20]};
`;

const shipmentSearchFieldList = [
  ShipmentSearchFieldEnum.SHIPMENT_NAME,
  ShipmentSearchFieldEnum.CARRIER_TRACKING_CODE,
  ShipmentSearchFieldEnum.LPUI,
  ShipmentSearchFieldEnum.RECIPIENT,
  ShipmentSearchFieldEnum.SENDER,
];

const PartnerShipmentsOverview = () => {
  const theme = useTheme();
  const { t } = useTranslation(['shipments', 'general', 'tabs']);
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);
  const language = i18next.language;

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const partner = useCurrentPartnerStore(currentPartnerSelector);
  const { getCurrentPartner } = useCurrentPartner();

  useEffect(() => {
    getCurrentPartner(activePartner.id);
  }, [activePartner.id, getCurrentPartner]);

  const disableNewShipment = useNewShipmentDisabled(partner);

  const {
    getShipmentListPaginationCurrent,
    setShipmentListPaginationCurrent,
    getShipmentListSearchCurrent,
    setShipmentListSearchCurrent,
    getShipmentListSearchFieldCurrent,
    setShipmentListSearchFieldCurrent,
    getShipmentListDateCurrent,
    setShipmentListDateCurrent,
  } = useShipmentList();

  const {
    getShipmentNavTabCurrent,
    setShipmentNavTabCurrent,
    getShipmentTabCurrent,
    setShipmentTabCurrent,
    getCurrentContractStateFromCurrentTab,
  } = useShipmentTab();

  const [currentNavTab, setCurrentNavTab] = useState<ShipmentListNavTabEnum>(
    state?.navtab || getShipmentNavTabCurrent()
  );

  const [currentTab, setCurrentTab] = useState<ShipmentListTabEnum>(
    state?.tab || getShipmentTabCurrent()
  );

  // Handle user comming from dashboard with specific tab
  useEffect(() => {
    if (state?.navtab) {
      // Clear state from history
      window.history.replaceState({}, '');
      setShipmentNavTabCurrent(state.navtab);
    }
    if (state?.tab) {
      // Clear state from history
      window.history.replaceState({}, '');
      setShipmentTabCurrent(state.tab);
    }
    // Only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    shipmentSearchFieldSelectedForDisplay,
    setShipmentSearchFieldSelectedForDisplay,
  ] = useState<ShipmentSearchFieldEnum>(getShipmentListSearchFieldCurrent());

  const [
    shipmentSearchFieldListDisplayed,
    setShipmentSearchFieldListDisplayed,
  ] = useState<boolean>(false);

  const [searchFieldHighLighted, setSearchFieldHighLighted] =
    useState<boolean>(false);

  const [viewMode, setViewMode] = useState<
    | { action: 'none' }
    | { action: 'delete' | 'archive' | 'viewDetails'; target: IShipment }
  >({ action: 'none' });
  const { params: paginationParams, setParams: setPaginationParams } =
    usePagination(getShipmentListPaginationCurrent());

  setShipmentListPaginationCurrent(paginationParams);

  const deleteShipmentMutation = useDeleteShipment();

  const [shipmentSearch, setShipmentSearch] = useState<{
    searchTerm: string;
    field: ShipmentSearchFieldEnum;
  }>({
    searchTerm: getShipmentListSearchCurrent(),
    field: getShipmentListSearchFieldCurrent(),
  });

  // Update the default search item every time the user change it
  useEffect(() => {
    setShipmentListSearchCurrent(shipmentSearch.searchTerm);
  }, [shipmentSearch.searchTerm, setShipmentListSearchCurrent]);

  // Update the default search field every time the user change it
  useEffect(() => {
    setShipmentListSearchFieldCurrent(shipmentSearchFieldSelectedForDisplay);
  }, [
    shipmentSearchFieldSelectedForDisplay,
    setShipmentListSearchFieldCurrent,
  ]);

  // Retrieves date object from local storage
  const shipmentListDateCurrent = getShipmentListDateCurrent();

  const defaultValues = {
    ...INITIAL_STATE_SHIPMENT_SEARCH_FORM,
    date: shipmentListDateCurrent.date || '',
    startTime: shipmentListDateCurrent.startTime || '',
    endTime: shipmentListDateCurrent.endTime || '',
  };

  const {
    handleSubmit,
    errors,
    register,
    formState: { touchedFields, isValid },
    setValue,
    reset,
    control,
    watch,
    trigger,
  } = useFormTemplate<IShipmentSearchForm>({
    formValidationMode: 'onTouched',
    resolver: shipmentSearchSchema,
    defaultValues,
  });

  // Trigger validation when the language changes
  // (and only if the forms has been touch/modified by the user)
  useEffect(() => {
    const { date, startTime, endTime } = watch();

    // Only trigger if the fields are not empty
    if (
      !(isEmpty(date) && isEmpty(startTime) && isEmpty(endTime)) &&
      touchedFields.date &&
      touchedFields.startTime &&
      touchedFields.endTime
    ) {
      trigger();
    }
  }, [language, trigger, touchedFields, watch]);

  // OnSubmit Date Filters
  const onSubmitDateFilters: SubmitHandler<FieldValues> = values => {
    resetPagination();
    setShipmentListDateCurrent({
      date: values.date,
      startTime: values.startTime,
      endTime: values.endTime,
    });
    setOpen(false);
  };

  const onCancelDateFilters = () => {
    resetPagination();
    // Update session storage first
    setShipmentListDateCurrent({ date: '', startTime: '', endTime: '' });
    // Then reset date fields from the form
    reset({ ...watch(), date: '', startTime: '', endTime: '' });
  };

  const getSearchParamsInformation = useCallback(() => {
    let search = {};
    if (shipmentSearch.searchTerm !== '') {
      switch (shipmentSearch.field) {
        case ShipmentSearchFieldEnum.SHIPMENT_NAME:
          search = { shipmentName: shipmentSearch.searchTerm };
          break;
        case ShipmentSearchFieldEnum.CARRIER_TRACKING_CODE:
          search = { carrier: shipmentSearch.searchTerm };
          break;
        case ShipmentSearchFieldEnum.RECIPIENT:
          search = { recipient: shipmentSearch.searchTerm };
          break;
        case ShipmentSearchFieldEnum.SENDER:
          search = { sender: shipmentSearch.searchTerm };
          break;
        case ShipmentSearchFieldEnum.LPUI:
          search = { lpui: shipmentSearch.searchTerm };
          break;
      }
    }

    return search;
  }, [shipmentSearch]);

  const shipmentsParams: GetPartnerShipmentsParams = useMemo(() => {
    let contractState = undefined;
    let isArchived = false;
    let direction: ShipmentDirection | undefined = undefined;

    switch (currentTab) {
      case ShipmentListTabEnum.labelPurchased:
        contractState = ShipmentContractStateEnum.PURCHASED;
        direction = 'original';
        break;
      case ShipmentListTabEnum.labelTransferred:
        contractState = ShipmentContractStateEnum.TRANSFERRED;
        direction = 'original';
        break;
      case ShipmentListTabEnum.onGoing:
        contractState = ShipmentContractStateEnum.DEPLOYED;
        direction = 'original';
        break;
      case ShipmentListTabEnum.return:
        contractState = ShipmentContractStateEnum.DEPLOYED;
        direction = 'return';
        break;
      case ShipmentListTabEnum.finished:
        contractState = ShipmentContractStateEnum.ARRIVED;
        direction = 'original';
        break;
      case ShipmentListTabEnum.archived:
        isArchived = true;
        break;
    }

    const search = getSearchParamsInformation();
    let dateValues = {};
    if (
      currentNavTab === ShipmentListNavTabEnum.ongoing &&
      shipmentListDateCurrent.date &&
      shipmentListDateCurrent.startTime &&
      shipmentListDateCurrent.endTime
    ) {
      dateValues = formatDateTime(
        shipmentListDateCurrent.date,
        shipmentListDateCurrent.startTime,
        shipmentListDateCurrent.endTime
      );
    }

    return {
      contractState,
      partnerId: activePartner.id,
      isArchived,
      ...(isEmpty(search) ? { direction: direction } : {}),
      ...paginationParams,
      ...search,
      ...dateValues,
    };
  }, [
    currentTab,
    currentNavTab,
    getSearchParamsInformation,
    shipmentListDateCurrent.date,
    shipmentListDateCurrent.startTime,
    shipmentListDateCurrent.endTime,
    activePartner.id,
    paginationParams,
  ]);

  const fetchShipments = usePartnerShipments(shipmentsParams);

  const { archiveShipment } = useEditShipment();

  const [displaySearchMagnifierIcon, setDisplaySearchMagnifierIcon] =
    useState<boolean>(shipmentSearch.searchTerm === '');

  useEffect(() => {
    if (
      getShipmentListSearchCurrent() !== '' &&
      touchedFields.shipmentData !== true
    ) {
      setValue('shipmentData', getShipmentListSearchCurrent(), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [getShipmentListSearchCurrent, setValue, touchedFields]);

  const handleDebounce = (value: string) => {
    if (value === '') {
      // Do not reset the Field when the user
      // clear the search input by pressing multiple backspaces
      handleClearSearch({ resetField: false });
    } else {
      triggerUpdateTerm(value);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedShipmentSearch = useCallback(debounce(handleDebounce, 500), [
    shipmentSearchFieldSelectedForDisplay,
  ]);

  const handleInputChange = (event: any) => {
    setDisplaySearchMagnifierIcon(!event.target.value);
    debouncedShipmentSearch(event.target.value);
  };

  const resetPagination = useCallback(
    () => setPaginationParams({ page: 1 }),
    [setPaginationParams]
  );

  const triggerUpdateTerm = (term: string) => {
    resetPagination();

    setShipmentSearch({
      searchTerm: term,
      field: shipmentSearchFieldSelectedForDisplay,
    });
  };

  const handleClearSearch = useCallback(
    ({
      resetField = true,
    }: {
      resetField?: boolean;
    } = {}) => {
      resetPagination();

      setDisplaySearchMagnifierIcon(true);

      if (resetField) {
        setShipmentSearchFieldSelectedForDisplay(
          ShipmentSearchFieldEnum.SHIPMENT_NAME
        );
        setShipmentSearch({
          searchTerm: '',
          field: ShipmentSearchFieldEnum.SHIPMENT_NAME,
        });
      } else {
        setShipmentSearch({
          ...shipmentSearch,
          searchTerm: '',
        });
      }

      reset();
    },
    [reset, resetPagination, shipmentSearch]
  );

  const redirectToImportShipment = () => navigate(`${pathname}/labels/import`);

  const redirectToShipmentDetail = (shipmentId: string) => {
    navigate(
      generatePath(PATHS.SHIPMENT.DETAIL, {
        partnerId: activePartner.id,
        shipmentId: shipmentId,
      })
    );
  };

  const updateShipmentListNavTab = (
    shipmentListNavTab: ShipmentListNavTabEnum
  ) => {
    setPaginationParams({ page: 1 });
    setCurrentNavTab(shipmentListNavTab);
    setShipmentNavTabCurrent(shipmentListNavTab);

    if (shipmentListNavTab === ShipmentListNavTabEnum.labels) {
      updateShipmentListTab(ShipmentListTabEnum.labelPurchased);
    }

    if (shipmentListNavTab === ShipmentListNavTabEnum.ongoing) {
      updateShipmentListTab(ShipmentListTabEnum.onGoing);
    }

    if (shipmentListNavTab === ShipmentListNavTabEnum.completed) {
      updateShipmentListTab(ShipmentListTabEnum.finished);
    }
  };

  const updateShipmentListTab = (shipmentListTab: ShipmentListTabEnum) => {
    setCurrentTab(shipmentListTab);
    setShipmentTabCurrent(shipmentListTab);
  };

  const handleArchive = useCallback(
    async (shipmentId: string) => {
      await archiveShipment(shipmentId);
    },
    [archiveShipment]
  );

  const isShipmentListEmpty = useCallback(
    () =>
      fetchShipments.state === 'success' && fetchShipments.resource.total === 0,
    [fetchShipments]
  );

  const redirectToShipmentRemote = (shipmentId: string) => {
    navigate(
      generatePath(PATHS.SHIPMENT.REMOTE_CONTROL, {
        partnerId: activePartner.id,
        shipmentId: shipmentId,
      })
    );
  };
  const redirectToShipmentDeployReturn = (shipmentId: string) => {
    navigate(
      generatePath(PATHS.SHIPMENT.DEPLOY_RETURN, {
        partnerId: activePartner.id,
        shipmentId: shipmentId,
      }),
      { state: { origin: STATE_ORIGIN_SHIPMENT } }
    );
  };

  const shipmentsTotal = () => (
    <Grid item paddingTop="4px">
      {fetchShipments.state === 'success' && (
        <Chip
          data-testid="partnerShipmentTable-total-text"
          label={
            t('general:total').toUpperCase() +
              ' ' +
              fetchShipments.resource.total || '0'
          }
          state="default"
          type="tags"
          variant="titleXS"
        />
      )}
    </Grid>
  );

  return (
    <>
      <Title pageName={t('general:overview')} />
      <NavTabs>
        <NavTab
          selected={currentNavTab === ShipmentListNavTabEnum.labels}
          icon={<ShipmentPurchasedIcon />}
          onClick={() =>
            updateShipmentListNavTab(ShipmentListNavTabEnum.labels)
          }
          testId="partnerShipments-navtab-shipmentPurchased"
        >
          {t('tabs:shipmentLabels')}
        </NavTab>
        <NavTab
          selected={currentNavTab === ShipmentListNavTabEnum.ongoing}
          icon={<ShipmentOngoingIcon />}
          onClick={() =>
            updateShipmentListNavTab(ShipmentListNavTabEnum.ongoing)
          }
          testId="partnerShipments-navtab-shipmentOngoing"
        >
          {t('tabs:shipmentOngoing')}
        </NavTab>
        <NavTab
          selected={currentNavTab === ShipmentListNavTabEnum.completed}
          icon={<ShipmentCompletedIcon />}
          onClick={() =>
            updateShipmentListNavTab(ShipmentListNavTabEnum.completed)
          }
          testId="partnerShipments-navtab-shipmentCompleted"
        >
          {t('tabs:shipmentCompleted')}
        </NavTab>
      </NavTabs>
      <WrapperHeader>
        <Stack width="100%">
          <Stack
            direction="row"
            justifyContent="space-between"
            zIndex={5}
            gap=".9063rem"
            mb="1rem"
          >
            <Box flex={1}>
              <Stack direction="row">
                <Stack style={{ position: 'relative' }}>
                  {/** Select Component */}
                  <SearchFieldSelector
                    searchFieldHighLighted={searchFieldHighLighted}
                    tabIndex={0}
                    onFocus={() => {
                      setShipmentSearchFieldListDisplayed(true);
                      setSearchFieldHighLighted(true);
                    }}
                    onBlur={() => {
                      // Wait a short amount time in order avoid the field selection problem
                      setTimeout(function () {
                        if (shipmentSearchFieldListDisplayed) {
                          setShipmentSearchFieldListDisplayed(false);
                        }

                        if (searchFieldHighLighted) {
                          setSearchFieldHighLighted(false);
                        }
                      }, 500);
                    }}
                    data-testid="shipment-search-field-selector"
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Text variant="titleS" color="custom.neutral.black.50">
                        {t(
                          `tabs:shipmentSearchField.${shipmentSearchFieldSelectedForDisplay}`
                        )}
                      </Text>
                      <SearchFieldIconContainer>
                        <ChevronDownIcon
                          color={
                            shipmentSearchFieldListDisplayed
                              ? theme.palette.custom.primary[100]
                              : theme.palette.custom.neutral.black[50]
                          }
                        />
                      </SearchFieldIconContainer>
                    </Stack>
                  </SearchFieldSelector>
                  {/** Select Component */}

                  {shipmentSearchFieldListDisplayed && (
                    <ShipmentSearchFieldListContainer>
                      {shipmentSearchFieldList.map(
                        (shipmentSearchField: ShipmentSearchFieldEnum) => (
                          <ShipmentSearchFieldContainer
                            onClick={() => {
                              setShipmentSearchFieldSelectedForDisplay(
                                shipmentSearchField
                              );

                              if (shipmentSearch.searchTerm) {
                                setShipmentSearch({
                                  ...shipmentSearch,
                                  field: shipmentSearchField,
                                });
                              }

                              setSearchFieldHighLighted(false);
                              setShipmentSearchFieldListDisplayed(false);
                            }}
                            key={shipmentSearchField}
                            data-testid={
                              'shipment-search-field-' + shipmentSearchField
                            }
                          >
                            <Text
                              variant="bodyTextM"
                              color="custom.neutral.black.90"
                            >
                              {t(
                                `tabs:shipmentSearchField.${shipmentSearchField}`
                              )}
                            </Text>
                          </ShipmentSearchFieldContainer>
                        )
                      )}
                    </ShipmentSearchFieldListContainer>
                  )}
                </Stack>
                <InputContainer style={{ marginLeft: 0 }}>
                  <InputSearch
                    id="shipment-search-input"
                    name="shipmentData"
                    placeholder={t('shipments:list.inputSearch.placeholder')}
                    error={get(errors, 'shipmentData', false)}
                    isTouched={get(touchedFields, 'shipmentData', false)}
                    register={register}
                    onChange={handleInputChange}
                    width="100%"
                    searchFieldHighLighted={searchFieldHighLighted}
                    onFocus={() => setSearchFieldHighLighted(true)}
                    onBlur={() => setSearchFieldHighLighted(false)}
                    style={{
                      height: '3rem',
                      borderStyle: 'solid solid solid none',
                      fontWeight: 500,
                      fontSize: '.875rem',
                      lineHeight: '1.125rem',
                    }}
                  />
                  <InputIconContainer
                    displaySearchMagnifierIcon={displaySearchMagnifierIcon}
                  >
                    {displaySearchMagnifierIcon ? (
                      <SearchIcon color={theme.palette.custom.primary[100]} />
                    ) : (
                      <ClearButton
                        aria-label={t('general:clearSearch')}
                        title={t('general:clearSearch')}
                        onClick={() => handleClearSearch({ resetField: false })}
                      />
                    )}
                  </InputIconContainer>
                </InputContainer>
              </Stack>
            </Box>
            <ButtonV2
              icon={ArrowRightIcon}
              onClick={() => redirectToImportShipment()}
              data-testid="open-import"
              disabled={disableNewShipment}
            >
              {t('shipments:importShipment.title')}
            </ButtonV2>
          </Stack>
          <SubTabContainer>
            {currentNavTab === ShipmentListNavTabEnum.labels && (
              <TabContainer container>
                <TabsPart item display="flex" flexDirection="row">
                  <Tab
                    onClick={() => {
                      updateShipmentListTab(ShipmentListTabEnum.labelPurchased);
                      setPaginationParams({ page: 1 });
                    }}
                    selected={currentTab === ShipmentListTabEnum.labelPurchased}
                    testId="partnerShipments-tab-labelPurchased"
                  >
                    {t('tabs:tabs.labelPurchased')}
                  </Tab>
                  <Tab
                    onClick={() => {
                      updateShipmentListTab(
                        ShipmentListTabEnum.labelTransferred
                      );
                      setPaginationParams({ page: 1 });
                    }}
                    selected={
                      currentTab === ShipmentListTabEnum.labelTransferred
                    }
                    testId="partnerShipments-tab-labelTransferred"
                  >
                    {t('tabs:tabs.labelTransferred')}
                  </Tab>
                </TabsPart>
                <Grid item>{shipmentsTotal()}</Grid>
              </TabContainer>
            )}
            {currentNavTab === ShipmentListNavTabEnum.ongoing && (
              <TabContainer container>
                <TabsPart item display="flex" flexDirection="row">
                  <Tab
                    onClick={() => {
                      updateShipmentListTab(ShipmentListTabEnum.onGoing);
                      setPaginationParams({ page: 1 });
                    }}
                    selected={currentTab === ShipmentListTabEnum.onGoing}
                    testId="partnerShipments-tab-onGoing"
                  >
                    {t('tabs:tabs.onGoing')}
                  </Tab>
                  <Tab
                    onClick={() => {
                      updateShipmentListTab(ShipmentListTabEnum.return);
                      setPaginationParams({ page: 1 });
                    }}
                    selected={currentTab === ShipmentListTabEnum.return}
                    testId="partnerShipments-tab-return"
                  >
                    {t('tabs:tabs.return')}
                  </Tab>
                </TabsPart>

                <Grid item display="flex" flexDirection="row" gap="1.5rem">
                  {shipmentsTotal()}
                  <DateTimeFilter
                    control={control}
                    onSubmit={handleSubmit(onSubmitDateFilters)}
                    onCancel={onCancelDateFilters}
                    setValue={setValue}
                    isValid={isValid}
                    watch={watch}
                    setOpen={setOpen}
                    open={open}
                    trigger={trigger}
                  />
                </Grid>
              </TabContainer>
            )}
            {currentNavTab === ShipmentListNavTabEnum.completed && (
              <TabContainer container>
                <TabsPart item display="flex" flexDirection="row">
                  <Tab
                    onClick={() => {
                      updateShipmentListTab(ShipmentListTabEnum.finished);
                      setPaginationParams({ page: 1 });
                    }}
                    selected={currentTab === ShipmentListTabEnum.finished}
                    testId="partnerShipments-tab-finished"
                  >
                    {t('tabs:tabs.finished')}
                  </Tab>
                  <Tab
                    onClick={() => {
                      updateShipmentListTab(ShipmentListTabEnum.archived);
                      setPaginationParams({ page: 1 });
                    }}
                    selected={currentTab === ShipmentListTabEnum.archived}
                    testId="partnerShipments-tab-archived"
                  >
                    {t('tabs:tabs.archived')}
                  </Tab>
                </TabsPart>
                <Grid item>{shipmentsTotal()}</Grid>
              </TabContainer>
            )}
          </SubTabContainer>
        </Stack>
      </WrapperHeader>
      <PartnerShipmentsTable
        shipments={fetchShipments}
        activeShipment={
          viewMode.action !== 'none' ? viewMode.target : undefined
        }
        pagination={paginationParams}
        onPaginationChange={setPaginationParams}
        onShipmentSelect={shipment => {
          redirectToShipmentDetail(shipment.id!);
        }}
        onShipmentArchive={shipment =>
          setViewMode({ action: 'archive', target: shipment })
        }
        onShipmentDelete={shipment => {
          setViewMode({ action: 'delete', target: shipment });
        }}
        currentContractState={getCurrentContractStateFromCurrentTab()}
        onRemoteSelect={shipment => redirectToShipmentRemote(shipment.id!)}
        onDeployReturnSelect={shipment =>
          redirectToShipmentDeployReturn(shipment.id!)
        }
        currentNavTab={currentNavTab}
      />
      {isShipmentListEmpty() && (
        <Box>
          {shipmentSearch.searchTerm === '' ? (
            <EmptyState type="Shipments">
              <Text variant="titleM" color="custom.neutral.black.50">
                {t('shipments:list.emptyState.emptyMessage')}
              </Text>
              <Text variant="bodyTextL" color="custom.neutral.black.50">
                {t('shipments:list.emptyState.emptySubMessage')}
              </Text>
              <ButtonV2
                icon={ArrowIcon}
                sx={{ marginTop: '1.625rem' }}
                data-testid="shipment-list-redirect-import-button"
                disabled={disableNewShipment}
                onClick={() => redirectToImportShipment()}
              >
                {t('shipments:list.emptyState.cta')}
              </ButtonV2>
            </EmptyState>
          ) : (
            <EmptyState type="Results">
              <Text variant="titleM" color="custom.neutral.black.50">
                {t('shipments:list.noResult.emptyMessage')}
              </Text>
              <Text variant="bodyTextL" color="custom.neutral.black.50">
                {t('shipments:list.noResult.emptySubMessage')}
              </Text>
              <ButtonV2
                sx={{ marginTop: '1.625rem' }}
                data-testid="shipment-list-clear-search-button"
                onClick={() => handleClearSearch()}
              >
                {t('shipments:list.noResult.cta')}
              </ButtonV2>
            </EmptyState>
          )}
        </Box>
      )}
      <ActionModal
        title={t('shipments:deleteShipment')}
        text={t('shipments:deleteShipmentText')}
        ctaText={t('general:delete')}
        open={viewMode.action === 'delete'}
        onClose={() => setViewMode({ action: 'none' })}
        loading={deleteShipmentMutation.state === 'loading'}
        onAction={async () => {
          if (viewMode.action !== 'delete') return;
          await deleteShipmentMutation.execute(viewMode.target.shipment_id);
          fetchShipments.invalidate();
          setViewMode({ action: 'none' });
        }}
      />
      <ArchiveShipmentModal
        open={viewMode.action === 'archive'}
        onSubmit={async () => {
          if (viewMode.action !== 'archive') return;

          if (viewMode.target.id) {
            await handleArchive(viewMode.target.id);
          } else if (viewMode.target.shipment_id) {
            await handleArchive(viewMode.target.shipment_id);
          } else return;

          fetchShipments.invalidate();
          setViewMode({ action: 'none' });
        }}
        onCancel={() => setViewMode({ action: 'none' })}
        loading={deleteShipmentMutation.state === 'loading'}
      />
    </>
  );
};

export default PartnerShipmentsOverview;
