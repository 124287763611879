// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Text, styled } from '@livingpackets/design-system-react-next';
import lpLogo from 'assets/lpLogo.png';
import lpLogo2x from 'assets/lpLogo@2x.png';
import lpLogo3x from 'assets/lpLogo@3x.png';

import Card from '../atoms/Card';

const Wrapper = styled('div')`
  background: ${({ theme }) => theme.palette.custom.neutral.black[3]};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  overflow-x: auto;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    background: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  }
`;

const Container = styled(Card)`
  display: inline-block;
  width: auto;
  padding: 50px 25px 25px;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    border-radius: 0;
  }
`;

const TextDescription = styled(Text)`
  max-width: 34ch;
  text-align: center;
`;

export function AnonView({
  title,
  description,
  children,
}: {
  title: string;
  description: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <Wrapper>
      <Container>
        <img
          alt="The Box by LivingPackets"
          src={lpLogo}
          srcSet={`${lpLogo2x} 2x, ${lpLogo3x} 3x`}
        />
        <Text variant="titleL" mb={4} mt={6}>
          {title}
        </Text>
        <TextDescription variant="bodyTextL">{description}</TextDescription>
        {children}
      </Container>
    </Wrapper>
  );
}
