// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Tag } from '@livingpackets/design-system-react';
import {
  Text,
  Stack,
  NewTooltip as Tooltip,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const TooltipModifyBankInformation = ({
  getRewardStatusTagState,
  getRewardStatusText,
  row,
  accountInformation,
}: any) => {
  const { t } = useTranslation('contribution');

  return (
    <Tooltip
      id="modify-bank-information-tooltip"
      placement="bottom-start"
      sx={{ width: '275px' }}
      title={
        <Stack>
          <Text variant="titleM" color="custom.neutral.black.100" mb=".625rem">
            {t(
              'contributionsTab.contributionsList.info.modifyBankInformation.title'
            )}
          </Text>
          <Text variant="bodyTextS" color="custom.neutral.black.100">
            {t(
              'contributionsTab.contributionsList.info.modifyBankInformation.action'
            )}
          </Text>
        </Stack>
      }
    >
      <Tag
        state={getRewardStatusTagState(row.reward_status)}
        text={getRewardStatusText(
          row.reward_status,
          row.state,
          accountInformation?.is_completed
        )}
      />
    </Tooltip>
  );
};

export default TooltipModifyBankInformation;
