// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  SearchIcon,
  ChevronDownIcon,
  Box,
  Text,
  Stack,
  styled,
} from '@livingpackets/design-system-react-next';
import Input from 'components/atoms/Input';
import NameCard from 'components/atoms/NameCard';
import { normalizeKey } from 'helpers/i18n';
import useContactAddress from 'hooks/useContactAddress';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import useFormTemplate from 'hooks/useFormTemplate';
import { debounce, get } from 'lodash';
import {
  IContactAddress,
  IContactSearchForm,
  INITIAL_STATE_CONTACT_SEARCH_FORM,
  ISearchContactAddress,
  ISearchContactAddressElement,
} from 'models/contactAddress';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import { contactSearchSchema } from 'schemas/contactAddressSchema';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const VStackSearchContactForm = styled(Stack)`
  width: 100%;
  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    width: 100%;
  }
`;

const InputContainer = styled('div')`
  position: relative;
`;

const InputIconContainer = styled('div')`
  position: absolute;
  right: 12px;
  top: 12px;
`;

const ContactInfoText = styled('span')`
  color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  font-style: normal;
`;

const ContactInfoType = styled(ContactInfoText)`
  background-color: ${({ theme }) => theme.palette.custom.primary[10]};
  color: ${({ theme }) => theme.palette.custom.primary[100]};
  font-size: 10px;
  line-height: 11px;
  font-weight: 400;
  font-style: normal;
  border-radius: 7px;
  padding: 8px;
  text-transform: capitalize;
`;

const ContactSearchListContainer = styled('div')`
  margin-top: 0;
`;

const ContactSearchBlockContainer = styled(Stack)`
  margin-bottom: 8px;
`;

const ContactSearchBlock = styled(Box, {
  shouldForwardProp: prop => prop !== 'isContactAddressSelected',
})<{ isContactAddressSelected: boolean }>`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  padding: 15px;
  transition: 500ms;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  border: 1.5px;
  border-style: solid;
  border-color: ${({ isContactAddressSelected, theme }) =>
    isContactAddressSelected
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.black[8]};

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom.primary[10]};
    cursor: pointer;
  }
`;

const IconWrap = styled('div')`
  cursor: pointer;
`;

const AddressListContainer = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'isDisplayAddressList' && prop !== 'nbAddressList',
})<{
  isDisplayAddressList: boolean;
  nbAddressList: number;
}>`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  margin-top: ${({ isDisplayAddressList }) =>
    isDisplayAddressList ? '-20px' : '0'};
  padding-top: ${({ isDisplayAddressList }) =>
    isDisplayAddressList ? '20px' : '0'};
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: 200ms;

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom.primary[10]};
  }
`;

const AddressListInfo = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isDisplayAddressList',
})<{ isDisplayAddressList: boolean }>`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 60px;
  transition: 200ms;

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom.primary[10]};
    cursor: pointer;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

interface ISearchContactType {
  visible: boolean;
  onAddressSelect: (address: IContactAddress) => void;
  onContactSelect?: (contact: ISearchContactAddress) => void;
  onDismiss: () => void;
}

const SearchContactAddressRow = ({
  contact,
  onContactIdSelected,
  onContactSelected,
  onAddressSelected,
  isContactAddressSelected,
  contactIdSelected,
  index,
}: {
  contact: ISearchContactAddress;
  onContactIdSelected: (contactId: string) => void;
  onContactSelected: (contact: ISearchContactAddress) => void;
  onAddressSelected: (address: IContactAddress) => void;
  isContactAddressSelected: boolean;
  contactIdSelected: string | undefined;
  index: number;
}) => {
  const { t } = useTranslation('contactAddress');

  const [, getCountryNameFromCode] = useCountryCodesWrapper();
  const [isDisplayAddressList, setIsDisplayAddressList] =
    useState<boolean>(false);

  const [isDisplayAddressListContainer, setIsDisplayAddressListContainer] =
    useState<boolean>(false);

  const [addressListLength, setAddressListLength] = useState<number>(0);

  function getAddressFirstLine(address: IContactAddress) {
    return address.houseNumber + ' ' + address.street + ',';
  }

  const props = useSpring({
    to: { height: '0', opacity: 0 },
    from: { height: `${addressListLength * 72}px`, opacity: 1 },
    reverse: isDisplayAddressList,
  });

  function getAddressSecondLine(address: IContactAddress) {
    return (
      address.postalCode +
      ' ' +
      address.city +
      ', ' +
      getCountryNameFromCode(address.countryCode)
    );
  }

  const addressSearch = contact.addressList.find(
    (addressSearchList: ISearchContactAddressElement) =>
      addressSearchList.isAddressSelected
  );

  const handleContactSearchBlockClick = useCallback(async () => {
    setIsDisplayAddressList(!isDisplayAddressList);
    setIsDisplayAddressListContainer(!isDisplayAddressListContainer);
    setAddressListLength(contact.addressList.length);
  }, [
    isDisplayAddressList,
    isDisplayAddressListContainer,
    setIsDisplayAddressList,
    setIsDisplayAddressListContainer,
    contact,
  ]);

  const handleAddressSelectClick = useCallback(
    async (address: IContactAddress) => {
      setIsDisplayAddressList(false);
      setIsDisplayAddressListContainer(false);
      onAddressSelected(address);
      onContactIdSelected(contact.id);

      onContactSelected(contact);

      contact.addressList = contact.addressList.map(
        (addressInList: ISearchContactAddressElement) => {
          addressInList.isAddressSelected =
            address.id === addressInList.address.id;

          return addressInList;
        }
      );
    },
    [
      contact,
      onAddressSelected,
      onContactSelected,
      onContactIdSelected,
      setIsDisplayAddressList,
      setIsDisplayAddressListContainer,
    ]
  );

  useEffect(() => {
    setIsDisplayAddressList(false);
    setIsDisplayAddressListContainer(false);
  }, [
    contactIdSelected,
    setIsDisplayAddressList,
    setIsDisplayAddressListContainer,
  ]);

  return (
    <ContactSearchBlockContainer data-testid={`contact-address-list-${index}`}>
      <ContactSearchBlock
        isContactAddressSelected={isContactAddressSelected}
        onClick={() => handleContactSearchBlockClick()}
      >
        <Box width="10%">
          <NameCard
            firstName={contact.firstName}
            lastName={contact.lastName}
            email={contact.email}
          />
        </Box>
        <Box width="25%">
          <Text variant="titleXS">{contact.name}</Text>
        </Box>
        <Box width="20%">
          {addressSearch && (
            <Text variant="titleXS">{addressSearch.address.company}</Text>
          )}
        </Box>
        <Box width="35%">
          {addressSearch && (
            <Stack>
              <Text variant="bodyTextS">
                {getAddressFirstLine(addressSearch.address)}
              </Text>
              <Text variant="bodyTextS">
                {getAddressSecondLine(addressSearch.address)}
              </Text>
            </Stack>
          )}
        </Box>
        <Box width="10%">
          {contact.addressList.length !== 0 && (
            <IconWrap>
              <ChevronDownIcon />
            </IconWrap>
          )}
        </Box>
      </ContactSearchBlock>
      {contact.addressList.length !== 0 && (
        <AddressListContainer
          isDisplayAddressList={isDisplayAddressList}
          nbAddressList={contact.addressList.length}
        >
          <animated.div style={props}>
            {contact.addressList.map(
              (
                addressSearchList: ISearchContactAddressElement,
                indexAddress: number
              ) => (
                <Box
                  key={addressSearchList.address.id}
                  data-testid={`contact-address-list-${index}-address-${indexAddress}`}
                >
                  {isDisplayAddressListContainer && (
                    <AddressListInfo
                      direction="row"
                      onClick={() =>
                        handleAddressSelectClick(addressSearchList.address)
                      }
                      isDisplayAddressList={isDisplayAddressList}
                      key={addressSearchList.address.id}
                    >
                      <Box width="25%">
                        <ContactInfoType>
                          {t(
                            normalizeKey(
                              'contactAddress:form.type.choices.' +
                                addressSearchList.address.type
                            )
                          )}
                        </ContactInfoType>
                      </Box>
                      <Box width="25%">
                        {addressSearchList.address.company && (
                          <Text>{addressSearchList.address.company}</Text>
                        )}
                      </Box>
                      <Box width="50%">
                        <Stack>
                          <Text>
                            {getAddressFirstLine(addressSearchList.address)}
                          </Text>
                          <Text>
                            {getAddressSecondLine(addressSearchList.address)}
                          </Text>
                        </Stack>
                      </Box>
                    </AddressListInfo>
                  )}
                </Box>
              )
            )}
          </animated.div>
        </AddressListContainer>
      )}
    </ContactSearchBlockContainer>
  );
};

const SearchContactAddressForm = ({
  visible,
  onAddressSelect,
  onContactSelect,
}: ISearchContactType) => {
  const { t } = useTranslation('contactAddress');

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const [contactListSearchResult, setContactListSearchResult] = useState<
    ISearchContactAddress[]
  >([]);
  const [
    isContactListSearchResultDisplayed,
    setIsContactListSearchResultDisplayed,
  ] = useState<boolean>(false);
  const [contactIdSelected, setContactIdSelected] = useState<
    string | undefined
  >(undefined);

  const {
    errors,
    register,
    formState: { touchedFields },
    getValues,
  } = useFormTemplate<IContactSearchForm>({
    resolver: contactSearchSchema,
    defaultValues: INITIAL_STATE_CONTACT_SEARCH_FORM,
  });

  const { searchContactAddress } = useContactAddress(activePartner.id);

  useEffect(() => {
    if (!visible) {
      setContactListSearchResult([]);
      setIsContactListSearchResultDisplayed(false);
      setContactIdSelected(undefined);
    }
  }, [visible]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactIdSelected(undefined);

    const formValues = getValues();
    const contactName = formValues.contactName;

    if (contactName) {
      debouncedSearch(contactName);
    } else {
      setContactListSearchResult([]);
    }
  };

  const debouncedSearch = React.useRef(
    debounce(async (contactName: string) => {
      searchContactAddress(contactName).then(({ contactAddressList }) => {
        setContactListSearchResult(contactAddressList || []);
        setIsContactListSearchResultDisplayed(true);
      });
    }, 500)
  ).current;

  const handleContactIdSelect = useCallback((contactId: string) => {
    setContactIdSelected(contactId);
  }, []);

  const handleContactSelect = useCallback(
    (contact: ISearchContactAddress) => {
      if (onContactSelect) {
        onContactSelect(contact);
      }
    },
    [onContactSelect]
  );

  const handleAddressSelect = useCallback(
    (address: IContactAddress) => {
      onAddressSelect(address);
    },
    [onAddressSelect]
  );

  const submitHandler = useCallback((e: any) => e.preventDefault(), []);

  const preventEnterKeySubmission = (e: any) => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    if (e.key === 'Enter' && !['TEXTAREA'].includes(target.tagName)) {
      e.preventDefault();
    }
  };

  return (
    <VStackSearchContactForm
      as="form"
      onSubmit={submitHandler}
      onKeyPress={preventEnterKeySubmission}
    >
      <InputContainer>
        <Input
          name="contactName"
          label={
            get(touchedFields, 'contactName', false)
              ? t('modal.searchContactAddress.inputSearch.placeholder')
              : undefined
          }
          placeholder={t('modal.searchContactAddress.inputSearch.placeholder')}
          error={get(errors, 'contactName', false)}
          isTouched={get(touchedFields, 'contactName', false)}
          register={register}
          onChange={handleInputChange}
          width="100%"
          data-testid="search-contact-address-input"
        />
        <InputIconContainer>
          <SearchIcon />
        </InputIconContainer>
      </InputContainer>
      {isContactListSearchResultDisplayed &&
        contactListSearchResult.length !== 0 && (
          <ContactSearchListContainer>
            {contactListSearchResult.map(
              (contact: ISearchContactAddress, index: number) => (
                <SearchContactAddressRow
                  contact={contact}
                  onContactIdSelected={handleContactIdSelect}
                  onContactSelected={handleContactSelect}
                  onAddressSelected={handleAddressSelect}
                  isContactAddressSelected={
                    contactIdSelected ? contactIdSelected === contact.id : false
                  }
                  contactIdSelected={contactIdSelected}
                  key={contact.id}
                  index={index}
                />
              )
            )}
          </ContactSearchListContainer>
        )}
    </VStackSearchContactForm>
  );
};

export default SearchContactAddressForm;
