// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useEffect } from 'react';

import { AxiosResponse } from 'axios';
import { useApiClient } from 'hooks/useApiClient';
import { IPartnerAccount } from 'models/user';

import { PaginationParams } from '../shared-components';
import usePartnerMemberManagementStore from '../stores/usePartnerMemberManagementStore';

export interface PaginatedResponse<Type> {
  partner_accounts: Type[];
  total: number;
}

export interface PaginatedResponsePartnerAccount<Type> {
  items: Type[];
  count: number;
  total: string;
}

export interface GetPartnerAccountsParams extends Partial<PaginationParams> {
  partnerId: string;
  search?: string;
}

type GetPartnerAccounts = (
  params: GetPartnerAccountsParams
) => Promise<PaginatedResponsePartnerAccount<IPartnerAccount>>;

export function useGetPartnerAccounts(): GetPartnerAccounts {
  const lpVillage = useApiClient('lpVillageV3');
  const { dispatch } = usePartnerMemberManagementStore();
  useEffect(() => {}, [dispatch]);

  return useCallback(
    ({
      page = 1,
      pageSize = 100,
      orderColumn = 'updated_at',
      orderDirection = 'DESC',
      partnerId,
      search,
    }: GetPartnerAccountsParams) =>
      lpVillage
        .get<PaginatedResponse<IPartnerAccount>>(
          `/me/partners/${partnerId}/accounts`,
          {
            params: {
              'list_request.page_size': pageSize,
              'list_request.page_offset': (page - 1) * pageSize,
              'list_request.order_by': orderColumn + ' ' + orderDirection,
              'list_request.filter': search,
            },
          }
        )
        .then((response: AxiosResponse) => {
          if (!('partner_accounts' in response.data)) {
            response.data.partner_accounts = [];
          }

          if (!('total' in response.data)) {
            response.data.total = '0';
          }

          response.data.partner_accounts = response.data.partner_accounts.map(
            (partnerAccount: IPartnerAccount) => {
              if (partnerAccount.updated_at) {
                partnerAccount.updated_at =
                  new Date(partnerAccount.updated_at).getTime() / 1000;
              }

              if (partnerAccount.created_at) {
                partnerAccount.created_at =
                  new Date(partnerAccount.created_at).getTime() / 1000;
              }

              return partnerAccount;
            }
          );

          dispatch({
            type: 'updateRowData',
            args: {
              rowData: response.data.partner_accounts,
            },
          });
          dispatch({
            type: 'updateTotal',
            args: {
              total: response.data.total,
            },
          });

          return {
            items: response.data.partner_accounts,
            count: response.data.partner_accounts.length,
            total: response.data.total,
          };
        }),
    [lpVillage, dispatch]
  );
}
