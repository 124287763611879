// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import {
  Stack,
  NewTextField as TextField,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { AuthenticationView } from 'components/containers/AuthenticationView';
import { PATHS } from 'configs';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useCustomer from 'hooks/useCustomer';
import useFormTemplate from 'hooks/useFormTemplate';
import { PaginatedResponse, useGetPartnerList } from 'hooks/useGetPartnerList';
import useLpVillageService from 'hooks/useLpVillageService';
import useToastMessages from 'hooks/useToastMessages';
import {
  INITIAL_STATE_PARTNERSHIP_INFORMATION_FORM,
  IPartnershipInformationForm,
} from 'models/partnershipInformation';
import { IUser } from 'models/user';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { partnershipInformationSchema } from 'schemas/partnershipInformationSchema';
import useAppState, {
  dispatch,
  userSelector,
} from 'stores/appState/useAppState';
import useMyPartnersStore, {
  setActivePartnerSelector,
  setPartnersSelector,
} from 'stores/useMyPartnersStore';

const AddPartnershipInformationPage = () => {
  const { state } = useLocation();
  const { accountType } = state || {};

  const navigate = useNavigate();
  const { t } = useTranslation(['authentication', 'messages']);

  const appUser = useAppState(userSelector);
  const setActivePartner = useMyPartnersStore(setActivePartnerSelector);
  const setPartners = useMyPartnersStore(setPartnersSelector);
  const { getBusinessTypeFromAccountType } = useCustomer();

  const { selectPartnerType, isPartnerAvailable } = useLpVillageService();
  const getPartnerPartners = useGetPartnerList();

  const { error: errorToast } = useToastMessages();

  const {
    formState: { isValid },
    getValues,
    control,
  } = useFormTemplate<IPartnershipInformationForm>({
    resolver: partnershipInformationSchema,
    defaultValues: INITIAL_STATE_PARTNERSHIP_INFORMATION_FORM,
  });

  const submitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      if (!accountType) {
        navigate({
          pathname: PATHS.AUTHENTICATION.ACCOUNT_TYPE_CHOICE,
        });

        return;
      }

      const formValues = getValues();

      isPartnerAvailable(formValues.companyName).then(({ success, data }) => {
        if (!data.available) {
          errorToast('messages:companyNameDuplicated.message', { wide: true });

          return;
        }

        selectPartnerType(
          getBusinessTypeFromAccountType(accountType),
          formValues.companyName
        ).then(({ success, data }) => {
          getPartnerPartners({
            orderColumn: 'createdAt',
            orderDirection: 'ASC',
          }).then((response: PaginatedResponse<any>) => {
            if (response.total !== 0) {
              setPartners(response.items);
              setActivePartner(response.items[0]);
            }

            dispatch({
              type: 'SET_USER',
              payload: {
                ...appUser,
                ...{
                  businessType: UserBusinessTypeEnum.BUSINESS,
                },
              } as IUser,
            });

            navigate(PATHS.AUTHENTICATION.ACCOUNT_CREATED_SUCCESS, {
              state: { accountType: accountType },
            });
          });
        });
      });
    },
    [
      accountType,
      appUser,
      errorToast,
      getBusinessTypeFromAccountType,
      getPartnerPartners,
      getValues,
      navigate,
      isPartnerAvailable,
      selectPartnerType,
      setActivePartner,
      setPartners,
    ]
  );

  return (
    <AuthenticationView
      title={t('authentication:addPartnershipInformation.title')}
    >
      <Stack
        component="form"
        onSubmit={submitHandler}
        data-testid="add-partnership-information-key-container"
        gap="2.5rem"
      >
        <Controller
          name="companyName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t(
                'authentication:addPartnershipInformation.form.companyName.placeholder'
              )}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
        <Button
          data-testid="submit-btn-compagny-name"
          variant="primary"
          disabled={!isValid}
          sx={{ width: '100%', marginBottom: '16px' }}
          onClick={submitHandler}
        >
          {t('authentication:addPartnershipInformation.buttons.confirm')}
        </Button>
      </Stack>
    </AuthenticationView>
  );
};

export default AddPartnershipInformationPage;
