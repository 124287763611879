// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, {
  ReactChildren,
  ReactChild,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { styled } from '@livingpackets/design-system-react-next';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import {
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_GOOGLE_MAPS_API_VERSION,
} from 'configs';
import mapStyleForGoogleMaps from 'helpers/mapStyleForGoogleMaps';
import useActiveLanguage from 'hooks/useGetActiveLanguage';
import { IPosition } from 'models/position';

interface IMap {
  center?: { lat: number; lng: number };
  height?: string;
  width?: string;
  zoom?: number;
  children: ReactChild | ReactChildren;
  bounds?: IPosition[];
  isFullScreen?: boolean;
  isMapLoadedAction?: (isLoaded: boolean, mapInstance: any) => void;
  mapTypeControl?: boolean;
  streetViewControl?: boolean;
  rotateControl?: boolean;
  fullscreenControl?: boolean;
  zoomControl?: boolean;
  onZoomChanged?: (currentZoom?: number) => void;
  onCenterChanged?: (currentMapCenter?: google.maps.LatLng) => void;
  minZoom?: number;
  maxZoom?: number;
}

const Mask = styled('div')<{
  width?: string;
  height?: string;
  isFullScreen?: boolean;
}>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '400px'};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: ${({ isFullScreen }) =>
    isFullScreen ? '20px' : '0'};
  border-top-right-radius: ${({ isFullScreen }) =>
    isFullScreen ? '20px' : '0'};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
`;

const StyledMap = styled((props: any) => (
  <GoogleMap
    // Cannot use data-testid here because it is not supported by Google Maps
    id="map"
    onLoad={props.onLoad}
    mapContainerClassName={props.className}
    zoom={props.zoom}
    center={props.center}
    options={props.options}
    onZoomChanged={props.onZoomChanged}
    onCenterChanged={props.onCenterChanged}
  >
    {props.children}
  </GoogleMap>
))`
  transform: scale(1.02);
  height: 100%;
  width: 100%;

  .gm-style-cc {
    display: none;
  }

  .gmnoprint > div {
    border-radius: 10px !important;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1) !important;
  }

  // InfoWindow style
  .gm-style-iw-tc,
  .gm-ui-hover-effect {
    display: none !important;
  }

  .gm-style-iw {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px;
  }

  .infoBox > img {
    visibility: hidden;
  }
`;

const Map = ({
  center,
  zoom,
  children,
  height,
  width,
  bounds,
  isFullScreen = false,
  isMapLoadedAction,
  mapTypeControl = false,
  streetViewControl = false,
  rotateControl = false,
  fullscreenControl = false,
  zoomControl = false,
  onZoomChanged,
  onCenterChanged,
  minZoom = 2,
  maxZoom = 13,
}: IMap) => {
  const activeLanguage = useActiveLanguage();

  const [currentLanguage] = useState<string>(activeLanguage);

  const { isLoaded, loadError } = useJsApiLoader({
    id: `map-${currentLanguage}`,
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY as string,
    language: currentLanguage,
    version: REACT_APP_GOOGLE_MAPS_API_VERSION,
  });

  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const getBounds = (positions: IPosition[]) => {
    const bounds = new google.maps.LatLngBounds();
    positions.forEach((position: IPosition) => {
      bounds.extend({
        lat: position.position.lat,
        lng: position.position.lng,
      });
    });

    return bounds;
  };

  useEffect(() => {
    if (isMapLoadedAction) {
      isMapLoadedAction(isLoaded, map);
    }
  }, [isLoaded, isMapLoadedAction, map]);

  const onLoad = useCallback(
    (mapInstance: any) => {
      if (mapInstance) {
        if (bounds) {
          mapInstance.fitBounds(getBounds(bounds));
        }
      }

      setMap(mapInstance);
    },
    [bounds]
  );

  if (loadError) console.error(loadError);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(undefined);
  }, []);

  if (!isLoaded) return null;

  return (
    <Mask width={width} height={height} isFullScreen={isFullScreen}>
      <StyledMap
        onLoad={onLoad}
        onZoomChanged={() => {
          if (onZoomChanged) {
            onZoomChanged(map?.getZoom());
          }
        }}
        onCenterChanged={() => {
          if (onCenterChanged) {
            onCenterChanged(map?.getCenter());
          }
        }}
        onUnmount={onUnmount}
        zoom={zoom}
        center={center}
        options={{
          styles: mapStyleForGoogleMaps,
          mapTypeControl,
          streetViewControl,
          rotateControl,
          fullscreenControl,
          zoomControl,
          minZoom,
          maxZoom,
        }}
      >
        {children}
      </StyledMap>
    </Mask>
  );
};

export default Map;
