// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Grid, Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import { ContactUs } from './help/contactUs';
import { Onboarding } from './help/Onboarding';

export const Help = () => {
  const { t } = useTranslation('profileSettings');

  return (
    <Grid container direction="column" gap=".875rem">
      <Grid item>
        <Text variant="titleL" color="custom.neutral.black.50">
          {t('needHelp')}
        </Text>
      </Grid>
      <Grid item container spacing="2rem">
        <Grid item mobile={12} tablet={6}>
          <Onboarding />
        </Grid>
        <Grid item mobile={12} tablet={6}>
          <ContactUs />
        </Grid>
      </Grid>
    </Grid>
  );
};
