// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { ProductMapContractStateTypeEnum } from 'components/organisms/PartnerInChargeProducts';

const productMapZoomCurrentKey = 'productMapZoomCurrent';
const productMapZoomCurrentDefaultValue = undefined;

const productMapCenterCurrentKey = 'productMapCenterCurrent';
const productMapCenterCurrentDefaultValue = undefined;

const productMapContractStateTypeCurrentKey =
  'productMapContractStateTypeCurrent';

const productMapProductOpenCurrentKey = 'productMapProductOpenCurrent';

const useProductMap = () => {
  const getProductMapZoomCurrent = useCallback((): number | undefined => {
    const value = sessionStorage.getItem(productMapZoomCurrentKey);

    return value ? +value : productMapZoomCurrentDefaultValue;
  }, []);

  const setProductMapZoomCurrent = useCallback((productMapZoom?: number) => {
    if (productMapZoom) {
      sessionStorage.setItem(productMapZoomCurrentKey, String(productMapZoom));
    } else {
      sessionStorage.removeItem(productMapZoomCurrentKey);
    }
  }, []);

  const getProductMapCenterCurrent = useCallback((): any | undefined => {
    const value = sessionStorage.getItem(productMapCenterCurrentKey);

    return value ? JSON.parse(value) : productMapCenterCurrentDefaultValue;
  }, []);

  const setProductMapCenterCurrent = useCallback(
    (productMapCenter?: google.maps.LatLng) => {
      if (productMapCenter) {
        sessionStorage.setItem(
          productMapCenterCurrentKey,
          JSON.stringify(productMapCenter.toJSON())
        );
      } else {
        sessionStorage.removeItem(productMapCenterCurrentKey);
      }
    },
    []
  );

  const getProductMapContractStateTypeCurrent =
    useCallback((): ProductMapContractStateTypeEnum[] => {
      const value = sessionStorage.getItem(
        productMapContractStateTypeCurrentKey
      );

      return value
        ? JSON.parse(value)
        : [
            ProductMapContractStateTypeEnum.IN_SHIPMENT,
            ProductMapContractStateTypeEnum.AVAILABLE,
          ];
    }, []);

  const setProductMapContractStateTypeCurrent = useCallback(
    (productMapContractStateTypeList: ProductMapContractStateTypeEnum[]) => {
      sessionStorage.setItem(
        productMapContractStateTypeCurrentKey,
        JSON.stringify(productMapContractStateTypeList)
      );
    },
    []
  );

  const getProductMapProductOpenCurrent = useCallback((): string => {
    const value = sessionStorage.getItem(productMapProductOpenCurrentKey);

    return value ? value : '';
  }, []);

  const setProductMapProductOpenCurrent = useCallback(
    (productLpui?: string) => {
      sessionStorage.setItem(
        productMapProductOpenCurrentKey,
        productLpui ? productLpui : ''
      );
    },
    []
  );

  return {
    getProductMapZoomCurrent,
    setProductMapZoomCurrent,
    getProductMapCenterCurrent,
    setProductMapCenterCurrent,
    getProductMapContractStateTypeCurrent,
    setProductMapContractStateTypeCurrent,
    getProductMapProductOpenCurrent,
    setProductMapProductOpenCurrent,
  };
};

export default useProductMap;
