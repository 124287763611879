// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { ShipmentContractStateEnum } from 'models/shipment';

/**
 * @param shipment `any` type because to much types of shipment
 * @returns {boolean} true if shipment is in cancelled state
 */
const isCancelledState = (shipment: any) =>
  (shipment?.contract_state || shipment?.contractState) ===
  ShipmentContractStateEnum.CANCELLED;

export default isCancelledState;
