// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Stack, Text } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import NewsletterCard from './NewsletterCard';
import useNewsletters from '../../../hooks/useNewsletters';
import useNewsletterStore, {
  newsletterSelector,
} from '../../../stores/useNewslettersStore';

const NewsletterCardList = () => {
  const { t } = useTranslation('dashboard');

  const { loading } = useNewsletters();
  const newsletters = useNewsletterStore(newsletterSelector);

  return (
    <Stack mt="3rem" ml="2rem" width="45%">
      <Stack direction="row" justifyContent="space-between" width="95%">
        <Text variant="titleM" mb="1rem">
          {t('newsletterList.title')}
        </Text>
        <Text color="custom.neutral.black.50" mb="1rem">
          {t('newsletterList.newsletterNumbers')}
        </Text>
      </Stack>
      {!loading &&
        newsletters.map((newsletter: any, key: any) => (
          <NewsletterCard key={key} newsletter={newsletter} />
        ))}
    </Stack>
  );
};
export default NewsletterCardList;
