// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useMemo } from 'react';

import { LpVillageScopes } from 'configs';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const useHasUserScopes = (scopes: LpVillageScopes[]) => {
  const { role } = useMyPartnersStore(activePartnerSelector);
  const scopesOnPartner = useMemo(() => role.scopes.map(s => s.name), [role]);

  return scopes.every((scope: LpVillageScopes) =>
    scopesOnPartner.includes(scope)
  );
};

export default useHasUserScopes;
