// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ButtonV2 as Button,
  Grid,
  Paper,
  RefreshIcon,
  RocketIcon,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import { LeftPart } from './LeftPart';
import { RightPart } from './RightPart';
import { useOffer } from '../../../api/offer/getOffer';
import { ErrorEmptyState } from '../../commons/ErrorEmptyState';
import { Loading } from '../../commons/Loading';

export const Offer = () => {
  const { t } = useTranslation('partnerSettings');

  const { data, isPending, error, refetch } = useOffer();

  const renderContent = () => {
    if (isPending && !data) {
      return <Loading />;
    }

    if (error) {
      return <ErrorEmptyState />;
    }

    return (
      <Grid container spacing="1.25rem">
        <Grid
          item
          mobile={12}
          maxWidth={{ mobile: 'initial', tablet: '14rem' }}
        >
          <LeftPart offer={data} />
        </Grid>
        <Grid
          item
          mobile={12}
          tablet="auto"
          flexGrow={{ mobile: 'initial', tablet: 1 }}
        >
          <RightPart />
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper
      headerIcon={RocketIcon}
      headerTitle={t('offer.title')}
      headerInfo={
        error && (
          <Button
            variant="tertiary"
            size="small"
            icon={RefreshIcon}
            onClick={refetch as any}
            data-testid="refresh-offer-button"
          />
        )
      }
      sx={{ p: 0, pt: '1.5rem', gap: '1rem' }}
      LeftHeaderProps={{
        sx: {
          opacity: error ? 0.3 : 1,
        },
      }}
    >
      {renderContent()}
    </Paper>
  );
};
