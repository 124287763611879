// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export const SENTRY_DEBUG = process.env.REACT_APP_SENTRY_DEBUG === 'true';
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DNS;
export const SENTRY_ENABLED = process.env.REACT_APP_SENTRY_ENABLED === 'true';
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;

export const CRISP_WEBSITE_ID = '115c2c3a-1065-4a45-ac85-76fa58e592a6';

export const LD_CLIENT_ID = process.env.REACT_APP_LD_CLIENT_ID!;
