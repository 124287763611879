// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ChevronDoubleRightIcon,
  Stack,
  styled,
  Text,
} from '@livingpackets/design-system-react-next';
import { LayoutProps } from 'styled-system';

const ContributionsCardWrapper = styled('div')<LayoutProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ color }) => color};
  border-radius: 1.25rem 1.25rem 0 0;
  padding: 1rem 1.5rem 2.2rem 1.5rem;
  margin-bottom: -1.5rem;
  width: ${({ width }) => width};
`;
const ContributionsSummaryWrapper = styled('div')<LayoutProps>`
  display: flex;
  align-items: center;
`;
const InvestmentsSummaryWrapper = styled('div')<LayoutProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 1.25rem;
  padding: 0.625rem 1.125rem;
  width: 100%;
`;

const InvestmentsCover = styled('div')<LayoutProps>`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  border-radius: 0.875rem;
  padding: 1.5rem;
  height: 10rem;
`;
const TotalWrapper = styled('div')<LayoutProps>`
  background-color: ${({ theme }) => theme.colors.green.basic50};
  padding: 0.75rem 1.438rem 0.75rem 1.438rem;
  border-radius: 0.625rem;
  align-self: center;
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;
const InvestmentsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.063rem solid;
  border-bottom-color: ${({ theme }) => theme.palette.custom.neutral.black[10]};
  height: 2.5rem;
`;
const PaymentsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const RewardWrapper = styled('div')<LayoutProps>`
  background-color: ${({ theme }) => theme.palette.custom.primary[120]};
  padding: 0.2rem 1rem 0.2rem 1rem;
  height: 1.6rem;
  border-radius: 0.875rem;
`;

const IconWrapper = styled('div')<LayoutProps>`
  display: flex;
  align-items: center;
`;

type Props = {
  width?: string;
  icon?: any;
  title: string;
  payoutAvailability?: any;
  amount: string;
  investments: string;
  investmentAmount: any;
  tag?: string;
  firstLine: any;
  initialDate?: any;
  contributionPrice?: string;
  secondLine: string;
  sponsorshipPrice?: any;
  cardHeaderColor: string;
  cardBalanceColor: string;
  payoutAvailable: boolean;
  totalContributions?: string;
  totalContributionsPrice?: number;
  bankInfo?: any;
};

const BalanceCard = ({
  icon,
  title,
  payoutAvailability,
  amount,
  investments,
  investmentAmount,
  tag,
  firstLine,
  initialDate,
  contributionPrice,
  secondLine,
  sponsorshipPrice,
  cardHeaderColor,
  cardBalanceColor,
  payoutAvailable = false,
  totalContributions,
  totalContributionsPrice,
  bankInfo,
}: Props) => (
  <Stack width="100%" data-testid="contribSummary">
    <ContributionsCardWrapper width="100%" color={cardHeaderColor}>
      <ContributionsSummaryWrapper>
        {icon}
        <Text
          variant="titleM"
          color="custom.neutral.white.pure"
          marginLeft="0.953rem"
        >
          {title}
        </Text>
      </ContributionsSummaryWrapper>
      {!payoutAvailable && (
        <TotalWrapper width="100%" color={cardBalanceColor}>
          <Text color="custom.neutral.white.pure" variant="bodyTextS">
            {payoutAvailability}
          </Text>
          <Text variant="titleXL" color="custom.neutral.white.pure">
            {amount}
          </Text>
        </TotalWrapper>
      )}
      {payoutAvailable && (
        <Stack direction="row" justifyContent="space-between">
          <TotalWrapper width="42%" color={cardBalanceColor}>
            <Text color="custom.neutral.white.pure" variant="bodyTextS">
              {totalContributions}
            </Text>
            <Text color="custom.neutral.white.pure" variant="titleXL">
              {totalContributionsPrice}
            </Text>
          </TotalWrapper>
          <IconWrapper>
            <ChevronDoubleRightIcon color="custom.neutral.white.pure" />
          </IconWrapper>
          <TotalWrapper width="42%">
            <Text color="custom.neutral.white.pure" variant="bodyTextS">
              {payoutAvailability}
            </Text>
            <Text variant="titleXL" color="custom.neutral.white.pure">
              {amount}
            </Text>
          </TotalWrapper>
        </Stack>
      )}
    </ContributionsCardWrapper>
    <InvestmentsSummaryWrapper>
      {bankInfo}
      <InvestmentsCover>
        <Stack justifyContent="space-between" height="6.875rem">
          <InvestmentsWrapper>
            <Text variant="titleM">{investments}</Text>
            <div style={{ display: 'flex' }}>
              {!!tag && (
                <RewardWrapper>
                  <Text color="custom.neutral.white.pure" variant="titleS">
                    {tag}
                  </Text>
                </RewardWrapper>
              )}
              <Text variant="titleM" sx={{ marginLeft: '0.563rem' }}>
                {investmentAmount}
              </Text>
            </div>
          </InvestmentsWrapper>
          <Stack justifyContent="space-between" height="3.5rem" pl="0.5rem">
            <PaymentsWrapper>
              <Stack direction="row">
                <Text variant="bodyTextM">{firstLine}</Text>
                {initialDate && (
                  <Text color="custom.neutral.black.50">{initialDate}</Text>
                )}
              </Stack>
              <Text variant="bodyTextM">{contributionPrice}</Text>
            </PaymentsWrapper>
            <PaymentsWrapper>
              <Text variant="bodyTextM">{secondLine}</Text>
              <Text variant="bodyTextM">{sponsorshipPrice}</Text>
            </PaymentsWrapper>
          </Stack>
        </Stack>
      </InvestmentsCover>
    </InvestmentsSummaryWrapper>
  </Stack>
);

export default BalanceCard;
