// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, {
  CSSProperties,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import {
  CheckOutlineIcon,
  WarningIcon,
  InformationIcon,
  styled,
  Box,
  Text,
  Stack,
} from '@livingpackets/design-system-react-next';
import useIsMobile from 'hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useLayoutStore, { openNavSelector } from 'stores/useLayoutStore';
import { MessageType } from 'stores/useToasts.types';

const IN_MS = 300;
const OUT_MS = 200;

const Wrapper = styled(Box)`
  width: 100%;
  max-width: 80rem;
  height: 4.5rem;
  border-radius: 1.25rem;
  padding: 1.5rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  display: flex;
  box-shadow: 0 0 0.625rem rgba(37, 182, 118, 0.12);
  align-items: center;
  margin-bottom: 1rem;
  margin-top: 1rem;
  transition: all ${IN_MS}ms ease;
  transform: scale(0);
  opacity: 0;

  &.cancel {
    transition: all ${OUT_MS}ms ease;
  }
`;

const Container = styled('div')`
  width: 100%;
  padding-left: 0.75rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CloseAction = styled(Text)`
  cursor: pointer;
  margin-left: 1rem;
  pointer-events: all;
`;

const StyledDivIcon = styled('div', {
  shouldForwardProp: prop => prop !== 'type',
})<{ type: MessageType }>`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  border-radius: 7px;
  background-color: ${({ theme, type }) =>
    type === 'error' ? '#ffebeb' : theme.palette.custom.primary[10]};
  justify-content: center;
`;

const LinkWrapper = styled('div')`
  width: 7.813rem;
  height: 2.5rem;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.palette.custom.primary[100]};
  border-radius: 12px;
  line-height: 2.5rem;
  text-align: center;
  cursor: pointer;
`;

export interface IToast {
  msg?: string;
  type: MessageType;
  onClick?: (e: React.MouseEvent) => void;
  showClose?: boolean;
  cancel?: boolean;
  style?: CSSProperties;
  render?: React.FC<{ closeModal?: (e: React.MouseEvent) => void }>;
  title?: string;
  goToLink?: string;
  textLink?: string;
}

const getIcon = (type: MessageType) => {
  switch (type) {
    case 'info':
      return <InformationIcon />;
    case 'success':
      return <CheckOutlineIcon />;
    case 'error':
      return <WarningIcon />;
  }
};

const expandSection = (element: HTMLElement) => {
  requestAnimationFrame(() => {
    element.style.transform = 'scale(1)';
    element.style.opacity = '1';
  });
};

const collapseSection = (element: HTMLElement, anim: any) => {
  // get the height of the element's inner content, regardless of its actual size
  const sectionHeight = element.scrollHeight;

  // temporarily disable all css transitions
  const elementTransition = element.style.transition;
  element.style.transition = '';

  // on the next frame (as soon as the previous style change has taken effect),
  // explicitly set the element's height to its current pixel height, so we
  // aren't transitioning out of 'auto'
  requestAnimationFrame(() => {
    element.style.height = sectionHeight + 'px';
    element.style.transition = elementTransition;

    // on the next frame (as soon as the previous style change has taken effect),
    // have the element transition to height: 0
    requestAnimationFrame(() => {
      element.style.transform = 'scale(0)';
      element.style.opacity = '1';

      anim = window.setTimeout(
        () =>
          requestAnimationFrame(function () {
            element.style.marginTop = '0';
            element.style.marginBottom = '0';
            element.style.paddingTop = '0';
            element.style.paddingBottom = '0';
            element.style.height = '0';
          }),
        OUT_MS
      );
    });
  });
};

const Toast = ({
  type,
  title,
  msg,
  showClose,
  onClick,
  goToLink,
  textLink,
  cancel,
  render: Component,
}: IToast) => {
  const { t } = useTranslation('messages');

  const openNav = useLayoutStore(openNavSelector);
  const { isMobile } = useIsMobile();

  const color = useMemo(
    () => (type === 'error' ? 'custom.error.red.100' : 'custom.primary.100'),
    [type]
  );

  const animTimeout = useRef<number>();
  const ref = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (!cancel && ref.current) {
      expandSection(ref.current);
    }
    const animTimeoutCopy = animTimeout.current;
    if (cancel && ref.current) {
      collapseSection(ref.current, animTimeout.current);
    }

    return () => {
      if (animTimeoutCopy) {
        window.clearTimeout(animTimeoutCopy);
      }
    };
  }, [cancel, ref]);

  return (
    <Wrapper
      ref={ref}
      color={color}
      data-testid={`toast toast-${type}`}
      className={cancel ? 'cancel' : ''}
      sx={{
        marginLeft: !isMobile ? (openNav ? '14.375rem' : '3.4625rem') : '0',
      }}
    >
      <StyledDivIcon type={type}>{getIcon(type)}</StyledDivIcon>
      <Container>
        {Component ? (
          <Component closeModal={onClick} />
        ) : (
          <div>
            {title && (
              <Text variant="titleXS">
                {t(title as unknown as TemplateStringsArray)}
              </Text>
            )}
            <Text variant="bodyTextS">{msg}</Text>
          </div>
        )}
        <Stack direction="row" alignItems="center">
          {goToLink && (
            <Link to={goToLink}>
              <LinkWrapper data-testid="go-to-page">{textLink}</LinkWrapper>
            </Link>
          )}
          {showClose && (
            <CloseAction
              variant="titleS"
              data-testid="close-text"
              onClick={onClick}
            >
              {t('closeToast.message')}
            </CloseAction>
          )}
        </Stack>
      </Container>
    </Wrapper>
  );
};

export default Toast;
