// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { styled } from '@livingpackets/design-system-react-next';
import Toast from 'components/molecules/Toast';
import useIsMobile from 'hooks/useIsMobile';
import { createPortal } from 'react-dom';
import useLayoutStore, { openNavSelector } from 'stores/useLayoutStore';
import useToasts, { itemsSelector, markItemsSelector } from 'stores/useToasts';

const Container = styled('div')<{ openNav: boolean; isMobile: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: ${({ openNav, isMobile }) =>
    isMobile ? '100%' : `calc(100vw - 20% - ${openNav ? '12.5' : '3.75'}rem)`};
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  align-items: center;
  ${props => props.theme.breakpoints.down('laptop')} {
    padding: 2.625rem;
    padding-bottom: 0;
    align-items: flex-start;
  }
  ${props => props.theme.breakpoints.down('tablet')} {
    padding: 1.625rem;
    padding-bottom: 0;
    align-items: flex-start;
  }
`;

const MessageHub: React.FC<{
  t?: (msg: string) => string;
}> = ({ t }) => {
  const items = useToasts(itemsSelector);
  const markItems = useToasts(markItemsSelector);
  const openNav = useLayoutStore(openNavSelector);
  const { isMobile } = useIsMobile();

  return createPortal(
    <Container id="message-hub" openNav={openNav} isMobile={isMobile}>
      {items
        .filter((t: any) => t.view === 'root')
        .map(({ key, toast }: any) => (
          <Toast
            key={key}
            cancel={toast.cancel}
            render={toast.render}
            msg={t ? t(toast.msg) : toast.msg}
            type={toast.type}
            showClose={toast.close}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              markItems(key);
            }}
            title={toast.title}
          />
        ))
        .reverse()}
    </Container>,
    document.body
  );
};

export default MessageHub;
