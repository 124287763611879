// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useMemo } from 'react';

import {
  Grid,
  Stack,
  Text,
  DeliveryKeyIcon,
} from '@livingpackets/design-system-react-next';
import { DetailsSpan } from 'components/atoms/ShipmentAtoms';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'utils/phoneNumber';

import { ITrackingCode } from '../../types/trackingCode';
import { sortAndGroupByDate } from '../../utils/trackingCode';

const DayItem = ({ group }: any) => {
  const formatTimestamp = useIntlFormatTimestamp();

  const mainDate = formatTimestamp(group[0].createdAt, {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: undefined,
    minute: undefined,
  });

  return (
    <Grid container rowSpacing="0.1875rem">
      <Grid item mobile={12}>
        <Text variant="titleXS">{mainDate}</Text>
      </Grid>
      <Grid item mobile={12}>
        <Grid container spacing="0.125rem">
          {group.map((tracking: ITrackingCode) => {
            const date = formatTimestamp(tracking.createdAt, {
              day: undefined,
              month: undefined,
              year: undefined,
              hour: 'numeric',
              minute: '2-digit',
            });

            const phoneNumber = formatPhoneNumber(tracking.phoneNumber);

            return (
              <Grid
                item
                container
                key={tracking.id}
                alignItems="baseline"
                columnSpacing="0.75rem"
              >
                <Grid item>
                  <Text
                    sx={{
                      display: 'flex',
                      width: '3rem',
                      justifyContent: ' flex-end',
                    }}
                    variant="bodyTextXS"
                    color="custom.neutral.black.50"
                  >
                    {date}
                  </Text>
                </Grid>
                <Grid item flex={1}>
                  <Text variant="bodyTextS">{tracking.email}</Text>
                </Grid>
                <Grid item mr="1rem">
                  <Text variant="bodyTextS">{phoneNumber}</Text>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export const DeliverySharingList = ({ code, trackingCodeData }: any) => {
  const { t } = useTranslation('shipments');

  const sortedData = useMemo(
    () => sortAndGroupByDate(trackingCodeData),
    [trackingCodeData]
  );

  if (isEmpty(sortedData)) {
    return;
  }

  return (
    <Stack gap=".875rem">
      <Grid
        container
        spacing="1rem"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <DetailsSpan>
            {code
              ? t('detail.summary.tracking.sharedTrackingCodes')
              : t('detail.summary.tracking.sharedTrackingLinks')}
          </DetailsSpan>
        </Grid>
        {/* TODO: Conditionnaly display only if user is partner admin (has the correct scope (when scope available)) */}
        {code && (
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                padding: '6px 15px',
                gap: '8px',
                borderRadius: '8px',
                color: 'custom.neutral.white.pure',
                bgcolor: 'info.main',
              }}
            >
              <DeliveryKeyIcon size="18px" />
              <Text variant="titleXS">
                {t('detail.summary.tracking.deliveryCode')}{' '}
                <span data-testid="delivery-code">{code}</span>
              </Text>
            </Stack>
          </Grid>
        )}
      </Grid>
      {sortedData.map(group => (
        <DayItem key={group[0].id} group={group} />
      ))}
    </Stack>
  );
};
