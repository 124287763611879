// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { useApiClient } from './useApiClient';
import { PaginationParams } from '../shared-components';

export interface PaginatedResponseBoxContracts<Type> {
  items: Type[];
  count: number;
  total: number;
}

export interface GetBoxContractsParams extends Partial<PaginationParams> {
  partnerId?: string;
}

type GetBoxContracts = (
  params: GetBoxContractsParams
) => Promise<PaginatedResponseBoxContracts<any>>;

export function useGetBoxContracts(): GetBoxContracts {
  const apiClient = useApiClient('lpAccount');

  return useCallback(
    ({ partnerId }: GetBoxContractsParams) =>
      apiClient
        .get<PaginatedResponseBoxContracts<any>>(
          partnerId
            ? `api/v1/me/partners/${partnerId}/box-contracts`
            : 'api/v1/me/box-contracts'
        )
        .then(response => ({
          items: response.data.items,
          count: response.data.items.length,
          total: response.data.total,
        })),
    [apiClient]
  );
}
