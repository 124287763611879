// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Grid,
  ModalV2,
  SavIcon,
  Text,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import { ContactUsItem } from './ContactUsItem';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

const items = ['franceOrUK', 'germany', 'switzerland'];

export const ContactUsModal = ({ open, setOpen }: Props) => {
  const { t } = useTranslation(['profileSettings']);

  return (
    <ModalV2 open={open} setOpen={setOpen}>
      <Grid
        container
        direction="column"
        sx={{ minWidth: { mobile: 'auto', tablet: '30rem' } }}
        gap="1.625rem"
      >
        <Grid item container flexDirection="column" alignItems="center">
          <SavIcon />
          <Text
            variant="titleXL"
            color="custom.neutral.black.100"
            sx={{ paddingTop: '12px' }}
          >
            {t('profileSettings:needHelp')}
          </Text>

          <Text
            variant="bodyTextL"
            color="custom.primary.100"
            component="a"
            href="mailto:contact@livingpackets.com"
            sx={{ textDecoration: 'underline' }}
          >
            contact@livingpackets.com
          </Text>
        </Grid>
        <Grid item container direction="column" gap="1.5rem">
          {items.map(item => (
            <ContactUsItem key={item} prefix={item} />
          ))}
        </Grid>
      </Grid>
    </ModalV2>
  );
};
