// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { useApiClient } from './useApiClient';
import { ShipmentDirection } from '../components/organisms/PartnerShipmentsTable';
import { IShipment, ShipmentContractState } from '../models/shipment';
import { PaginationParams, Paginated } from '../shared-components';

export interface PaginatedResponse<Type> {
  items: Type[];
  count: number;
  total: number;
}

export interface GetUserShipmentsParams extends Partial<PaginationParams> {
  direction?: ShipmentDirection;
  contractState?: ShipmentContractState[];
  isArchived?: boolean;
  reference?: string;
  recipientLastName?: string;
  recipientEmail?: string;
  senderLastName?: string;
  senderEmail?: string;
  search?: string;
  offset?: number;
}

type GetUserShipments = (
  params: GetUserShipmentsParams
) => Promise<Paginated<IShipment>>;

export function useGetUserShipments(): GetUserShipments {
  const shipment = useApiClient('shipment');
  const getShipments = useCallback(
    ({
      page = 1,
      offset,
      pageSize = 100,
      orderColumn = 'receivedAt',
      orderDirection = 'DESC',
      direction,
      contractState,
      isArchived,
      reference,
      recipientEmail,
      recipientLastName,
      senderLastName,
      senderEmail,
      search,
    }: GetUserShipmentsParams) =>
      shipment
        .get<PaginatedResponse<IShipment>>('api/v2/me/products/shipments', {
          params: {
            offset: (page - 1) * pageSize,
            pageSize,
            sortBy: orderColumn,
            order: orderDirection,
            direction,
            contractState,
            isArchived,
            reference,
            recipientEmail,
            recipientLastName,
            senderEmail,
            senderLastName,
            search,
          },
        })
        .then(response => ({
          items: response.data.items,
          page,
          pageSize,
          orderColumn,
          orderDirection,
          totalPages: Math.ceil(response.data.total / pageSize),
        })),
    [shipment]
  );

  return getShipments;
}
