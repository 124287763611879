// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Text,
  ButtonV2 as Button,
  styled,
} from '@livingpackets/design-system-react-next';
import errorImage from 'assets/error/403.png';
import { PATHS } from 'configs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const IMGMod = styled('img')`
  max-width: 42.5rem;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[8]};
  border-radius: 1.25rem;
`;

const ErrorWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    height: calc(100% - 5rem);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Error403 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('errorPages');

  return (
    <ErrorWrapper>
      <IMGMod src={errorImage} alt="" />
      <Text variant="titleXL" mt={4}>
        {t('403.headline')}
      </Text>
      <Text mt={3} mb={6} variant="titleS">
        {t('403.bodyText')}
      </Text>
      <Button onClick={() => navigate(PATHS.ROOT)}>{t('403.btn')}</Button>
    </ErrorWrapper>
  );
};

export default Error403;
