// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  SenderAddressIcon,
  RecipientAddressIcon,
  Stack,
  StackProps,
  styled,
} from '@livingpackets/design-system-react-next';
import { DetailsCard, DetailsSpan } from 'components/atoms/ShipmentAtoms';
import {
  AddressAnonymous,
  AddressNameAnonymous,
} from 'components/molecules/AddressAnonymous';
// @ts-ignore
import { FlexDirectionProperty } from 'csstype';
import { IShipmentAnonymousData } from 'models/shipment';
import { Trans, useTranslation } from 'react-i18next';

interface IShipmentAddress {
  shipmentData: IShipmentAnonymousData;
  titleTransKey?: string;
  blockDirection?: FlexDirectionProperty;
}

const IconCard = styled('div')`
  height: 2.8125rem;
  width: 2.8125rem;
  min-width: 2.8125rem;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  background: ${({ theme }) => theme.palette.custom.neutral.black[4]};
  margin-right: 1rem;
  text-align: center;
  line-height: 3.75rem;
`;

const ShipmentAnonymousAddressCard = ({
  shipmentData,
  titleTransKey,
  blockDirection = 'column',
  ...rest
}: IShipmentAddress & StackProps) => {
  const { t } = useTranslation('shipments');

  return (
    <Stack {...rest}>
      <DetailsSpan>
        <Trans t={t}>
          {titleTransKey ? titleTransKey : 'detail.address.title'}
        </Trans>
      </DetailsSpan>
      <DetailsCard
        padding="1.25rem"
        mt=".75rem"
        fontSize=".75rem"
        lineHeight="1.1875rem"
        style={{ borderRadius: '.625rem', flexDirection: blockDirection }}
      >
        <Stack direction="row" data-testid="shipment-sender-adress">
          <IconCard>
            <SenderAddressIcon />
          </IconCard>
          <Stack>
            <DetailsSpan>{t('detail.address.sender')}</DetailsSpan>
            <AddressNameAnonymous
              address={shipmentData.shipment.sender.address}
            />
            <AddressAnonymous
              address={shipmentData.shipment.sender.address}
              inline
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          mt={blockDirection === 'column' ? '1.25rem' : 0}
          data-testid="shipment-recipient-adress"
        >
          <IconCard>
            <RecipientAddressIcon />
          </IconCard>
          <Stack>
            <DetailsSpan>{t('detail.address.recipient')}</DetailsSpan>
            <AddressNameAnonymous
              address={shipmentData.shipment.recipient.address}
            />
            <AddressAnonymous
              address={shipmentData.shipment.recipient.address}
              inline
            />
          </Stack>
        </Stack>
      </DetailsCard>
    </Stack>
  );
};

export default ShipmentAnonymousAddressCard;
