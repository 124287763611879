// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import useImperativeAnonymousRequest from 'hooks/useImperativeAnonymousRequest';
export const usePostCreateCommand = () => {
  // Switch call V2 to V3
  const [, makeRequest] = useImperativeAnonymousRequest('shipment');

  const generateCommand = async (
    boxId: string,
    shipmentId: string,
    command: string
  ): Promise<ArrayBuffer | undefined> => {
    const { data } = await makeRequest({
      path: `api/v2/products/${boxId}/commands/${command}`,
      method: 'POST',
      body: { shipmentId },
      responseType: 'arraybuffer',
    });

    if (data) {
      return data;
    }
  };

  return { generateCommand };
};
