// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export enum ContactAddressType {
  professional = 'professional',
  personal = 'personal',
}

export interface IContact {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  partnerId: string;
  createdAt?: number;
  updatedAt?: number;
  addresses: Array<IContactAddress>;
}

export interface IModifiedContact extends IContact {}

export interface IContactPost {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
}

export interface IContactAddressPost {
  type: ContactAddressType;
  city: string;
  company?: string;
  countryCode: string;
  firstName: string;
  houseNumber: string;
  lastName: string;
  postalCode: string;
  state: string;
  street: string;
  street2: string;
}

export interface IContactAddress {
  id: string;
  type: ContactAddressType;
  city: string;
  company?: string;
  countryCode: string;
  firstName: string;
  houseNumber: string;
  lastName: string;
  postalCode: string;
  state: string;
  street: string;
  street2: string;
}

export interface ISearchContact {
  id: string;
  name: string;
}

export interface ISearchContactAddress {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  addressList: ISearchContactAddressElement[];
  phoneNumber?: string;
}

export interface ISearchContactAddressElement {
  address: IContactAddress;
  isAddressSelected: boolean;
}

export interface IContactForm {
  basicInformation: IContactInformationForm;
  address: IContactAddressForm;
  address2?: IContactAddressForm;
}

export interface IContactInformationForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
}

export interface IContactAddressForm {
  type: ContactAddressType;
  companyName?: string;
  city: string;
  addressInfo: string;
  zipCode: string;
  countryCode: string;
}

export interface IContactSearchForm {
  contactName: string;
}

export const INITIAL_STATE_CONTACT_INFORMATION_FORM: IContactInformationForm = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
};

export const INITIAL_STATE_CONTACT_ADDRESS_FORM: IContactAddressForm = {
  type: ContactAddressType.professional,
  companyName: '',
  addressInfo: '',
  city: '',
  zipCode: '',
  countryCode: '',
};

export const INITIAL_STATE_CONTACT_ADDRESS_COMPLETE_FORM: IContactForm = {
  basicInformation: INITIAL_STATE_CONTACT_INFORMATION_FORM,
  address: INITIAL_STATE_CONTACT_ADDRESS_FORM,
};

export const INITIAL_STATE_CONTACT_ADDRESS: IContact = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  partnerId: '',
  createdAt: undefined,
  updatedAt: undefined,
  addresses: [
    {
      id: '',
      type: ContactAddressType.professional,
      city: '',
      company: undefined,
      countryCode: '',
      firstName: '',
      houseNumber: '',
      lastName: '',
      postalCode: '',
      state: '',
      street: '',
      street2: '',
    },
  ],
};

export const INITIAL_STATE_CONTACT_SEARCH_FORM: IContactSearchForm = {
  contactName: '',
};
