// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useContext, useEffect, useState } from 'react';

import {
  Stack,
  Box,
  ButtonV2 as Button,
  EditIcon,
} from '@livingpackets/design-system-react-next';
import ImportShipmentConfirmInformationSection from 'components/atoms/importShipment/ImportShipmentConfirmInformationSection';
import CreateShipmentAddressFormTemplate from 'components/molecules/importShipment/CreateShipmentAddressFormTemplate';
import { ShipmentEditionContext } from 'components/organisms/ImportShipmentFlow';
import { usePartnership } from 'features/account';
import useFormTemplate from 'hooks/useFormTemplate';
import {
  IOcrLabel,
  ShipmentAddressData,
  ShipmentAddressAndContactData,
} from 'models/shipment';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shipmentAddressInformationSchema } from 'schemas/shipmentSchema';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

export interface ShipmentAddressFormProps {
  label: IOcrLabel;
  onSubmit: ({
    phoneNumbers,
    shipmentAddressData,
  }: {
    phoneNumbers: {
      senderPhoneNumber: string;
      recipientPhoneNumber: string;
    };
    shipmentAddressData: ShipmentAddressData;
  }) => void | Promise<void>;
  isReturnShipment?: boolean;
  isShippingAddressesBlockOpened: boolean;
  setIsShippingAddressesBlockOpened: (value: boolean) => void;
  isParcelDetailsBlockConfirmed: boolean;
}

const ShipmentAddressForm = ({
  label,
  onSubmit,
  isReturnShipment = false,
  isShippingAddressesBlockOpened,
  setIsShippingAddressesBlockOpened,
  isParcelDetailsBlockConfirmed,
}: ShipmentAddressFormProps) => {
  const { t } = useTranslation(['shipments', 'general', 'profileSettings']);

  const { data: partnership } = usePartnership();

  // BEGIN Handle show ReadOnly address or the form

  const hasBothDefaultAddress =
    !!partnership?.default_sender_address_id &&
    !!partnership?.default_recipient_address_id;

  const [showReadOnlyAddress, setShowReadOnlyAddress] = useState(
    hasBothDefaultAddress
  );

  const { isEditingCreationShipment } = useContext(ShipmentEditionContext);

  useEffect(() => {
    if (isEditingCreationShipment) {
      setShowReadOnlyAddress(false);

      return;
    }

    setShowReadOnlyAddress(hasBothDefaultAddress);
  }, [hasBothDefaultAddress, isEditingCreationShipment]);
  // END Handle show ReadOnly address or the form

  const { smsEnabled } = useMyPartnersStore(activePartnerSelector);

  const {
    handleSubmit,
    formState: { touchedFields, isValid },
    setValue,
    trigger,
    register,
    errors,
    watch,
    control,
    resetField,
  } = useFormTemplate<ShipmentAddressAndContactData>({
    resolver: shipmentAddressInformationSchema({
      smsEnabled,
    }),
    defaultValues: {
      senderAddress: label.senderAddress,
      recipientAddress: label.recipientAddress,
      // Only for partnership with smsEnabled
      senderPhoneNumber: label.senderPhoneNumber || '',
      recipientPhoneNumber: label.recipientPhoneNumber || '',
    },
  });

  const onSubmitForm: SubmitHandler<FieldValues> = (data: FieldValues) => {
    onSubmit({
      phoneNumbers: {
        senderPhoneNumber: data.senderPhoneNumber,
        recipientPhoneNumber: data.recipientPhoneNumber,
      },
      shipmentAddressData: {
        senderAddress: data.senderAddress,
        recipientAddress: data.recipientAddress,
      },
    });
  };

  return (
    <>
      <ImportShipmentConfirmInformationSection
        dataTestId="import-shipment-address"
        title={t(
          'shipments:importLabel.confirmInformation.shippingAddresses.title'
        )}
        isOpen={isShippingAddressesBlockOpened}
        onClick={() => {
          if (isParcelDetailsBlockConfirmed) {
            setIsShippingAddressesBlockOpened(!isShippingAddressesBlockOpened);
          }
        }}
        isDisabled={!isParcelDetailsBlockConfirmed}
      >
        <Box component="form" mt="1rem">
          <Stack>
            <Stack direction="row" justifyContent="space-between">
              <Stack mr="1.25rem" width="100%">
                <CreateShipmentAddressFormTemplate
                  isReturnShipment={isReturnShipment}
                  partnership={partnership}
                  prefix="senderAddress."
                  smsEnabled={smsEnabled}
                  isSender
                  title={t('shipments:form.sender.title')}
                  setValue={setValue}
                  trigger={trigger}
                  touchedFields={touchedFields}
                  register={register}
                  errors={errors}
                  watch={watch}
                  control={control}
                  resetField={resetField}
                  showReadOnlyAddress={showReadOnlyAddress}
                  setShowReadOnlyAddress={setShowReadOnlyAddress}
                />
              </Stack>

              <Stack marginLeft="1.25rem" width="100%">
                <CreateShipmentAddressFormTemplate
                  isReturnShipment={isReturnShipment}
                  partnership={partnership}
                  prefix="recipientAddress."
                  smsEnabled={smsEnabled}
                  isSender={false}
                  title={t('shipments:form.recipient.title')}
                  setValue={setValue}
                  trigger={trigger}
                  touchedFields={touchedFields}
                  register={register}
                  errors={errors}
                  watch={watch}
                  control={control}
                  resetField={resetField}
                  showReadOnlyAddress={showReadOnlyAddress}
                  setShowReadOnlyAddress={setShowReadOnlyAddress}
                />
              </Stack>
            </Stack>
            {isReturnShipment && showReadOnlyAddress && (
              <Button
                data-testid="use-different-return-address"
                variant="secondary"
                onClick={() => setShowReadOnlyAddress(false)}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  gap=".625rem"
                >
                  {t('shipments:form.useDifferentReturnAddress')} <EditIcon />
                </Stack>
              </Button>
            )}
          </Stack>
        </Box>
      </ImportShipmentConfirmInformationSection>
      <Box display="flex" justifyContent="flex-end">
        <Button
          data-testid="import-shipment-address-confirm"
          onClick={handleSubmit(onSubmitForm)}
          variant="primary"
          disabled={!isValid || !isParcelDetailsBlockConfirmed}
          sx={{ width: '12rem' }}
          {...{ id: 'submitBtn' }}
        >
          {t('shipments:form.importInformation.button.confirm')}
        </Button>
      </Box>
    </>
  );
};

export default ShipmentAddressForm;
