// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import useContributionsStore, {
  setContributionOverviewSelector,
} from 'stores/useContributionsStore';

const useGetContribution = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpAccountV3');
  const setContributionOverview = useContributionsStore(
    setContributionOverviewSelector
  );

  const getContributionOverview = useCallback(async () => {
    const { data, error } = await makeRequest({
      path: `/me/contributions/overview`,
      method: 'get',
    });

    if (error) {
      return { sucess: false };
    }

    if (data) {
      setContributionOverview(data);

      return { sucess: true };
    }
  }, [makeRequest, setContributionOverview]);

  return { loading, getContributionOverview };
};

export default useGetContribution;
