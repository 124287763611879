// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ArrowRightIcon,
  Stack,
  Text,
  styled,
  useTheme,
  Divider,
} from '@livingpackets/design-system-react-next';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';

import useIntlFormatTimestamp from '../../hooks/useIntlFormatTimestamp';

const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 14px;
`;
const DetailedWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 59rem;
  height: 5rem;
  background-color: #ffffff;
  border-radius: 14px;
  margin-bottom: 0.5rem;
  padding: 2rem 3rem 2rem 3rem;
`;

const TransactionListItem = ({ shipment }: { shipment: IShipment }) => {
  const theme = useTheme();
  const { t } = useTranslation('buyAndRent');

  const costPerShipment = shipment?.costPerShipment
    ? (shipment.costPerShipment * 30) / 100
    : 0;
  const intlFormatTimestamp = useIntlFormatTimestamp();

  return (
    <>
      <DetailedWrapper style={{ height: '5rem' }}>
        <Wrapper>
          <Stack direction="row" width="12%">
            <Text variant="titleXS">
              {intlFormatTimestamp(shipment.receivedAt, {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: undefined,
                minute: undefined,
              })}
            </Text>
          </Stack>
          <Divider
            orientation="vertical"
            sx={{
              height: '3.25rem',
              marginTop: '-1rem',
              marginRight: '6rem',
              marginLeft: '0.5rem',
              borderColor: theme.palette.custom.neutral.black[10],
            }}
          />
          <Stack direction="row" width="17%">
            <Text variant="titleXS">{shipment.sender.address.city}</Text>
          </Stack>
          <Stack direction="row" width="11%">
            <ArrowRightIcon color="#00BF6F" />
          </Stack>
          <Stack direction="row" width="28%">
            <Text variant="titleXS">{shipment.recipient.address.city}</Text>
          </Stack>
          <Stack
            direction="row"
            width="15%"
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              mr: '2.8rem',
            }}
          >
            <Text variant="titleXS">
              {t('buyNRent.shipments.costPerShipment', {
                costPerShipment: costPerShipment,
              })}
            </Text>
          </Stack>
        </Wrapper>
      </DetailedWrapper>
    </>
  );
};

export default TransactionListItem;
