// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useQuery } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';

import { Address } from '../../types/address';

export type AddressesResponse = {
  count: string;
  items: Address[];
};

export const getAddresses = async ({
  partnershipId,
  makeRequest,
}: {
  partnershipId?: string;
  makeRequest: any;
}): Promise<AddressesResponse> => {
  const { data, error } = await makeRequest({
    path: `api/v1/me/partners/${partnershipId}/addresses`,
  });

  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnType = typeof getAddresses;

type UseAddressOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useAddresses = ({ config }: UseAddressOptions = {}) => {
  const { partnerId } = useParams();
  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['partnership', partnerId, 'addresses'],
    queryFn: () => getAddresses({ partnershipId: partnerId, makeRequest }),
  });
};
