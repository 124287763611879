// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { AxiosResponse } from 'axios';

import { useApiClient } from './useApiClient';
import useToastMessages from './useToastMessages';
import { IContribution } from '../models/contribution';
import { PaginationParams } from '../shared-components';
import useContributionsStore, {
  setContributionsSelector,
} from '../stores/useContributionsStore';

export interface PaginatedResponseGetMeContributions<Type> {
  account_information: any;
  contributions: Type[];
}

export interface GetMeContributionsParams extends Partial<PaginationParams> {}

type GetMeContributions = (
  params: GetMeContributionsParams
) => Promise<PaginatedResponseGetMeContributions<IContribution>>;

export function useGetMeContributions(): GetMeContributions {
  const lpAccountApiV3 = useApiClient('lpAccountV3');
  const { error: toastError } = useToastMessages();
  const setContributions = useContributionsStore(setContributionsSelector);

  return useCallback(
    ({
      page = 1,
      pageSize = 100,
      orderColumn = 'contributed_at',
      orderDirection = 'DESC',
    }: GetMeContributionsParams): Promise<
      PaginatedResponseGetMeContributions<IContribution>
    > =>
      lpAccountApiV3
        .get<PaginatedResponseGetMeContributions<IContribution>>(
          '/me/contributions',
          {
            params: {
              'list_request.page_size': pageSize,
              'list_request.page_offset': (page - 1) * pageSize,
              'list_request.order_by': orderColumn + ' ' + orderDirection,
            },
          }
        )
        .then((response: AxiosResponse) => {
          response.data.contributions = response.data.contributions.map(
            (contribution: IContribution) => {
              if (contribution.contributed_at) {
                contribution.contributed_at =
                  new Date(contribution.contributed_at).getTime() / 1000;
              }

              if (contribution.reward_estimated_date) {
                contribution.reward_estimated_date =
                  new Date(contribution.reward_estimated_date).getTime() / 1000;
              }

              if (contribution.rewarded_at) {
                contribution.rewarded_at =
                  new Date(contribution.rewarded_at).getTime() / 1000;
              }

              return contribution;
            }
          );

          if (response.data) {
            setContributions(response.data.contributions);
          }

          return response.data;
        })
        .catch(() => {
          toastError('messages:contributionsError');

          setContributions([]);

          return {
            account_information: {},
            contributions: [],
          } as PaginatedResponseGetMeContributions<IContribution>;
        }),
    [lpAccountApiV3, setContributions, toastError]
  );
}
