// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  Text,
  styled,
  Stack,
} from '@livingpackets/design-system-react-next';
import { IPartnerEntry } from 'stores/useMyPartnersStore';

const PartnerInitialContainer = styled(Box)`
  height: 50px;
  min-width: 50px;
  padding: 10px;
  border-radius: 7px;
  border: 1px solid ${({ theme }) => theme.palette.custom.neutral.black[4]};
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PartnerInitial = ({ initial }: { initial: string }) => (
  <PartnerInitialContainer>
    <Text variant="titleM" color="custom.neutral.black.2">
      {initial}
    </Text>
  </PartnerInitialContainer>
);

const Container = styled(Box)`
  padding: 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.palette.custom.neutral.black[8]};
`;

interface IInvitationPartnershipRow {
  partner: IPartnerEntry;
}

const InvitationPartnershipRow = ({ partner }: IInvitationPartnershipRow) => {
  const getPartnerNameInitial = (partnerName: string): string =>
    partnerName
      .split(' ')
      .map((word: string) => word.slice(0, 1).toUpperCase())
      .join('');

  return (
    <Container>
      <Stack direction="row" alignItems="center">
        <PartnerInitial initial={getPartnerNameInitial(partner.name)} />
        <Stack alignItems="flex-start" marginLeft="16px !important">
          <Text variant="titleM" color="custom.neutral.black.100">
            {partner.name}
          </Text>
        </Stack>
      </Stack>
    </Container>
  );
};

export default InvitationPartnershipRow;
