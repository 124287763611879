// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import {
  ButtonV2 as Button,
  Stack,
} from '@livingpackets/design-system-react-next';
import {
  AuthenticationView,
  DescriptionTextFontStyleEnum,
} from 'components/containers/AuthenticationView';
import { PATHS } from 'configs';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useResendVerificationEmail from 'hooks/useResendVerificationEmail';
import useToastMessages from 'hooks/useToastMessages';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAppState, {
  dispatch,
  userSelector,
} from 'stores/appState/useAppState';

const VerifyEmail = () => {
  const appUser = useAppState(userSelector);
  const { t } = useTranslation(['general', 'authentication', 'messages']);
  const [, makeRequest] = useImperativeRequest('lpAccount');
  const navigate = useNavigate();
  const { error: errorToast, success: successToast } = useToastMessages();

  const { sendEmail, loading } = useResendVerificationEmail();

  const handleResendVerificationEmail = useCallback(async () => {
    sendEmail()
      .then(() => {
        successToast('messages:verificationSent.message', { wide: true });
      })
      .catch(() => {
        errorToast('messages:somethingWentWrong.message', { wide: true });
      });
  }, [sendEmail, successToast, errorToast]);

  const handleEmailVerifiedCheck = useCallback(async () => {
    const { data } = await makeRequest({ path: 'api/v1/me' });
    if (data?.profile?.emailVerified) {
      dispatch({ type: 'SET_USER', payload: data.profile });
      navigate(PATHS.AUTHENTICATION.RETRIEVE_DELIVERY_KEY);

      return;
    }
    errorToast('messages:needToVerifyToast.message', { wide: true });
  }, [navigate, makeRequest, errorToast]);

  return (
    <AuthenticationView
      title={t('authentication:verifyEmail.title')}
      description={
        appUser
          ? t('authentication:verifyEmail.description', {
              email: appUser.email,
            })
          : ''
      }
      descriptionFontStyle={DescriptionTextFontStyleEnum.REGULAR}
    >
      <Stack>
        <Button
          onClick={() => handleResendVerificationEmail()}
          disabled={loading}
          sx={{ mb: '16px' }}
        >
          {t('authentication:verifyEmail.buttons.resend')}
        </Button>
        <Button
          variant="tertiary"
          onClick={() => handleEmailVerifiedCheck()}
          sx={{ mt: 0 }}
        >
          {t('authentication:verifyEmail.buttons.confirmVerification')}
        </Button>
      </Stack>
    </AuthenticationView>
  );
};

export default VerifyEmail;
