// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';
import { useState } from 'react';

import { RequestState } from './RequestState';

export interface UseMutationOptions {
  enabled?: boolean;
}

export type UseMutation<
  Resource,
  Args extends any[],
  Error = globalThis.Error
> = RequestState<Resource, Error> & {
  execute: (...args: Args) => Promise<Resource>;
};

export function useMutation<
  Resource,
  Args extends any[],
  Error = globalThis.Error
>(
  fetchFunction: (...args: Args) => Promise<Resource>
): UseMutation<Resource, Args, Error> {
  const [state, setState] = useState<RequestState<Resource, Error>>({
    state: 'idle',
  });

  const execute = useCallback(
    (...args: Args) => {
      setState({ state: 'loading' });

      return fetchFunction(...args)
        .then(resource => {
          setState({ state: 'success', resource });

          return resource;
        })
        .catch(error => {
          setState({ state: 'error', error });
          throw error;
        });
    },
    [fetchFunction]
  );

  return { ...state, execute };
}
