// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  Box,
  SavedIcon,
  CloseIcon,
  TooltipIcon,
  Stack,
  styled,
  Text,
  useTheme,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import toolTipPolygon from 'assets/img/authentication/toolTipPolygon.png';
import toolTipPolygon2x from 'assets/img/authentication/toolTipPolygon@2x.png';
import toolTipPolygon3x from 'assets/img/authentication/toolTipPolygon@3x.png';
import { AccountTypeDisplayMode } from 'enums/accountTypeDisplayMode';
import { AccountTypeEnum } from 'enums/accountTypeEnum';
import { AccountTypeInformation } from 'models/accountTypeInformation';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';

const Container = styled(Box)<{ displayMode: AccountTypeDisplayMode }>`
  padding: 24px 20px;
  border-radius: 20px;
  background-color: ${({ theme, displayMode }) =>
    displayMode === AccountTypeDisplayMode.LIGHT
      ? theme.palette.custom.neutral.white.pure
      : theme.palette.custom.neutral.black[100]};
  width: 300px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
`;

const IconContainer = styled(Box)<{ displayMode: AccountTypeDisplayMode }>`
  height: 46px;
  width: 46px;
  border-radius: 10px;
  background-color: ${({ theme, displayMode }) =>
    displayMode === AccountTypeDisplayMode.LIGHT
      ? theme.palette.custom.primary[10]
      : theme.palette.custom.neutral.black[90]};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;

const HelpTextContainer = styled(Stack)`
  position: relative;
  align-items: center;
`;

const TooltipHelpTextContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  white-space: pre;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 10px 15px;
  width: max-content;
  text-align: left;
  margin-left: 0;
`;

interface IAccountTypeChoiceContainer {
  accountTypeInformation: AccountTypeInformation;
  onAccountTypeChoose: (accountType: AccountTypeEnum) => void;
}

const AccountTypeChoiceContainer = ({
  accountTypeInformation,
  onAccountTypeChoose,
}: IAccountTypeChoiceContainer) => {
  const { t } = useTranslation('authentication');
  const theme = useTheme();

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState<boolean>(false);

  const props = useSpring({
    to: { opacity: 0 },
    from: { opacity: 1 },
    reverse: isTooltipDisplayed,
  });

  return (
    <Container displayMode={accountTypeInformation.displayMode}>
      <Box
        height="152px"
        display="flex"
        flexDirection="column"
        marginBottom="10px"
      >
        <IconContainer displayMode={accountTypeInformation.displayMode}>
          {accountTypeInformation.icon}
        </IconContainer>
        <Text
          variant="titleL"
          color={
            accountTypeInformation.displayMode === AccountTypeDisplayMode.LIGHT
              ? 'custom.neutral.black.100'
              : 'custom.neutral.white.pure'
          }
          sx={{ marginBottom: '8px' }}
        >
          {t(accountTypeInformation.title as unknown as TemplateStringsArray)}
        </Text>
        <Text
          variant="bodyTextM"
          color={
            accountTypeInformation.displayMode === AccountTypeDisplayMode.LIGHT
              ? 'custom.neutral.black.20'
              : 'custom.neutral.black.50'
          }
          sx={{ whiteSpace: 'break-spaces' }}
        >
          {t(
            accountTypeInformation.description as unknown as TemplateStringsArray
          )}
        </Text>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          marginBottom: '11px',
        }}
      >
        {accountTypeInformation.information.map(information => (
          <Stack
            direction="row"
            alignItems="center"
            style={{ marginBottom: '12px' }}
            key={information.text}
          >
            {information.isActivated ? (
              <SavedIcon color={theme.palette.custom.primary[100]} />
            ) : (
              <CloseIcon color={theme.palette.custom.neutral.black[50]} />
            )}
            <Text
              variant="titleS"
              color={
                accountTypeInformation.displayMode ===
                AccountTypeDisplayMode.LIGHT
                  ? information.isActivated
                    ? 'custom.neutral.black.100'
                    : 'custom.neutral.black.50'
                  : 'custom.neutral.white.pure'
              }
              sx={{ marginLeft: '16px', whiteSpace: 'nowrap' }}
            >
              {t(information.text as unknown as TemplateStringsArray)}
            </Text>
            {information.helpText && (
              <HelpTextContainer direction="row">
                <TooltipIcon
                  color="white"
                  size="13px"
                  onMouseEnter={() => setIsTooltipDisplayed(true)}
                  onMouseLeave={() => setIsTooltipDisplayed(false)}
                  style={{ cursor: 'pointer' }}
                />
                <animated.div
                  style={{ ...props, display: 'flex', alignItems: 'center' }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{ position: 'absolute', left: '15px' }}
                  >
                    <img
                      src={toolTipPolygon}
                      srcSet={`${toolTipPolygon} 1x,${toolTipPolygon2x} 2x, ${toolTipPolygon3x} 3x`}
                      alt=""
                    />
                    <TooltipHelpTextContainer>
                      <Text color="custom.neutral.black.100">
                        {t(
                          information.helpText as unknown as TemplateStringsArray
                        )}
                      </Text>
                    </TooltipHelpTextContainer>
                  </Stack>
                </animated.div>
              </HelpTextContainer>
            )}
          </Stack>
        ))}
      </Box>
      <Button
        variant="primary"
        sx={{ width: '100%' }}
        onClick={() => onAccountTypeChoose(accountTypeInformation.accountType)}
        data-testid={accountTypeInformation.buttonTestId}
      >
        {t(
          accountTypeInformation.buttonText as unknown as TemplateStringsArray
        )}
      </Button>
    </Container>
  );
};

export default AccountTypeChoiceContainer;
