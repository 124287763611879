// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  CloseIcon,
  Text,
  Stack,
} from '@livingpackets/design-system-react-next';

import Modal, { IModalWrapperType } from '../../atoms/Modal';

interface IViewProductScreenModal {
  visible: boolean;
  onDismiss: () => void;
  productScreenUrl?: string;
  hideLabelFullScreenModal: () => void;
  headerLabel: string;
}

const ViewProductScreenModal = ({
  visible,
  onDismiss,
  productScreenUrl,
  headerLabel,
}: IViewProductScreenModal) => (
  <Modal
    open={visible}
    handleClose={onDismiss}
    wrapperType={IModalWrapperType.shipmentLabel}
  >
    <Stack height="90%">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginBottom="2rem"
      >
        <Text variant="titleL">{headerLabel}</Text>
        <CloseIcon onClick={() => onDismiss()} style={{ cursor: 'pointer' }} />
      </Stack>
      <img
        src={productScreenUrl}
        style={{ height: '100%', marginLeft: 'auto', marginRight: 'auto' }}
        alt="Product Screen"
      />
    </Stack>
  </Modal>
);

export default ViewProductScreenModal;
