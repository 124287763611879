// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useQuery } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { isEmpty } from 'lodash/fp';
import { IContact } from 'models/contactAddress';
import { useParams } from 'react-router-dom';

export type ContactsResponse = {
  count: number;
  total: number;
  items: IContact[];
};

export const getContacts = async ({
  search,
  strictEqual,
  partnershipId,
  makeRequest,
}: {
  search?: string;
  strictEqual?: boolean;
  partnershipId?: string;
  makeRequest: any;
}): Promise<ContactsResponse> => {
  // Encode search params and appends them to the url
  let encodedSearchParams = '';
  if (!isEmpty(search)) {
    encodedSearchParams = `?offset=0&pageSize=100&search=${search}`;

    if (strictEqual) {
      encodedSearchParams += '&strictEqual=true';
    }
  }

  const { data, error } = await makeRequest({
    path: `api/v1/me/partners/${partnershipId}/contacts${encodedSearchParams}`,
  });

  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnType = typeof getContacts;

type UseAddressOptions = {
  search?: string;
  strictEqual?: boolean;
  partnershipId?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useContacts = ({
  search,
  strictEqual,
  partnershipId,
  config,
}: UseAddressOptions = {}) => {
  const { partnerId } = useParams();

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const [, makeRequest] = useImperativeRequest('lpVillage');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['partnership', id, 'contacts', search],
    queryFn: () =>
      getContacts({ search, strictEqual, partnershipId: id, makeRequest }),
  });
};
