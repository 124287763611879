// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { styled } from '@livingpackets/design-system-react-next';

//Use to wrap components of views that are not full width
const NonFullWidthWrapper = styled('div')`
  display: flex;
  min-width: 56.25rem;
  width: 56.25rem;
`;

export default NonFullWidthWrapper;
