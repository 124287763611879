// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ArrowRightIcon,
  ButtonV2 as Button,
  Text,
  Stack,
  styled,
  Grid,
} from '@livingpackets/design-system-react-next';
import ShipmentSumUpCard from 'components/molecules/ShipmentSumUpCard';
import ShipmentSumUpSkeleton from 'components/molecules/ShipmentSumUpSkeleton';
import { Carriers } from 'configs/carriers';
import { IFiguralInsurance } from 'models/figuralInsurance';
import { IOcrLabel } from 'models/shipment';
import { useTranslation } from 'react-i18next';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding-top: 2.1875rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

interface ImportShipmentOverviewProps {
  label: IOcrLabel;
  returnLabel: IOcrLabel;
  carrierCode: Carriers;
  onChangeOutgoingLabel: () => void;
  onChangeReturnLabel: () => void;
  onAddReturnLabel: () => void;
  onRemoveReturnLabel: () => void;
  onSubmit: () => void;
  onOpenFiguralInsuranceFormModal: () => void;
  onOpenFiguralInsuranceSummaryModal: () => void;
  figuralInsuranceData?: IFiguralInsurance;
  isShipmentCreationButtonDisabled: boolean;
}

const ImportShipmentOverview = ({
  label,
  returnLabel,
  carrierCode,
  onChangeOutgoingLabel,
  onChangeReturnLabel,
  onAddReturnLabel,
  onRemoveReturnLabel,
  onSubmit,
  onOpenFiguralInsuranceFormModal,
  onOpenFiguralInsuranceSummaryModal,
  figuralInsuranceData,
  isShipmentCreationButtonDisabled,
}: ImportShipmentOverviewProps) => {
  const { t } = useTranslation('shipments');

  return (
    <Wrapper>
      <Stack pb="0.375rem">
        <Text variant="titleS" color="custom.neutral.black.100">
          {t('importLabel.sumUp.title')}
        </Text>
        <Text
          variant="bodyTextM"
          color="custom.neutral.black.100"
          mt=".125rem"
          mb={3}
        >
          {t('importLabel.sumUp.subTitle')}
        </Text>
      </Stack>
      <ShipmentSumUpCard
        onEdit={onChangeOutgoingLabel}
        carrierCode={carrierCode}
        label={label}
        onOpenFiguralInsuranceSummaryModal={onOpenFiguralInsuranceSummaryModal}
        figuralInsuranceData={figuralInsuranceData}
        isReturnLabelSummary={false}
      />
      {returnLabel.referenceNumber ? (
        <ShipmentSumUpCard
          onEdit={onChangeReturnLabel}
          carrierCode={carrierCode}
          label={returnLabel}
          onRemove={onRemoveReturnLabel}
          onOpenFiguralInsuranceSummaryModal={
            onOpenFiguralInsuranceSummaryModal
          }
          figuralInsuranceData={figuralInsuranceData}
          isReturnLabelSummary={true}
        />
      ) : (
        <ShipmentSumUpSkeleton onClickAddReturn={onAddReturnLabel} />
      )}
      <Grid
        container
        justifyContent={
          returnLabel.referenceNumber !== undefined
            ? 'flex-end'
            : 'space-between'
        }
        alignItems="center"
        mt="0rem"
        spacing="1.5rem"
      >
        {!returnLabel.referenceNumber && (
          <Grid item flex={1}>
            <Text variant="bodyTextM" color="custom.neutral.black.100">
              {t('importLabel.sumUp.warningMissingReturnLabel')}
            </Text>
          </Grid>
        )}

        <Grid item mobile={12} tablet="auto">
          <Button
            data-testid="create-shipment"
            icon={ArrowRightIcon}
            onClick={onSubmit}
            disabled={isShipmentCreationButtonDisabled}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {t('createShipment')}
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ImportShipmentOverview;
