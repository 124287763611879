// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect } from 'react';

import {
  Box,
  Stack,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import CountrySelector from 'components/organisms/CountrySelector';
import useFormTemplate from 'hooks/useFormTemplate';
import { set } from 'lodash';
import {
  IBillingAddressForm,
  INITIAL_STATE_BILLING_ADDRESS_FORM,
} from 'models/billingAddress';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { billingAddressSchema } from 'schemas/billingAddressSchema';

interface IBillingAddressType {
  defaultValues?: IBillingAddressForm;
  onValuesChanged: (values: IBillingAddressForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  prefix?: string;
  isDismissTriggered?: boolean;
}

const BillingAddressForm = ({
  defaultValues,
  onValuesChanged,
  isFormValidChanged,
}: IBillingAddressType) => {
  const { t } = useTranslation('accountInformation');

  const {
    register,
    formState: { touchedFields, isValid },
    control,
    getValues,
    trigger,
  } = useFormTemplate<IBillingAddressForm>({
    resolver: billingAddressSchema,
    defaultValues: defaultValues
      ? defaultValues
      : INITIAL_STATE_BILLING_ADDRESS_FORM,
  });

  useEffect(() => {
    if (defaultValues) {
      // Manually set the fields as touched
      set(touchedFields, `firstName`, true);
      set(touchedFields, `lastName`, true);
      set(touchedFields, `companyName`, true);
      set(touchedFields, `city`, true);
      set(touchedFields, `address`, true);
      set(touchedFields, `zipCode`, true);
      set(touchedFields, `countryCode`, true);

      trigger();
    }
  }, [defaultValues, touchedFields, trigger]);

  useEffect(() => {
    isFormValidChanged(isValid);
  }, [isValid, isFormValidChanged]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onValuesChanged(getValues() as IBillingAddressForm);
    },
    [getValues, onValuesChanged]
  );

  return (
    <Stack gap="1rem">
      <Stack direction="row" gap="1rem">
        <Controller
          name="firstName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('form.billingAddress.fields.firstName.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('form.billingAddress.fields.lastName.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
      </Stack>
      <Stack direction="row" gap="1rem">
        <Controller
          name="companyName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              label={t('form.billingAddress.fields.companyName.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
        <Controller
          name="city"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('form.billingAddress.fields.city.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
      </Stack>
      <Stack direction="row" gap="1rem">
        <Controller
          name="address"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('form.billingAddress.fields.address.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
        <Controller
          name="zipCode"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('form.billingAddress.fields.zipCode.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
      </Stack>
      <Box width="50%" pr=".5rem">
        <CountrySelector
          id="countryCode"
          fieldName="countryCode"
          width="100%"
          control={control}
          register={register}
          onChange={handleInputChange}
          required
        />
      </Box>
    </Stack>
  );
};

export default BillingAddressForm;
