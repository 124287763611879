// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  LockOnIcon,
  AddIcon,
  Banner,
  Box,
  ButtonV2 as Button,
  Text,
  styled,
  useTheme,
  Stack,
  Grid,
} from '@livingpackets/design-system-react-next';
import bgBanner from 'assets/img/banner/bg-banner.svg';
import boxBannerShipment from 'assets/img/dashboard_box_shipment.png';
import HomeInfoCard from 'components/molecules/HomeInfoCard';
import HomeShipmentCard from 'components/molecules/HomeShipmentCard';
import Title from 'components/molecules/Title';
import { PATHS } from 'configs';
import {
  PartnerBusinessStatusEnum,
  PartnerBusinessTypeEnum,
} from 'enums/accountTypeEnum';
import useCurrentPartner from 'hooks/useCurrentPartner';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import useNavigatePartner from 'hooks/useNavigatePartner';
import useNewShipmentDisabled from 'hooks/useNewShipmentDisabled';
import { find } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import useCurrentPartnerStore, {
  currentPartnerSelector,
  registrationAddressSelector,
} from 'stores/useCurrentPartnerStore';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const ShipmentBanner = styled(Box, {
  shouldForwardProp: prop => prop !== 'bgImage',
})<{ bgImage: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 10rem;
  border-radius: 1.25rem;
  padding-left: 1.5rem;
  color: ${({ theme }) => theme.palette.custom.neutral.white};
  background: url(${props => props.bgImage}), linear-gradient(80deg, ${({
  theme,
}) => theme.palette.custom.primary[100]} -45.64%, ${({ theme }) =>
  theme.palette.custom.primary[140]} 92.72%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}
`;

const CreateShipmentButtonWrapper = styled(Stack)<{ disabled?: boolean }>`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[4]};
  border: 1.5px dashed ${({ theme }) => theme.palette.custom.neutral.black[20]};
  height: 10rem;
  min-width: 15.875rem;
  border-radius: 0.75rem;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const PartnerHome = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['general', 'partnerSettings', 'dashboard']);

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const partner = useCurrentPartnerStore(currentPartnerSelector);
  const { reset, getCurrentPartner, getRegistrationAddress } =
    useCurrentPartner();
  const appUser = useAppState(userSelector);

  const formatTimestamp = useIntlFormatTimestamp();
  const navigateAccount = useNavigatePartner();

  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const [bannerContent, setBannerContent] = useState<string>('');
  const [tagCreateShipmentMessage, setTagCreateShipmentMessage] =
    useState<string>('');
  const [update, setUpdate] = useState<number>(0);

  const manageBanner = useCallback(
    (status: PartnerBusinessStatusEnum, type: PartnerBusinessTypeEnum) => {
      if ([PartnerBusinessStatusEnum.BLOCKED].includes(status)) {
        setDisplayBanner(true);
        setBannerContent(status.toLocaleLowerCase());
      } else if (
        status === PartnerBusinessStatusEnum.PENDING &&
        type === PartnerBusinessTypeEnum.PRO &&
        find({ name: 'update:partner' }, activePartner.role.scopes)
      ) {
        setDisplayBanner(true);
        setBannerContent(status.toLocaleLowerCase());
      } else {
        setDisplayBanner(false);
        setBannerContent('');
      }
    },
    [activePartner.role.scopes]
  );

  const manageTagMessage = useCallback(
    (status: PartnerBusinessStatusEnum, type: PartnerBusinessTypeEnum) => {
      let message = '';
      if (type === PartnerBusinessTypeEnum.TEST) {
        message = t(`dashboard:createShipment.tagInformation`, {
          profil: type,
          maxOfShipements: partner.maxNbOfShipments,
        });
      } else if (status === PartnerBusinessStatusEnum.PENDING) {
        message = t(`dashboard:createShipment.tagInformation`, {
          profil: status,
          maxOfShipements: partner.maxNbOfShipments,
        });
      }

      setTagCreateShipmentMessage(message);
    },
    [partner, t]
  );

  useEffect(() => {
    getRegistrationAddress(activePartner.id);
    getCurrentPartner(activePartner.id);

    return () => {
      reset();
    };
  }, [activePartner.id, getRegistrationAddress, reset, getCurrentPartner]);

  useEffect(() => {
    manageBanner(
      partner.businessInformation.status,
      partner.businessInformation.type
    );
    manageTagMessage(
      partner.businessInformation.status,
      partner.businessInformation.type
    );
  }, [bannerContent, displayBanner, manageBanner, manageTagMessage, partner]);

  const registrationAddress = useCurrentPartnerStore(
    registrationAddressSelector
  );
  const disableNewShipment = useNewShipmentDisabled(partner);

  return (
    <Stack>
      {displayBanner && (
        // ------ BANNER -------
        <Banner
          bgPattern={bgBanner}
          title={t(
            `dashboard:banner.${bannerContent}.title` as unknown as TemplateStringsArray
          )}
          message={t(
            `dashboard:banner.${bannerContent}.message` as unknown as TemplateStringsArray
          )}
          ctaLabel={t('dashboard:banner.btnLabel')}
          customStyle={{ marginBottom: '3.375rem' }}
          buttonProps={{
            variant: 'secondary',
            color: 'info',
            onClick: () => navigateAccount('/account/partnership/information'),
          }}
        />
      )}
      {/* ------ TITLE ------- */}
      <Title pageName={t('general:dashboard')} />
      {/* ------ GREEN CARD + Shipment button ------ */}
      <Grid container direction="row" gap="1.25rem">
        <Grid item flex="1">
          <ShipmentBanner bgImage={boxBannerShipment}>
            <Stack gap=".375rem">
              <Text variant="titleM" color="custom.neutral.white.pure">
                {t('dashboard:createShipment.title')}
              </Text>
              <Text variant="titleXL" color="custom.neutral.white.pure">
                {t('dashboard:createShipment.description')}
              </Text>
              {tagCreateShipmentMessage && (
                <Stack
                  direction="row"
                  bgcolor={theme.colors.green.basic50}
                  gap=".5rem"
                  px=".625rem"
                  py=".5rem"
                  borderRadius=".5rem"
                  justifyContent="center"
                  alignItems="center"
                  color="custom.neutral.white.pure"
                >
                  <LockOnIcon size="1.5rem" />
                  <Text variant="bodyTextS">{tagCreateShipmentMessage}</Text>
                </Stack>
              )}
            </Stack>
          </ShipmentBanner>
        </Grid>
        <Grid item mobile={12} tablet="auto">
          <CreateShipmentButtonWrapper
            gap="1rem"
            justifyContent="center"
            alignItems="center"
            onClick={() => {
              navigate(
                generatePath(PATHS.SHIPMENT.LABEL_IMPORT, {
                  partnerId: activePartner.id,
                })
              );
            }}
            data-testid="partnerHome-create-shipment-button"
            disabled={disableNewShipment}
          >
            <Text variant="titleM" color="custom.neutral.black.50">
              {t('dashboard:createShipment.btnLabel')}
            </Text>
            <Button disabled={disableNewShipment} icon={AddIcon}></Button>
          </CreateShipmentButtonWrapper>
        </Grid>
      </Grid>
      {/* ------ OVERVIEW ----- */}
      <Stack direction="row" mt="2.125rem">
        <Stack gap="0.875rem" width="100%">
          <Text variant="titleM">{t('dashboard:overviewSection.title')}</Text>
          <Stack direction="row" alignItems="baseline" gap="0.125rem">
            <Text variant="bodyTextS">
              {t('dashboard:overviewSection.lastUpdate')}
            </Text>
            {update > 0 && (
              <Text variant="titleXS" color="custom.neutral.black.50">
                {formatTimestamp(update, {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Text>
            )}
          </Stack>
          <HomeShipmentCard setUpdate={setUpdate} />
        </Stack>
      </Stack>
      {/* ------ PARTNER INFO ----- */}
      <Stack mt="2.125rem" gap="0.875rem">
        <Text variant="titleM">{t('partnerSettings:partnerInfo')}</Text>
        <HomeInfoCard
          user={appUser}
          subtitle={activePartner.name}
          address={registrationAddress}
          website={partner.website}
          phoneNumber={partner.phoneNumber}
          editLink={generatePath(PATHS.SETTINGS_PARTNER_PARTNERSHIP, {
            partnerId: activePartner.id,
          })}
        />
      </Stack>
    </Stack>
  );
};

export default PartnerHome;
