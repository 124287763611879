// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useEffect } from 'react';

import useImperativeRequest from './useImperativeRequest';
import useContributionsStore, {
  setCO2SavingsSelector,
} from '../stores/useContributionsStore';

const useGetCO2Savings = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpAccountV3');
  const setCO2Savings = useContributionsStore(setCO2SavingsSelector);
  const getCO2Savings = useCallback(async () => {
    const { data, error } = await makeRequest({
      path: `/co2`,
      method: 'get',
    });

    if (error) {
      return { success: false };
    }

    return data;
  }, [makeRequest]);

  useEffect(() => {
    getCO2Savings().then(r => {
      setCO2Savings(r);
    });
  }, [getCO2Savings, setCO2Savings]);

  return { loading };
};

export default useGetCO2Savings;
