// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import loadUserProfile from 'helpers/loadUserProfile';
import useImperativeRequest from 'hooks/useImperativeRequest';
import LoadingKeys from 'models/loadingKeys';
import useAppState, {
  dispatch,
  tokenSelector,
  userSelector,
} from 'stores/appState/useAppState';

const loadingDispatch = (state: boolean) =>
  dispatch({
    type: 'SET_LOADING',
    payload: { key: LoadingKeys.Authentication, state },
  });

enum MachineState {
  INITIAL = 'initial',
  AUTH0_LOADING = 'auth0Loading',
  AUTH0_LOADED = 'auth0Loaded',
  AUTHENTICATED = 'authenticated',
  GETTING_TOKEN = 'gettingToken',
  GETTING_USER = 'gettingUser',
  DONE = 'done',
}

const {
  INITIAL,
  AUTH0_LOADING,
  AUTH0_LOADED,
  AUTHENTICATED,
  GETTING_TOKEN,
  GETTING_USER,
  DONE,
} = MachineState;

const useLoadUserAndToken = () => {
  const [machineState, setMachineState] = useState<MachineState>(INITIAL);
  const appToken = useAppState(tokenSelector);
  const appUser = useAppState(userSelector);
  const {
    isAuthenticated,
    user: auth0User,
    isLoading: auth0Loading,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const getTokenFromAuth = useCallback(async () => {
    if (window.Cypress !== undefined) {
      const auth0 = JSON.parse(localStorage.getItem('auth0Cypress')!);

      if (auth0) {
        dispatch({ type: 'SET_TOKEN', payload: auth0.body.access_token });
        setMachineState(GETTING_USER);
      } else {
        setMachineState(DONE);
      }
    } else {
      try {
        const token = await getAccessTokenSilently();
        dispatch({ type: 'SET_TOKEN', payload: token });
        setMachineState(GETTING_USER);
      } catch (e) {
        setMachineState(DONE);
      }
    }
  }, [getAccessTokenSilently]);

  const [, makeRequest] = useImperativeRequest('lpAccount');

  const stateReducer = useCallback(
    async (machineState: MachineState) => {
      switch (machineState) {
        case INITIAL:
          loadingDispatch(true);
          setMachineState(AUTH0_LOADING);

          return;
        case AUTH0_LOADING:
          if (!auth0Loading) {
            setMachineState(AUTH0_LOADED);
          }

          return;
        case AUTH0_LOADED:
          setMachineState(isAuthenticated ? AUTHENTICATED : GETTING_TOKEN);

          return;
        case AUTHENTICATED:
          setMachineState(GETTING_TOKEN);

          return;
        case GETTING_TOKEN:
          await getTokenFromAuth();

          return;
        case GETTING_USER:
          if (appToken && !appUser) {
            await loadUserProfile(makeRequest, auth0User, logout);
            setMachineState(DONE);
          }

          return;
        case DONE:
          loadingDispatch(false);

          return;
      }
    },
    [
      makeRequest,
      auth0User,
      getTokenFromAuth,
      isAuthenticated,
      appToken,
      appUser,
      auth0Loading,
      logout,
    ]
  );

  useEffect(() => {
    stateReducer(machineState);
  }, [stateReducer, machineState]);
};

export default useLoadUserAndToken;
