// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import i18next from 'i18next';
import * as yup from 'yup';

export const partnershipInformationSchema = () =>
  yup.object().shape({
    companyName: yup
      .string()
      .required(
        i18next.t(
          'authentication:addPartnershipInformation.form.companyName.validation.required'
        )
      ),
  });
