// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  Grid,
  Paper,
  SavIcon,
  Text,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import { ContactUsModal } from './ContactUsModal';

export const ContactUs = () => {
  const { t } = useTranslation(['general', 'profileSettings']);

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <Paper
        justifyContent="center"
        height="100%"
        sx={{
          ':hover': {
            cursor: 'pointer',
          },
        }}
        onClick={() => setOpen(true)}
        data-testid="contact-us"
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Text variant="titleM">{t('general:contactUs')}</Text>
          </Grid>
          <Grid item>
            <Text
              variant="titleM"
              color="primary.main"
              sx={{
                display: 'flex',
                gap: '0.5rem',
                alignItems: 'center',
              }}
            >
              {t('general:customerCare')} <SavIcon />
            </Text>
          </Grid>
        </Grid>
      </Paper>
      <ContactUsModal open={open} setOpen={setOpen} />
    </>
  );
};
