// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useContext } from 'react';

import {
  Box,
  Chip,
  DeleteIcon,
  EditIcon,
  Text,
  Stack,
  styled,
} from '@livingpackets/design-system-react-next';
import { DefaultAddressesContext } from 'components/molecules/contactAddress/PartnerContactAddressAdd';
import { normalizeKey } from 'helpers/i18n';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import { IContactAddress } from 'models/contactAddress';
import { useTranslation } from 'react-i18next';

interface IContactAddressBlockType {
  address: IContactAddress;
  displayAddressForm: boolean;
  onBlockClick?: (event: any) => void;
  handleDeleteClick?: (address: IContactAddress) => void;
  isAddressSelected: boolean;
  contactAddressesLength: number;
}

const ContactAddressInformationContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isAddressSelected',
})<{
  isAddressSelected: boolean;
}>`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  border-radius: 20px;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 24px;
  display: block;
  transition: 200ms;
  outline-width: '1.5px';
  outline-style: solid;
  outline-color: ${({ isAddressSelected, theme }) =>
    isAddressSelected ? theme.palette.custom.primary[100] : 'transparent'};
  &:hover {
    outline-color: ${({ theme }) => theme.palette.custom.primary[100]};
  }
`;

const ContactAddressBlock = ({
  address,
  onBlockClick,
  handleDeleteClick,
  isAddressSelected,
  contactAddressesLength,
}: IContactAddressBlockType) => {
  const { t } = useTranslation('contactAddress');

  const [, getCountryNameFromCode] = useCountryCodesWrapper();

  const { setDefaultSenderAddress, setDefaultRecipientAddress } = useContext(
    DefaultAddressesContext
  );

  return (
    <ContactAddressInformationContainer
      onClick={event => {
        if (onBlockClick) {
          onBlockClick(event);

          setDefaultSenderAddress(undefined);
          setDefaultRecipientAddress(undefined);
        }
      }}
      isAddressSelected={isAddressSelected}
      data-testid="contactAddressInformationContainer"
    >
      <Stack direction="row" justifyContent="space-between">
        <Box>
          {address.type && (
            <Chip
              state="default"
              type="tags"
              variant="titleXS"
              label={t(normalizeKey('form.type.choices.' + address.type))}
            />
          )}
        </Box>
        <Box display="flex">
          {contactAddressesLength > 1 && address && handleDeleteClick && (
            <div onClick={_ => handleDeleteClick(address)}>
              <DeleteIcon className="DeleteIcon" />
            </div>
          )}
          <EditIcon />
        </Box>
      </Stack>
      <Box mt="1rem">
        {address.company && <Text variant="titleXS">{address.company}</Text>}
        <Text variant="bodyTextM">{address.street}</Text>
        <Stack direction="row" gap="0.25rem">
          {address.postalCode && (
            <Text variant="bodyTextM">{address.postalCode + ' '}</Text>
          )}
          {address.city && <Text variant="bodyTextM">{address.city}</Text>}
        </Stack>
        <Text variant="bodyTextM">
          {getCountryNameFromCode(address.countryCode)}
        </Text>
      </Box>
    </ContactAddressInformationContainer>
  );
};

export default ContactAddressBlock;
