// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  AddIcon,
  Text,
  Stack,
  styled,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import ContactAddressForm from 'components/molecules/Form/ContactAddressForm';
import ContactForm from 'components/molecules/Form/ContactForm';
import {
  IContactAddressForm,
  IContactForm,
  IContactInformationForm,
  INITIAL_STATE_CONTACT_ADDRESS_FORM,
  INITIAL_STATE_CONTACT_INFORMATION_FORM,
} from 'models/contactAddress';
import { useTranslation } from 'react-i18next';

interface IContactAddressType {
  onValuesChanged: (values: IContactForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  isDismissTriggered?: boolean;
}

const BlockSubTitleText = styled(Text)``;

BlockSubTitleText.defaultProps = {
  color: 'custom.neutral.black.50',
};

const ContactAddressCreateForm = ({
  onValuesChanged,
  isFormValidChanged,
  isDismissTriggered,
}: IContactAddressType) => {
  const { t } = useTranslation('contactAddress');

  const [contactInformationData, setContactInformationData] =
    useState<IContactInformationForm>(INITIAL_STATE_CONTACT_INFORMATION_FORM);
  const [contactAddressData, setContactAddressData] =
    useState<IContactAddressForm>(INITIAL_STATE_CONTACT_ADDRESS_FORM);
  const [contactAddress2Data, setContactAddress2Data] =
    useState<IContactAddressForm>(INITIAL_STATE_CONTACT_ADDRESS_FORM);

  const [isContactInformationFormValid, setIsContactInformationFormValid] =
    useState<boolean>(false);
  const [isContactAddressFormValid, setIsContactAddressFormValid] =
    useState<boolean>(false);
  const [isContactAddress2FormValid, setIsContactAddress2FormValid] =
    useState<boolean>(false);
  const [isSecondAddressDisplayed, setIsSecondAddressDisplayed] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isSecondAddressDisplayed) {
      onValuesChanged({
        basicInformation: contactInformationData,
        address: contactAddressData,
      });
    } else {
      onValuesChanged({
        basicInformation: contactInformationData,
        address: contactAddressData,
        address2: contactAddress2Data,
      });
    }
  }, [
    contactInformationData,
    contactAddressData,
    contactAddress2Data,
    onValuesChanged,
    isSecondAddressDisplayed,
  ]);

  useEffect(() => {
    if (!isSecondAddressDisplayed) {
      isFormValidChanged(
        isContactInformationFormValid && isContactAddressFormValid
      );
    } else {
      isFormValidChanged(
        isContactInformationFormValid &&
          isContactAddressFormValid &&
          isContactAddress2FormValid
      );
    }
  }, [
    isContactInformationFormValid,
    isContactAddressFormValid,
    isContactAddress2FormValid,
    isFormValidChanged,
    isSecondAddressDisplayed,
  ]);

  useEffect(() => {
    if (isDismissTriggered) {
      setIsSecondAddressDisplayed(false);
      isFormValidChanged(false);
    }
  }, [isDismissTriggered, isFormValidChanged]);

  const handleAddAddressButtonClick = useCallback(() => {
    setIsSecondAddressDisplayed(true);
  }, []);

  const handleContactInformationFormValuesChanged = useCallback(
    (values: IContactInformationForm) => {
      setContactInformationData(values);
    },
    []
  );

  const handleContactInformationFormValidChanged = useCallback(
    (isFormValid: boolean) => {
      setIsContactInformationFormValid(isFormValid);
    },
    []
  );

  const handleContactAddressFormValuesChanged = useCallback(
    (values: IContactAddressForm) => {
      setContactAddressData(values);
    },
    []
  );

  const handleContactAddressFormValidChanged = useCallback(
    (isFormValid: boolean) => {
      setIsContactAddressFormValid(isFormValid);
    },
    []
  );

  const handleContactAddress2FormValuesChanged = useCallback(
    (values: IContactAddressForm) => {
      setContactAddress2Data(values);
    },
    []
  );

  const handleContactAddress2FormValidChanged = useCallback(
    (isFormValid: boolean) => {
      setIsContactAddress2FormValid(isFormValid);
    },
    []
  );

  return (
    <>
      <Stack>
        <Text variant="titleM" mb=".5rem">
          {t('create.block.basicInformation.title')}
        </Text>
        <BlockSubTitleText variant="bodyTextS" mb="1.5rem">
          {t('create.block.basicInformation.subTitle')}
        </BlockSubTitleText>
        <ContactForm
          defaultValues={undefined}
          onValuesChanged={handleContactInformationFormValuesChanged}
          isFormValidChanged={handleContactInformationFormValidChanged}
          triggerResetForm={isDismissTriggered}
        />
      </Stack>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Text variant="titleM" mb=".5rem">
              {t('create.block.address.title')}
            </Text>
            {isSecondAddressDisplayed ? (
              <BlockSubTitleText variant="bodyTextS">
                {t('create.block.address.subTitle1on2')}
              </BlockSubTitleText>
            ) : (
              <BlockSubTitleText variant="bodyTextS">
                {t('create.block.address.subTitle')}
              </BlockSubTitleText>
            )}
          </Stack>
          <Stack>
            {isContactAddressFormValid && !isSecondAddressDisplayed && (
              <Button
                data-testid="addAddressBtn"
                icon={AddIcon}
                onClick={handleAddAddressButtonClick}
              />
            )}
          </Stack>
        </Stack>
        <ContactAddressForm
          defaultValues={undefined}
          onValuesChanged={handleContactAddressFormValuesChanged}
          isFormValidChanged={handleContactAddressFormValidChanged}
          prefix="address."
          isDismissTriggered={isDismissTriggered}
        />
        {isSecondAddressDisplayed && (
          <>
            <BlockSubTitleText variant="bodyTextS" marginTop="32px">
              {t('create.block.address.subTitle2on2')}
            </BlockSubTitleText>
            <ContactAddressForm
              defaultValues={undefined}
              onValuesChanged={handleContactAddress2FormValuesChanged}
              isFormValidChanged={handleContactAddress2FormValidChanged}
              prefix="address2."
              isDismissTriggered={isDismissTriggered}
            />
          </>
        )}
      </Stack>
    </>
  );
};

export default ContactAddressCreateForm;
