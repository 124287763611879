// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import downloadOnTheAppStoreDE from 'assets/app-store-de.svg';
import downloadOnTheAppStoreEN from 'assets/app-store-en.svg';
import downloadOnTheAppStoreFR from 'assets/app-store-fr.svg';
import { IOS_APP_STORE_APP_LINK } from 'configs';
import { useTranslation } from 'react-i18next';

export function GetOnAppStore() {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const badgeByLanguage =
    currentLanguage === 'fr'
      ? downloadOnTheAppStoreFR
      : currentLanguage === 'en'
      ? downloadOnTheAppStoreEN
      : downloadOnTheAppStoreDE;

  return (
    <a
      style={{ display: 'block' }}
      href={IOS_APP_STORE_APP_LINK}
      data-testid="get-on-app-store"
    >
      <img src={badgeByLanguage} alt="Download on the App Store" />
    </a>
  );
}
