// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import MemberForm from 'components/molecules/Form/MemberForm';
import { IMemberForm, IMember } from 'models/user';

interface IMemberType {
  onValuesChanged: (values: IMemberForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  defaultValues: IMember;
}

const PartnerMemberEditForm = ({
  onValuesChanged,
  isFormValidChanged,
  defaultValues,
}: IMemberType) => (
  <MemberForm
    isEmailInputDisabled
    defaultValues={defaultValues}
    onValuesChanged={onValuesChanged}
    isFormValidChanged={isFormValidChanged}
  />
);

export default PartnerMemberEditForm;
