// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect } from 'react';

import {
  Box,
  Grid,
  Stack,
  Text,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import useFormTemplate from 'hooks/useFormTemplate';
import { IOcrLabel, ShipmentParcelData } from 'models/shipment';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { shipmentParcelSchema } from 'schemas/shipmentSchema';

export interface ShipmentParcelFormProps {
  label: IOcrLabel;
  onChange: (shipmentParcelData?: ShipmentParcelData) => void;
}

const ShipmentParcelForm = ({ label, onChange }: ShipmentParcelFormProps) => {
  const { t } = useTranslation('shipments');

  const {
    formState: { isValid },
    control,
    watch,
  } = useFormTemplate<ShipmentParcelData>({
    resolver: shipmentParcelSchema,
    defaultValues: {
      weightInGrams: label.weightInGrams
        ? label.weightInGrams / 1000
        : undefined,
      carrierTrackingCode: label.carrierTrackingCode,
      referenceNumber: label.referenceNumber,
    },
    formValidationMode: 'onTouched',
  });

  const values = watch();

  // Allow to enable/disable the `Shipping addresses` section according to the form validity
  useEffect(() => {
    if (isValid) {
      const data = values;

      onChange({
        weightInGrams: Math.round(
          parseFloat(data.weightInGrams?.toString().replace(',', '.')) * 1000
        ),
        carrierTrackingCode: data.carrierTrackingCode,
        referenceNumber: data.referenceNumber,
      });
    } else {
      onChange();
    }
  }, [isValid, values, onChange]);

  return (
    <Box>
      <Grid container direction="column" gap=".625rem">
        <Grid item mobile={12}>
          <Text variant="titleS" color="custom.neutral.black.50">
            {t('importLabel.confirmInformation.parcelDetails.subTitle')}
          </Text>
        </Grid>
        <Grid item mobile={12}>
          <Stack direction="row" gap="1.5rem">
            <Controller
              name="referenceNumber"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  label={t(
                    'form.importInformation.fields.referenceNumber.label'
                  )}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="weightInGrams"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  label={t('form.importInformation.fields.weight.label')}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  sx={{ maxWidth: '140px' }}
                  {...field}
                />
              )}
            />
            <Controller
              name="carrierTrackingCode"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  label={t(
                    'form.importInformation.fields.carrierTrackingCode.label'
                  )}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShipmentParcelForm;
