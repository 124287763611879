// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  ShipmentCompletedIcon,
  ShipmentPurchasedIcon,
  ShipmentOngoingIcon,
  Paper,
  Text,
  Grid,
  Stack,
  useTheme,
  IconProps,
} from '@livingpackets/design-system-react-next';
import { ShipmentDirection } from 'components/organisms/PartnerShipmentsTable';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useNavigatePartner from 'hooks/useNavigatePartner';
import useShipmentList from 'hooks/useShipmentList';
import {
  ShipmentListNavTabEnum,
  ShipmentListTabEnum,
} from 'hooks/useShipmentTab';
import useToastMessages from 'hooks/useToastMessages';
import { ShipmentContractState } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const SHIPMENT_STATES = [
  'PURCHASED',
  'DEPLOYED',
  'TRANSFERRED',
  'ARRIVED',
  'ARCHIVED',
] as ShipmentContractState[];

const INITIAL_TOTALS = {
  DRAFT: 0,
  PURCHASED: 0,
  TRANSFERRED: 0,
  DEPLOYED: 0,
  ARRIVED: 0,
  ARCHIVED: 0,
};

export const OverviewCard = ({
  dataTestId,
  onClick,
  title,
  count,
  label,
  icon: Icon,
}: {
  dataTestId: string;
  onClick: () => void;
  title: string;
  count: number | string;
  label: string;
  icon: React.FC<IconProps>;
}) => {
  const theme = useTheme();

  return (
    <Paper
      padding="1.5rem !important"
      sx={{ cursor: 'pointer' }}
      data-testid={dataTestId}
      onClick={onClick}
    >
      <Stack gap="1rem">
        <Stack
          direction="row"
          alignItems="center"
          gap=".5rem"
          alignSelf="stretch"
        >
          <Icon color={theme.palette.custom.primary[100]} />
          <Text variant="titleM">{title}</Text>
        </Stack>
        <Stack direction="row" alignItems="baseline" gap=".5rem">
          <Text variant="titleXL">{count}</Text>
          <Text variant="bodyTextS" color="custom.neutral.black.30">
            {label}
          </Text>
        </Stack>
      </Stack>
    </Paper>
  );
};

const HomeShipmentCard = ({ setUpdate }: { setUpdate?: any }) => {
  const { t } = useTranslation('dashboard');

  const navigatePartner = useNavigatePartner();
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const [, makeRequest] = useImperativeRequest('shipment');
  const { error: toastError } = useToastMessages();
  const [total, setTotal] = useState<typeof INITIAL_TOTALS>();

  const { isShipmentRowCompactInList } = useShipmentList();

  const getShipmentTotals = useCallback(
    async (contractState: ShipmentContractState) => {
      let direction: ShipmentDirection | undefined = undefined;

      if (isShipmentRowCompactInList(contractState)) {
        direction = 'original';
      }

      const url = 'api/v2/shipments';

      const parameterList = [];

      if (direction) {
        parameterList.push(`direction=${direction}`);
      }

      if (contractState !== 'ARCHIVED') {
        parameterList.push(`contractState=${contractState}`);
      }

      parameterList.push(
        `isArchived=${contractState === 'ARCHIVED' ? 'true' : 'false'}`
      );

      parameterList.push('sortBy=updatedAt');
      parameterList.push('order=DESC');
      parameterList.push('pageSize=1');
      parameterList.push(`partnerId=${activePartner.id}`);

      const { data, error } = await makeRequest({
        path: `${url}?${parameterList.join('&')}`,
        method: 'get',
      });

      if (error) {
        toastError('messages:getShipmentTotals.message');

        return { data: [], contractState };
      }

      return { data, contractState };
    },
    [makeRequest, toastError, activePartner, isShipmentRowCompactInList]
  );

  useEffect(() => {
    Promise.all(SHIPMENT_STATES.map(type => getShipmentTotals(type))).then(
      results => {
        let lastUpdate = 0;
        results.forEach(res => {
          if (res.data.items && res.data.items[0]?.updatedAt > lastUpdate) {
            lastUpdate = res.data.items[0]?.updatedAt;
          }
        });

        setUpdate(lastUpdate);

        const totals = results.reduce(
          (tot, res) => ({
            ...tot,
            [res.contractState]: res.data.total,
          }),
          INITIAL_TOTALS
        );

        setTotal(totals);
      }
    );
  }, [getShipmentTotals, setUpdate]);

  return (
    <Grid container spacing="1.25rem">
      <Grid item mobile={12} tablet={4}>
        <OverviewCard
          dataTestId="shipment-card-labels"
          onClick={() => {
            navigatePartner(`/shipments`, {
              state: {
                navtab: ShipmentListNavTabEnum.labels,
                tab: ShipmentListTabEnum.labelPurchased,
              },
            });
          }}
          title={t('overviewSection.inPreparationCardTitle')}
          count={total ? total.PURCHASED + total.TRANSFERRED || '0' : '0'}
          label={t('overviewSection.inPreparationCardLabel', {
            count: total ? total.PURCHASED + total.TRANSFERRED || 0 : 0,
          })}
          icon={ShipmentPurchasedIcon}
        />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <OverviewCard
          dataTestId="shipment-card-in-transit"
          onClick={() => {
            navigatePartner(`/shipments`, {
              state: {
                navtab: ShipmentListNavTabEnum.ongoing,
                tab: ShipmentListTabEnum.onGoing,
              },
            });
          }}
          title={t('overviewSection.inTransitCardTitle')}
          count={total?.DEPLOYED || '0'}
          label={t('overviewSection.inTransitCardLabel', {
            count: total?.DEPLOYED || 0,
          })}
          icon={ShipmentOngoingIcon}
        />
      </Grid>
      <Grid item mobile={12} tablet={4}>
        <OverviewCard
          dataTestId="shipment-card-delivered"
          onClick={() => {
            navigatePartner(`/shipments`, {
              state: {
                navtab: ShipmentListNavTabEnum.completed,
                tab: ShipmentListTabEnum.finished,
              },
            });
          }}
          title={t('overviewSection.deliveredCardTitle')}
          count={total ? total.ARRIVED + total.ARCHIVED || '0' : '0'}
          label={t('overviewSection.deliveredCardLabel', {
            count: total ? total.ARRIVED + total.ARCHIVED || 0 : 0,
          })}
          icon={ShipmentCompletedIcon}
        />
      </Grid>
    </Grid>
  );
};

export default HomeShipmentCard;
