// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import PartnerMemberManagementAdd from 'components/molecules/userManagement/PartnerMemberManagementAdd';
import PartnerMemberManagementEdit from 'components/molecules/userManagement/PartnerMemberManagementEdit';
import PartnerUserManagementOverview from 'components/molecules/userManagement/PartnerMemberManagementOverview';
import { ROUTE_PATHS } from 'configs';
import { Route, Routes } from 'react-router-dom';

const PartnerUserManagement = () => (
  <Routes>
    <Route index element={<PartnerUserManagementOverview />} />
    <Route
      path={ROUTE_PATHS.PARTNER.CHILDREN.USER_MANAGEMENT.CHILDREN.CREATE.ROOT}
      element={<PartnerMemberManagementAdd />}
    />
    <Route path={ROUTE_PATHS.PARTNER.CHILDREN.USER_MANAGEMENT.CHILDREN.ID.ROOT}>
      <Route
        path={
          ROUTE_PATHS.PARTNER.CHILDREN.USER_MANAGEMENT.CHILDREN.ID.CHILDREN.EDIT
            .ROOT
        }
        element={<PartnerMemberManagementEdit />}
      />
    </Route>
  </Routes>
);

export default PartnerUserManagement;
