// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Text, Stack } from '@livingpackets/design-system-react-next';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { IShipmentTimelineEntry } from 'models/shipment';
import { useTranslation } from 'react-i18next';

interface IEventBoxViolationBlockType {
  event: IShipmentTimelineEntry;
}

const EventBoxViolationBlock = ({ event }: IEventBoxViolationBlockType) => {
  const { t } = useTranslation('shipments');
  const formatTimestamp = useIntlFormatTimestamp();

  return (
    <Stack direction="row" marginTop="11px !important">
      <Stack>
        <Text variant="bodyTextXS" mb="0.5rem">
          {t('detail.timeline.information.status')}
        </Text>
        <Text variant="bodyTextXS" mb="0.5rem" sx={{ whiteSpace: 'pre' }}>
          {t('detail.timeline.information.startOfAlert')}
        </Text>
      </Stack>
      <Stack marginLeft="10px !important">
        <Text variant="bodyTextXS" mb="0.5rem">
          {t('detail.timeline.information.fall.oneTime')}
        </Text>
        <Text variant="bodyTextXS" mb="0.5rem" sx={{ whiteSpace: 'pre' }}>
          {formatTimestamp(event.createdAt, {
            day: 'numeric',
            month: 'long',
            year: undefined,
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Text>
      </Stack>
    </Stack>
  );
};

export default EventBoxViolationBlock;
