// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  MapReduceIcon,
  MapZoomIcon,
  useTheme,
  styled,
} from '@livingpackets/design-system-react-next';
import { InfoWindow, Marker, Polyline } from '@react-google-maps/api';
import SmallBoxImg from 'assets/mapMarkerBox.png';
import RecipientImg from 'assets/mapMarkerRecipient.png';
import SenderImg from 'assets/mapMarkerSender.png';
import GMInfoWindowContent from 'components/atoms/GMInfoWindowContent';
import Map from 'components/molecules/Map';
import ShipmentDetailMapControl from 'components/molecules/shipmentAnonymousDetail/ShipmentDetailMapControl';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import useGetShipmentGeoPath from 'hooks/useGetShipmentGeoPath';
import { IPosition } from 'models/position';
import { IShipment, ITrackingHistoryResponse } from 'models/shipment';
import { useTranslation } from 'react-i18next';

import { AddressToStringAnonymous } from './AddressAnonymous';

const ShipmentMapFullScreenBtn = styled('div', {
  shouldForwardProp: prop => prop !== 'isFullScreen',
})<{ isFullScreen: boolean }>`
  margin-right: ${({ isFullScreen }) => (!isFullScreen ? '12px' : '12px')};
  margin-top: ${({ isFullScreen }) => (!isFullScreen ? '12px' : '0')};
  cursor: pointer;
`;

interface IShipmentMap {
  trackingData: ITrackingHistoryResponse;
  shipment: IShipment;
  displayMapFullScreenModal?: () => void;
  hideMapFullScreenModal?: () => void;
  isFullScreen?: boolean;
}

const getPositions = ({ trackingData, shipment }: IShipmentMap) => {
  const boxLat = trackingData.lastKnownLocation?.latitude;
  const boxLong = trackingData.lastKnownLocation?.longitude;
  const senderLat = shipment.sender.address.position?.latitude;
  const senderLong = shipment.sender.address.position?.longitude;
  const recipientLat = shipment.recipient.address.position?.latitude;
  const recipientLong = shipment.recipient.address.position?.longitude;
  let boxPos, senderPos, recipientPos;

  if (boxLat && boxLong) {
    boxPos = { position: { lat: boxLat, lng: boxLong } } as IPosition;
  }
  if (senderLat && senderLong) {
    senderPos = { position: { lat: senderLat, lng: senderLong } } as IPosition;
  }
  if (recipientLat && recipientLong) {
    recipientPos = {
      position: { lat: recipientLat, lng: recipientLong },
    } as IPosition;
  }

  return { boxPos, senderPos, recipientPos };
};

const ShipmentMap = ({
  trackingData,
  shipment,
  displayMapFullScreenModal,
  hideMapFullScreenModal,
  isFullScreen = false,
}: IShipmentMap) => {
  const theme = useTheme();
  const { t } = useTranslation(['shipments', 'general']);

  const polylineOptions = {
    strokeColor: theme.palette.custom.primary[100],
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  const [, getCountryNameFromCode] = useCountryCodesWrapper();
  const { geoPath, loading: isGeoPathLoading } = useGetShipmentGeoPath(
    shipment.shipment_id
  );

  const [boxPos, setBoxPos] = useState<IPosition>();
  const [senderPos, setSenderPos] = useState<IPosition>();
  const [recipientPos, setRecipientPos] = useState<IPosition>();

  const [bounds, setBounds] = useState<any>();

  const [infoWindows, setInfoWindows] = useState({
    sender: false,
    recipient: false,
  });
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);

  const handleHover = useCallback(
    (type: 'sender' | 'recipient', value: boolean) => {
      setInfoWindows(prev => ({ ...prev, [type]: value }));
    },
    []
  );

  const updateIsMapLoaded = useCallback(
    (isMapLoadedInfo: boolean) => {
      setIsMapLoaded(isMapLoadedInfo);
    },
    [setIsMapLoaded]
  );

  useEffect(() => {
    const { boxPos, senderPos, recipientPos } = getPositions({
      trackingData,
      shipment,
    });

    setBoxPos(boxPos);
    setSenderPos(senderPos);
    setRecipientPos(recipientPos);

    let bounds = [boxPos, senderPos, recipientPos];

    if (!isGeoPathLoading && geoPath) {
      bounds = bounds.concat(
        geoPath.map(
          (path: { lat: number; lng: number }) =>
            ({
              position: { lat: path.lat, lng: path.lng },
            } as IPosition)
        )
      );
    }

    // Remove undefined bound in list
    bounds = bounds.filter(bound => bound);

    setBounds(bounds);
  }, [
    trackingData,
    shipment,
    geoPath,
    isGeoPathLoading,
    setBoxPos,
    setSenderPos,
    setRecipientPos,
    setBounds,
  ]);

  if (isGeoPathLoading) {
    return <></>;
  }

  return (
    <Map
      bounds={bounds}
      height={isFullScreen ? '100%' : '312px'}
      isFullScreen={isFullScreen}
      isMapLoadedAction={updateIsMapLoaded}
      minZoom={3}
      maxZoom={18}
    >
      <>
        {geoPath && <Polyline path={geoPath} options={polylineOptions} />}
        {boxPos && (
          <Marker
            icon={SmallBoxImg}
            position={boxPos.position}
            data-testid="boxMarker"
          />
        )}
        {senderPos && (
          <Marker
            data-testid="senderMarker"
            position={senderPos.position}
            icon={SenderImg}
            onMouseOver={() => handleHover('sender', true)}
            onMouseOut={() => handleHover('sender', false)}
          >
            {infoWindows.sender && (
              <InfoWindow options={{ disableAutoPan: true }}>
                <GMInfoWindowContent
                  header={t('shipments:sender')}
                  content={AddressToStringAnonymous(
                    {
                      ...shipment.sender.address,
                      country: getCountryNameFromCode(
                        shipment.sender.address.countryCode
                      ),
                    },
                    t
                  )}
                />
              </InfoWindow>
            )}
          </Marker>
        )}
        {recipientPos && (
          <Marker
            data-testid="recipientMarker"
            position={recipientPos.position}
            icon={RecipientImg}
            onMouseOver={() => handleHover('recipient', true)}
            onMouseOut={() => handleHover('recipient', false)}
          >
            {infoWindows.recipient && (
              <InfoWindow options={{ disableAutoPan: true }}>
                <GMInfoWindowContent
                  header={t('shipments:recipient')}
                  content={AddressToStringAnonymous(
                    {
                      ...shipment.recipient.address,
                      country: getCountryNameFromCode(
                        shipment.recipient.address.countryCode
                      ),
                    },
                    t
                  )}
                />
              </InfoWindow>
            )}
          </Marker>
        )}
        {isMapLoaded && window.google && (
          <ShipmentDetailMapControl
            position={window.google.maps.ControlPosition.TOP_RIGHT}
          >
            <div>
              {!isFullScreen && (
                <ShipmentMapFullScreenBtn
                  onClick={displayMapFullScreenModal}
                  isFullScreen={isFullScreen}
                >
                  <MapZoomIcon size="70px" />
                </ShipmentMapFullScreenBtn>
              )}
              {isFullScreen && (
                <ShipmentMapFullScreenBtn
                  onClick={hideMapFullScreenModal}
                  isFullScreen={isFullScreen}
                >
                  <MapReduceIcon size="70px" />
                </ShipmentMapFullScreenBtn>
              )}
            </div>
          </ShipmentDetailMapControl>
        )}
      </>
    </Map>
  );
};

export default ShipmentMap;
