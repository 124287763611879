// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  ButtonV2 as Button,
  WalletIcon,
  Text,
  styled,
  useTheme,
  Stack,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const ContributionDetailInProgress = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 20px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  padding: 12px 14px;
`;

const WalletIconContainer = styled(Box)`
  height: 48px;
  width: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

const WalletWaitingIconContainer = styled(WalletIconContainer)`
  background-color: ${({ theme }) => theme.palette.custom.secondary.yellow[10]};
`;

interface IContributionWarningToComplete {
  onClickEdtAccountInformation: () => void;
}

const ContributionWarningToComplete = ({
  onClickEdtAccountInformation,
}: IContributionWarningToComplete) => {
  const { t } = useTranslation('contribution');
  const theme = useTheme();

  return (
    <ContributionDetailInProgress>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <WalletWaitingIconContainer>
            <WalletIcon color={theme.palette.custom.secondary.yellow[100]} />
          </WalletWaitingIconContainer>
          <Stack pl="1.5rem">
            <Text variant="titleXS" color="custom.secondary.yellow.100">
              {t(
                'contributionsTab.contributionsList.detail.waitingForBankInformation.title'
              )}
            </Text>
            <Text variant="bodyTextS" color="custom.secondary.yellow.100">
              {t(
                'contributionsTab.contributionsList.detail.waitingForBankInformation.text'
              )}
            </Text>
          </Stack>
        </Stack>
        <Button
          color="warning"
          onClick={onClickEdtAccountInformation}
          data-testid="contributionList-detail-button-edit-account-information"
        >
          {t(
            'contributionsTab.contributionsList.detail.button.editAccountInformation'
          )}
        </Button>
      </Stack>
    </ContributionDetailInProgress>
  );
};

export default ContributionWarningToComplete;
