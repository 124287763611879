// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useQuery } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';

import { Offer } from '../../types/offer';

export const getOffer = async ({
  partnershipId,
  makeRequest,
}: {
  partnershipId?: string;
  makeRequest: any;
}): Promise<Offer> => {
  if (!partnershipId) {
    throw new Error('PartnershipId is required');
  }

  const { data, error } = await makeRequest({
    path: `me/partners/${partnershipId}/offer`,
  });

  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnType = typeof getOffer;

type UsePartnershipOfferOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useOffer = ({ config }: UsePartnershipOfferOptions = {}) => {
  const { partnerId } = useParams();

  const [, makeRequest] = useImperativeRequest('deviceV3');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['partnership', 'offer', partnerId],
    queryFn: () => getOffer({ partnershipId: partnerId, makeRequest }),
  });
};
