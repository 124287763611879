// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  InformationIcon,
  ModalV2 as Modal,
  useTheme,
  Stack,
  Text,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { isIOS } from 'react-device-detect';
import { Trans, useTranslation } from 'react-i18next';

export const BrowserWarning = ({
  openWarningModal,
  handleClick,
}: {
  openWarningModal: boolean;
  handleClick: () => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['shipments', 'general']);

  return (
    <Modal
      open={openWarningModal}
      setOpen={() => {}}
      hideCloseIcon
      bottomPosition="0"
      topPosition="auto"
      borderRadiusCustom="1.25rem 1.25rem 0rem 0rem"
    >
      <Stack height="20rem" alignItems="center" paddingBottom="1.5rem">
        <InformationIcon
          color={theme.palette.custom.secondary.yellow[50]}
          size="2rem"
        />
        <Text variant="titleM" py="1rem">
          {t(
            'shipments:anonymous.mobile.navigatorError.warningDeviceTitleMessage'
          )}
        </Text>
        <Stack direction="row" spacing={1}>
          <Text variant="bodyTextM" textAlign="center">
            <Trans
              t={t}
              i18nKey="shipments:anonymous.mobile.navigatorError.warningDeviceMessage"
              components={{
                1: (
                  <span
                    style={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                    }}
                    key={0}
                  />
                ),
              }}
              values={{
                browser: isIOS
                  ? t('general:browserSafari')
                  : t('general:browserChrome'),
              }}
            />
          </Text>
        </Stack>
      </Stack>
      <Button color="warning" fullWidth onClick={handleClick}>
        {t('shipments:anonymous.mobile.navigatorError.warningCtaModal')}
      </Button>
    </Modal>
  );
};
