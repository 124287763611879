// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import {
  Text,
  Stack,
  useTheme,
  NewTooltip as Tooltip,
  DownloadDocumentIcon,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const TooltipCertificate = () => {
  const { t } = useTranslation('contribution');
  const theme = useTheme();

  const crispHandleClick = useCallback(() => {
    if ((window as any).$crisp) {
      const CRISP = (window as any).$crisp;

      CRISP.push(['safe', true]);
      CRISP.push(['do', 'chat:open']);
    }
  }, []);

  return (
    <Tooltip
      id="certificate-tooltip"
      placement="bottom-start"
      title={
        <>
          <Text variant="titleM" color="custom.neutral.black.100" mb=".625rem">
            {t('contributionsTab.contributionsList.info.certificate.title')}
          </Text>
          <Stack>
            <Text variant="bodyTextS" color="custom.neutral.black.100">
              {t('contributionsTab.contributionsList.info.certificate.action')}
              <span
                style={{
                  color: theme.palette.custom.primary['100'],
                  textDecoration: 'underline',
                  marginLeft: '3px',
                  cursor: 'pointer',
                }}
                onClick={() => crispHandleClick()}
              >
                {t(
                  'contributionsTab.contributionsList.info.certificate.cxTeam'
                )}
              </span>
            </Text>
          </Stack>
        </>
      }
      sx={{
        width: '275px',
      }}
    >
      <DownloadDocumentIcon color={theme.palette.custom.primary[100]} />
    </Tooltip>
  );
};

export default TooltipCertificate;
