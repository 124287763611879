// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  MapReduceIcon,
  MapZoomIcon,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import { InfoWindow, Marker, Polyline } from '@react-google-maps/api';
import SmallBoxImg from 'assets/mapMarkerBox.png';
import RecipientImg from 'assets/mapMarkerRecipient.png';
import SenderImg from 'assets/mapMarkerSender.png';
import Map from 'components/molecules/Map';
import ShipmentAnonymousDetailMapControl from 'components/molecules/shipmentDetail/ShipmentAnonymousDetailMapControl';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import useGetShipmentAnonymousGeoPath from 'hooks/useGetShipmentAnonymousGeoPath';
import { IPosition } from 'models/position';
import { IShipmentAnonymousData } from 'models/shipment';
import { useTranslation } from 'react-i18next';

import { AddressToStringAnonymous } from './AddressAnonymous';
import GMInfoWindowContent from '../atoms/GMInfoWindowContent';

const ShipmentAnonymousMapFullScreenBtn = styled('div', {
  shouldForwardProp: prop => prop !== 'isFullScreen',
})<{ isFullScreen: boolean }>`
  margin-right: ${({ isFullScreen }) => (!isFullScreen ? '12px' : '12px')};
  margin-top: ${({ isFullScreen }) => (!isFullScreen ? '12px' : '0')};
  cursor: pointer;
`;

interface IShipmentAnonymousMap {
  shipmentData: IShipmentAnonymousData;
  displayMapFullScreenModal?: () => void;
  hideMapFullScreenModal?: () => void;
  isFullScreen?: boolean;
}

const getPositions = (shipmentData: IShipmentAnonymousData) => {
  const boxLat = shipmentData.geo_path.coordinates[0]?.latitude;
  const boxLong = shipmentData.geo_path.coordinates[0]?.longitude;
  const senderLat = shipmentData.shipment.sender.address.position?.latitude;
  const senderLong = shipmentData.shipment.sender.address.position?.longitude;
  const recipientLat =
    shipmentData.shipment.recipient.address.position?.latitude;
  const recipientLong =
    shipmentData.shipment.recipient.address.position?.longitude;
  let boxPos, senderPos, recipientPos;

  if (boxLat && boxLong) {
    boxPos = { position: { lat: boxLat, lng: boxLong } } as IPosition;
  }
  if (senderLat && senderLong) {
    senderPos = { position: { lat: senderLat, lng: senderLong } } as IPosition;
  }
  if (recipientLat && recipientLong) {
    recipientPos = {
      position: { lat: recipientLat, lng: recipientLong },
    } as IPosition;
  }

  return { boxPos, senderPos, recipientPos };
};

const ShipmentAnonymousMap = ({
  shipmentData,
  displayMapFullScreenModal,
  hideMapFullScreenModal,
  isFullScreen = false,
}: IShipmentAnonymousMap) => {
  const theme = useTheme();
  const { t } = useTranslation(['shipments', 'general']);

  const polylineOptions = {
    strokeColor: theme.palette.custom.primary[100],
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
  };

  const [, getCountryNameFromCode] = useCountryCodesWrapper();
  const geoPath = useGetShipmentAnonymousGeoPath(
    shipmentData.geo_path.coordinates
  );

  const [boxPos, setBoxPos] = useState<IPosition>();
  const [senderPos, setSenderPos] = useState<IPosition>();
  const [recipientPos, setRecipientPos] = useState<IPosition>();

  const [bounds, setBounds] = useState<any>();

  const [infoWindows, setInfoWindows] = useState({
    sender: false,
    recipient: false,
  });
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
  const updateIsMapLoaded = useCallback(
    (isMapLoadedInfo: boolean) => {
      setIsMapLoaded(isMapLoadedInfo);
    },
    [setIsMapLoaded]
  );

  useEffect(() => {
    const { boxPos, senderPos, recipientPos } = getPositions(shipmentData);

    setBoxPos(boxPos);
    setSenderPos(senderPos);
    setRecipientPos(recipientPos);

    setBounds(
      [boxPos, senderPos, recipientPos]
        .concat(
          geoPath.map(
            (path: { lat: number; lng: number }) =>
              ({
                position: { lat: path.lat, lng: path.lng },
              } as IPosition)
          )
        )
        .filter(bound => bound)
    );
  }, [
    shipmentData,
    geoPath,
    setBoxPos,
    setSenderPos,
    setRecipientPos,
    setBounds,
  ]);
  const handleHover = useCallback(
    (type: 'sender' | 'recipient', value: boolean) => {
      setInfoWindows(prev => ({ ...prev, [type]: value }));
    },
    []
  );

  return (
    <Map
      bounds={bounds}
      height={isFullScreen ? '100%' : '312px'}
      isFullScreen={isFullScreen}
      isMapLoadedAction={updateIsMapLoaded}
      minZoom={3}
      maxZoom={18}
    >
      <>
        {geoPath && <Polyline path={geoPath} options={polylineOptions} />}
        {boxPos && (
          <Marker
            icon={SmallBoxImg}
            position={boxPos.position}
            data-testid="boxMarker"
          />
        )}
        {senderPos && (
          <Marker
            data-testid="senderMarker"
            position={senderPos.position}
            icon={SenderImg}
            onMouseOver={() => handleHover('sender', true)}
            onMouseOut={() => handleHover('sender', false)}
          >
            {infoWindows.sender && (
              <InfoWindow options={{ disableAutoPan: true }}>
                <GMInfoWindowContent
                  header={t('shipments:sender')}
                  content={AddressToStringAnonymous(
                    {
                      ...shipmentData.shipment.sender.address,
                      country: getCountryNameFromCode(
                        shipmentData.shipment.sender.address.country_code
                      ),
                    },
                    t
                  )}
                />
              </InfoWindow>
            )}
          </Marker>
        )}
        {recipientPos && (
          <Marker
            data-testid="recipientMarker"
            position={recipientPos.position}
            icon={RecipientImg}
            onMouseOver={() => handleHover('recipient', true)}
            onMouseOut={() => handleHover('recipient', false)}
          >
            {infoWindows.recipient && (
              <InfoWindow options={{ disableAutoPan: true }}>
                <GMInfoWindowContent
                  header={t('shipments:recipient')}
                  content={AddressToStringAnonymous(
                    {
                      ...shipmentData.shipment.recipient.address,
                      country: getCountryNameFromCode(
                        shipmentData.shipment.recipient.address.country_code
                      ),
                    },
                    t
                  )}
                />
              </InfoWindow>
            )}
          </Marker>
        )}
        {isMapLoaded && window.google && (
          <ShipmentAnonymousDetailMapControl
            position={window.google.maps.ControlPosition.TOP_RIGHT}
          >
            <div>
              {!isFullScreen && (
                <ShipmentAnonymousMapFullScreenBtn
                  onClick={displayMapFullScreenModal}
                  isFullScreen={isFullScreen}
                >
                  <MapZoomIcon size="70px" />
                </ShipmentAnonymousMapFullScreenBtn>
              )}
              {isFullScreen && (
                <ShipmentAnonymousMapFullScreenBtn
                  onClick={hideMapFullScreenModal}
                  isFullScreen={isFullScreen}
                >
                  <MapReduceIcon size="70px" />
                </ShipmentAnonymousMapFullScreenBtn>
              )}
            </div>
          </ShipmentAnonymousDetailMapControl>
        )}
      </>
    </Map>
  );
};

export default ShipmentAnonymousMap;
