// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { styled } from '@livingpackets/design-system-react-next';
import { IContactAddress } from 'models/contactAddress';

import useCountryCodesWrapper from '../../../hooks/useCountryCodeWrapper';

const ContactCompanyName = styled('p')`
  color: ${({ theme }) => theme.palette.custom.neutral.black[90]};
  ${({ theme }) => theme.fontStyles.titleXS}
  margin-bottom: 5px;
`;

const ContactAddressInfoText = styled(ContactCompanyName)`
  color: ${({ theme }) => theme.palette.custom.neutral.black[90]};
  ${({ theme }) => theme.fontStyles.bodyTextS}
  margin-bottom: 0;
  white-space: nowrap;
`;

interface IContactAddressInfo {
  contactAddress: IContactAddress;
}

const ContactAddressInfo = ({ contactAddress }: IContactAddressInfo) => {
  const [, getCountryNameFromCode] = useCountryCodesWrapper();

  function getAddressFirstLine() {
    return contactAddress.houseNumber + ' ' + contactAddress.street + ',';
  }

  function getAddressSecondLine() {
    return (
      contactAddress.postalCode +
      ' ' +
      contactAddress.city +
      ', ' +
      getCountryNameFromCode(contactAddress.countryCode)
    );
  }

  return (
    <>
      {ContactCompanyName && (
        <ContactCompanyName>{contactAddress.company}</ContactCompanyName>
      )}
      <ContactAddressInfoText>{getAddressFirstLine()}</ContactAddressInfoText>
      <ContactAddressInfoText>{getAddressSecondLine()}</ContactAddressInfoText>
    </>
  );
};

export default ContactAddressInfo;
