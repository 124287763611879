// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Text, styled } from '@livingpackets/design-system-react-next';
import { animated } from 'react-spring';

interface AddressProps {
  houseName?: string;
  street: string;
  city: string;
  postalCode: string;
  place: string;
  openingHours: string;
  phoneNumber?: string;
  country: string;
  image: string;
}

const ShowroomCard = styled(animated.div, {
  shouldForwardProp: prop =>
    prop !== 'image' && prop !== 'isOpen' && prop !== 'height',
})<{
  image: string;
  isOpen: boolean;
  height: number;
}>`
  height: ${props => (props.isOpen ? 28.188 : props.height)}rem;
  width: 100%;
  -webkit-transition: height 0.3s ease-in-out;
  -moz-transition: height 0.3s ease-in-out;
  -o-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
  border-radius: 0.625rem;
  background-image: url(${props => props.image});
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  margin-bottom: 1.5rem;
`;
const CardWrapper = styled('div')<{ isOpen: boolean }>`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 0.625rem;
  padding: 2rem 1.5rem;
`;
const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;
const TitlesWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;
const IconWrapper = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.primary[10]};
  border-radius: 0.625rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AddressWrapper = styled('div')`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const LPPointCard = ({
  place,
  houseName,
  street,
  city,
  postalCode,
  openingHours,
  phoneNumber,
  country,
  image,
  isOpen,
  onClick,
  height,
  icon,
}: AddressProps & {
  isOpen: boolean;
  onClick: () => void;
  height: number;
  icon: JSX.Element;
}) => (
  <ShowroomCard isOpen={isOpen} image={image} onClick={onClick} height={height}>
    <CardWrapper isOpen={isOpen}>
      <Wrapper>
        <TitlesWrapper>
          <Text variant="titleL">{city}</Text>
          <Text mt="0.5rem" variant="bodyTextS" color="custom.neutral.black.50">
            {place}
          </Text>
        </TitlesWrapper>
        <IconWrapper>
          <IconWrapper>{icon}</IconWrapper>
        </IconWrapper>
      </Wrapper>
      <AddressWrapper>
        <Text variant="titleS"> {houseName} </Text>
        <Text variant="titleS">{street}</Text>
        <Text variant="titleS">
          {postalCode} {city} {country}
        </Text>
      </AddressWrapper>
      <Text>{openingHours}</Text>
      <Text mt="0.5rem" variant="titleS">
        {phoneNumber}
      </Text>
    </CardWrapper>
  </ShowroomCard>
);

export default LPPointCard;
