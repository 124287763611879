// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { styled } from '@livingpackets/design-system-react-next';
import getOnGooglePlayDe from 'assets/google-play-de.svg';
import getOnGooglePlayEn from 'assets/google-play-en.svg';
import getOnGooglePlayFr from 'assets/google-play-fr.svg';
import { PLAY_STORE_APP_LINK } from 'configs';
import { useTranslation } from 'react-i18next';

const StyledA = styled('a')`
  display: block;
`;

export function GetOnGooglePlay() {
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const badgeByLanguage =
    currentLanguage === 'fr'
      ? getOnGooglePlayFr
      : currentLanguage === 'en'
      ? getOnGooglePlayEn
      : getOnGooglePlayDe;

  return (
    <StyledA href={PLAY_STORE_APP_LINK} data-testid="get-on-google-play">
      <img src={badgeByLanguage} alt="Get it on Google Play" />
    </StyledA>
  );
}
