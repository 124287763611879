// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';

const BulletStep = styled('span')`
  background: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  border-radius: 1.25rem;
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
`;

interface RemoteInstructionMessageProps {
  message: string | React.ReactNode;
  stepIndex?: number;
  title: string;
  isLoading?: boolean;
}

const RemoteInstructionMessage = ({
  title,
  stepIndex,
  message,
  isLoading,
}: RemoteInstructionMessageProps) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      marginBottom="1.5rem"
    >
      <Box
        display="flex"
        style={{ gap: '.62rem' }}
        alignItems="center"
        justifyContent="center"
      >
        {stepIndex && (
          <BulletStep color={theme.palette.custom.neutral.black[100]}>
            {stepIndex}
          </BulletStep>
        )}
        <Text variant="titleXL">{title}</Text>
      </Box>

      <Text
        variant="bodyTextM"
        sx={{
          textAlign: 'center',
          paddingTop: '.625rem',
        }}
      >
        {message}
      </Text>
    </Box>
  );
};

export default RemoteInstructionMessage;
