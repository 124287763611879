// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import {
  IShipment,
  ShipmentContractState,
  ShipmentContractStateEnum,
  ShipmentSearchFieldEnum,
} from 'models/shipment';

const shipmentListPaginationCurrentKey = 'shipmentListPaginationCurrent';
const shipmentListPaginationCurrentDefaultValue = {
  pageSize: 6,
  orderDirection: 'DESC',
  orderColumn: 'createdAt',
  page: 1,
};

// Local storage key for date filter
const shipmentListDateCurrentKey = 'shipmentListDateCurrent';
const shipmentListStartTimeCurrentKey = 'shipmentListStartTimeCurrent';
const shipmentListEndTimeCurrentKey = 'shipmentListEndTimeCurrent';

const shipmentListSearchCurrentKey = 'shipmentListSearchCurrent';
const shipmentListSearchCurrentDefaultValue = '';

const shipmentListSearchFieldCurrentKey = 'shipmentListSearchFieldCurrent';
const shipmentListSearchFieldCurrentDefaultValue =
  ShipmentSearchFieldEnum.SHIPMENT_NAME;

const useShipmentList = () => {
  const isShipmentRowCompactInList = useCallback(
    (shipmentContractState: ShipmentContractState): boolean =>
      [
        ShipmentContractStateEnum.PURCHASED,
        ShipmentContractStateEnum.ARRIVED,
      ].includes(shipmentContractState as ShipmentContractStateEnum),
    []
  );

  const isShipmentPurchased = useCallback(
    (shipment: IShipment): boolean =>
      shipment.contract_state === ShipmentContractStateEnum.PURCHASED,
    []
  );

  const isShipmentDeliveryKeyShared = (shipment: IShipment): boolean =>
    shipment.hasAnonymousTracking === true;

  const isShipmentDeployed = useCallback(
    (shipment: IShipment): boolean =>
      shipment.contractState === ShipmentContractStateEnum.DEPLOYED,
    []
  );

  const isShipmentArrived = useCallback(
    (shipment: IShipment): boolean =>
      shipment.contractState === ShipmentContractStateEnum.ARRIVED,
    []
  );

  const isShipmentOutgoing = useCallback(
    (shipment: IShipment): boolean =>
      shipment.returnShipment === null && shipment.originalShipment === null,
    []
  );

  const isShipmentOutgoingAndReturn = useCallback(
    (shipment: IShipment): boolean =>
      shipment.returnShipment !== null && shipment.originalShipment === null,
    []
  );

  const isShipmentReturn = useCallback(
    (shipment: IShipment): boolean =>
      shipment.returnShipment === null && shipment.originalShipment !== null,
    []
  );

  const getShipmentListPaginationCurrent = useCallback(
    () =>
      sessionStorage.getItem(shipmentListPaginationCurrentKey)
        ? JSON.parse(
            sessionStorage.getItem(shipmentListPaginationCurrentKey) as string
          )
        : shipmentListPaginationCurrentDefaultValue,
    []
  );

  const setShipmentListPaginationCurrent = useCallback(
    (shipmentListPaginationInfo: any) => {
      sessionStorage.setItem(
        shipmentListPaginationCurrentKey,
        JSON.stringify(shipmentListPaginationInfo)
      );
    },
    []
  );

  const resetShipmentListPaginationCurrent = useCallback(() => {
    sessionStorage.setItem(
      shipmentListPaginationCurrentKey,
      JSON.stringify(shipmentListPaginationCurrentDefaultValue)
    );
  }, []);

  const getShipmentListSearchCurrent = useCallback(
    (): string =>
      sessionStorage.getItem(shipmentListSearchCurrentKey)
        ? (sessionStorage.getItem(shipmentListSearchCurrentKey) as string)
        : shipmentListSearchCurrentDefaultValue,
    []
  );

  const setShipmentListSearchCurrent = useCallback(
    (shipmentListSearch: string) => {
      sessionStorage.setItem(shipmentListSearchCurrentKey, shipmentListSearch);
    },
    []
  );

  // Saves filter values by date in local storage
  const setShipmentListDateCurrent = ({
    date,
    endTime,
    startTime,
  }: {
    date: string;
    startTime: string;
    endTime: string;
  }) => {
    sessionStorage.setItem(shipmentListDateCurrentKey, date);
    sessionStorage.setItem(shipmentListStartTimeCurrentKey, startTime);
    sessionStorage.setItem(shipmentListEndTimeCurrentKey, endTime);
  };

  // Retrieves filter values by date from local storage
  const getShipmentListDateCurrent = () => {
    const date =
      sessionStorage.getItem(shipmentListDateCurrentKey) ||
      shipmentListSearchCurrentDefaultValue;

    const startTime =
      sessionStorage.getItem(shipmentListStartTimeCurrentKey) ||
      shipmentListSearchCurrentDefaultValue;

    const endTime =
      sessionStorage.getItem(shipmentListEndTimeCurrentKey) ||
      shipmentListSearchCurrentDefaultValue;

    return {
      date,
      startTime,
      endTime,
    };
  };

  const resetShipmentListSearchCurrent = useCallback(() => {
    sessionStorage.setItem(
      shipmentListSearchCurrentKey,
      shipmentListSearchCurrentDefaultValue
    );
  }, []);

  const getShipmentListSearchFieldCurrent = useCallback(
    (): ShipmentSearchFieldEnum =>
      sessionStorage.getItem(shipmentListSearchFieldCurrentKey)
        ? (sessionStorage.getItem(
            shipmentListSearchFieldCurrentKey
          ) as ShipmentSearchFieldEnum)
        : shipmentListSearchFieldCurrentDefaultValue,
    []
  );

  const setShipmentListSearchFieldCurrent = useCallback(
    (shipmentListSearchField: ShipmentSearchFieldEnum) => {
      sessionStorage.setItem(
        shipmentListSearchFieldCurrentKey,
        shipmentListSearchField
      );
    },
    []
  );

  const resetShipmentListSearchFieldCurrent = useCallback(() => {
    sessionStorage.setItem(
      shipmentListSearchFieldCurrentKey,
      shipmentListSearchFieldCurrentDefaultValue
    );
  }, []);

  return {
    isShipmentRowCompactInList,
    isShipmentPurchased,
    isShipmentDeployed,
    isShipmentArrived,
    isShipmentOutgoing,
    isShipmentOutgoingAndReturn,
    isShipmentReturn,
    getShipmentListPaginationCurrent,
    setShipmentListPaginationCurrent,
    resetShipmentListPaginationCurrent,
    getShipmentListSearchCurrent,
    setShipmentListSearchCurrent,
    resetShipmentListSearchCurrent,
    getShipmentListSearchFieldCurrent,
    setShipmentListSearchFieldCurrent,
    resetShipmentListSearchFieldCurrent,
    isShipmentDeliveryKeyShared,
    setShipmentListDateCurrent,
    getShipmentListDateCurrent,
  };
};

export default useShipmentList;
