// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useState } from 'react';

import {
  ButtonV2 as Button,
  Stack,
} from '@livingpackets/design-system-react-next';
import { DetailsSpan } from 'components/atoms/ShipmentAtoms';
import ViewShipmentLabelModal from 'components/molecules/modals/ViewShipmentLabelModal';
import ShipmentIdData from 'components/molecules/ShipmentIdData';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import useAppState, { tokenSelector } from 'stores/appState/useAppState';

interface IShipmentInformation {
  shipment: IShipment;
}

const ShipmentInformation = ({ shipment, ...rest }: IShipmentInformation) => {
  const { t } = useTranslation('shipments');
  const appToken = useAppState(tokenSelector);

  const isReturn = !!shipment.original_shipment;
  const hasReturn = !!shipment.return_shipment;

  const [labelImageBase64, setLabelImageBase64] = useState<string | null>(null);

  const [
    showShipmentLabelFullScreenModal,
    setShowShipmentLabelFullScreenModal,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (shipment.label_url) {
      fetch(shipment.label_url, {
        method: 'GET',
        headers: { Authorization: `Bearer ${appToken}` },
      })
        .then((response: Response) => response.blob())
        .then((blob: Blob) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            if (typeof reader.result === 'string') {
              setLabelImageBase64(reader.result);
            }
          };
        });
    }
  }, [shipment, appToken]);

  return (
    <Stack>
      <DetailsSpan data-testid="current-card-headline">
        {t(
          isReturn
            ? 'returnShipment'
            : hasReturn
            ? 'initialShipment'
            : 'shipmentInfo'
        )}
      </DetailsSpan>
      <ShipmentIdData shipment={shipment} />
      <Button
        fullWidth
        variant="secondary"
        onClick={() => setShowShipmentLabelFullScreenModal(true)}
        sx={{
          mt: '1.5rem',
        }}
        disabled={!labelImageBase64}
      >
        {t('detail.summary.information.showLabelButton')}
      </Button>
      {labelImageBase64 && (
        <ViewShipmentLabelModal
          visible={showShipmentLabelFullScreenModal}
          onDismiss={() => setShowShipmentLabelFullScreenModal(false)}
          shipmentLabelUrl={labelImageBase64}
          hideLabelFullScreenModal={() =>
            setShowShipmentLabelFullScreenModal(false)
          }
        />
      )}
    </Stack>
  );
};

export default ShipmentInformation;
