// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useQuery } from '@tanstack/react-query';
import useImperativeRequest from 'hooks/useImperativeRequest';
import { ExtractFnReturnType, QueryConfig } from 'lib/react-query';
import { useParams } from 'react-router-dom';

import { Partnership } from '../../types/partnership';

export const getPartnership = async ({
  partnershipId,
  makeRequest,
}: {
  partnershipId?: string;
  makeRequest: any;
}): Promise<Partnership> => {
  const { data, error } = await makeRequest({
    path: `me/partners/${partnershipId}`,
  });

  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnType = typeof getPartnership;

type UsePartnershipOptions = {
  partnershipId?: string;
  config?: QueryConfig<QueryFnType>;
};

export const usePartnership = ({
  partnershipId,
  config,
}: UsePartnershipOptions = {}) => {
  const { partnerId } = useParams();

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const [, makeRequest] = useImperativeRequest('lpVillageV3');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    // Unique and stable query key (used for caching)
    queryKey: ['partnership', id],
    queryFn: () => getPartnership({ partnershipId: id, makeRequest }),
  });
};
