// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import {
  ChevronDownIcon,
  Stack,
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import Divider from 'components/atoms/Divider';
import { FetchStatus } from 'models/enums';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { space } from 'styled-system';

import TablePagination from './TablePagination';
import TransactionListItem from './TransactionListItem';
import { UseFetchResource } from '../../shared-components/useFetchResource';
import {
  OrderDirection,
  Paginated,
  PaginationParams,
} from '../../shared-components/usePagination';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin-top: 3rem;
  margin-bottom: 1.406rem;
  ${space}
`;
const HeaderWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: 0.75rem;
`;

const SortWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.custom.neutral.black[50]};
  background: none;
  border: none;
  display: inline-flex;
  align-items: center;
  ${space}
`;

interface ShipmentListProps {
  isPartner?: boolean;
  shipments: UseFetchResource<Paginated<IShipment>>;
  pagination: PaginationParams;
  onOrderChange: (order: {
    orderColumn: string;
    orderDirection: OrderDirection;
  }) => void;
  onPaginationChange: (pagination: { page: number }) => void;
}

function TransactionsList({
  pagination,
  onOrderChange,
  onPaginationChange,
  shipments,
}: ShipmentListProps) {
  const { t } = useTranslation(['shipments', 'buyAndRent']);
  const theme = useTheme();
  const handleReorder = useCallback(
    (column: string) => {
      if (shipments.state !== FetchStatus.Success) return;
      onPaginationChange({ page: 1 });
      if (shipments.resource.orderColumn === column) {
        onOrderChange({
          orderColumn: column,
          orderDirection:
            shipments.resource.orderDirection === 'ASC' ? 'DESC' : 'ASC',
        });

        return;
      }
      onOrderChange({ orderColumn: column, orderDirection: 'DESC' });
    },
    [shipments, onOrderChange, onPaginationChange]
  );

  const renderRows = () => {
    if (shipments.state === FetchStatus.Success) {
      return shipments.resource.items.map((shipment: any) => (
        <TransactionListItem key={shipment.id} shipment={shipment} />
      ));
    }
  };
  const order = 'ASC';

  return (
    <Wrapper>
      <HeaderWrapper>
        <Stack direction="row" width="22%" ml="2.5rem">
          <SortWrapper onClick={() => handleReorder('receivedAt')}>
            <Text variant="titleS" color="custom.neutral.black.100" mr="0.5rem">
              {t('buyAndRent:buyNRent.shipments.deliveryDate')}
            </Text>
            <ChevronDownIcon
              color={theme.palette.custom.primary[100]}
              style={{
                transform:
                  shipments.state === FetchStatus.Success &&
                  order === shipments.resource.orderDirection
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                transition: '500ms',
              }}
            />
          </SortWrapper>
        </Stack>
        <Stack direction="row" width="25%" alignItems="center">
          <Text variant="titleS" color="custom.neutral.black.50">
            {t('buyAndRent:buyNRent.shipments.startingCity')}
          </Text>
        </Stack>
        <Stack direction="row" width="25%">
          <SortWrapper>
            <Text variant="titleS" color="custom.neutral.black.50">
              {t('buyAndRent:buyNRent.shipments.endingCity')}
            </Text>
          </SortWrapper>
        </Stack>
        <Stack direction="row" width="14%">
          <SortWrapper>
            <Text variant="titleS" color="custom.neutral.black.50">
              {t('buyAndRent:buyNRent.shipments.amountOfRent')}
            </Text>
          </SortWrapper>
        </Stack>
      </HeaderWrapper>

      <Divider direction="horizontal" />

      <div>{renderRows()}</div>
      {shipments.state === FetchStatus.Success ? (
        <TablePagination
          currentPage={pagination.page - 1}
          pageOptions={[
            ...Array(Math.round(shipments.resource.totalPages)).keys(),
          ]}
          gotoPage={page => {
            onPaginationChange({ page: page + 1 });
          }}
          nextPage={() => {
            onPaginationChange({ page: pagination.page + 1 });
          }}
          previousPage={() => {
            onPaginationChange({ page: pagination.page - 1 });
          }}
        />
      ) : null}
    </Wrapper>
  );
}

export default TransactionsList;
