// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { AxiosError } from 'axios';
import { IShipment } from 'models/shipment';
import { ITos } from 'models/tos';
import { IUser } from 'models/user';
import setLoadingAction from 'stores/appState/actions/setLoadingAction';

import { TLoadingElement, TRoles, TState, TTosState } from './useAppState';

export type TAction =
  | { type: 'SET_USER'; payload: IUser }
  | {
      type: 'SET_TOS';
      payload: {
        state: TTosState;
        currentTosPaaS: ITos | null;
        upcomingTosPaaS: ITos | null;
        currentTosFinancial: ITos | null;
        upcomingTosFinancial: ITos | null;
        firstTime: boolean;
      };
    }
  | { type: 'SET_LOADING'; payload: TLoadingElement }
  | { type: 'SET_TOKEN'; payload: string }
  | { type: 'SET_ANONYMOUS_TOKEN'; payload: string }
  | { type: 'SET_ROLES'; payload: TRoles }
  | { type: 'SET_ERROR'; payload: Error | null }
  | { type: 'SET_API_ERROR'; payload: AxiosError | null }
  | { type: 'DELETE_SHIPMENT'; payload: IShipment };

const reducer: (state: TState, input: TAction) => TState = (
  state: TState,
  input: TAction
) => {
  switch (input.type) {
    case 'SET_USER':
      return { user: input.payload } as TState;
    case 'SET_TOS':
      return { tos: input.payload } as TState;
    case 'SET_LOADING':
      return {
        loading: setLoadingAction(state.loading, input.payload),
      } as TState;
    case 'SET_TOKEN':
      return { token: input.payload } as TState;
    case 'SET_ANONYMOUS_TOKEN':
      return { anonymousToken: input.payload } as TState;
    case 'SET_ERROR':
      return { error: input.payload } as TState;
    case 'SET_API_ERROR':
      return { apiError: input.payload } as TState;
    default:
      return state;
  }
};

export default reducer;
