// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export type TApiEnv = 'dev' | 'prod' | 'test';

export const getApiServices = (env: TApiEnv) => {
  switch (env) {
    case 'dev':
      return {
        lpVillage: 'https://api.dev.livingpackets.com/lpvillage/',
        lpVillageV3: 'https://api.dev.livingpackets.com/v3/lpvillage/',
        lpAccount: 'https://api.dev.livingpackets.com/lpaccount/',
        lpAccountV3: 'https://api.dev.livingpackets.com/v3/lpaccount',
        shipment: 'https://api.dev.livingpackets.com/shipment/',
        shipmentV2: 'https://api.dev.livingpackets.com/shipment/api/v2',
        shipmentV3: 'https://api.dev.livingpackets.com/v3/shipment',
        device: 'https://api.dev.livingpackets.com/device/',
        deviceV3: 'https://api.dev.livingpackets.com/v3/device/',
        tracking: 'https://api.dev.livingpackets.com/tracking/',
        trackingV2: 'https://api.dev.livingpackets.com/tracking/api/v2',
        trackingV3: 'https://api.dev.livingpackets.com/v3/tracking/',
        tos: 'https://api.dev.livingpackets.com/tos/',
        auth: 'https://auth.dev.livingpackets.com/',
      } as const;
    case 'test':
      return {
        lpVillage: 'https://api.test.livingpackets.com/lpvillage/',
        lpVillageV3: 'https://api.test.livingpackets.com/v3/lpvillage/',
        lpAccount: 'https://api.test.livingpackets.com/lpaccount/',
        lpAccountV3: 'https://api.test.livingpackets.com/v3/lpaccount',
        shipment: 'https://api.test.livingpackets.com/shipment/',
        shipmentV2: 'https://api.test.livingpackets.com/shipment/api/v2',
        shipmentV3: 'https://api.test.livingpackets.com/v3/shipment',
        device: 'https://api.test.livingpackets.com/device/',
        deviceV3: 'https://api.test.livingpackets.com/v3/device/',
        tracking: 'https://api.test.livingpackets.com/tracking/',
        trackingV2: 'https://api.test.livingpackets.com/tracking/api/v2',
        trackingV3: 'https://api.test.livingpackets.com/v3/tracking/',
        tos: 'https://api.test.livingpackets.com/tos/',
        auth: 'https://auth.test.livingpackets.com/',
      } as const;
    default:
      return {
        lpVillage: 'https://api.livingpackets.com/lpvillage/',
        lpVillageV3: 'https://api.livingpackets.com/v3/lpvillage/',
        lpAccount: 'https://api.livingpackets.com/lpaccount/',
        lpAccountV3: 'https://api.livingpackets.com/v3/lpaccount',
        shipment: 'https://api.livingpackets.com/shipment/',
        shipmentV2: 'https://api.livingpackets.com/shipment/api/v2',
        shipmentV3: 'https://api.livingpackets.com/v3/shipment',
        device: 'https://api.livingpackets.com/device/',
        deviceV3: 'https://api.livingpackets.com/v3/device/',
        tracking: 'https://api.livingpackets.com/tracking/',
        trackingV2: 'https://api.livingpackets.com/tracking/api/v2',
        trackingV3: 'https://api.livingpackets.com/v3/tracking/',
        tos: 'https://api.livingpackets.com/tos/',
        auth: 'https://auth.livingpackets.com/',
      } as const;
  }
};

export const getShopRoles = (env: TApiEnv) => {
  switch (env) {
    case 'dev':
      return {
        member: '',
        owner: '',
      };
    case 'test':
      return {
        member: '21184742-0d57-11eb-9376-0a07e28b42f0',
        owner: '211846e8-0d57-11eb-9376-0a07e28b42f0',
      };
    default:
      return {
        member: '143e2520-19d5-11eb-877a-069342e47874',
        owner: '143e24b2-19d5-11eb-877a-069342e47874',
      };
  }
};
