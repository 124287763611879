// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Box, Stack, Text } from '@livingpackets/design-system-react-next';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import { Trans, useTranslation } from 'react-i18next';

interface AddressNamePart {
  firstName: string;
  first_name?: string;
  lastName: string;
  last_name?: string;
  company: string;
}

interface AddressWithoutCountry extends AddressNamePart {
  houseNumber?: string;
  street: string;
  street2?: string;
  city: string;
  postalCode: string;
  postal_code?: string;
  country_code?: string;
}

type AddressWithCountryCodeOrCountry = AddressWithoutCountry &
  ({ country?: string } & { countryCode?: string }) & { country_code?: string };

type AddressWithCountry = AddressWithoutCountry & { country: string };

function AddressToString(
  address: Omit<AddressWithCountry, keyof AddressNamePart>,
  t: any
) {
  return t('general:addressInline', { ...address });
}

export interface AddressNameProps {
  address: AddressWithCountryCodeOrCountry;
  inline?: boolean;
  style?: React.CSSProperties;
}

export function AddressName({
  address,
  inline,
  style = {},
}: AddressNameProps): JSX.Element {
  const { t } = useTranslation('general');
  const hasCompany = address.company !== undefined && address.company !== '';

  const firstName = address.firstName || address.first_name!;
  const lastName = address.lastName || address.last_name!;
  const hasName = firstName !== undefined && lastName !== undefined;

  if (inline)
    return (
      <Stack direction="row">
        {hasName && (
          <Text
            variant="bodyTextXS"
            color="custom.neutral.black.100"
            style={style}
          >
            {t('fullName', {
              firstName: firstName,
              lastName: lastName,
            })}
          </Text>
        )}
        {hasCompany && (
          <Text
            variant="titleXS"
            color="custom.neutral.black.100"
            style={{ marginLeft: '0', ...style }}
          >
            {hasName && ', '}
            {address.company}
          </Text>
        )}
      </Stack>
    );

  return (
    <Stack>
      {hasCompany && (
        <Text variant="titleXS" color="custom.neutral.black.100" style={style}>
          {address.company}
        </Text>
      )}
      {hasName && (
        <Text
          variant="bodyTextXS"
          color="custom.neutral.black.100"
          sx={{ marginTop: '0', ...style }}
        >
          {t('fullName', {
            firstName: firstName,
            lastName: lastName,
          })}
        </Text>
      )}
    </Stack>
  );
}

export interface AddressProps {
  address: AddressWithCountryCodeOrCountry;
  inline?: boolean;
  countryInline?: boolean;
  style?: React.CSSProperties;
}

export function Address({
  address,
  inline = false,
  countryInline = false,
  style = {},
}: AddressProps): JSX.Element {
  const [, getCountryNameFromCode] = useCountryCodesWrapper();
  const { t } = useTranslation('general');
  const countryCode = address.country_code || address.countryCode;

  const country = countryCode
    ? getCountryNameFromCode(countryCode)
    : countryCode || address.country || '';

  const postalCode = address.postalCode || address.postal_code!;

  if (inline) {
    return (
      <Text
        variant="bodyTextXS"
        color="custom.neutral.black.100"
        sx={{ marginTop: '0', ...style }}
      >
        {AddressToString(
          {
            street: address.street,
            // Add space here to avoid having it when not present
            street2: address.street2 ? ` ${address.street2}` : '',
            city: address.city,
            postalCode,
            country,
          },
          t
        )}
      </Text>
    );
  }

  return (
    <Box
      component="div"
      sx={{
        fontFamily: 'TT-Norms-Pro',
        fontWeight: 400,
        fontSize: '.75rem',
        lineHeight: '1rem',
      }}
    >
      <Trans
        ns="general"
        i18nKey={countryInline ? 'addressCountryInline' : 'address'}
        components={{
          AddressLine: (
            <Box
              component="span"
              style={{ ...style }}
              sx={{ display: 'block' }}
            />
          ),
        }}
        values={{
          street: address.street,
          street2: address.street2,
          city: address.city,
          postalCode,
          country,
        }}
      />
    </Box>
  );
}
