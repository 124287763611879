// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  ButtonV2 as Button,
  Stack,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';
import { LayoutProps } from 'styled-system';

import imageName from '../../assets/card_B_R.png';

const BoxDiv = styled(Stack)<LayoutProps>`
  background-color: ${({ theme }) => theme.palette.custom.primary['100']};
  border-radius: 20px;
  width: 35%;
  max-width: 20.375rem;
  height: 17.5rem;
  margin-left: 2rem;
  background-image: url(${imageName});
  background-position: center left;
  background-size: 100%;
  background-repeat: no-repeat;
`;

const BuyAndRentBox = () => {
  const { t } = useTranslation(['general', 'contribution', 'buyAndRent']);
  let url = t('general:websiteUrl.url');

  return (
    <BoxDiv alignItems="center" justifyContent="center">
      <Box maxWidth="10rem" pl="1.25rem">
        <Text
          color="custom.neutral.white.pure"
          variant="titleL"
          sx={{
            maxWidth: '10rem',
          }}
        >
          {t('buyAndRent:buyNRent.websiteLink.buyBoxes')}
        </Text>
        <Button
          sx={{
            mt: '2.875rem',
          }}
          variant="secondary"
          onClick={() => window.open(url, '_blank', 'noopener noreferrer')}
        >
          {t('buyAndRent:buyNRent.websiteLink.learnMore')}
        </Button>
      </Box>
    </BoxDiv>
  );
};

export default BuyAndRentBox;
