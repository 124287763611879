// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import { Tag } from '@livingpackets/design-system-react';
import {
  BillingAddressIcon,
  Box,
  Text,
  styled,
  Stack,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import useCountryCodesWrapper from '../../hooks/useCountryCodeWrapper';
import useMeAddresses from '../../hooks/useMeAddresses';
import useToastMessages from '../../hooks/useToastMessages';
import { ILPAccountAddress } from '../../models/address';
import {
  IBillingAddressForm,
  INITIAL_STATE_BILLING_ADDRESS_FORM,
} from '../../models/billingAddress';
import BillingAddressForm from '../molecules/Form/BillingAddressForm';
import BillingAddressCheckModal from '../molecules/modals/BillingAddressCheckModal';

const BillingAddressInfoContainer = styled(Stack)`
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.palette.custom.neutral.black[10]};
  padding: 24px 34px;
  border-radius: 14px;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
`;

export interface ProfileInformationBillingAddressPros {
  forceDisplayBillingAddressForm: number;
}

const ProfileInformationBillingAddress = ({
  forceDisplayBillingAddressForm,
}: ProfileInformationBillingAddressPros) => {
  const { t } = useTranslation(['accountInformation', 'messages']);
  const { error: toastError, success: toastSuccess } = useToastMessages();

  const [, getCountryNameFromCode] = useCountryCodesWrapper();

  const [currentBillingAddress, setCurrentBillingAddress] =
    useState<ILPAccountAddress>();

  const [isGetBillingAddressDone, setIsGetBillingAddressDone] =
    useState<boolean>(false);

  const [displayBillingAddressForm, setDisplayBillingAddressForm] =
    useState<boolean>(false);

  const [isBillingAddressFormValid, setIsBillingAddressFormValid] =
    useState<boolean>(false);

  const [billingAddressFormData, setBillingAddressFormData] =
    useState<IBillingAddressForm>(INITIAL_STATE_BILLING_ADDRESS_FORM);

  const [displayBillingAddressCheckModal, setDisplayBillingAddressCheckModal] =
    useState<boolean>(false);

  const {
    getBillingAddressList,
    createBillingAddress,
    updateAddress,
    getAccountAddressFromBillingAddressForm,
    getBillingAddressFormFromAccountAddress,
  } = useMeAddresses();

  useEffect(() => {
    getBillingAddressList().then(({ data }) => {
      if (data) {
        const billingAddress = data.items.find((address: any) =>
          address.types ? address.types.includes('billing') : false
        );

        if (billingAddress) {
          setCurrentBillingAddress(billingAddress);

          setBillingAddressFormData(
            getBillingAddressFormFromAccountAddress(billingAddress)
          );
        }
      }

      setIsGetBillingAddressDone(true);
    });
  }, [getBillingAddressFormFromAccountAddress, getBillingAddressList]);

  useEffect(() => {
    if (forceDisplayBillingAddressForm) {
      if (!currentBillingAddress) {
        setDisplayBillingAddressForm(true);
      } else {
        setDisplayBillingAddressCheckModal(true);
      }
    }
  }, [currentBillingAddress, forceDisplayBillingAddressForm]);

  const handleBillingAddressFormSubmit = useCallback(() => {
    if (currentBillingAddress) {
      updateAddress({
        ...currentBillingAddress,
        ...getAccountAddressFromBillingAddressForm(billingAddressFormData),
      })
        .then(response => {
          if (response.success) {
            toastSuccess('messages:updateBillingAddressSuccess.message', {
              wide: true,
            });

            setCurrentBillingAddress(response.data);
          } else {
            toastError('messages:updateBillingAddressError.message', {
              wide: true,
            });
          }
        })
        .finally(() => setDisplayBillingAddressForm(false));
    } else {
      createBillingAddress(
        getAccountAddressFromBillingAddressForm(billingAddressFormData)
      )
        .then(response => {
          if (response.success) {
            toastSuccess('messages:createBillingAddressSuccess.message', {
              wide: true,
            });

            setCurrentBillingAddress(response.data);
          } else {
            toastError('messages:createBillingAddressError.message', {
              wide: true,
            });
          }
        })
        .finally(() => setDisplayBillingAddressForm(false));
    }
  }, [
    billingAddressFormData,
    createBillingAddress,
    currentBillingAddress,
    getAccountAddressFromBillingAddressForm,
    toastError,
    toastSuccess,
    updateAddress,
  ]);

  return (
    <Stack>
      <Stack direction="row" alignItems="center">
        <BillingAddressIcon />
        <Text variant="titleL" marginLeft="12px">
          {t('accountInformation:emptyState.billingAddresses.title')}
        </Text>
      </Stack>
      {isGetBillingAddressDone && !currentBillingAddress && (
        <Text variant="bodyTextS" mb="18px" mt="18px">
          {t('accountInformation:emptyState.billingAddresses.description')}
        </Text>
      )}
      {isGetBillingAddressDone && (
        <>
          {currentBillingAddress && !displayBillingAddressForm ? (
            <>
              <BillingAddressInfoContainer>
                <Text variant="titleXS" color="custom.neutral.black.100">
                  {currentBillingAddress.firstName}
                </Text>
                <Text variant="titleXS" color="custom.neutral.black.100">
                  {currentBillingAddress.lastName}
                </Text>
                <Box>
                  <Tag state="default" text="Billing Address" />
                </Box>
                <Stack>
                  <Text variant="bodyTextS" color="custom.neutral.black.90">
                    {currentBillingAddress.street}
                  </Text>
                  <Text variant="bodyTextS" color="custom.neutral.black.90">{`${
                    currentBillingAddress.postalCode
                  } ${currentBillingAddress.city}, ${getCountryNameFromCode(
                    currentBillingAddress.countryCode
                  )}`}</Text>
                </Stack>
              </BillingAddressInfoContainer>
              <Box
                display="flex"
                justifyContent="flex-end"
                marginTop="25px !important"
              >
                <Button
                  variant="tertiary"
                  onClick={() => setDisplayBillingAddressForm(true)}
                  disabled={!isGetBillingAddressDone}
                  data-testid="accountInformation-button-update-billing-address"
                >
                  {t(
                    'accountInformation:fullState.billingAddresses.button.changeBillingAddress'
                  )}
                </Button>
              </Box>
            </>
          ) : (
            <>
              {displayBillingAddressForm ? (
                <Box marginTop="2rem">
                  <BillingAddressForm
                    defaultValues={
                      currentBillingAddress
                        ? getBillingAddressFormFromAccountAddress(
                            currentBillingAddress
                          )
                        : undefined
                    }
                    onValuesChanged={setBillingAddressFormData}
                    isFormValidChanged={setIsBillingAddressFormValid}
                  />
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    marginTop="32px !important"
                  >
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setDisplayBillingAddressForm(false);
                      }}
                      sx={{ width: '15.625rem' }}
                      data-testid="billing-address-form-cancel-button"
                    >
                      {t(
                        'accountInformation:form.billingAddress.buttons.cancel'
                      )}
                    </Button>
                    <Button
                      variant="primary"
                      disabled={!isBillingAddressFormValid}
                      sx={{ width: '15.625rem' }}
                      onClick={handleBillingAddressFormSubmit}
                      data-testid="billing-address-form-save-button"
                    >
                      {t('accountInformation:form.billingAddress.buttons.save')}
                    </Button>
                  </Stack>
                </Box>
              ) : (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  marginTop="32px !important"
                >
                  <Button
                    variant="tertiary"
                    onClick={() => setDisplayBillingAddressForm(true)}
                    disabled={!isGetBillingAddressDone}
                    data-testid="accountInformation-button-add-billing-address"
                  >
                    {t(
                      'accountInformation:emptyState.billingAddresses.button.addBillingAddress'
                    )}
                  </Button>
                </Box>
              )}
            </>
          )}
        </>
      )}
      <BillingAddressCheckModal
        isVisible={displayBillingAddressCheckModal}
        onCancelClick={() => {
          setDisplayBillingAddressCheckModal(false);
        }}
      />
    </Stack>
  );
};
export default ProfileInformationBillingAddress;
