// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useMemo, useState } from 'react';

import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const DotsWrapper = styled('div')`
  padding: 0 1px;
  width: 1.5rem;
  text-align: center;
`;

const Dots = ({ t }: { t: any }) => (
  <DotsWrapper>
    <Text variant="bodyTextS">{t('general:pagination:threeDots')}</Text>
  </DotsWrapper>
);

const StyledPageIcon = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})<{ active: boolean }>`
  cursor: pointer;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.125rem;
  margin: 0 2px;
  color: ${({ active, theme }) =>
    active
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.black[50]};
  border-bottom: ${({ active, theme }) =>
    active ? `1px ${theme.palette.custom.primary[100]} solid` : '0'};

  &:hover {
    background: ${({ theme }) => theme.palette.custom.primary[10]};
    color: ${({ theme, active }) =>
      active
        ? theme.palette.custom.primary[100]
        : theme.palette.custom.neutral.black[100]};
  }
`;

const SelectorWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 1px;
`;

const ArrowSpan = styled('span', {
  shouldForwardProp: prop => prop !== 'isDisabled',
})<{ isDisabled: boolean }>`
  transform: translateY(1px);
  color: ${({ theme, isDisabled }) =>
    isDisabled
      ? theme.palette.custom.neutral.black[30]
      : theme.palette.custom.neutral.black[50]};
  cursor: ${({ isDisabled }) => (isDisabled ? `auto` : `pointer`)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? `none` : `auto`)};
  padding: 0 5px;

  &:hover {
    color: ${({ theme, isDisabled }) =>
      isDisabled
        ? theme.palette.custom.neutral.black[30]
        : theme.palette.custom.neutral.black[100]};
  }
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  padding-top: 1.5rem;
`;

const PageSelector = ({ gotoPage, index, currentPage }: any) => (
  <SelectorWrapper>
    <StyledPageIcon
      onClick={() => gotoPage(index)}
      active={currentPage === index}
    >
      <Text variant="bodyTextS">{index + 1}</Text>
    </StyledPageIcon>
  </SelectorWrapper>
);

const getPagination = ({
  pageOptions,
  gotoPage,
  currentPage,
  state,
  t,
}: any) => {
  if (pageOptions.length < 7) {
    // SIMPLE PAGINATION
    return pageOptions.map((option: any) => (
      <PageSelector
        key={option}
        gotoPage={gotoPage}
        index={option}
        currentPage={currentPage}
      />
    ));
  }
  switch (state) {
    case 'start':
      return (
        <>
          {pageOptions.slice(0, 5).map((option: any) => (
            <PageSelector
              key={option}
              gotoPage={gotoPage}
              index={option}
              currentPage={currentPage}
            />
          ))}
          <Dots t={t} />
          <PageSelector
            gotoPage={gotoPage}
            index={pageOptions.slice(-1)[0]}
            currentPage={currentPage}
          />
        </>
      );
    case 'middle':
      return (
        <>
          <PageSelector
            gotoPage={gotoPage}
            index={pageOptions[0]}
            currentPage={currentPage}
          />
          <Dots t={t} />
          {pageOptions
            .slice(currentPage - 1, currentPage + 2)
            .map((option: any) => (
              <PageSelector
                key={option}
                gotoPage={gotoPage}
                index={option}
                currentPage={currentPage}
              />
            ))}
          <Dots t={t} />
          <PageSelector
            gotoPage={gotoPage}
            index={pageOptions.slice(-1)[0]}
            currentPage={currentPage}
          />
        </>
      );
    case 'end':
      return (
        <>
          {' '}
          <PageSelector
            gotoPage={gotoPage}
            index={pageOptions[0]}
            currentPage={currentPage}
          />
          <Dots t={t} />
          {pageOptions.slice(-5).map((option: any) => (
            <PageSelector
              key={option}
              gotoPage={gotoPage}
              index={option}
              currentPage={currentPage}
            />
          ))}
        </>
      );
    default:
      return <div></div>;
  }
};

interface ITablePagination {
  previousPage: () => void;
  nextPage: () => void;
  currentPage: number;
  pageOptions: string | number[];
  gotoPage: (input: number) => void;
}

const TablePagination = ({
  previousPage,
  nextPage,
  currentPage,
  pageOptions,
  gotoPage,
}: ITablePagination) => {
  const { t } = useTranslation('general');

  const [state, setState] = useState<'start' | 'middle' | 'end'>('start');

  const lastPage = useMemo(() => pageOptions.length - 1, [pageOptions.length]);

  useEffect(() => {
    if (currentPage < 4) {
      setState('start');

      return;
    }
    if (lastPage - currentPage < 4) {
      setState('end');

      return;
    }
    setState('middle');
  }, [lastPage, pageOptions.length, currentPage]);

  const handleFFBack = () => {
    switch (state) {
      case 'end':
        gotoPage(lastPage - 4);

        return;
      case 'middle':
        gotoPage(currentPage - 2);
    }
  };

  const handleBack = () => previousPage();

  const handleForward = () => nextPage();

  const handleFFForward = () => {
    switch (state) {
      case 'start':
        gotoPage(5);

        return;
      case 'middle':
        gotoPage(currentPage + 2);

        return;
    }
  };

  return (
    <Wrapper>
      <ArrowSpan
        isDisabled={pageOptions.length < 7 || state === 'start'}
        onClick={handleFFBack}
      >
        <ChevronDoubleLeftIcon />
      </ArrowSpan>
      <ArrowSpan isDisabled={currentPage === 0} onClick={handleBack}>
        <ChevronLeftIcon />
      </ArrowSpan>

      {getPagination({ pageOptions, gotoPage, currentPage, state, t })}
      <ArrowSpan isDisabled={currentPage === lastPage} onClick={handleForward}>
        <ChevronRightIcon />
      </ArrowSpan>
      <ArrowSpan
        isDisabled={pageOptions.length < 7 || state === 'end'}
        onClick={handleFFForward}
      >
        <ChevronDoubleRightIcon />
      </ArrowSpan>
    </Wrapper>
  );
};

export default TablePagination;
