// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import { ProductModel, ProductStateModel } from 'models/product';
import { getProductType } from 'utils/product';
import { create } from 'zustand';

interface IProductStore {
  product?: ProductModel;
  setProduct: (product: ProductModel) => void;
}

export const useProductStore = create<IProductStore>(set => ({
  setProduct: (product: ProductModel) => set({ product }),
}));

const useProductDetail = () => {
  const [, makeRequest] = useImperativeRequest('deviceV3');
  const [, makeRequestTrackingV3] = useImperativeRequest('trackingV3');
  const { error: toastError } = useToastMessages();

  const getProductDetail = useCallback(
    async (
      activePartnerId: string,
      lpUi: string,
      displayErrorToast = false
    ) => {
      const { error, data } = await makeRequest({
        path: `me/partners/${activePartnerId}/products/${lpUi}/state`,
        method: 'get',
      });
      if (error) {
        if (displayErrorToast) {
          toastError('messages:getBoxDetailError.message');
        }

        return { success: false, product: null };
      }

      if (data.contract_state_updated_at) {
        data.contract_state_updated_at =
          new Date(data.contract_state_updated_at).getTime() / 1000;
      }

      if (data.location_updated_at) {
        data.location_updated_at =
          new Date(data.location_updated_at).getTime() / 1000;
      }

      if (data.partner_since) {
        data.partner_since = new Date(data.partner_since).getTime() / 1000;
      }

      if (data.updated_at) {
        data.updated_at = new Date(data.updated_at).getTime() / 1000;
      }

      data.productType = getProductType(data.article_code);

      return {
        success: true,
        product: data as ProductModel,
      };
    },
    [makeRequest, toastError]
  );

  const getProductState = useCallback(
    async (shipmentId: string) => {
      const { error, data } = await makeRequestTrackingV3({
        path: `me/shipments/${shipmentId}/product/state`,
        method: 'GET',
      });
      if (error) {
        return { success: false, productState: null };
      }

      if (data.last_update) {
        data.last_update =
          typeof data.last_update === 'string'
            ? new Date(data.last_update).getTime() / 1000
            : data.last_update;
      }

      return {
        success: true,
        productState: data as ProductStateModel,
      };
    },
    [makeRequestTrackingV3]
  );

  return {
    getProductDetail,
    getProductType,
    getProductState,
  };
};

export default useProductDetail;
