// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  ButtonV2 as Button,
  WalletIcon,
  Text,
  styled,
  useTheme,
  Stack,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const ContributionDetailInProgress = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 20px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  padding: 12px 14px;
`;

const WalletIconContainer = styled(Box)`
  height: 24px;
  width: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
`;

const DoubleIconContainer = styled(Box)`
  width: 36px;
  height: 33px;
  position: relative;
`;

const WalletWaitingIconContainer = styled(WalletIconContainer)`
  background-color: ${({ theme }) => theme.palette.custom.secondary.yellow[10]};
  position: absolute;
  left: 0;
  bottom: 0;
`;

const WalletFailIconContainer = styled(WalletIconContainer)`
  background-color: ${({ theme }) => theme.palette.custom.error.red[10]};
  position: absolute;
  top: 0;
  right: 0;
`;

interface IContributionWarningToComplete {
  onClickEdtAccountInformation: () => void;
}

const ContributionWarningUpdateBankInformation = ({
  onClickEdtAccountInformation,
}: IContributionWarningToComplete) => {
  const { t } = useTranslation('contribution');
  const theme = useTheme();

  return (
    <ContributionDetailInProgress>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <DoubleIconContainer>
            <WalletWaitingIconContainer>
              <WalletIcon color={theme.palette.custom.secondary.yellow[100]} />
            </WalletWaitingIconContainer>
            <WalletFailIconContainer>
              <WalletIcon color={theme.palette.custom.error.red[100]} />
            </WalletFailIconContainer>
          </DoubleIconContainer>
          <Stack pl="1.5rem">
            <Text variant="titleXS" color="custom.secondary.yellow.100">
              {t(
                'contributionsTab.contributionsList.detail.waitingUpdateBankInformation.title'
              )}
            </Text>
            <Text variant="bodyTextS" color="custom.secondary.yellow.100">
              {t(
                'contributionsTab.contributionsList.detail.waitingUpdateBankInformation.text'
              )}
            </Text>
          </Stack>
        </Stack>
        <Button
          color="warning"
          onClick={onClickEdtAccountInformation}
          data-testid="contributionList-detail-button-edit-account-information"
        >
          {t(
            'contributionsTab.contributionsList.detail.button.editAccountInformation'
          )}
        </Button>
      </Stack>
    </ContributionDetailInProgress>
  );
};

export default ContributionWarningUpdateBankInformation;
