// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { intlFormat, fromUnixTime } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { SentryCaptureException } from 'helpers/tracking';
import { useTranslation } from 'react-i18next';
import { getLocale } from 'utils/date/locale';

const useIntlFormatTimestamp = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useCallback(
    (timestamp: number | string, formatOptions?: any) => {
      let time: number;

      if (typeof timestamp === 'string') {
        time = new Date(timestamp).getTime() / 1000;
      } else {
        time = timestamp;
      }

      try {
        return intlFormat(
          fromUnixTime(time),
          {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: getLocale(language) === enGB,
            ...formatOptions,
          },
          {
            locale: getLocale(language).code,
          }
        );
      } catch (e) {
        //FIXME: CHANGE TO DASHBOARDV2 ERROR IMPLEMENTATION
        SentryCaptureException(new Error('Invalid time value ' + time), null);

        return '--';
      }
    },
    [language]
  );
};

export default useIntlFormatTimestamp;
