// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  Divider,
  Grid,
  PhoneIcon,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import { CountryCircle } from 'features/shipments';
import { normalizeKey } from 'helpers/i18n';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from 'utils/phoneNumber';

const countries = {
  germany: {
    translations: {
      de: 'Deutschland',
      fr: 'Allemagne',
    },
    name: 'Germany',
    phoneCode: '+49',
    alpha2Code: 'DE',
  },
  switzerland: {
    translations: {
      de: 'Schweiz',
      fr: 'Suisse',
    },
    name: 'Switzerland',
    phoneCode: '+41',
    alpha2Code: 'CH',
  },
  franceOrUK: {
    translations: {
      de: 'Frankreich',
      fr: 'France',
    },
    name: 'France',
    phoneCode: '+33',
    alpha2Code: 'FR',
  },
};

export const ContactUsItem = ({
  prefix,
  hideBubble = false,
}: {
  prefix: string;
  hideBubble?: boolean;
}) => {
  const { t } = useTranslation(['profileSettings']);

  return (
    <Grid
      item
      sx={{
        p: '1rem',
        borderRadius: '.625rem',
        border: '.0625rem solid',
        borderColor: 'custom.neutral.black.8',
        background: 'custom.neutral.white.pure',
        height: '100%',
      }}
    >
      <Grid container gap="1rem" flexWrap="nowrap">
        {!hideBubble && (
          <Grid item>
            <CountryCircle country={(countries as any)[prefix]} />
          </Grid>
        )}
        <Grid item flex={1}>
          <Grid container direction="column">
            <Grid item>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingBottom=".75rem"
                sx={{
                  paddingBottom: {
                    mobile: '.75rem',
                    tablet: '0',
                    desktop: '0',
                  },
                }}
              >
                <Text variant="titleL">
                  {t(
                    normalizeKey(
                      `profileSettings:contactModal.${prefix}.country`
                    )
                  )}
                </Text>
                <Stack direction="row" gap=".625rem">
                  <Text
                    variant="bodyTextS"
                    sx={{ display: { mobile: 'none', tablet: 'block' } }}
                    color="custom.neutral.black.90"
                  >
                    {t(
                      normalizeKey(
                        `profileSettings:contactModal.${prefix}.openingDays`
                      )
                    )}
                  </Text>
                  <Divider
                    orientation="vertical"
                    sx={{ color: 'red', height: '.875rem' }}
                  />
                  <Text
                    variant="bodyTextS"
                    sx={{ display: { mobile: 'none', tablet: 'block' } }}
                    color="custom.neutral.black.90"
                  >
                    {t(
                      normalizeKey(
                        `profileSettings:contactModal.${prefix}.openingHours`
                      )
                    )}
                  </Text>
                </Stack>
              </Stack>
            </Grid>
            <Grid item>
              <Box
                component="div"
                sx={{
                  fontFamily: 'TT-Norms-Pro',
                  fontWeight: 400,
                  fontSize: '.875rem',
                  lineHeight: '1.125rem',
                  color: {
                    mobile: 'custom.neutral.black.100',
                    desktop: 'custom.neutral.black.50',
                    tablet: 'custom.neutral.black.50',
                  },
                }}
              >
                {t(
                  normalizeKey(
                    `profileSettings:contactModal.${prefix}.contactUs`
                  )
                )}

                <Box
                  sx={{
                    paddingTop: { mobile: '.5rem', tablet: '', desktop: '' },
                    display: { mobile: 'flex', tablet: 'none' },
                  }}
                  flexDirection="row"
                >
                  {t(
                    normalizeKey(
                      `profileSettings:contactModal.${prefix}.openingDays`
                    )
                  )}{' '}
                  :{' '}
                  {t(
                    normalizeKey(
                      `profileSettings:contactModal.${prefix}.openingHours`
                    )
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item pt=".25rem">
              <Stack direction="row" alignItems="end" gap=".25rem">
                <PhoneIcon />
                <Text
                  variant="bodyTextM"
                  sx={{
                    textDecoration: 'underline',
                    color: {
                      mobile: 'custom.primary.100',
                      desktop: 'custom.neutral.black.100',
                      tablet: 'custom.neutral.black.100',
                    },
                  }}
                  component="a"
                  href={`tel:${(
                    t(
                      normalizeKey(
                        `profileSettings:contactModal.${prefix}.phoneNumber`
                      )
                    ) as string
                  ).replace(/\s/g, '')}`}
                >
                  {formatPhoneNumber(
                    t(
                      normalizeKey(
                        `profileSettings:contactModal.${prefix}.phoneNumber`
                      )
                    )
                  )}
                </Text>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
