// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useContext, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Grid,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import { RHFSelectCountry } from 'components/form/SelectCountry';
import WarnBeforeQuit from 'components/form/WarnBeforeQuit';
import { AddressNotFoundGeocodingError } from 'core/errors/AddressNotFoundGeocodingError';
import useToastMessages from 'hooks/useToastMessages';
import { isEmpty } from 'lodash/fp';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { address } from 'schemas/addresses';

import { SpecificContact } from './SpecificContact';
import { WbqContext } from '../../../routes/Partnership';
import { Address } from '../../../types/address';
import { ExpandableSubmitButton } from '../ExpandableSubmitButton';

export const AddressForm = ({
  alwaysShowActions,
  showIdentificationName = false,
  initialValues,
  disabled,
  mutate,
  isPending,
  onDismiss,
  onSave,
}: {
  alwaysShowActions?: boolean;
  showIdentificationName?: boolean;
  initialValues: Partial<Address>;
  disabled?: boolean;
  mutate: any;
  isPending: boolean;
  onDismiss?: () => void;
  onSave?: () => void;
}) => {
  const { t } = useTranslation(['forms', 'account']);
  const { pathname } = useLocation();

  const { error: toastError } = useToastMessages();

  // Only show on Partnership / Information tab
  const showHelperText = pathname.match(/partnership\/information/);

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, errors, isValid },
  } = useForm<Partial<Address>>({
    resolver: yupResolver(address()),
    defaultValues: initialValues,
    mode: 'onTouched',
  });

  const { queue, setQueue } = useContext(WbqContext);

  const onCancel = () => {
    reset(initialValues);
    onDismiss && onDismiss();
  };

  const onSubmit = useCallback(async () => {
    handleSubmit((values: any) =>
      mutate(values, {
        onError: (e: Error) => {
          if (e instanceof AddressNotFoundGeocodingError) {
            toastError('messages:updateAddressNotFoundGeocodingError.message');
          } else {
            toastError('messages:updateAddressError.message');
          }
        },
        onSuccess: () => {
          onSave && onSave();
        },
      })
    )();
  }, [handleSubmit, mutate, onSave, toastError]);

  useEffect(() => {
    setQueue((queue: any) => ({
      ...queue,
      address: {
        isDirty,
        onSubmit,
        reset,
      },
    }));

    // Clean up queue on unmount
    return () => {
      setQueue((queue: any) => ({
        ...queue,
        address: undefined,
      }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return (
    <Grid container spacing="0.875rem">
      <WarnBeforeQuit queue={queue} />
      {/* Identification Name */}
      {showIdentificationName && (
        <Grid item mobile={12}>
          <Controller
            name="company"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                disabled={disabled}
                label={t('forms:identification_name.label')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
      )}
      {/* Street */}
      <Grid item mobile={12}>
        <Controller
          name="street"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              disabled={disabled}
              label={t('forms:address.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      {/* Additional Address */}
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="street2"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              disabled={disabled}
              label={t('forms:additionalAddress.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      {/* Building */}
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="building"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              disabled={disabled}
              label={t('forms:building.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      {/* Zipcode */}
      <Grid item mobile={12} tablet={2}>
        <Controller
          name="postalCode"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              disabled={disabled}
              label={t('forms:postalCode.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      {/* City */}
      <Grid item mobile={12} tablet={4}>
        <Controller
          name="city"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              disabled={disabled}
              label={t('forms:city.label')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      {/* Country */}
      <Grid item mobile={12} tablet={6}>
        <RHFSelectCountry required control={control} disabled={disabled} />
      </Grid>
      {/* Phone / Email */}
      <SpecificContact
        initialValues={initialValues}
        control={control}
        disabled={disabled}
      />
      {/* Submit Buttons */}
      <ExpandableSubmitButton
        alwaysShowActions={alwaysShowActions}
        disabled={disabled}
        prefix="addresses"
        isDirty={isDirty}
        isValid={isEmpty(errors) && isValid}
        isSubmitting={isPending}
        onCancel={onCancel}
        onSubmit={onSubmit}
        // Display custom text only for MAIN address
        textOnLeftSide={
          showHelperText
            ? t('account:partnership.information.address.bottomHelperBis')
            : undefined
        }
      />
    </Grid>
  );
};
