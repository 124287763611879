// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ButtonV2 as Button,
  Grid,
  HexagoneExclamationMarkError,
  ModalV2,
  Text,
} from '@livingpackets/design-system-react-next';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const ErrorDeleteAccountContactSupport = ({ open, setOpen }: Props) => {
  const { t } = useTranslation('account');

  return (
    <ModalV2 open={open} setOpen={setOpen} hideCloseIcon>
      <Grid
        container
        direction="column"
        sx={{
          height: '100%',
          maxWidth: { mobile: '100%', tablet: '23.375rem' },
          justifyContent: 'space-around',
        }}
      >
        <Grid container direction="column" alignItems="center" gap="1rem">
          <HexagoneExclamationMarkError />
          <Text variant="titleL" align="center">
            {t('personal.deletion.deleteAccountFinalError.title')}
          </Text>
          <Text
            variant="bodyTextM"
            color="custom.neutral.black.90"
            align="center"
          >
            <Trans
              ns="account"
              i18nKey="personal.deletion.deleteAccountFinalError.message"
            />
          </Text>
          <Grid container justifyContent="center">
            <Button
              variant="flat"
              color="critical"
              onClick={() => setOpen(false)}
            >
              {t('personal.deletion.cancelButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalV2>
  );
};
