// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { ApiFilterGroupModel, ApiFilterModel } from 'models/api';

const useApiHelper = () => {
  const getFilterStringFromGroupList = useCallback(
    (filterGroupList: Array<ApiFilterGroupModel>): string | undefined => {
      if (filterGroupList.length === 0) {
        return undefined;
      }

      let filter = '';

      filterGroupList.forEach(
        (filterGroup: ApiFilterGroupModel, filterGroupIndex: number) => {
          if (filterGroup.list.length === 0) {
            return;
          }

          filter = filter + '(';

          filterGroup.list.forEach(
            (filterData: ApiFilterModel, index: number) => {
              filter =
                filter +
                filterData.fieldName +
                filterData.filterType +
                "'" +
                filterData.fieldData +
                "'";

              if (filterGroup.list.length !== index + 1) {
                const nextFieldSeparator = filterData.nextFieldSeparator
                  ? ' ' + filterData.nextFieldSeparator + ' '
                  : ' AND ';
                filter = filter + nextFieldSeparator;
              }
            }
          );

          filter = filter + ')';

          if (filterGroupList.length !== filterGroupIndex + 1) {
            const nextGroupSeparator = filterGroup.nextGroupSeparator
              ? ' ' + filterGroup.nextGroupSeparator + ' '
              : ' AND ';

            filter = filter + nextGroupSeparator;
          }
        }
      );

      return filter;
    },
    []
  );

  return { getFilterStringFromGroupList };
};

export default useApiHelper;
