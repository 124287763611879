// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { APIServices, TAPIService } from 'configs';
import useAppState, { tokenSelector } from 'stores/appState/useAppState';

import { useImperativeRequestHook } from '../shared-components';

const useImperativeRequest = (service: TAPIService) => {
  const token = useAppState(tokenSelector);

  return useImperativeRequestHook({ url: APIServices[service], token: token });
};

export default useImperativeRequest;
