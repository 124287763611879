// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { HttpRequestMethod } from 'enums/httpRequest';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import useActiveLanguage from 'hooks/useGetActiveLanguage';
import useImperativeRequest from 'hooks/useImperativeRequest';
import {
  IFiguralCreatePartnerParcelRequest,
  IFiguralInsurance,
} from 'models/figuralInsurance';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const useLpVillageService = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpVillageV3');
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const activeLang = useActiveLanguage();
  const [, getCountryNameFromCode] = useCountryCodesWrapper();

  const getFiguralCreatePartnerParcelRequestFromFiguralInsuranceData =
    useCallback(
      (
        figuralInsuranceData: IFiguralInsurance
      ): IFiguralCreatePartnerParcelRequest => ({
        carrier_name: figuralInsuranceData.carrierName,
        company: activePartner.name,
        country_destination:
          figuralInsuranceData.arrivalCountryCode.toLowerCase(),
        country_origin: figuralInsuranceData.departureCountryCode.toLowerCase(),
        currency: 'eur',
        email: figuralInsuranceData.sender.email,
        first_name: figuralInsuranceData.sender.firstName,
        full_address:
          figuralInsuranceData.sender.address +
          ', ' +
          figuralInsuranceData.sender.zipCode +
          ' ' +
          figuralInsuranceData.sender.city +
          ', ' +
          getCountryNameFromCode(
            figuralInsuranceData.sender.countryCode.toUpperCase()
          ),
        internal_reference: '',
        lang: activeLang,
        last_name: figuralInsuranceData.sender.lastName,
        parcel_value: figuralInsuranceData.goodsValue
          ? figuralInsuranceData.goodsValue * 100
          : 100,
        send_email: true,
        tracking_number: figuralInsuranceData.trackingNumber,
        cart_data: { note: figuralInsuranceData.note },
        shipment_id: figuralInsuranceData.shipmentId,
      }),
      [activeLang, activePartner, getCountryNameFromCode]
    );

  const createPartnerParcelRequest = useCallback(
    async (figuralInsuranceData: IFiguralInsurance) => {
      let figuralCreatePartnerParcelRequest: IFiguralCreatePartnerParcelRequest =
        getFiguralCreatePartnerParcelRequestFromFiguralInsuranceData(
          figuralInsuranceData
        );
      const { error, data } = await makeRequest({
        path: `me/partners/${activePartner.id}/parcel_requests`,
        method: 'POST',
        body: figuralCreatePartnerParcelRequest,
      });
      if (error) {
        return { success: false, data };
      }

      return { success: true, data };
    },
    [
      makeRequest,
      activePartner.id,
      getFiguralCreatePartnerParcelRequestFromFiguralInsuranceData,
    ]
  );

  const getPartnerParcelRequest = useCallback(
    async (parcelRequestId: string) => {
      const { error, data } = await makeRequest({
        path: `me/partners/${activePartner.id}/parcel_requests/${parcelRequestId}`,
        method: 'GET',
      });
      if (error) {
        return { success: false, data };
      }

      return { success: true, data };
    },
    [makeRequest, activePartner.id]
  );

  const getPartnerParcel = useCallback(
    async (parcelId: string) => {
      const { error, data } = await makeRequest({
        path: `me/partners/${activePartner.id}/parcels/${parcelId}`,
        method: 'GET',
      });
      if (error) {
        return { success: false, data };
      }

      return { success: true, data };
    },
    [makeRequest, activePartner.id]
  );

  const selectPartnerType = useCallback(
    async (partnerBusinessType: string, companyName?: string) => {
      const { error, data } = await makeRequest({
        path: 'me/partners/type',
        method: HttpRequestMethod.POST,
        body: {
          name: companyName,
          type: partnerBusinessType,
        },
      });

      if (error) {
        return { success: false, data };
      }

      return { success: true, data };
    },
    [makeRequest]
  );

  const isPartnerAvailable = useCallback(
    async (companyName: string) => {
      const { error, data } = await makeRequest({
        path: `partners/available?name=${companyName}`,
        method: HttpRequestMethod.GET,
      });

      if (error) {
        return { success: false, data };
      }

      return { success: true, data };
    },
    [makeRequest]
  );

  return {
    loading,
    createPartnerParcelRequest,
    getPartnerParcelRequest,
    getPartnerParcel,
    selectPartnerType,
    isPartnerAvailable,
  };
};

export default useLpVillageService;
