// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import * as Sentry from '@sentry/react';
import App from 'App';
import { Auth0ProviderWithNavigate } from 'Auth0ProviderWithNavigate';
import setupI18n from 'i18n';
import initSentry from 'initSentry';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import './i18n';

import * as serviceWorker from './serviceWorker';

import './css/index.css';

import '@livingpackets/design-system-react/index.css';
import '@livingpackets/design-system-react-next/style.css';

setupI18n();

declare global {
  interface Window {
    Cypress?: any;
  }
}

initSentry();

const container = document.getElementById('root');
const root = createRoot(container!);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: '/*',
    element: (
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    ),
  },
]);

root.render(<RouterProvider router={router} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
