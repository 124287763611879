// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useEffect, useMemo } from 'react';

import find from 'lodash/find';
import get from 'lodash/get';
import { object, string } from 'yup';
import { create } from 'zustand';

const NO_TRANS_ENDPOINT =
  'https://restcountries.eu/rest/v2/all?fields=name;alpha2Code';

const WITH_TRANS_ENDPOINT =
  'https://restcountries.eu/rest/v2/all?fields=name;alpha2Code;translations';

const countryCodeSchema = object().shape({
  name: string().required(),
  alpha2Code: string().required(),
  translations: object().notRequired(),
});

type CountryCode = {
  name: string;
  alpha2Code: string;
  translations?: { [key: string]: string };
};

interface ICountryCodes {
  data: CountryCode[];
  error: string;
  loading: boolean;
}

const useCountryCodesStore = create<{
  countryCodes: ICountryCodes;
  setCountryCodes: any;
}>(set => ({
  countryCodes: { data: [], error: '', loading: true },
  setCountryCodes: (countryCodes: ICountryCodes) =>
    set(state => ({ ...state, countryCodes })),
}));

interface IUseCountryCodes {
  language?: string;
  includeTranslations?: boolean;
  localCountryCodesPath?: string;
}

const useCountryCodes = ({
  includeTranslations,
  language,
  localCountryCodesPath,
}: IUseCountryCodes = {}): [ICountryCodes, (code: string) => string] => {
  const countryCodes = useCountryCodesStore(
    (state: { countryCodes: any }) => state.countryCodes
  );
  const setCountryCodes = useCountryCodesStore(
    (state: { setCountryCodes: any }) => state.setCountryCodes
  );

  const getCountryNameFromCode = useCallback(
    (code: string): string => {
      const country = find(
        countryCodes?.data,
        (x: { alpha2Code: string }) => x.alpha2Code === code
      );
      if (!country) {
        return '';
      }
      const englishName = get(country, 'name', '');
      if (language === 'en') {
        return englishName;
      }
      if (includeTranslations && language) {
        return get(country, `translations.${language}`, englishName);
      }

      return englishName;
    },
    [countryCodes, includeTranslations, language]
  );

  const countryCodesFilePath = useMemo(() => {
    if (localCountryCodesPath) {
      return localCountryCodesPath;
    }

    return includeTranslations ? WITH_TRANS_ENDPOINT : NO_TRANS_ENDPOINT;
  }, [localCountryCodesPath, includeTranslations]);

  useEffect(() => {
    if (
      countryCodes.data &&
      countryCodes.data.length === 0 &&
      !countryCodes.error
    ) {
      fetch(countryCodesFilePath)
        .then(response => response.json())
        .then(async data => {
          try {
            await countryCodeSchema.validate(data[0]);

            return data;
          } catch (err: any) {
            setCountryCodes((prev: ICountryCodes) => ({
              ...prev,
              loading: false,
              error: err.toString(),
            }));
          }
        })
        .then(validated => setCountryCodes({ loading: false, data: validated }))
        .catch(error => {
          setCountryCodes((prev: ICountryCodes) => ({
            ...prev,
            loading: false,
            error: error.toString(),
          }));
        });
    }
  }, [
    setCountryCodes,
    countryCodes,
    includeTranslations,
    countryCodesFilePath,
  ]);

  return [countryCodes, getCountryNameFromCode];
};

export default useCountryCodes;
