// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  ButtonV2 as Button,
  Chip,
  Grid,
  Stack,
  styled,
  Text,
} from '@livingpackets/design-system-react-next';
import DeleteShipmentModal from 'components/molecules/modals/DeleteShipmentModal';
import ShipmentAddressCard from 'components/molecules/ShipmentAddressCard';
import ShipmentDetailInsuranceCard from 'components/molecules/shipmentDetail/ShipmentDetailInsuranceCard';
import ShipmentInformation from 'components/molecules/ShipmentInformation';
import SmallTabNavigation from 'components/molecules/SmallTabNavigation';
import { Offer } from 'features/account';
import { TrackingInfoCard, ShareTrackingLink } from 'features/shipments';
import isCancelledState from 'helpers/isCancelledState';
import useEditShipment from 'hooks/useEditShipment';
import useGetShipmentTracking from 'hooks/useGetShipmentTracking';
import {
  IShipment,
  IShipmentTimelineResponse,
  ITrackingHistoryResponse,
  ShipmentContractState,
  ShipmentContractStateEnum,
} from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

import ShipmentTracking from './ShipmentTracking';

const Wrapper = styled('div')`
  position: relative;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  height: 100%;

  overflow-x: auto;

  ::-webkit-scrollbar-track {
    border: 1px solid #f6f6f6;
    padding: 2px 0;
    background-color: #f6f6f6;
    border-radius: 2px;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #cccccc;
  }
`;

export const ShipmentWrapperContainer = styled('div')`
  height: inherit;
  overflow: hidden;
`;

export const ShipmentDetailNavigationContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-between;
  height: 48px;

  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;

  z-index: 2;

  margin-bottom: 32px;
`;

export const CONTRACT_STATE_SHOW_HISTORY = ['DEPLOYED', 'ARRIVED', 'ERROR'];

export enum ShipmentTabEnum {
  HISTORY = 1,
  SUMMARY = 2,
}

interface ShipmentDetailsProps {
  shipment: IShipment;
  offerData: Offer;
  onShipmentMapZoomClick: () => void;
  onCurrentTrackingDataUpdate?: (
    currentTrackingData?: ITrackingHistoryResponse
  ) => void;
}

const ShipmentDetails = ({
  shipment,
  offerData,
  onShipmentMapZoomClick,
  onCurrentTrackingDataUpdate,
}: ShipmentDetailsProps) => {
  const { t } = useTranslation(['shipments', 'messages']);
  const navigate = useNavigate();

  const isCancelled = isCancelledState(shipment);
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const shouldShowHistory = CONTRACT_STATE_SHOW_HISTORY.includes(
    shipment.contract_state
  );
  const [activeKey, setActiveKey] = useState<number>(
    shouldShowHistory ? ShipmentTabEnum.HISTORY : ShipmentTabEnum.SUMMARY
  );

  const [trackingHistory, setTrackingHistory] =
    useState<ITrackingHistoryResponse>();
  const [trackingError, setTrackingError] = useState(false);

  const [shipmentTimeline, setShipmentTimeline] =
    useState<IShipmentTimelineResponse>();
  const [shipmentTimelineError, setShipmentTimelineError] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { loadData } = useGetShipmentTracking(shipment.shipment_id);
  const hasTrackingSection =
    shipment.contract_state === ShipmentContractStateEnum.DEPLOYED ||
    shipment.contract_state === ShipmentContractStateEnum.ARRIVED;

  const displayDeleteShipmentAction = useCallback(
    (shipment: IShipment): boolean => {
      const shipmentContractState = shipment.contract_state;
      let shipmentOtherContractState: null | ShipmentContractState = null;

      if (shipment.return_shipment) {
        shipmentOtherContractState = shipment.return_shipment.contract_state;
      }

      if (shipment.original_shipment) {
        shipmentOtherContractState = shipment.original_shipment.contract_state;
      }

      return (
        shipmentContractState === ShipmentContractStateEnum.PURCHASED &&
        (shipmentOtherContractState === ShipmentContractStateEnum.PURCHASED ||
          shipmentOtherContractState === null)
      );
    },
    []
  );

  const loadTrackingData = useCallback(async () => {
    const { success, trackingHistory, timelineEvents } = await loadData();
    if (!success) {
      setTrackingError(true);
      setShipmentTimelineError(true);

      return;
    }

    setTrackingHistory(trackingHistory);
    setShipmentTimeline(timelineEvents);

    if (onCurrentTrackingDataUpdate) {
      onCurrentTrackingDataUpdate(trackingHistory);
    }
  }, [loadData, onCurrentTrackingDataUpdate]);

  const backToShipmentList = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const { deleteShipment, deleteLoading: deleteInProgress } = useEditShipment();

  const handleDelete = useCallback(async () => {
    const { success: successDeleteShipment } = await deleteShipment(
      shipment.shipment_id
    );

    if (successDeleteShipment) {
      let successDeleteShipmentOther = true;

      if (shipment.original_shipment) {
        const { success: successDeleteShipmentOriginal } = await deleteShipment(
          shipment.original_shipment.shipment_id
        );

        successDeleteShipmentOther = successDeleteShipmentOriginal;
      }

      if (shipment.return_shipment) {
        const { success: successDeleteShipmentReturn } = await deleteShipment(
          shipment.return_shipment.shipment_id
        );

        successDeleteShipmentOther = successDeleteShipmentReturn;
      }
      if (successDeleteShipmentOther) {
        setShowDeleteModal(false);
        backToShipmentList();
      }
    }
  }, [deleteShipment, shipment, setShowDeleteModal, backToShipmentList]);

  useEffect(() => {
    if (!hasTrackingSection) return;
    loadTrackingData();
  }, [hasTrackingSection, loadTrackingData]);

  useEffect(() => {
    if (!shouldShowHistory && activeKey === ShipmentTabEnum.HISTORY) {
      setActiveKey(ShipmentTabEnum.SUMMARY);
    }
  }, [shouldShowHistory, activeKey]);

  const recipientNotificationEmail =
    shipment.recipient.notificationEmail ||
    shipment.recipient.notification_email;

  return (
    <>
      <ShipmentWrapperContainer>
        <Wrapper>
          <ShipmentDetailNavigationContainer>
            <SmallTabNavigation
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              options={[
                {
                  key: ShipmentTabEnum.HISTORY,
                  label: t('shipments:detail.tab.history'),
                  isDisabled: !shouldShowHistory,
                },
                {
                  key: ShipmentTabEnum.SUMMARY,
                  label: t('shipments:detail.tab.summary'),
                },
              ]}
            />
            {activeKey === ShipmentTabEnum.HISTORY && shipmentTimelineError && (
              <Text variant="bodyTextS" color="custom.error.red.100" my={4}>
                {t('messages:shipmentTrackingError.message')}
              </Text>
            )}
            <Stack direction="row" gap={2} alignItems="center">
              {isCancelled && (
                <Chip
                  type="suggestion"
                  state="idle"
                  label={t('shipments:stateEvents.cancelled')}
                />
              )}
              {trackingHistory &&
                shipment.contract_state === 'DEPLOYED' &&
                shipmentTimeline &&
                !isCancelled && (
                  <ShareTrackingLink
                    shipmentId={shipment.shipment_id}
                    shipment={shipment}
                  />
                )}
            </Stack>
          </ShipmentDetailNavigationContainer>
          {/* History TAB */}
          {activeKey === ShipmentTabEnum.HISTORY &&
            trackingHistory &&
            shipmentTimeline &&
            !trackingError && (
              <ShipmentTracking
                shipment={shipment}
                offerData={offerData}
                trackingData={trackingHistory}
                timelineData={shipmentTimeline}
                displayTrackingLink={false}
                onShipmentMapZoomClick={onShipmentMapZoomClick}
              />
            )}
          {/* Summary TAB */}
          {activeKey === ShipmentTabEnum.SUMMARY && (
            <Grid container spacing={2}>
              <Grid
                item
                mobile={12}
                tablet={5}
                container
                direction="column"
                gap="1.5rem"
              >
                <ShipmentInformation shipment={shipment} />
                {displayDeleteShipmentAction(shipment) && (
                  <Box
                    display="flex"
                    alignItems="flex-end"
                    flexDirection="column"
                  >
                    <Button
                      fullWidth
                      variant="tertiary"
                      color="critical"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      {t('shipments:detail.summary.deleteShipmentBtn')}
                    </Button>
                  </Box>
                )}
              </Grid>
              <Grid item mobile={12} tablet={7}>
                <ShipmentAddressCard shipment={shipment} blockDirection="row" />
                <TrackingInfoCard
                  shipment={shipment}
                  recipientEmail={recipientNotificationEmail}
                  recipientPhone={shipment.recipient.phone_number}
                  shipmentCarrierCode={shipment.carrier_code}
                  carrierTrackingCode={shipment.carrier_tracking_code}
                  isShipmentAnonymous={false}
                />
                {activePartner.secursusEnabled && (
                  <ShipmentDetailInsuranceCard shipment={shipment} />
                )}
              </Grid>
            </Grid>
          )}
        </Wrapper>
      </ShipmentWrapperContainer>
      {displayDeleteShipmentAction(shipment) && (
        <DeleteShipmentModal
          open={showDeleteModal}
          onSubmit={handleDelete}
          onCancel={() => setShowDeleteModal(false)}
          loading={deleteInProgress}
        />
      )}
    </>
  );
};

export default ShipmentDetails;
