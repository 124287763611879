// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useCallback } from 'react';

import {
  CloseIcon,
  Text,
  styled,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

export enum IModalWrapperType {
  default = 'default',
  large = 'large',
  extraLarge = 'extraLarge',
  fullScreen = 'fullScreen',
  shipmentLabel = 'shipmentLabel',
  figuralInsuranceForm = 'figuralInsuranceForm',
  koalendarContactRequest = 'koalendarContactRequest',
  accountInformationBillingAddress = 'accountInformationBillingAddress',
}

interface IModal {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
  className?: string;
  wrapperType?: IModalWrapperType;
  displayCloseButton?: boolean;
}

const Mask = styled('div')`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[30]};
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  width: 35rem;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.1));
`;

const WrapperLarge = styled(Wrapper)`
  width: 600px;
  padding: initial;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    width: 95%;
  }
`;

const WrapperExtraLarge = styled(Wrapper)`
  width: 680px;
  padding: initial;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    width: 95%;
  }
`;

const WrapperFullScreen = styled(Wrapper)`
  width: 80%;
  height: 80%;
  padding: 24px;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    width: 95%;
    height: 95%;
  }
`;

const WrapperShipmentLabel = styled(Wrapper)`
  width: 50%;
  height: 80%;
  padding: 32px;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    width: 95%;
    height: 95%;
  }
`;

const WrapperFiguralInsuranceForm = styled(Wrapper)`
  height: 65%;
`;

const WrapperKoalendarContactRequest = styled(Wrapper)`
  width: 896px;
  padding: initial;
`;

const WrapperAccountInformationBillingAddress = styled(Wrapper)`
  width: 625px;
  padding: 24px;
`;

const ModalCloseButton = styled(Button)`
  position: absolute;
  top: -50px;
  right: 0;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  height: 36px;

  &:hover:enabled {
    background-color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  }
`;

const modalRoot = document.getElementById('modal-root');

const Modal = ({
  open,
  handleClose,
  children,
  className,
  wrapperType = IModalWrapperType.default,
  displayCloseButton = false,
}: IModal) => {
  const { t } = useTranslation('general');

  const handleEscapeKey = useCallback(
    (event: any) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [handleEscapeKey, open]);

  if (!open) return <></>;

  return modalRoot ? (
    createPortal(
      <>
        <Mask onClick={handleClose} />
        {wrapperType === IModalWrapperType.default && (
          <Wrapper className={className}>{children}</Wrapper>
        )}
        {wrapperType === IModalWrapperType.large && (
          <WrapperLarge className={className}>{children}</WrapperLarge>
        )}
        {wrapperType === IModalWrapperType.extraLarge && (
          <WrapperExtraLarge className={className}>
            {children}
          </WrapperExtraLarge>
        )}
        {wrapperType === IModalWrapperType.fullScreen && (
          <WrapperFullScreen className={className}>
            {children}
          </WrapperFullScreen>
        )}
        {wrapperType === IModalWrapperType.shipmentLabel && (
          <WrapperShipmentLabel className={className}>
            {children}
          </WrapperShipmentLabel>
        )}
        {wrapperType === IModalWrapperType.figuralInsuranceForm && (
          <WrapperFiguralInsuranceForm className={className}>
            {children}
          </WrapperFiguralInsuranceForm>
        )}
        {wrapperType === IModalWrapperType.koalendarContactRequest && (
          <WrapperKoalendarContactRequest className={className}>
            {displayCloseButton && (
              <ModalCloseButton
                variant="links"
                icon={CloseIcon}
                onClick={handleClose}
              >
                <Text variant="titleXS" color="custom.neutral.white.pure">
                  {t('close')}
                </Text>
              </ModalCloseButton>
            )}
            {children}
          </WrapperKoalendarContactRequest>
        )}
        {wrapperType === IModalWrapperType.accountInformationBillingAddress && (
          <WrapperAccountInformationBillingAddress className={className}>
            {children}
          </WrapperAccountInformationBillingAddress>
        )}
      </>,
      modalRoot
    )
  ) : (
    <></>
  );
};

export default Modal;
