// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { ILPAccountAddress } from 'models/address';
import { create } from 'zustand';

interface IUseMyAddressesStore {
  primaryAddress: ILPAccountAddress | null;
  setPrimaryAddress: (primaryAddress: ILPAccountAddress | null) => void;
}

const useMyAddressesStore = create<IUseMyAddressesStore>(set => ({
  primaryAddress: null,
  setPrimaryAddress: primaryAddress => set({ primaryAddress }),
}));

export const primaryAddressSelector = (state: IUseMyAddressesStore) =>
  state.primaryAddress;
export const setPrimaryAddressSelector = (state: IUseMyAddressesStore) =>
  state.setPrimaryAddress;

export default useMyAddressesStore;
