// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect } from 'react';

import {
  Box,
  Stack,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import ContactAddressTypeSelector from 'components/organisms/ContactAddressTypeSelector';
import CountrySelector from 'components/organisms/CountrySelector';
import getFieldNameWithPrefix from 'helpers/getFieldNameWithPrefix';
import useFormTemplate from 'hooks/useFormTemplate';
import { set } from 'lodash';
import {
  ContactAddressType,
  IContactAddress,
  IContactAddressForm,
  INITIAL_STATE_CONTACT_ADDRESS_FORM,
} from 'models/contactAddress';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { contactAddressSchema } from 'schemas/contactAddressSchema';

import { DefaultAddresses } from './DefaultAddresses';

interface IContactAddressType {
  defaultValues?: IContactAddress;
  onValuesChanged: (values: IContactAddressForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  prefix?: string;
  isDismissTriggered?: boolean;
}

const ContactAddressForm = ({
  defaultValues,
  onValuesChanged,
  isFormValidChanged,
  prefix = undefined,
  isDismissTriggered,
}: IContactAddressType) => {
  const { t } = useTranslation('contactAddress');

  const {
    register,
    formState: { touchedFields, isValid },
    getValues,
    setValue,
    watch,
    control,
    reset,
    trigger,
  } = useFormTemplate<IContactAddressForm>({
    resolver: contactAddressSchema,
    defaultValues: INITIAL_STATE_CONTACT_ADDRESS_FORM,
  });

  useEffect(() => {
    if (defaultValues) {
      const inputConfig = {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      };

      const defaultValueAddressType = defaultValues.type
        ? defaultValues.type
        : ContactAddressType.professional;

      setValue(
        getFieldNameWithPrefix(prefix, 'type') as keyof IContactAddressForm,
        defaultValueAddressType,
        inputConfig
      );

      setValue('city', defaultValues.city, inputConfig);
      setValue('zipCode', defaultValues.postalCode, inputConfig);
      setValue('addressInfo', defaultValues.street, inputConfig);
      setValue('countryCode', defaultValues.countryCode, inputConfig);
      setValue(
        'companyName',
        defaultValues.company ? defaultValues.company : '',
        inputConfig
      );

      // Manually set the fields as touched
      set(touchedFields, `type`, true);
      set(touchedFields, `city`, true);
      set(touchedFields, `zipCode`, true);
      set(touchedFields, `address`, true);
      set(touchedFields, `countryCode`, true);

      if (defaultValues.company) {
        set(touchedFields, `companyName`, true);
      }

      onValuesChanged(getValues() as IContactAddressForm);

      trigger();
    } else {
      const inputConfig = {
        shouldValidate: false,
        shouldDirty: false,
        shouldTouch: false,
      };

      setValue(
        getFieldNameWithPrefix(prefix, 'type') as keyof IContactAddressForm,
        ContactAddressType.professional,
        inputConfig
      );

      setValue(
        getFieldNameWithPrefix(prefix, 'city') as keyof IContactAddressForm,
        '',
        inputConfig
      );
      setValue(
        getFieldNameWithPrefix(prefix, 'zipCode') as keyof IContactAddressForm,
        '',
        inputConfig
      );
      setValue(
        getFieldNameWithPrefix(
          prefix,
          'addressInfo'
        ) as keyof IContactAddressForm,
        '',
        inputConfig
      );
      setValue(
        getFieldNameWithPrefix(
          prefix,
          'countryCode'
        ) as keyof IContactAddressForm,
        '',
        inputConfig
      );
      setValue(
        getFieldNameWithPrefix(
          prefix,
          'companyName'
        ) as keyof IContactAddressForm,
        '',
        inputConfig
      );
    }
  }, [
    defaultValues,
    trigger,
    setValue,
    getValues,
    touchedFields,
    onValuesChanged,
    prefix,
  ]);

  useEffect(() => {
    isFormValidChanged(isValid);
  }, [isValid, isFormValidChanged]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const values = getValues();

      if (prefix && event.currentTarget) {
        if (
          event.currentTarget.name === getFieldNameWithPrefix(prefix, 'type')
        ) {
          values.type = event.currentTarget.value as ContactAddressType;
        }
      }

      onValuesChanged(values as IContactAddressForm);
    },
    [getValues, onValuesChanged, prefix]
  );

  const handleInputTypeChange = useCallback(
    (addressType: ContactAddressType | undefined) => {
      const addressTypeData = addressType
        ? addressType
        : ContactAddressType.professional;

      setValue('type', addressTypeData);
      trigger('type');

      // Remove the company name when you select the personal type
      if (addressTypeData === ContactAddressType.personal) {
        setValue(
          'companyName',
          INITIAL_STATE_CONTACT_ADDRESS_FORM.companyName,
          {
            shouldValidate: true,
            shouldDirty: true,
          }
        );

        set(touchedFields, `companyName`, true);
      }

      onValuesChanged(getValues() as IContactAddressForm);
    },
    [setValue, trigger, onValuesChanged, getValues, touchedFields]
  );

  useEffect(() => {
    if (isDismissTriggered) {
      reset();
    }
  }, [isDismissTriggered, reset]);

  return (
    <Stack>
      <Stack direction="row" pl="0.3125rem" pb="1rem">
        <ContactAddressTypeSelector
          prefix={prefix}
          register={register}
          fieldName="type"
          watch={watch}
          watchUsePrefix={false}
          onValueChanged={handleInputTypeChange}
        />
      </Stack>
      <Stack direction="row" gap="1rem">
        <Box width="50%" mb="1.25rem">
          <Controller
            name="companyName"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required={watch('type') === ContactAddressType.professional}
                label={t('form.companyName.placeholder')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={watch('type') === ContactAddressType.personal}
                {...field}
                onChange={e => {
                  field.onChange(e);
                  handleInputChange(e);
                }}
              />
            )}
          />
        </Box>
        <Box width="50%" mb="1.25rem">
          <Controller
            name="addressInfo"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('form.address.placeholder')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
                onChange={e => {
                  field.onChange(e);
                  handleInputChange(e);
                }}
              />
            )}
          />
        </Box>
      </Stack>
      <Stack direction="row" gap="1rem">
        <Box width="50%" mb="1.25rem">
          <Controller
            name="city"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('form.city.placeholder')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
                onChange={e => {
                  field.onChange(e);
                  handleInputChange(e);
                }}
              />
            )}
          />
        </Box>
        <Box width="50%" mb="1.25rem">
          <Controller
            name="zipCode"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('form.zipCode.placeholder')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
                onChange={e => {
                  field.onChange(e);
                  handleInputChange(e);
                }}
              />
            )}
          />
        </Box>
      </Stack>
      <Stack direction="row" gap="1rem">
        <Box width="50%">
          <CountrySelector
            id={
              prefix
                ? `${prefix.replace('.', '_')}countryCodeSelector`
                : 'countryCodeSelector'
            }
            width="100%"
            control={control}
            onChange={handleInputChange}
            register={register}
            required
          />
        </Box>
        <Box width="50%" />
      </Stack>
      <DefaultAddresses
        addressId={defaultValues?.id || null}
        isDismissTriggered={isDismissTriggered}
        prefix={prefix}
      />
    </Stack>
  );
};

export default ContactAddressForm;
