// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useMemo } from 'react';

import { AddressesResponse } from '../api/addresses/getAddresses';
import { Address, AddressTypes, AddressTypesKeys } from '../types/address';

/**
 * Custom hook to retrieve an address by its type.
 *
 * @param options - The options for retrieving the address.
 * @param options.type - The type of the address to retrieve.
 * @param options.addresses - The list of addresses to search in.
 * @param options.exclusive - Whether the address should have only one type.
 *
 * @returns The address matching the specified type, or `undefined` if not found.
 */
export const useAddressByType = ({
  type = AddressTypes.MAIN,
  addresses,
  exclusive = false,
}: {
  type?: AddressTypesKeys;
  addresses?: AddressesResponse;
  exclusive?: boolean;
}): Address | void => {
  const address = useMemo(() => {
    if (!addresses) return;

    return addresses?.items.find(address =>
      address.addressTypes?.includes(type)
    );
  }, [addresses, type]);

  if (exclusive && address && address.addressTypes.length > 1) return;

  return address;
};
