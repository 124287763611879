// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { APIServices } from 'configs';
import { IRole } from 'models/role';
import { rolesSchema } from 'schemas/roleSchema';

const getPartnerRoles = async (
  partnerId: string,
  token: string
): Promise<{ data?: IRole; error: any }> => {
  try {
    const json = await fetch(
      `${APIServices.lpVillage}api/v1/me/partners/${partnerId}/roles`,
      {
        headers: { Authorization: 'Bearer ' + token },
        method: 'GET',
      }
    );
    const data = await json.json();
    if (data?.items && data.items.length !== 0) {
      await rolesSchema.validate(data.items);

      return { data: data.items[0], error: '' };
    }

    return { error: new Error('Could not get roles!') };
  } catch (e) {
    return { error: e };
  }
};

export default getPartnerRoles;
