// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export const BleProductType = {
  UNKNOWN: '0', //'Unknown',
  BOX: '10', // 'THE BOX'
  BOX_PRO: '11', // 'THE BOX Pro'
  TABLET_HOLDER: '20', // 'THE TABLET'
} as const;

export type BleProductTypeKeys = keyof typeof BleProductType;
export type BleProductTypeValues = (typeof BleProductType)[BleProductTypeKeys];
