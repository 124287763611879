// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useState } from 'react';

import {
  ButtonV2,
  Grid,
  ModalV2 as Modal,
  Text,
} from '@livingpackets/design-system-react-next';
import { filter, get, isEmpty, map } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';

const BLOCKER_BLOCKED_STATE = 'blocked';

const useComputeQueueElements = (queue: Props['queue']) => {
  const keys = Object.keys(queue);

  const dirtyKeys = filter((key: string) => get([key, 'isDirty'], queue), keys);
  const dirtyQueue = map((key: string) => get(key, queue), dirtyKeys);

  const isDirty = !isEmpty(dirtyKeys);
  const onSubmit = async () => {
    await Promise.all(map(({ onSubmit }: any) => onSubmit(), dirtyQueue));
  };
  const reset = async () => {
    await Promise.all(map(({ reset }: any) => reset(), dirtyQueue));
  };

  return { isDirty, onSubmit, reset };
};

interface Props {
  queue: {
    [key: string]: {
      isDirty: boolean;
      onSubmit: () => Promise<void>;
      reset: () => void;
    };
  };
}

/**
 * Warns the user before leaving the page if the form is dirty
 * via a custom modal if he navigate away (through react-router-dom)
 * or via a browser alert if he close the tab/window or refresh
 *
 * To handle multiple forms at once :
 * Usage:
 *  Wrap the closest common parent (of all forms) with a context and the context provider:
 *  ```
 *    // Create the empty context
 *    export const WbqContext = createContext<any>(null);
 *
 *    const [queue, setQueue] = useState({});
 *
 *    <WbqContext.Provider value={{ queue, setQueue }}>
 *      <FirstForm />
 *      <SecondForm />
 *    </WbqContext.Provider>
 *
 *    // In each form, you need to add the form to the queue
 *    const { handleSubmit, formState: {isDirty ...} } = useForm(...);
 *
 *    const onSubmit = handleSubmit(mutate); // Mutate from useMutation or anything else
 *
 *    useEffect(() => {
 *      setQueue((queue: any) => ({
 *        ...queue,
 *        partnership: {
 *          isDirty,
 *          onSubmit,
 *          reset,
 *        },
 *      }));
 *      // eslint-disable-next-line react-hooks/exhaustive-deps
 *    }, [isDirty]); // Only add this dependency !
 *
 *    // Finally, you need to add the WarnBeforeQuit component
 *    <WarnBeforeQuit queue={queue} />
 *  ```
 *
 *
 * @param queue - Object containing all the forms to watch
 * `formName`: An arbitrary name to identify the form
 * @param queue.[formName].isDirty - Whether the form is dirty or not
 * @param queue.[formName].onSubmit - Function to call when the user confirms he wants to leave the page
 * @param queue.[formName].reset - Use to reset the form after the user has confirmed he wants to leave the page
 * @returns {JSX.Element}
 */
const WarnBeforeQuit = React.memo(({ queue }: Props) => {
  const { t } = useTranslation(['general']);

  const { isDirty, onSubmit, reset } = useComputeQueueElements(queue);

  const navigationBlocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname
  );

  const [open, setOpen] = useState<boolean>(false);

  // Prevent the user from leaving (close tab/window or refresh) if the form is dirty
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  // Show modal if the user tries to navigate away the page while the form is dirty
  useEffect(() => {
    if (navigationBlocker.state === BLOCKER_BLOCKED_STATE) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [navigationBlocker]);

  // Follow up the initial action (navigation away) after the user has confirmed he wants to leave the page
  const followUpInitialAction = async () => {
    if (navigationBlocker.state === BLOCKER_BLOCKED_STATE) {
      reset();
      navigationBlocker.proceed();

      return;
    }
  };

  return (
    <Modal open={open} setOpen={setOpen}>
      <Grid
        container
        direction="column"
        sx={{ minWidth: { mobile: 'auto', tablet: '30rem' } }}
        gap="1.625rem"
      >
        <Grid item>
          <Text variant="titleM" color="custom.neutral.black.100">
            {t('general:warnBeforeQuit.title')}
          </Text>
        </Grid>
        <Grid item>
          <Text variant="bodyTextM" color="custom.neutral.black.100">
            {t('general:warnBeforeQuit.message')}
            <br />
            {t('general:warnBeforeQuit.message2')}
          </Text>
        </Grid>
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item>
            <ButtonV2 variant="secondary" onClick={followUpInitialAction}>
              {t('general:warnBeforeQuit.leaveWithoutSaving')}
            </ButtonV2>
          </Grid>
          <Grid item>
            <ButtonV2
              onClick={async () => {
                await onSubmit();
                followUpInitialAction();
              }}
            >
              {t('general:warnBeforeQuit.saveChanges')}
            </ButtonV2>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
});

WarnBeforeQuit.displayName = 'WarnBeforeQuit';

export default WarnBeforeQuit;
