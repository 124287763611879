// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useState } from 'react';

import colissimoLogo from 'assets/colissimo.png';
import chronopostLogo from 'assets/img/carrier/chronopost.png';
import dhlLogo from 'assets/img/carrier/dhl.png';
import fedexLogo from 'assets/img/carrier/fedex.png';
import upsLogo from 'assets/img/carrier/ups.png';
import lpCarrierLogo from 'assets/lpCarrier.png';
import miistLogo from 'assets/miist.png';
import OtherLogo from 'assets/other-carrier-logo.png';
import TNTLogo from 'assets/tnt.png';
import {
  ShipmentAnonymousTimelineThresholdTypeEnum,
  ShipmentTimelineEventTypeEnum,
  ShipmentTimelineThresholdTypeEnum,
} from 'enums/ShipmentTimelineEnum';
import { TAcceptedEvents } from 'helpers/shipmentEventsHelpers';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useLpVillageService from 'hooks/useLpVillageService';
import useToastMessages from 'hooks/useToastMessages';
import {
  IShipment,
  IShipmentGeoPath,
  IShipmentTimelineEntry,
  IShipmentTimelineEntryMultiInfo,
  IShipmentTimelineEntryValueItem,
  ITrackingHistoryResponse,
  ShipmentContractStateEnum,
  TCarrierCodes,
} from 'models/shipment';
import { create } from 'zustand';

interface IShipmentStore {
  shipment?: IShipment;
  setShipment: (shipment: IShipment) => void;
}

export const useShipmentStore = create<IShipmentStore>(set => ({
  setShipment: (shipment: IShipment) => set({ shipment }),
}));

const useShipmentDetail = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('shipmentV3');
  const { error: toastError } = useToastMessages();
  const { getPartnerParcel } = useLpVillageService();

  const getTString = useCallback(
    (eventName: TAcceptedEvents) =>
      `stateEvents.${eventName.toLowerCase()}.title`,
    []
  );

  const getTSubtitle = useCallback(
    (eventName: TAcceptedEvents) =>
      `stateEvents.${eventName.toLowerCase()}.subtitle`,
    []
  );

  const [currentTrackingData, setCurrentTrackingData] = useState<
    ITrackingHistoryResponse | undefined
  >(undefined);

  const getCurrentTrackingData = useCallback(
    () => currentTrackingData,
    [currentTrackingData]
  );

  const [currentAnonymousTrackingData, setCurrentAnonymousTrackingData] =
    useState<IShipmentGeoPath | undefined>(undefined);

  const getCurrentAnonymousTrackingData = useCallback(
    () => currentAnonymousTrackingData,
    [currentAnonymousTrackingData]
  );

  const isShipmentHistoryDisplayed = useCallback(
    (shipmentContractState: ShipmentContractStateEnum): boolean =>
      [
        ShipmentContractStateEnum.DEPLOYED,
        ShipmentContractStateEnum.ARRIVED,
        ShipmentContractStateEnum.ERROR,
      ].includes(shipmentContractState),
    []
  );

  const getMeShipmentDetail = useCallback(
    async (activePartnerId: string, shipmentId: string) => {
      const { error, data } = await makeRequest({
        path: `/me/shipments/${shipmentId}/details`,
        method: 'get',
        params: {
          partnerId: activePartnerId,
        },
      });

      if (error) {
        toastError('messages:getShipmentDetailError');

        return { success: false, shipment: null };
      }
      const { shipment, timeline, geo_path, product_state } = data;

      if (shipment.created_at) {
        shipment.created_at = new Date(shipment.created_at).getTime() / 1000;
      }

      if (product_state.last_update) {
        product_state.last_update =
          new Date(product_state.last_update).getTime() / 1000;
      }

      return { shipment, timeline, geo_path, product_state };
    },
    [toastError, makeRequest]
  );

  const getShipmentDetail = useCallback(
    async (activePartnerId: string, shipmentId: string) => {
      const shipmentDetail = await getMeShipmentDetail(
        activePartnerId,
        shipmentId
      );
      const { shipment, timeline, product_state, success } = shipmentDetail;

      if (success === false) {
        return { success: false, shipment: null };
      }

      if (isShipmentHistoryDisplayed(shipment.contract_state)) {
        shipment.product_state = product_state;

        // Get Shipment Figural information
        if (
          shipment.parcel_request_id &&
          shipment.contract_state !== ShipmentContractStateEnum.PURCHASED
        ) {
          const response = await getPartnerParcel(shipment.parcel_request_id);

          if (response.success) {
            shipment.partnerParcelData = response.data;
          }
        }
      }

      if (shipment.return_shipment_id) {
        const shipmentReturn = await getMeShipmentDetail(
          activePartnerId,
          shipment.return_shipment_id
        );

        shipment.returnShipment = shipmentReturn.shipment;
        shipment.return_shipment = shipmentReturn.shipment;

        if (
          isShipmentHistoryDisplayed(shipmentReturn.shipment.contract_state)
        ) {
          // Get Return Shipment Product State
          shipment.returnShipment.product_state = shipmentReturn.product_state;
          shipment.return_shipment.product_state = shipmentReturn.product_state;
        }

        // Get Return Shipment Figural information
        if (
          shipment.returnShipment &&
          shipment.returnShipment.hasOwnProperty('parcelRequestId') &&
          shipment.returnShipment.parcel_request_id &&
          shipment.returnShipment.contract_state !==
            ShipmentContractStateEnum.PURCHASED
        ) {
          const response = await getPartnerParcel(
            shipment.returnShipment.parcel_request_id
          );

          if (response.success) {
            shipment.returnShipment.partnerParcelData = response.data;
          }
        }
      }

      if (shipment.original_shipment_id) {
        const shipmentOriginal = await getMeShipmentDetail(
          activePartnerId,
          shipment.original_shipment_id
        );

        shipment.originalShipment = shipmentOriginal.shipment;
        shipment.original_shipment = shipmentOriginal.shipment;

        if (
          isShipmentHistoryDisplayed(shipmentOriginal.shipment.contract_state)
        ) {
          // Get Original Shipment Product State
          shipment.originalShipment.product_state =
            shipmentOriginal.product_state;
          shipment.original_shipment.product_state =
            shipmentOriginal.product_state;
        }

        // Get Original Shipment Figural information
        if (
          shipment.originalShipment &&
          shipment.originalShipment.hasOwnProperty('parcelRequestId') &&
          shipment.originalShipment.parcel_request_id &&
          shipment.originalShipment.contract_state !==
            ShipmentContractStateEnum.PURCHASED
        ) {
          const response = await getPartnerParcel(
            shipment.originalShipment.parcel_request_id
          );

          if (response.success) {
            shipment.originalShipment.partnerParcelData = response.data;
          }
        }
      }

      return {
        success: true,
        shipment: shipment,
        timelines: timeline,
      };
    },
    [getPartnerParcel, isShipmentHistoryDisplayed, getMeShipmentDetail]
  );

  const getCarrierLogo = useCallback((carrier: TCarrierCodes) => {
    switch (carrier) {
      case 'CHRONOPOST':
        return chronopostLogo;
      case 'DHL':
        return dhlLogo;
      case 'UPS':
        return upsLogo;
      case 'LPCarrier':
        return lpCarrierLogo;
      case 'FEDEX':
        return fedexLogo;
      case 'TNTExpress':
        return TNTLogo;
      case 'MIIST':
        return miistLogo;
      case 'COLISSIMO':
        return colissimoLogo;
      case 'Other':
        return OtherLogo;
      default:
        return '';
    }
  }, []);

  const isShipmentTimelineItemAlert = useCallback(
    (shipmentTimelineItem: IShipmentTimelineEntry): boolean =>
      [
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_SHOCK,
        ShipmentTimelineEventTypeEnum.ALERT_FALL,
        ShipmentTimelineEventTypeEnum.ALERT_PRESSURE,
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_BOX_VIOLATION,
        ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL,
        ShipmentTimelineEventTypeEnum.ALERT_BOX_CODE,
      ].includes(shipmentTimelineItem.eventType),
    []
  );

  const getAlertThreshold = useCallback(
    (event: IShipmentTimelineEntry): number => {
      const threshold = (event.payload as IShipmentTimelineEntryMultiInfo)
        .threshold;

      if (
        event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL
      ) {
        return Math.floor(threshold / 100);
      }

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL
      ) {
        return threshold / 10;
      }

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL
      ) {
        return threshold / 10;
      }

      return threshold;
    },
    []
  );

  const getAlertThresholdType = useCallback(
    (event: IShipmentTimelineEntry): ShipmentTimelineThresholdTypeEnum =>
      (event.payload as IShipmentTimelineEntryMultiInfo).thresholdType,
    []
  );

  const getAlertThresholdLimitText = useCallback(
    (
      baseTransKey: string,
      thresholdType:
        | ShipmentTimelineThresholdTypeEnum
        | ShipmentAnonymousTimelineThresholdTypeEnum
    ): string =>
      thresholdType === ShipmentTimelineThresholdTypeEnum.MAX ||
      thresholdType === ShipmentAnonymousTimelineThresholdTypeEnum.MAX
        ? baseTransKey + '.thresholdLimitMax'
        : baseTransKey + '.thresholdLimitMin',
    []
  );

  const getAlertReachedValue = useCallback(
    (event: IShipmentTimelineEntry): number => {
      const valueList = (
        event.payload as IShipmentTimelineEntryMultiInfo
      ).values.map((value: IShipmentTimelineEntryValueItem) => value.value);

      let valueDisplayed =
        getAlertThresholdType(event) === ShipmentTimelineThresholdTypeEnum.MAX
          ? Math.max(...valueList)
          : Math.min(...valueList);

      if (
        event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL
      ) {
        valueDisplayed = Math.floor(valueDisplayed / 100);
      }

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL
      ) {
        valueDisplayed = valueDisplayed / 10;
      }

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL
      ) {
        valueDisplayed = valueDisplayed / 10;
      }

      return valueDisplayed;
    },
    [getAlertThresholdType]
  );

  const getAlertCurrentValue = useCallback(
    (event: IShipmentTimelineEntry): number => {
      const currentValue = (event.payload as IShipmentTimelineEntryMultiInfo)
        .values[0].value;

      if (
        event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL
      ) {
        return Math.floor(currentValue / 100);
      }

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL
      ) {
        return currentValue / 10;
      }

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL
      ) {
        return currentValue / 10;
      }

      return currentValue;
    },
    []
  );

  const isAlertClosed = useCallback(
    (event: IShipmentTimelineEntry): boolean => event.closedAt !== undefined,
    []
  );

  const isAlertWithGauge = useCallback(
    (event: IShipmentTimelineEntry): boolean =>
      [
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_PRESSURE,
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL,
        ShipmentTimelineEventTypeEnum.ALERT_SHOCK,
        ShipmentTimelineEventTypeEnum.ALERT_FALL,
        ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL,
      ].includes(event.eventType),
    []
  );

  return {
    getTString,
    getTSubtitle,
    currentTrackingData,
    setCurrentTrackingData,
    getCurrentTrackingData,
    currentAnonymousTrackingData,
    setCurrentAnonymousTrackingData,
    getCurrentAnonymousTrackingData,
    getShipmentDetail,
    loading,
    getCarrierLogo,
    isShipmentTimelineItemAlert,
    getAlertThreshold,
    getAlertThresholdType,
    getAlertThresholdLimitText,
    getAlertReachedValue,
    getAlertCurrentValue,
    isAlertClosed,
    isAlertWithGauge,
  };
};

export default useShipmentDetail;
