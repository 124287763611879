// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  TooltipIcon,
  Text,
  useTheme,
  NewTooltip as Tooltip,
} from '@livingpackets/design-system-react-next';
import { Trans, useTranslation } from 'react-i18next';

interface IWeightContent {
  title: string;
  content: string;
}

const WeightContent = ({ title, content }: IWeightContent) => {
  const { t } = useTranslation('shipments');

  return (
    <>
      <Text variant="titleS">
        <Trans t={t}>{title}</Trans>
      </Text>
      <Text variant="bodyTextS" mt={1}>
        <Trans t={t}>{content}</Trans>
      </Text>
    </>
  );
};

const WeightTooltip = ({ children }: any) => {
  const theme = useTheme();

  return (
    <Tooltip title={children}>
      <div>
        <TooltipIcon
          color={theme.palette.custom.neutral.black[50]}
          size="13px"
        />
      </div>
    </Tooltip>
  );
};

export const VerifiedWeightTooltip = () => (
  <WeightTooltip>
    <WeightContent
      title="weightTooltip.verifiedTitle"
      content="weightTooltip.verifiedContent"
    />
  </WeightTooltip>
);

export const ProvidedWeightTooltip = () => (
  <WeightTooltip>
    <WeightContent
      title="weightTooltip.providedTitle"
      content="weightTooltip.providedContent"
    />
  </WeightTooltip>
);
