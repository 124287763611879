// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useEffect } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';

import useNewsletterStore, {
  setNewsletterSelector,
} from '../stores/useNewslettersStore';

const useNewsletters = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpAccountV3');
  const setNewsletters = useNewsletterStore(setNewsletterSelector);

  const getNewsletters = useCallback(async () => {
    const { data, error } = await makeRequest({
      path: '/me/newsletters',
      method: 'get',
    });

    if (error) {
      return { success: false };
    }
    Array.isArray(data?.newsletters) && setNewsletters(data.newsletters);

    return { success: true };
  }, [makeRequest, setNewsletters]);

  useEffect(() => {
    getNewsletters();
  }, [getNewsletters]);

  return { loading };
};

export default useNewsletters;
