// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import { ButtonV2 as Button } from '@livingpackets/design-system-react-next';
import {
  AuthenticationView,
  DescriptionTextFontStyleEnum,
} from 'components/containers/AuthenticationView';
import { ROUTE_PATHS } from 'configs';
import { normalizeKey } from 'helpers/i18n';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const AccountCreatedSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('authentication');

  const { state } = useLocation();
  const { accountType } = state || {};

  const redirectToDashboardPage = useCallback(() => {
    navigate(ROUTE_PATHS.ROOT);
  }, [navigate]);

  if (!accountType) {
    redirectToDashboardPage();
  }

  return (
    <AuthenticationView
      title={t(normalizeKey(`accountCreatedSuccess.${accountType}.title`))}
      description={t(
        normalizeKey(`accountCreatedSuccess.${accountType}.description`)
      )}
      descriptionFontStyle={DescriptionTextFontStyleEnum.REGULAR}
    >
      <Button fullWidth onClick={() => redirectToDashboardPage()}>
        {t(normalizeKey(`accountCreatedSuccess.${accountType}.button`))}
      </Button>
    </AuthenticationView>
  );
};

export default AccountCreatedSuccess;
