// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { AxiosError } from 'axios';
import LoadingKeys from 'models/loadingKeys';
import { ITos } from 'models/tos';
import { IUser } from 'models/user';
import reducer, { TAction } from 'stores/appState/reducer';
import { create } from 'zustand';
import { devtools, redux } from 'zustand/middleware';

export type TLoadingElement = { key: LoadingKeys; state: boolean };

export type TLoading = [Array<TLoadingElement>, boolean];

export type TRole = { name: string; scopes: Array<string> };

export type TRoles = Array<{ id: string; roles: Array<TRole> }>;

export type TTosState = 'UNKNOWN' | 'ERROR' | 'READY';

export type TState = {
  user: IUser | null;
  token: string;
  anonymousToken: string;
  tos: {
    state: TTosState;
    currentTosPaaS: ITos | null;
    upcomingTosPaaS: ITos | null;
    currentTosFinancial: ITos | null;
    upcomingTosFinancial: ITos | null;
    firstTime: boolean;
  };
  error: Error | null;
  apiError: AxiosError | null;
  handledError: Error | null;
  loading: TLoading;
  roles: TRoles;
};

export const initialState: TState = {
  user: null,
  token: '',
  anonymousToken: '',
  tos: {
    state: 'UNKNOWN',
    currentTosPaaS: null,
    upcomingTosPaaS: null,
    currentTosFinancial: null,
    upcomingTosFinancial: null,
    firstTime: false,
  },
  error: null,
  apiError: null,
  handledError: null,
  loading: [[], true],
  roles: [],
};

const useAppState = create(
  devtools(redux<TState, TAction>(reducer, initialState), { name: 'AppState' })
);

export const loadingSelector = (s: TState) => s.loading;
export const tokenSelector = (s: TState) => s.token;
export const anonymousTokenSelector = (s: TState) => s.anonymousToken;
export const userSelector = (s: TState) => s.user;
export const errorSelector = (s: TState) => s.error;
export const apiErrorSelector = (s: TState) => s.apiError;
export const tosSelector = (s: TState) => s.tos;

// @ts-ignore
export const dispatch: (input: TAction) => void = useAppState.dispatch;
export default useAppState;
