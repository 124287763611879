// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Collapse } from '@livingpackets/design-system-react-next';
import useIsMobile from 'hooks/useIsMobile';
import useLayoutStore, { openNavSelector } from 'stores/useLayoutStore';

import LangSwitch from './LangSwitch';

const LANGUAGES = ['en', 'fr', 'de'];

const Languages = () => {
  const { isMobile } = useIsMobile();

  const openNav = useLayoutStore(openNavSelector);

  const langSwitch = <LangSwitch languages={LANGUAGES} />;

  // No animation for mobile menu
  if (isMobile) {
    return langSwitch;
  }

  return (
    <Collapse in={openNav} orientation="horizontal" collapsedSize={40}>
      {langSwitch}
    </Collapse>
  );
};

export default Languages;
