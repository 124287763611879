// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { IShipment } from 'models/shipment';

import {
  GetUserShipmentsParams,
  useGetUserShipments,
} from './useGetUserShipments';
import useToastMessages from './useToastMessages';
import {
  Paginated,
  useFetchResource,
  UseFetchResourceOptions,
} from '../shared-components';
import useShipmentsStore, {
  setShipmentsSelector,
} from '../stores/useShipmentStore';

export function useShipments(
  params: GetUserShipmentsParams,
  { enabled = true }: UseFetchResourceOptions = {}
): Paginated<IShipment> | any {
  const getUserShipments = useGetUserShipments();
  const { error: errorToast } = useToastMessages();
  const setShipments = useShipmentsStore(setShipmentsSelector);
  const fetchFunction = useCallback(
    () => getUserShipments(params),
    [getUserShipments, params]
  );
  const data = useFetchResource(fetchFunction, { enabled });
  if (data.state === 'success') {
    setShipments(data.resource.items);

    return data.resource.items;
  } else if (data.state === 'error') {
    return errorToast('shipments:shipmentsFetchError');
  }
}
