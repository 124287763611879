// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';
import { useCallback } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import {
  useTheme,
  Text,
  Box,
  Stack,
  NewTextField as TextField,
  ButtonV2 as Button,
  Checkbox,
} from '@livingpackets/design-system-react-next';
import {
  AuthenticationView,
  DescriptionTextFontStyleEnum,
} from 'components/containers/AuthenticationView';
import { PhoneNumber } from 'components/form/phoneNumber';
import { PATHS } from 'configs';
import { AccountTypeEnum } from 'enums/accountTypeEnum';
import useCustomer from 'hooks/useCustomer';
import useFormTemplate from 'hooks/useFormTemplate';
import { set } from 'lodash';
import {
  IFinalizeAccountForm,
  INITIAL_STATE_FINALIZE_ACCOUNT_FORM,
} from 'models/finalizeAccount';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { finalizeAccountSchema } from 'schemas/finalizeAccountSchema';
import useAppState, { userSelector } from 'stores/appState/useAppState';
import useMyPartnersStore, {
  partnersSelector,
} from 'stores/useMyPartnersStore';

const FinalizeAccount = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation('authentication');
  const { logout } = useAuth0();

  const appUser = useAppState(userSelector);
  const partners = useMyPartnersStore(partnersSelector);
  const { editCurrentUser } = useCustomer();

  const {
    formState: { touchedFields, isValid },
    getValues,
    control,
  } = useFormTemplate<IFinalizeAccountForm>({
    resolver: finalizeAccountSchema,
    defaultValues: {
      ...INITIAL_STATE_FINALIZE_ACCOUNT_FORM,
      firstName: appUser?.firstName,
      lastName: appUser?.lastName,
      phoneNumber: appUser?.phoneNumber,
    },
    formValidationMode: 'onTouched',
  });

  if (appUser?.firstName) {
    set(touchedFields, 'firstName', true);
  }

  if (appUser?.lastName) {
    set(touchedFields, 'lastName', true);
  }

  if (appUser?.phoneNumber) {
    set(touchedFields, 'phoneNumber', true);
  }

  const redirectToAccountCreatedSuccessPage = useCallback(() => {
    if (partners.length) {
      navigate(PATHS.AUTHENTICATION.ACCOUNT_CREATED_SUCCESS, {
        state: { accountType: AccountTypeEnum.FULL_VERSION },
      });
    } else {
      navigate(PATHS.AUTHENTICATION.ACCOUNT_TYPE_CHOICE);
    }
  }, [navigate, partners.length]);

  const redirectToLandingPage = useCallback(() => {
    logout({
      returnTo: window.location.origin,
    });
  }, [logout]);

  const submitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      const formValues = getValues();

      editCurrentUser(
        {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          phoneNumber: formValues.phoneNumber,
        },
        false
      ).then(() => redirectToAccountCreatedSuccessPage());
    },
    [getValues, editCurrentUser, redirectToAccountCreatedSuccessPage]
  );

  const getTermsOfUseLink = () => (
    <a
      href={t('finalizeAccount.form.isAgreedToTOS.termsOfUseWebsiteUrl')}
      style={{
        textDecoration: 'underline',
        textDecorationColor: theme.palette.custom.primary[100],
      }}
    >
      <Text variant="bodyTextS" color="custom.primary.100">
        {t('finalizeAccount.form.isAgreedToTOS.termsOfUseWebsite')}
      </Text>
    </a>
  );

  return (
    <AuthenticationView
      title={t('finalizeAccount.title')}
      description={t('finalizeAccount.description')}
      descriptionFontStyle={DescriptionTextFontStyleEnum.REGULAR}
    >
      <Stack
        component="form"
        onSubmit={submitHandler}
        data-testid="finalize-account-container"
        marginTop="50px"
        gap="1.25rem"
      >
        <Controller
          name="firstName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('finalizeAccount.form.firstName.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('finalizeAccount.form.lastName.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field, fieldState: { error, invalid } }) => (
            <PhoneNumber
              required
              defaultCountryCode="FR"
              label={t('finalizeAccount.form.phoneNumber.placeholder')}
              error={invalid}
              helperText={error?.message}
              {...field}
            />
          )}
        />
        <Box data-testid="form-field-checkbox-is-agreed-to-tos">
          <Controller
            control={control}
            name="isAgreedToTOS"
            render={({ field }) => (
              <Checkbox
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
                checked={field.value}
                data-testid="checkbox-finalize-account"
                label={
                  <Stack direction="row" alignItems="center" gap="0.125rem">
                    <Text
                      variant="bodyTextM"
                      color="custom.neutral.black.50"
                      sx={{ cursor: 'pointer' }}
                    >
                      {t('finalizeAccount.form.isAgreedToTOS.label')}
                    </Text>
                    {getTermsOfUseLink()}
                  </Stack>
                }
              ></Checkbox>
            )}
          />
        </Box>
        <Button
          data-testid="submit-btn-compagny-name"
          variant="primary"
          onClick={submitHandler}
          disabled={!isValid}
        >
          {t('finalizeAccount.buttons.confirm')}
        </Button>
        <Button variant="secondary" onClick={() => redirectToLandingPage()}>
          {t('finalizeAccount.buttons.cancel')}
        </Button>
      </Stack>
    </AuthenticationView>
  );
};
export default FinalizeAccount;
