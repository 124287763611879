// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  BoxOpenedIcon,
  CheckOutlineIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  LockOnIcon2,
  CloseIcon,
  ShipmentCompletedIcon,
  ShockIcon,
  FallIcon,
  TemperatureHighIcon,
  TemperatureIcon,
  TemperatureLowIcon,
  AirPressureHighIcon,
  AirPressureIcon,
  AirPressureLowIcon,
  HumidityHighIcon,
  HumidityIcon,
  HumidityLowIcon,
  BatteryLowIcon,
  Box,
  Grid,
  Text,
  Stack,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import EventAirPressureBlock from 'components/atoms/shipment/Timeline/EventAirPressureBlock';
import EventBatteryLevelBlock from 'components/atoms/shipment/Timeline/EventBatteryLevelBlock';
import EventBoxCode from 'components/atoms/shipment/Timeline/EventBoxCode';
import EventBoxViolationBlock from 'components/atoms/shipment/Timeline/EventBoxViolationBlock';
import EventFallBlock from 'components/atoms/shipment/Timeline/EventFallBlock';
import EventHumidityBlock from 'components/atoms/shipment/Timeline/EventHumidityBlock';
import EventShockBlock from 'components/atoms/shipment/Timeline/EventShockBlock';
import EventTemperatureBlock from 'components/atoms/shipment/Timeline/EventTemperatureBlock';
import TimeLineIcon from 'components/molecules/TimeLineIcon';
import { ShipmentTimelineEventTypeEnum } from 'enums/ShipmentTimelineEnum';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import useShipmentDetail from 'hooks/useShipmentDetail';
import {
  IShipmentTimelineEntry,
  IShipmentTimelineEntryMonoInfo,
  IShipmentTimelineEntryPayloadBoxViolation,
} from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';

const REDUCED_LIST_SIZE = 5;

const Card = styled('div')`
  background: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 20px;
  padding-top: 20px;
  z-index: 1;
  position: relative;
`;

const Wrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isShipmentTimelineItemAlert',
})<{ isShipmentTimelineItemAlert: boolean }>`
  flex-direction: row;
  align-items: center;
  padding-bottom: 12px;
  cursor: ${({ isShipmentTimelineItemAlert }) =>
    isShipmentTimelineItemAlert ? 'pointer' : 'default'};
  position: relative;
`;

const TimelineWrapper = styled(Stack)`
  height: 100%;
  position: absolute;
  left: 17px;
`;

const EventInformationContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'displayAlertInformation',
})<{
  displayAlertInformation: boolean;
}>`
  width: 90%;
  margin-right: 12px;
  margin-left: 45px;
  padding: 12px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
`;

const EventNameText = styled(Text)``;

EventNameText.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.100',
};

const EventDataText = styled(Text, {
  shouldForwardProp: prop => prop !== 'isAlertClosed',
})<{
  isAlertClosed: boolean;
  event: IShipmentTimelineEntry;
}>`
  color: ${({ theme, isAlertClosed, event }) =>
    event.eventType === 'ALERT_BOX_CODE'
      ? theme.palette.custom.error.red[100]
      : isAlertClosed
      ? theme.palette.custom.neutral.black[100]
      : theme.palette.custom.error.red[100]};
`;

EventDataText.defaultProps = {
  variant: 'bodyTextS',
};

const EventDateText = styled(Text)`
  color: ${({ theme }) => theme.palette.custom.neutral.black[50]};
`;

EventDateText.defaultProps = {
  variant: 'bodyTextS',
};

const EventIconContainer = styled(Box, {
  shouldForwardProp: (prop: string) =>
    !['isShipmentTimelineItemAlert', 'isAlertClosed', 'event'].includes(prop),
})<{
  isShipmentTimelineItemAlert: boolean;
  isAlertClosed: boolean;
  event: IShipmentTimelineEntry;
}>`
  width: 48px;
  height: 48px;
  border-radius: 14px;
  background-color: ${({
    theme,
    isShipmentTimelineItemAlert,
    isAlertClosed,
    event,
  }) =>
    !isShipmentTimelineItemAlert
      ? theme.palette.custom.primary[10]
      : event.eventType === 'ALERT_BOX_CODE'
      ? theme.palette.custom.error.red[50]
      : isAlertClosed
      ? theme.palette.custom.neutral.black[3]
      : theme.palette.custom.error.red[50]};
  text-align: center;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EventIcon = ({
  event,
  isShipmentTimelineItemAlert,
}: {
  event: IShipmentTimelineEntry;
  isShipmentTimelineItemAlert: boolean;
}) => {
  const theme = useTheme();
  const { getAlertThreshold, getAlertReachedValue } = useShipmentDetail();
  const getIconColor = useCallback(
    (event: IShipmentTimelineEntry) => {
      if (event.eventType === ShipmentTimelineEventTypeEnum.UNKNOWN) {
        return theme.palette.custom.neutral.black[100];
      }

      if (
        event.eventType === ShipmentTimelineEventTypeEnum.DEPLOYED ||
        event.eventType === ShipmentTimelineEventTypeEnum.ARRIVED
      ) {
        return theme.palette.custom.primary[100];
      }

      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BOX_CODE) {
        return theme.palette.custom.neutral.white.pure;
      }

      return !event.closedAt
        ? theme.palette.custom.neutral.white.pure
        : theme.palette.custom.neutral.black[50];
    },
    [theme]
  );

  const getIconComponent = useCallback(
    (event: IShipmentTimelineEntry) => {
      if (event.eventType === ShipmentTimelineEventTypeEnum.UNKNOWN)
        return <CloseIcon color={getIconColor(event)} />;

      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BOX_CODE)
        return <LockOnIcon2 color={getIconColor(event)} />;

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL
      ) {
        if (getAlertReachedValue(event) > getAlertThreshold(event)) {
          return <TemperatureHighIcon color={getIconColor(event)} />;
        } else if (getAlertReachedValue(event) < getAlertThreshold(event)) {
          return <TemperatureLowIcon color={getIconColor(event)} />;
        } else {
          return <TemperatureIcon color={getIconColor(event)} />;
        }
      }

      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_SHOCK) {
        return <ShockIcon color={getIconColor(event)} />;
      }

      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_FALL) {
        return <FallIcon color={getIconColor(event)} />;
      }

      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_PRESSURE) {
        if (getAlertReachedValue(event) > getAlertThreshold(event)) {
          return <AirPressureHighIcon color={getIconColor(event)} />;
        } else if (getAlertReachedValue(event) < getAlertThreshold(event)) {
          return <AirPressureLowIcon color={getIconColor(event)} />;
        } else {
          return <AirPressureIcon color={getIconColor(event)} />;
        }
      }

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL
      ) {
        if (getAlertReachedValue(event) > getAlertThreshold(event)) {
          return <HumidityHighIcon color={getIconColor(event)} />;
        } else if (getAlertReachedValue(event) < getAlertThreshold(event)) {
          return <HumidityLowIcon color={getIconColor(event)} />;
        } else {
          return <HumidityIcon color={getIconColor(event)} />;
        }
      }

      if (
        event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BOX_VIOLATION
      ) {
        return <BoxOpenedIcon color={getIconColor(event)} />;
      }

      if (event.eventType === ShipmentTimelineEventTypeEnum.DEPLOYED) {
        return <CheckOutlineIcon color={getIconColor(event)} />;
      }
      if (event.eventType === ShipmentTimelineEventTypeEnum.ARRIVED) {
        return <ShipmentCompletedIcon color={getIconColor(event)} />;
      }

      if (
        event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL
      ) {
        return <BatteryLowIcon color={getIconColor(event)} />;
      }

      return null;
    },
    [getIconColor, getAlertReachedValue, getAlertThreshold]
  );

  return (
    <EventIconContainer
      isShipmentTimelineItemAlert={isShipmentTimelineItemAlert}
      isAlertClosed={event.closedAt !== undefined}
      event={event}
    >
      {getIconComponent(event)}
    </EventIconContainer>
  );
};

const DisplayAlertInformationWrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'displayAlertInformation' &&
    prop !== 'isAlertInformationAlreadyDisplayed',
})<{
  displayAlertInformation: boolean;
  isAlertInformationAlreadyDisplayed: boolean;
}>`
  height: ${({ displayAlertInformation, isAlertInformationAlreadyDisplayed }) =>
    !displayAlertInformation && !isAlertInformationAlreadyDisplayed
      ? '0'
      : 'auto'};
`;

const ShipmentEventsTimelineWrapper = ({
  index,
  event,
  showLastLine,
  showMore,
  timelineEvents,
  fullList,
  reducedListSize,
  displayAlertInformationByDefault = false,
}: {
  index: number;
  event: IShipmentTimelineEntry;
  showLastLine: (index: number) => boolean;
  showMore: boolean;
  timelineEvents: IShipmentTimelineEntry[];
  fullList: boolean;
  reducedListSize: number;
  displayAlertInformationByDefault: boolean;
}) => {
  const { t } = useTranslation('shipments');
  const formatTimestamp = useIntlFormatTimestamp();
  const eventRef = useRef<HTMLDivElement>(null);

  const {
    isShipmentTimelineItemAlert,
    getAlertReachedValue,
    isAlertClosed,
    isAlertWithGauge,
  } = useShipmentDetail();

  const [displayAlertInformation, setDisplayAlertInformation] = useState(
    displayAlertInformationByDefault
  );

  const [
    isAlertInformationAlreadyDisplayed,
    setIsAlertInformationAlreadyDisplayed,
  ] = useState<boolean>(false);

  const [isAlreadyScrollToElement, setIsAlreadyScrollToElement] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      eventRef?.current &&
      !isAlreadyScrollToElement &&
      displayAlertInformationByDefault
    ) {
      eventRef.current.scrollIntoView({
        behavior: 'smooth',
      });
      setIsAlreadyScrollToElement(true);
    }
  }, [displayAlertInformationByDefault, eventRef, isAlreadyScrollToElement]);

  const handleWrapperClick = useCallback(() => {
    setDisplayAlertInformation(!displayAlertInformation);
  }, [displayAlertInformation]);

  const getEventAlertInformationComponent = useCallback(
    (event: IShipmentTimelineEntry) => {
      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BOX_CODE) {
        return <EventBoxCode />;
      }

      if (
        event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BOX_VIOLATION
      ) {
        return <EventBoxViolationBlock event={event} />;
      }

      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_SHOCK) {
        return <EventShockBlock event={event} />;
      }

      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_FALL) {
        return <EventFallBlock event={event} />;
      }

      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_PRESSURE) {
        return <EventAirPressureBlock event={event} />;
      }

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL
      ) {
        return <EventTemperatureBlock event={event} />;
      }

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL
      ) {
        return <EventHumidityBlock event={event} />;
      }

      if (
        event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL
      ) {
        return <EventBatteryLevelBlock event={event} />;
      }
    },
    []
  );

  const getEventAlertInformationData = useCallback(
    (event: IShipmentTimelineEntry) => {
      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BOX_CODE) {
        return t('detail.timeline.data.codeErrors');
      }
      if (
        event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BOX_VIOLATION
      ) {
        if (
          (event.payload as IShipmentTimelineEntryPayloadBoxViolation).value
            .coverOpen
        ) {
          return t('detail.timeline.data.boxViolation.coverOpen');
        }
        if (
          (event.payload as IShipmentTimelineEntryPayloadBoxViolation).value
            .keyPresent
        ) {
          return t('detail.timeline.data.boxViolation.keyPresent');
        }
        if (
          (event.payload as IShipmentTimelineEntryPayloadBoxViolation).value
            .lightSensorOn
        ) {
          return t('detail.timeline.data.boxViolation.lightSensorOn');
        }
      }

      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_SHOCK) {
        return t('detail.timeline.data.shock', {
          value: (event.payload as IShipmentTimelineEntryMonoInfo).value,
        });
      }

      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_FALL) {
        return t('detail.timeline.data.fall', {
          value: (event.payload as IShipmentTimelineEntryMonoInfo).value,
        });
      }

      if (event.eventType === ShipmentTimelineEventTypeEnum.ALERT_PRESSURE) {
        return t('detail.timeline.data.pressureReached', {
          value: getAlertReachedValue(event),
        });
      }

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL
      ) {
        return t('detail.timeline.data.temperatureReached', {
          value: getAlertReachedValue(event),
        });
      }

      if (
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL ||
        event.eventType ===
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL
      ) {
        return t('detail.timeline.data.humidityReached', {
          value: getAlertReachedValue(event),
        });
      }

      if (
        event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL
      ) {
        return isAlertClosed(event)
          ? t('detail.timeline.data.batteryLevel', {
              value: getAlertReachedValue(event),
            })
          : t('detail.timeline.data.batteryLevelMessage');
      }

      return null;
    },
    [t, getAlertReachedValue, isAlertClosed]
  );

  const isEventAlertInformationDataDisplayed = useCallback(
    (
      event: IShipmentTimelineEntry,
      displayAlertInformation: boolean
    ): boolean => {
      if (displayAlertInformation) {
        return ![
          ShipmentTimelineEventTypeEnum.ALERT_SHOCK,
          ShipmentTimelineEventTypeEnum.ALERT_FALL,
          ShipmentTimelineEventTypeEnum.ALERT_PRESSURE,
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_INTERNAL,
          ShipmentTimelineEventTypeEnum.ALERT_TEMPERATURE_EXTERNAL,
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_INTERNAL,
          ShipmentTimelineEventTypeEnum.ALERT_HUMIDITY_EXTERNAL,
          ShipmentTimelineEventTypeEnum.ALERT_BATTERY_LEVEL,
        ].includes(event.eventType);
      }

      return true;
    },
    []
  );

  useEffect(() => {
    if (displayAlertInformation && !isAlertInformationAlreadyDisplayed) {
      setIsAlertInformationAlreadyDisplayed(true);
    }
  }, [displayAlertInformation, isAlertInformationAlreadyDisplayed]);

  const props = useSpring({
    to: { height: '0', opacity: 0, zIndex: 0 },
    from: {
      height:
        event.eventType === ShipmentTimelineEventTypeEnum.ALERT_BOX_CODE
          ? '1.3rem'
          : isAlertWithGauge(event)
          ? '4.5625rem'
          : '3.875rem',
      opacity: 1,
      zIndex: 1,
    },
    reverse: displayAlertInformation,
  });

  const eventAlertInformationDataBlockProps = useSpring({
    to: { height: '0', opacity: 0, marginTop: '0' },
    from: {
      height: '12px',
      opacity: 1,
      marginTop: '5px',
    },
    reverse: isEventAlertInformationDataDisplayed(
      event,
      displayAlertInformation
    ),
  });

  return (
    <div ref={eventRef}>
      <Wrapper
        data-testid="eventItem"
        onClick={handleWrapperClick}
        isShipmentTimelineItemAlert={isShipmentTimelineItemAlert(event)}
      >
        <TimelineWrapper>
          <TimeLineIcon
            index={index}
            event={event}
            showLastLine={showLastLine}
            showMore={showMore}
            fullList={fullList}
            nEvents={
              timelineEvents.slice(0, fullList ? undefined : reducedListSize)
                .length
            }
          />
        </TimelineWrapper>

        <EventInformationContainer
          displayAlertInformation={displayAlertInformation}
        >
          <Stack direction="row" alignItems="center" width="100%">
            <Stack direction="row" alignItems="center" flex="1 1 0%">
              <EventIcon
                event={event}
                isShipmentTimelineItemAlert={isShipmentTimelineItemAlert(event)}
              />
              <Stack marginLeft="0 !important">
                <EventNameText>
                  {t(
                    `detail.timeline.event.${event.eventType}` as unknown as TemplateStringsArray
                  )}
                </EventNameText>
                {isShipmentTimelineItemAlert(event) && (
                  <animated.div style={eventAlertInformationDataBlockProps}>
                    {isEventAlertInformationDataDisplayed(
                      event,
                      displayAlertInformation
                    ) && (
                      <EventDataText
                        event={event}
                        isAlertClosed={event.closedAt !== undefined}
                      >
                        {getEventAlertInformationData(event)}
                      </EventDataText>
                    )}
                  </animated.div>
                )}
              </Stack>
            </Stack>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <EventDateText>
                {formatTimestamp(event.createdAt, {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </EventDateText>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              marginRight="25px"
            >
              {isShipmentTimelineItemAlert(event) &&
                (!displayAlertInformation ? (
                  <ChevronDownIcon />
                ) : (
                  <ChevronUpIcon />
                ))}
            </Box>
          </Stack>
          {isShipmentTimelineItemAlert(event) && (
            <DisplayAlertInformationWrapper
              displayAlertInformation={displayAlertInformation}
              isAlertInformationAlreadyDisplayed={
                isAlertInformationAlreadyDisplayed
              }
            >
              <animated.div style={props}>
                {displayAlertInformation &&
                  getEventAlertInformationComponent(event)}
              </animated.div>
            </DisplayAlertInformationWrapper>
          )}
        </EventInformationContainer>
      </Wrapper>
    </div>
  );
};

const ShipmentEventsTimeline = ({
  showMore,
  timelineEvents,
}: {
  showMore: boolean;
  timelineEvents: IShipmentTimelineEntry[];
}) => {
  const theme = useTheme();
  const { t } = useTranslation('shipments');
  const { alertId } = useParams<{ alertId: string }>();

  const [fullList, setFullList] = useState(alertId !== undefined);

  const showLastLine = (index: number) => {
    if (
      index ===
      timelineEvents.slice(0, fullList ? undefined : REDUCED_LIST_SIZE).length -
        1
    ) {
      return showMore && !fullList;
    }

    return true;
  };

  return (
    <Card>
      {timelineEvents
        .slice(0, fullList ? undefined : REDUCED_LIST_SIZE)
        .map((event: IShipmentTimelineEntry, index: number) => (
          <ShipmentEventsTimelineWrapper
            key={index}
            data-testid="eventItem"
            event={event}
            showLastLine={showLastLine}
            showMore={showMore}
            index={index}
            timelineEvents={timelineEvents}
            fullList={fullList}
            reducedListSize={REDUCED_LIST_SIZE}
            displayAlertInformationByDefault={
              event.eventID !== undefined && event.eventID === alertId
            }
          />
        ))}
      {showMore && (
        <Grid
          container
          alignItems="center"
          data-testid="showMore"
          onClick={() => setFullList(prev => !prev)}
          sx={{
            cursor: 'pointer',
            pt: '1rem',
            pl: '3.5rem',
          }}
        >
          <Text
            variant="bodyTextL"
            color="custom.primary.100"
            mr={2}
            display="inline"
          >
            {fullList ? t('showLess') : t('showMore')}
          </Text>
          <ChevronDownIcon
            color={theme.palette.primary.main}
            style={{
              transform: fullList ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: '500ms',
            }}
          />
        </Grid>
      )}
    </Card>
  );
};

export default ShipmentEventsTimeline;
