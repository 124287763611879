// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useContext, useEffect, useState } from 'react';

import {
  Checkbox,
  ModalV2 as Modal,
  Stack,
} from '@livingpackets/design-system-react-next';
import { usePartnership } from 'features/account';
import { get } from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { ConfirmDefaultAddressModal } from './ConfirmDefaultAddressModal';
import { DefaultAddressesContext } from '../contactAddress/PartnerContactAddressAdd';

export const NEW_DEFAULT_ADDRESS = 'NEW_DEFAULT_ADDRESS';

type Props = {
  addressId: string | null;
  isDismissTriggered?: boolean;
  prefix?: string;
};

export const DefaultAddresses = ({
  addressId,
  isDismissTriggered,
  prefix,
}: Props) => {
  const { t } = useTranslation('contactAddress');

  const { setDefaultSenderAddress, setDefaultRecipientAddress } = useContext(
    DefaultAddressesContext
  );

  const { data: partnership } = usePartnership();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedType, setSelectedType] = useState<string | undefined>();

  // Local two checkboxes states
  const [isDefaultSender, setIsDefaultSender] = useState(false);
  const [isDefaultRecipient, setIsDefaultRecipient] = useState(false);

  useEffect(() => {
    if (!partnership) return;

    if (addressId === partnership?.default_sender_address_id) {
      setIsDefaultSender(true);
    } else {
      setIsDefaultSender(false);
    }
    if (addressId === partnership?.default_recipient_address_id) {
      setIsDefaultRecipient(true);
    } else {
      setIsDefaultRecipient(false);
    }
  }, [partnership, addressId]);

  useEffect(() => {
    if (isDismissTriggered) {
      setIsDefaultSender(false);
      setIsDefaultRecipient(false);
    }
  }, [isDismissTriggered]);

  const onConfirm = () => setShowConfirmationModal(false);
  const onCancel = () => {
    setShowConfirmationModal(false);

    if (selectedType === 'sender') {
      setIsDefaultSender(false);
      // Clear context aswell
      setDefaultSenderAddress(undefined);
    } else {
      setIsDefaultRecipient(false);
      // Clear context aswell
      setDefaultRecipientAddress(undefined);
    }
  };

  const onChange =
    ({ type, attributeName, localSetter, ctxSetter }: any) =>
    (e: any) => {
      // Add Address
      if (e.target.checked && !addressId) {
        // Already have default
        if (get(attributeName, partnership)) {
          setSelectedType(type);
          setShowConfirmationModal(true);
        }

        ctxSetter(`${NEW_DEFAULT_ADDRESS}${prefix}`);
        localSetter(e.target.checked);

        return;
      }

      if (
        e.target.checked &&
        addressId &&
        get(attributeName, partnership) !== addressId
      ) {
        if (get(attributeName, partnership) === '') {
          ctxSetter(addressId);
        } else {
          setSelectedType(type);
          setShowConfirmationModal(true);

          // Update context for parent component
          ctxSetter(addressId || `${NEW_DEFAULT_ADDRESS}${prefix}`);
        }
      } else {
        ctxSetter(undefined);
      }

      if (!e.target.checked) {
        ctxSetter('');
      }

      localSetter(e.target.checked);
    };

  return (
    <Stack direction="row" gap="1rem">
      <Checkbox
        data-testid={`default-sender-address-${prefix}`}
        name={`default-sender-address-${prefix}`}
        label={t('defaultAddress.sender.checkboxLabel')}
        checked={isDefaultSender}
        onChange={onChange({
          type: 'sender',
          attributeName: 'default_sender_address_id',
          localSetter: setIsDefaultSender,
          ctxSetter: setDefaultSenderAddress,
        })}
      />
      <Checkbox
        data-testid={`default-recipient-address-${prefix}`}
        name={`default-recipient-address-${prefix}`}
        label={t('defaultAddress.recipient.checkboxLabel')}
        checked={isDefaultRecipient}
        onChange={onChange({
          type: 'recipient',
          attributeName: 'default_recipient_address_id',
          localSetter: setIsDefaultRecipient,
          ctxSetter: setDefaultRecipientAddress,
        })}
      />
      <Modal
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        onDismiss={onCancel}
      >
        <ConfirmDefaultAddressModal
          type={selectedType}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </Modal>
    </Stack>
  );
};
