// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useMemo } from 'react';

import { NewTextField as TextField } from '@livingpackets/design-system-react-next';
import Dropdown from 'components/atoms/Dropdown';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import get from 'lodash/get';
import {
  Control,
  Controller,
  RegisterOptions,
  useController,
} from 'react-hook-form';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { LayoutProps } from 'styled-system';

interface ICountrySelector extends LayoutProps {
  id?: string;
  control: Control;
  rules?: RegisterOptions;
  width?: string;
  prefix?: string;
  isDisabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  fieldName?: string;
  register?: UseFormRegister<any>;
  required?: boolean;
}

const CountrySelector = ({
  id,
  control,
  rules,
  prefix,
  width = '17.5rem',
  isDisabled = false,
  onChange,
  fieldName,
  required,
}: ICountrySelector) => {
  const name = useMemo(() => {
    const baseName = fieldName ? fieldName : 'countryCode';

    if (prefix) {
      return `${prefix}${baseName}`;
    }

    return baseName;
  }, [prefix, fieldName]);

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue: '',
  });

  const handleOnChange = useCallback(
    (option: any) => {
      field.onChange(option.value);
      field.onBlur();

      if (onChange) {
        onChange(option);
      }
    },
    [field, onChange]
  );

  const {
    t,
    i18n: { language },
  } = useTranslation('address');
  const [countryCodes, getCountryNameFromCode] = useCountryCodesWrapper();

  const formattedValue = useMemo(
    () => ({
      label: getCountryNameFromCode(field.value || ''),
      value: field.value || '',
    }),
    [getCountryNameFromCode, field.value]
  );

  const getOptionLabel = useCallback(
    (option: any) =>
      language === 'en'
        ? option['name']
        : get(option, `translations.${language}`, option['name']),
    [language]
  );

  const sortedCountries = useMemo(() => {
    if (countryCodes?.data?.length > 0) {
      switch (language) {
        case 'en':
          return countryCodes.data.map((c: { alpha2Code: any; name: any }) => ({
            value: c.alpha2Code,
            label: c.name,
          }));
        case 'de':
          return countryCodes.data
            .slice()
            .sort((a: any, b: any) =>
              get(a, 'translations.de', 'name').localeCompare(
                get(b, 'translations.de', 'name'),
                'de',
                { ignorePunctuation: true }
              )
            )
            .map((c: any) => ({
              value: c.alpha2Code,
              label: getOptionLabel(c),
            }));
        case 'fr':
          return countryCodes.data
            .slice()
            .sort((a: any, b: any) =>
              get(a, 'translations.fr', 'name').localeCompare(
                get(b, 'translations.fr', 'name'),
                'fr',
                { ignorePunctuation: true }
              )
            )
            .map((c: any) => ({
              value: c.alpha2Code,
              label: getOptionLabel(c),
            }));
        default:
          return countryCodes.data.map((c: { alpha2Code: any; name: any }) => ({
            value: c.alpha2Code,
            label: c.name,
          }));
      }
    }

    return [];
  }, [countryCodes, language, getOptionLabel]);

  if (isDisabled) {
    return (
      <Controller
        name="country"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            required
            id={id}
            placeholder={t('country')}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            disabled
            sx={{
              '& .MuiInputBase-input': {
                pb: '2.8125rem',
              },
              width: `${width}`,
            }}
            {...field}
            value={formattedValue.label}
          />
        )}
      />
    );
  }

  return (
    <Dropdown
      id={id}
      options={sortedCountries}
      label={`${t('country')}${required ? ' *' : ''}`}
      value={formattedValue}
      onChange={handleOnChange}
      name={name}
      width={width}
      isSearchable={true}
      error={fieldState.error}
    />
  );
};

export default CountrySelector;
