// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  LocationIcon,
  AddIcon,
  MinusIcon,
  Box,
  Text,
  Stack,
  styled,
} from '@livingpackets/design-system-react-next';
import { InfoBox, Marker, MarkerClusterer } from '@react-google-maps/api';
import {
  Cluster,
  Clusterer,
  ClustererOptions,
  ClusterIconStyle,
  MarkerExtended,
} from '@react-google-maps/marker-clusterer';
import imgMapCluster1 from 'assets/img/map/product/cluster/1.png';
import imgMapCluster2 from 'assets/img/map/product/cluster/2.png';
import imgMapCluster3 from 'assets/img/map/product/cluster/3.png';
import imgMapCluster4 from 'assets/img/map/product/cluster/4.png';
// Marker Open
import MarkerBoxAvailableOpenImg from 'assets/img/map/product/marker/marker-box-available-open.png';
import MarkerBoxAvailableOpenImg2x from 'assets/img/map/product/marker/marker-box-available-open@2x.png';
import MarkerBoxAvailableOpenImg3x from 'assets/img/map/product/marker/marker-box-available-open@3x.png';
import MarkerBoxAvailableImg from 'assets/img/map/product/marker/marker-box-available.png';
import MarkerBoxInShipmentOpenImg from 'assets/img/map/product/marker/marker-box-in-shipment-open.png';
import MarkerBoxInShipmentOpenImg2x from 'assets/img/map/product/marker/marker-box-in-shipment-open@2x.png';
import MarkerBoxInShipmentOpenImg3x from 'assets/img/map/product/marker/marker-box-in-shipment-open@3x.png';
import MarkerBoxInShipmentImg from 'assets/img/map/product/marker/marker-box-in-shipment.png';
import MarkerBoxProAvailableOpenImg from 'assets/img/map/product/marker/marker-box-pro-available-open.png';
import MarkerBoxProAvailableOpenImg2x from 'assets/img/map/product/marker/marker-box-pro-available-open@3x.png';
import MarkerBoxProAvailableOpenImg3x from 'assets/img/map/product/marker/marker-box-pro-available-open@3x.png';
import MarkerBoxProAvailableImg from 'assets/img/map/product/marker/marker-box-pro-available.png';
import MarkerBoxProInShipmentOpenImg from 'assets/img/map/product/marker/marker-box-pro-in-shipment-open.png';
import MarkerBoxProInShipmentOpenImg2x from 'assets/img/map/product/marker/marker-box-pro-in-shipment-open@2x.png';
import MarkerBoxProInShipmentOpenImg3x from 'assets/img/map/product/marker/marker-box-pro-in-shipment-open@3x.png';
import MarkerBoxProInShipmentImg from 'assets/img/map/product/marker/marker-box-pro-in-shipment.png';
import MarkerTabletAvailableOpenImg from 'assets/img/map/product/marker/marker-tablet-available-open.png';
import MarkerTabletAvailableOpenImg2x from 'assets/img/map/product/marker/marker-tablet-available-open@2x.png';
import MarkerTabletAvailableOpenImg3x from 'assets/img/map/product/marker/marker-tablet-available-open@3x.png';
import MarkerTabletAvailableImg from 'assets/img/map/product/marker/marker-tablet-available.png';
import MarkerTabletInShipmentOpenImg from 'assets/img/map/product/marker/marker-tablet-in-shipment-open.png';
import MarkerTabletInShipmentOpenImg2x from 'assets/img/map/product/marker/marker-tablet-in-shipment-open@2x.png';
import MarkerTabletInShipmentOpenImg3x from 'assets/img/map/product/marker/marker-tablet-in-shipment-open@3x.png';
// Marker Close
import MarkerTabletInShipmentImg from 'assets/img/map/product/marker/marker-tablet-in-shipment.png';
import Map from 'components/molecules/Map';
import ProductListMapControl from 'components/molecules/product/ProductListMapControl';
import { ProductMapContractStateTypeEnum } from 'components/organisms/PartnerInChargeProducts';
import { PATHS } from 'configs';
import { ProductTypeEnum } from 'enums/ProductEnum';
import { useAddresses, useAddressByType } from 'features/account';
import useIntlDistanceTimestamp from 'hooks/useIntlDistanceTimestamp';
import useProductDetail from 'hooks/useProductDetail';
import useProductMap from 'hooks/useProductMap';
import useProductPositionList, {
  useProductPositionStore,
} from 'hooks/useProductPositionList';
import useToastMessages from 'hooks/useToastMessages';
import { isEmpty } from 'lodash/fp';
import { IProductPosition } from 'models/position';
import { ProductPositionModel } from 'models/product';
import { ShipmentContractStateEnum } from 'models/shipment';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const ProductMapCenterCurrentPositionBtn = styled(Box)`
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductMapCustomZoomChangeBtn = styled('div')`
  cursor: pointer;
  width: 40px;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 8px;
`;

const ProductMapCustomZoomChangeDividerBtn = styled('div')`
  width: 24px;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[8]};
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
`;

const getPosition = (
  productPosition: ProductPositionModel
): IProductPosition | undefined => {
  const productLat = productPosition.location.latitude;
  const productLong = productPosition.location.longitude;
  let productPos;
  if (productLat && productLong) {
    productPos = {
      id: productPosition.lp_ui,
      position: {
        lat: productLat,
        lng: productLong,
      } as google.maps.LatLngLiteral,
      contractState: productPosition.contract_state,
      contractStateUpdatedAt: productPosition.contract_state_updated_at,
      productType: productPosition.productType,
      deployedShipmentId: productPosition.deployed_shipment_id,
    } as IProductPosition;
  }

  return productPos;
};

const markerClustererOptions = {
  zoomOnClick: false,
  averageCenter: false,
  ignoreHidden: true,
  imageSizes: [26, 34, 38, 74],
  imageExtension: 'png',
  imagePath: process.env.PUBLIC_URL + '/img/map/product/cluster/',
  styles: [
    {
      url: imgMapCluster1,
      height: 26,
      width: 26,
      textColor: '#ffffff',
      textSize: 12,
      fontWeight: '500',
      fontFamily: 'TT-Norms-Pro',
    },
    {
      url: imgMapCluster2,
      height: 34,
      width: 34,
      textColor: '#ffffff',
      textSize: 12,
      fontWeight: '500',
      fontFamily: 'TT-Norms-Pro',
    },
    {
      url: imgMapCluster3,
      height: 38,
      width: 38,
      textColor: '#ffffff',
      textSize: 12,
      fontWeight: '400',
      fontFamily: 'TT-Norms-Pro',
    },
    {
      url: imgMapCluster4,
      height: 74,
      width: 74,
      textColor: '#ffffff',
      textSize: 12,
      fontWeight: '400',
      fontFamily: 'TT-Norms-Pro',
    },
  ] as ClusterIconStyle[],
} as ClustererOptions;

const InfoBoxContainer = styled(Stack)`
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  padding: 8px;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  margin: 0 !important;
`;

const InfoWindowContractStateInformation = styled(Box, {
  shouldForwardProp: prop => prop !== 'isInShipment',
})<{
  isInShipment: boolean;
}>`
  height: 29px;
  width: 86px;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  background: ${({ theme, isInShipment }) =>
    isInShipment
      ? theme.palette.custom.primary[10]
      : theme.palette.custom.neutral.black[3]};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-bottom: 7px;
`;
const MarkerProduct = ({
  productPos,
  cluster,
  currentMarkerIdOpened,
  updateCurrentMarkerIdOpened,
  mapInstance,
}: {
  productPos: IProductPosition;
  cluster: any;
  currentMarkerIdOpened?: string;
  updateCurrentMarkerIdOpened: (currentMarkerIdOpened?: string) => void;
  mapInstance?: google.maps.Map;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('products');

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const intlDistanceTimestamp = useIntlDistanceTimestamp();

  const [marker, setMarker] = useState<google.maps.Marker | undefined>(
    undefined
  );

  const {
    setProductMapCenterCurrent,
    setProductMapProductOpenCurrent,
    setProductMapZoomCurrent,
  } = useProductMap();

  const isMarkerVisible = useCallback(
    (productPosId: string): boolean => currentMarkerIdOpened !== productPosId,
    [currentMarkerIdOpened]
  );

  const getMarkerImg = useCallback((productPos: IProductPosition) => {
    const isDeployed =
      productPos.contractState === ShipmentContractStateEnum.DEPLOYED;

    switch (productPos.productType) {
      case ProductTypeEnum.BOX:
        return isDeployed ? MarkerBoxInShipmentImg : MarkerBoxAvailableImg;
      case ProductTypeEnum.BOX_PRO:
        return isDeployed
          ? MarkerBoxProInShipmentImg
          : MarkerBoxProAvailableImg;
      case ProductTypeEnum.TABLET:
        return isDeployed
          ? MarkerTabletInShipmentImg
          : MarkerTabletAvailableImg;
      default:
        return isDeployed ? MarkerBoxInShipmentImg : MarkerBoxAvailableImg;
    }
  }, []);

  const getMarkerOpenImg = useCallback((productPos: IProductPosition) => {
    const isDeployed =
      productPos.contractState === ShipmentContractStateEnum.DEPLOYED;

    let imgSrc;
    let imgSrc2x;
    let imgSrc3x;

    switch (productPos.productType) {
      case ProductTypeEnum.BOX:
        imgSrc = isDeployed
          ? MarkerBoxInShipmentOpenImg
          : MarkerBoxAvailableOpenImg;
        imgSrc2x = isDeployed
          ? MarkerBoxInShipmentOpenImg2x
          : MarkerBoxAvailableOpenImg2x;
        imgSrc3x = isDeployed
          ? MarkerBoxInShipmentOpenImg3x
          : MarkerBoxAvailableOpenImg3x;
        break;
      case ProductTypeEnum.BOX_PRO:
        imgSrc = isDeployed
          ? MarkerBoxProInShipmentOpenImg
          : MarkerBoxProAvailableOpenImg;
        imgSrc2x = isDeployed
          ? MarkerBoxProInShipmentOpenImg2x
          : MarkerBoxProAvailableOpenImg2x;
        imgSrc3x = isDeployed
          ? MarkerBoxProInShipmentOpenImg3x
          : MarkerBoxProAvailableOpenImg3x;
        break;
      case ProductTypeEnum.TABLET:
        imgSrc = isDeployed
          ? MarkerTabletInShipmentOpenImg
          : MarkerTabletAvailableOpenImg;
        imgSrc2x = isDeployed
          ? MarkerTabletInShipmentOpenImg2x
          : MarkerTabletAvailableOpenImg2x;
        imgSrc3x = isDeployed
          ? MarkerTabletInShipmentOpenImg3x
          : MarkerTabletAvailableOpenImg3x;
        break;
      default:
        imgSrc = isDeployed
          ? MarkerBoxInShipmentOpenImg
          : MarkerBoxAvailableOpenImg;
        imgSrc2x = isDeployed
          ? MarkerBoxInShipmentOpenImg2x
          : MarkerBoxAvailableOpenImg2x;
        imgSrc3x = isDeployed
          ? MarkerBoxInShipmentOpenImg3x
          : MarkerBoxAvailableOpenImg3x;
    }

    return (
      <img
        src={imgSrc}
        srcSet={`${imgSrc2x} 2x, ${imgSrc3x} 3x`}
        style={{ width: '40px', height: '60px', marginRight: '8px' }}
        alt="Marker Product Info"
      />
    );
  }, []);

  const iconMarker = {
    url: getMarkerImg(productPos),
    size: { height: 32, width: 32 },
    scaledSize: { height: 32, width: 32 },
  } as google.maps.Icon;

  const onLoad = (marker: google.maps.Marker) => {
    marker.set('markerProductId', productPos.id);
    marker.setVisible(isMarkerVisible(productPos.id));

    setMarker(marker);
  };

  const onMarkerInfoBoxClick = useCallback(
    (productPosId: string, productShipmentId?: string) => {
      if (mapInstance) {
        const currentZoom = mapInstance.getZoom();

        if (currentZoom) {
          setProductMapZoomCurrent(currentZoom);
        }

        setProductMapCenterCurrent(mapInstance.getCenter());
        setProductMapProductOpenCurrent(productPosId);
      }

      if (productShipmentId) {
        navigate(
          generatePath(PATHS.SHIPMENT.DETAIL, {
            partnerId: activePartner.id,
            shipmentId: productShipmentId,
          }),
          { state: { isPreviousPageProductListPage: true } }
        );
      } else {
        navigate(
          generatePath(PATHS.PRODUCT.DETAIL, {
            partnerId: activePartner.id,
            lpUi: productPosId,
          })
        );
      }
    },
    [
      activePartner.id,
      navigate,
      mapInstance,
      setProductMapCenterCurrent,
      setProductMapProductOpenCurrent,
      setProductMapZoomCurrent,
    ]
  );

  return (
    <Marker
      onLoad={onLoad}
      icon={iconMarker}
      position={productPos.position}
      clusterer={cluster}
      data-testid={`marker-product-${productPos.id}`}
      key={`marker-product-${productPos.id}`}
      visible={isMarkerVisible(productPos.id)}
      onClick={() => {
        if (marker) {
          marker.setVisible(false);
          updateCurrentMarkerIdOpened(productPos.id);
        }
      }}
      onVisibleChanged={() => {
        // Condition to avoid missing marker in the map
        if (marker && !marker.getMap() && mapInstance) {
          marker.setMap(mapInstance);
        }
      }}
    >
      <InfoBox
        position={new google.maps.LatLng(productPos.position)}
        key={`infobox-${productPos.id}`}
        options={{
          visible: !isMarkerVisible(productPos.id),
          disableAutoPan: true,
          alignBottom: false,
          pixelOffset: new google.maps.Size(-20, -65),
          maxWidth: 300,
          infoBoxClearance: new google.maps.Size(100, 100),
          enableEventPropagation: true,
          boxStyle: {
            width: '100%',
            maxWidth: '180px',
            cursor: 'pointer',
          },
        }}
      >
        <Stack
          direction="row"
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            if (marker) {
              marker.setVisible(true);
              updateCurrentMarkerIdOpened(undefined);
            }
          }}
        >
          {getMarkerOpenImg(productPos)}
          <InfoBoxContainer
            onClick={() => {
              onMarkerInfoBoxClick(
                productPos.id,
                productPos.deployedShipmentId
              );
            }}
          >
            <InfoWindowContractStateInformation
              isInShipment={
                productPos.contractState === ShipmentContractStateEnum.DEPLOYED
              }
            >
              <Text
                variant="bodyTextXS"
                color={
                  productPos.contractState ===
                  ShipmentContractStateEnum.DEPLOYED
                    ? 'custom.primary.100'
                    : 'custom.neutral.black.50'
                }
              >
                {productPos.contractState === ShipmentContractStateEnum.DEPLOYED
                  ? t('common.contractState.deployed')
                  : t('common.contractState.available')}
              </Text>
            </InfoWindowContractStateInformation>
            <Text
              variant="bodyTextXS"
              color="custom.neutral.black.100"
              sx={{ textAlign: 'center' }}
            >
              {t('detail.lastContractStateChange', {
                lastContractStateChange: intlDistanceTimestamp(
                  productPos.contractStateUpdatedAt
                ),
              })}
            </Text>
          </InfoBoxContainer>
        </Stack>
      </InfoBox>
    </Marker>
  );
};

const ProductListMap = ({
  productListContractStateTypeSelectedList,
  selectedProductType,
}: {
  productListContractStateTypeSelectedList: ProductMapContractStateTypeEnum[];
  selectedProductType: ProductTypeEnum;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('messages');

  const { error: errorToast } = useToastMessages();

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const { getProductPositionList } = useProductPositionList();
  const { getProductType } = useProductDetail();

  const { data: addresses } = useAddresses();
  const mainAddress = useAddressByType({ addresses });

  const {
    getProductMapZoomCurrent,
    setProductMapZoomCurrent,
    getProductMapCenterCurrent,
    setProductMapCenterCurrent,
    getProductMapProductOpenCurrent,
    setProductMapProductOpenCurrent,
  } = useProductMap();

  const [productPositionListData, setProductPositionListData] =
    useState<ProductPositionModel[]>();

  const [currentMarkerIdOpened, setCurrentMarkerIdOpened] = useState<
    string | undefined
  >(
    getProductMapProductOpenCurrent()
      ? getProductMapProductOpenCurrent()
      : undefined
  );

  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
  const [mapInstance, setMapInstance] = useState<google.maps.Map | undefined>(
    undefined
  );

  const [
    isProductMapZoomCurrentInitiated,
    setIsProductMapZoomCurrentInitiated,
  ] = useState<boolean>(false);

  const [
    isProductMapCenterCurrentInitiated,
    setIsProductMapCenterCurrentInitiated,
  ] = useState<boolean>(false);

  const [
    isProductMapMarkerOpenCurrentInitiated,
    setIsProductMapMarkerOpenCurrentInitiated,
  ] = useState<boolean>(false);

  const initProductPositionListData = useCallback(
    (productPositionList: ProductPositionModel[]) => {
      setProductPositionListData(productPositionList);
    },
    [setProductPositionListData]
  );

  const centerMapOnCurrentPosition = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          if (mapInstance) {
            if (isProductMapCenterCurrentInitiated) {
              mapInstance.setCenter(
                new google.maps.LatLng(
                  position.coords.latitude,
                  position.coords.longitude
                )
              );
            }

            if (isProductMapZoomCurrentInitiated) {
              mapInstance.setZoom(16);
            }
          }
        },
        () => {
          errorToast(t('productMapUnableToGetPosition.message'));
        }
      );
    } else {
      // Browser doesn't support Geolocation
      errorToast(t('productMapBrowserSupportError.message'));
    }
  }, [
    errorToast,
    isProductMapCenterCurrentInitiated,
    isProductMapZoomCurrentInitiated,
    mapInstance,
    t,
  ]);

  const setProductPositionList = useProductPositionStore(
    (state: { setProductPositionList: any }) => state.setProductPositionList
  );

  useEffect(() => {
    if (!productPositionListData) {
      getProductPositionList(activePartner.id).then(
        ({ success, productPositionList }) => {
          if (!success || !productPositionList) {
            initProductPositionListData([]);
          } else {
            initProductPositionListData(productPositionList);
          }
        }
      );
    }

    return () => setProductPositionList();
  }, [
    activePartner,
    productPositionListData,
    getProductPositionList,
    initProductPositionListData,
    setProductPositionList,
    navigate,
    productListContractStateTypeSelectedList,
    getProductType,
  ]);

  const getProperProductPositionListData = useCallback(
    (productPositionList?: ProductPositionModel[]) => {
      if (!productPositionList) {
        return [];
      }

      return productPositionList
        .filter(
          (productPosition: ProductPositionModel) =>
            selectedProductType === getProductType(productPosition.article_code)
        )
        .filter((productPosition: ProductPositionModel) => {
          if (
            productPosition.contract_state ===
            ShipmentContractStateEnum.DEPLOYED
          ) {
            return productListContractStateTypeSelectedList.includes(
              ProductMapContractStateTypeEnum.IN_SHIPMENT
            );
          } else {
            return productListContractStateTypeSelectedList.includes(
              ProductMapContractStateTypeEnum.AVAILABLE
            );
          }
        });
    },
    [
      getProductType,
      productListContractStateTypeSelectedList,
      selectedProductType,
    ]
  );

  const getProductPositionListPos = useCallback(
    (productPositionListData?: ProductPositionModel[]): IProductPosition[] => {
      const productPositionList = getProperProductPositionListData(
        productPositionListData
      );

      const productListPos: IProductPosition[] = [];

      productPositionList.forEach((productPosition: ProductPositionModel) => {
        const position = getPosition(productPosition);

        if (position) {
          productListPos.push(position);
        }
      });

      return productListPos;
    },
    [getProperProductPositionListData]
  );

  const updateIsMapLoaded = useCallback(
    (isMapLoadedInfo: boolean, mapInstance: any) => {
      setIsMapLoaded(isMapLoadedInfo);
      setMapInstance(mapInstance);

      if (mapInstance) {
        if (getProductMapCenterCurrent()) {
          setTimeout(function () {
            mapInstance.setCenter(
              new google.maps.LatLng(getProductMapCenterCurrent())
            );
            setProductMapCenterCurrent(undefined);
            setIsProductMapCenterCurrentInitiated(true);
          }, 500);
        } else {
          if (isEmpty(getProductPositionListPos(productPositionListData))) {
            // LP Headquarter as default center
            let defaultCenter = {
              lat: 47.2679872,
              lng: -1.4752214,
            };

            if (mainAddress) {
              defaultCenter = {
                lat: mainAddress.position.latitude,
                lng: mainAddress.position.longitude,
              };
            }

            if (defaultCenter) {
              setTimeout(function () {
                mapInstance.setCenter(
                  new google.maps.LatLng(defaultCenter.lat, defaultCenter.lng)
                );
                mapInstance.setZoom(12);
                setProductMapCenterCurrent(undefined);
                setIsProductMapCenterCurrentInitiated(true);
              }, 100);
            }
          }
          setIsProductMapCenterCurrentInitiated(true);
        }

        if (getProductMapZoomCurrent()) {
          setTimeout(function () {
            if (mapInstance.getZoom() !== getProductMapZoomCurrent()) {
              mapInstance.setZoom(getProductMapZoomCurrent());
            }
            setProductMapZoomCurrent(undefined);
            setIsProductMapZoomCurrentInitiated(true);
          }, 1100);
        } else {
          setIsProductMapZoomCurrentInitiated(true);
        }

        if (getProductMapProductOpenCurrent()) {
          setTimeout(function () {
            setCurrentMarkerIdOpened(getProductMapProductOpenCurrent());
            setProductMapProductOpenCurrent(undefined);
            setIsProductMapMarkerOpenCurrentInitiated(true);
          }, 1200);
        } else {
          setIsProductMapMarkerOpenCurrentInitiated(true);
        }
      }
    },
    [
      getProductMapCenterCurrent,
      getProductMapProductOpenCurrent,
      getProductMapZoomCurrent,
      setProductMapCenterCurrent,
      setProductMapProductOpenCurrent,
      setProductMapZoomCurrent,
      mainAddress,
      productPositionListData,
      getProductPositionListPos,
    ]
  );

  const onZoomChanged = useCallback(
    (currentZoom?: number) => {
      if (currentZoom) {
        if (currentZoom <= 15 && isProductMapZoomCurrentInitiated) {
          setCurrentMarkerIdOpened(undefined);
        }
      }
    },
    [isProductMapZoomCurrentInitiated]
  );

  const increaseProductMapZoom = useCallback(() => {
    if (mapInstance) {
      const currentProductMapZoom = mapInstance.getZoom();

      if (currentProductMapZoom) {
        mapInstance.setZoom(currentProductMapZoom + 1);
      }
    }
  }, [mapInstance]);

  const decreaseProductMapZoom = useCallback(() => {
    if (mapInstance) {
      const currentProductMapZoom = mapInstance.getZoom();

      if (currentProductMapZoom) {
        mapInstance.setZoom(currentProductMapZoom - 1);
      }
    }
  }, [mapInstance]);

  if (!productPositionListData) {
    return <></>;
  }

  return (
    <Map
      bounds={getProductPositionListPos(productPositionListData)}
      isMapLoadedAction={updateIsMapLoaded}
      isFullScreen={true}
      height="556px"
      zoomControl={false}
      zoom={getProductMapZoomCurrent()}
      onZoomChanged={(currentZoom?: number) => onZoomChanged(currentZoom)}
      minZoom={3}
      maxZoom={18}
    >
      <>
        <MarkerClusterer
          options={markerClustererOptions}
          ignoreHidden={false}
          enableRetinaIcons={true}
          minimumClusterSize={5}
          onClusteringEnd={(markerClusterer: Clusterer) => {
            markerClusterer.getClusters().forEach((cluster: Cluster) => {
              const clusterMarkersList = cluster.getMarkers();

              if (clusterMarkersList.length > 1) {
                clusterMarkersList.forEach((marker: MarkerExtended) => {
                  if (
                    marker.get('markerProductId') === currentMarkerIdOpened &&
                    isProductMapMarkerOpenCurrentInitiated
                  ) {
                    setCurrentMarkerIdOpened(undefined);
                  }
                });
              }
            });
          }}
        >
          {cluster => (
            <>
              {getProductPositionListPos(productPositionListData).map(
                (productPos: IProductPosition) => (
                  <MarkerProduct
                    productPos={productPos}
                    cluster={cluster}
                    key={productPos.id}
                    currentMarkerIdOpened={currentMarkerIdOpened}
                    updateCurrentMarkerIdOpened={newCurrentMarkerIdOpened =>
                      setCurrentMarkerIdOpened(newCurrentMarkerIdOpened)
                    }
                    mapInstance={mapInstance}
                  />
                )
              )}
            </>
          )}
        </MarkerClusterer>
        {isMapLoaded && window.google && (
          <ProductListMapControl
            position={window.google.maps.ControlPosition.BOTTOM_RIGHT}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: '20px',
                marginBottom: '15px',
              }}
            >
              <ProductMapCustomZoomChangeBtn>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: '100%' }}
                >
                  <AddIcon
                    size="30px"
                    onClick={() => increaseProductMapZoom()}
                  />
                  <ProductMapCustomZoomChangeDividerBtn />
                  <MinusIcon
                    size="30px"
                    onClick={() => decreaseProductMapZoom()}
                  />
                </Stack>
              </ProductMapCustomZoomChangeBtn>
              <ProductMapCenterCurrentPositionBtn
                onClick={() => centerMapOnCurrentPosition()}
              >
                <LocationIcon />
              </ProductMapCenterCurrentPositionBtn>
            </Box>
          </ProductListMapControl>
        )}
      </>
    </Map>
  );
};

export default ProductListMap;
