// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Box, Stack, Text } from '@livingpackets/design-system-react-next';
import useCountryCodesWrapper from 'hooks/useCountryCodeWrapper';
import { Trans, useTranslation } from 'react-i18next';

interface AddressNamePart {
  first_name: string;
  last_name: string;
  company: string;
}

interface AddressWithoutCountry extends AddressNamePart {
  street: string;
  street2?: string;
  city: string;
  postal_code?: string;
  postalCode?: string;
}

type AddressWithCountryCodeOrCountry = AddressWithoutCountry &
  ({ country: string } | { country_code: string });

type AddressWithCountry = AddressWithoutCountry & { country: string };

export interface AddressNameProps {
  address: AddressWithCountryCodeOrCountry;
}

export const AddressNameAnonymous = ({ address }: AddressNameProps) => {
  const { t } = useTranslation('general');

  const hasCompany = address.company !== undefined;
  const hasName =
    address.first_name !== undefined && address.last_name !== undefined;

  return (
    <Stack>
      {hasCompany && (
        <Text variant="titleXS" color="custom.neutral.black.100">
          {address.company}
        </Text>
      )}
      {hasName && (
        <Text variant="bodyTextXS" color="custom.neutral.black.100" mt="0">
          {t('fullName', {
            firstName: address.first_name,
            lastName: address.last_name,
          })}
        </Text>
      )}
    </Stack>
  );
};

export interface AddressAnonymousProps {
  address: AddressWithCountryCodeOrCountry;
  inline?: boolean;
  countryInline?: boolean;
}

export const AddressAnonymous = ({
  address,
  inline = false,
  countryInline = false,
}: AddressAnonymousProps) => {
  const { t } = useTranslation('general');

  const [, getCountryNameFromCode] = useCountryCodesWrapper();
  const country =
    'country_code' in address
      ? getCountryNameFromCode(address.country_code)
      : address.country;

  if (inline) {
    return (
      <Text variant="bodyTextXS" color="custom.neutral.black.100" mt="0">
        {AddressToStringAnonymous(
          {
            street: address.street,
            // Add space here to avoid having it when not present
            street2: address.street2 ? ` ${address.street2}` : '',
            city: address.city,
            postal_code: address.postal_code,
            country,
          },
          t
        )}
      </Text>
    );
  }

  return (
    <Trans
      ns="general"
      i18nKey={!countryInline ? 'address' : 'addressCountryInline'}
      components={{ AddressLine: <Box /> }}
      values={{
        street: address.street,
        street2: address.street2,
        city: address.city,
        postal_code: address.postal_code,
        country,
      }}
    />
  );
};

export function AddressToStringAnonymous(
  address: Omit<AddressWithCountry, keyof AddressNamePart>,
  t: any
) {
  return t('general:addressInlineAnonymous', { defaultValue: '', ...address });
}
