// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useEffect } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import useBoxContractsStore, {
  orderSelector,
  setContractsSelector,
} from 'stores/useBoxContractsStore';

const useBoxContract = (partnerId?: string) => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpAccount');
  const setBoxContracts = useBoxContractsStore(setContractsSelector);
  const order = useBoxContractsStore(orderSelector);

  const getBoxContracts = useCallback(
    async (partnerId?: string) => {
      const { data, error } = await makeRequest({
        path: partnerId
          ? `api/v1/me/partners/${partnerId}/box-contracts`
          : `api/v1/me/box-contracts?order=${order}`,
        method: 'get',
      });

      if (error) {
        return { success: false };
      }
      Array.isArray(data?.items) && setBoxContracts(data.items);

      return { success: true };
    },
    [makeRequest, setBoxContracts, order]
  );

  useEffect(() => {
    getBoxContracts(partnerId);
  }, [getBoxContracts, partnerId]);

  return { loading };
};

export default useBoxContract;
