// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export const lockStatus = {
  ERROR: 0x00,
  LOCKED: 0x01,
  UNLOCKED: 0x02,
  UNDEFINED: 0x03, // undefined means that mechanism is neither lock nor unlock. this state should not happened, and mean mechanical issue
};

export type lockStatusKeys = keyof typeof lockStatus;
export type lockStatusValues = (typeof lockStatus)[lockStatusKeys];
