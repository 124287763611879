// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import { SentryCaptureException } from 'helpers/tracking';
import { useTranslation } from 'react-i18next';
import { getLocale } from 'utils/date/locale';

const useFormatTimestamp = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useCallback(
    (timestamp: Date, dateFormat: string) => {
      let formattedDate = format(timestamp, dateFormat, {
        locale: getLocale(language),
      });
      try {
        const formattedDateWithDot = formattedDate.replace(/May\./, 'May');

        return formattedDateWithDot
          .split(' ')
          .map((word, index) =>
            index === 1 || index === 0
              ? word.charAt(0).toUpperCase() + word.slice(1)
              : word
          )
          .join(' ');
      } catch (e) {
        const scope = new Sentry.Scope();
        scope.setTag('sharing angels', 'dashboard');
        SentryCaptureException(
          new Error('Invalid value for dates in Dashboard' + timestamp),
          null
        );

        return '--';
      }
    },
    [language]
  );
};

export default useFormatTimestamp;
