// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ShipmentPreShipmentIcon,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';

const Badge = styled('div')`
  height: 48px;
  width: 48px;
  background: ${({ theme }) => theme.palette.custom.primary[10]};
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
`;

const ReadyForTransitBadge = () => {
  const theme = useTheme();

  return (
    <Badge>
      <ShipmentPreShipmentIcon color={theme.palette.custom.primary[100]} />
    </Badge>
  );
};

export default ReadyForTransitBadge;
