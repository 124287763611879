// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  BulletListIcon,
  Stack,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import HalfBoxFront from 'assets/half-box-front.png';
import Card from 'components/atoms/Card';
import TextSkeleton from 'components/atoms/loadingSkeletons/TextSkeleton';
import { useMonetaryAmountConverter } from 'helpers/currencyConverter';
import { getLocationLanguage } from 'helpers/tosHelpers';
import { BoxContractStats } from 'hooks/useGetBoxContractStats';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import { IBaseAddress } from 'models/address';
import { useTranslation } from 'react-i18next';

import { Address } from './Address';
import { RequestState } from '../../shared-components/RequestState';

const Wrapper = styled('div')`
  width: 17.75rem;
  margin-right: 1rem;
`;

const UpperWrapper = styled(Card)`
  background-image: url(${HalfBoxFront});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-position-y: 9.2rem;
  background-color: ${({ theme }) => theme.palette.custom.primary[10]};
  height: 16.495rem;
  padding: 1.25rem 1rem;
`;

const LowerWrapper = styled(Card)`
  margin-top: -1.62rem;
  padding: 1.5rem 1rem;
  height: 20.125rem;
`;

const Link = styled('a')`
  font-size: 12px;
  line-height: 1.67;
  font-weight: 450;
  text-decoration: underline;
  margin: 0;
  color: ${({ theme }) => theme.palette.custom.neutral.black[50]};
  cursor: pointer;
`;

const StyledRow = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: unset;
`;

const BuyAndRentBalance = ({
  address,
  stats,
}: {
  isPartner?: boolean;
  address: IBaseAddress | null;
  stats: RequestState<BoxContractStats>;
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('buyAndRent');
  const convertMoney = useMonetaryAmountConverter();
  const [, makeRequest] = useImperativeRequest('tos');
  const [tosLocation, setTosLocation] = useState<string>('');
  const formatTimestamp = useIntlFormatTimestamp();

  const handleClickTos = useCallback(async () => {
    const { data } = await makeRequest({
      path: 'api/v1/tos?ServiceType=financial_models',
      method: 'get',
    });
    if (data && data.tosLocation) {
      setTosLocation(getLocationLanguage(language, data.tosLocation));
    }
  }, [makeRequest, language]);

  const showLastUpdate = useCallback(
    (lastUpdate: any) => !(lastUpdate === 0),
    []
  );

  useEffect(() => {
    handleClickTos();
  }, [handleClickTos]);

  return (
    <Wrapper>
      <UpperWrapper>
        {stats.state === 'success' &&
          showLastUpdate(stats.resource.lastUpdate) && (
            <div>
              <Text variant="titleXS" marginRight={2}>
                {t('lastUpdate')}
              </Text>
              <Text
                variant="bodyTextXS"
                color="custom.neutral.black.50"
                marginBottom={4}
              >
                {formatTimestamp(stats.resource.lastUpdate, {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Text>
            </div>
          )}
        <Text marginTop="1rem" variant="titleXS">
          {t('total')}
        </Text>
        <Text variant="titleL">
          {stats.state === 'success' ? (
            t('contractCount', { contractCount: stats.resource.contractCount })
          ) : (
            <TextSkeleton background="rgba(0,0,0,0.04)" width="10ch" />
          )}
        </Text>
      </UpperWrapper>
      <LowerWrapper>
        <StyledRow mb="1rem" justifyContent="flex-start">
          <BulletListIcon />
          <Text variant="titleS">{t('contractDetails')}</Text>
        </StyledRow>
        <StyledRow mb="0.5rem">
          <Text variant="bodyTextS">
            {stats.state === 'success' ? (
              stats.resource.boxCount
            ) : (
              <TextSkeleton background="rgba(0,0,0,0.04)" width="10ch" />
            )}
          </Text>
        </StyledRow>
        <StyledRow mb="3.875rem">
          <Text variant="bodyTextS">
            {stats.state === 'success' ? (
              convertMoney(stats.resource.totalEarnedCents / 100, {
                precision: 2,
              })
            ) : (
              <TextSkeleton background="rgba(0,0,0,0.04)" width="10ch" />
            )}
          </Text>
        </StyledRow>
        <StyledRow height="100%" flexDirection="column">
          <div>
            {address !== null && address.postalCode !== '' ? (
              <Address address={address} inline />
            ) : (
              <>
                <TextSkeleton background="rgba(0, 0, 0, 0.04)" width="100%" />
                <TextSkeleton background="rgba(0, 0, 0, 0.04)" width="100%" />
              </>
            )}
          </div>
          <Link href={tosLocation} target="_blank" rel="noopener noreferrer">
            {t('termsOfService')}
          </Link>
        </StyledRow>
      </LowerWrapper>
    </Wrapper>
  );
};

export default BuyAndRentBalance;
