// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export enum AccountTypeEnum {
  FULL_VERSION = 'fullVersion',
  PERSONAL_ACCOUNT = 'personalAccount',
  BUSINESS_ACCOUNT = 'businessAccount',
  TRIAL_ACCOUNT = 'trialAccount',
}

export enum PartnerBusinessStatusEnum {
  UNSPECIFIED = 'UNSPECIFIED',
  PENDING = 'PENDING',
  VALIDATED = 'VALIDATED',
  BLOCKED = 'BLOCKED',
}

export enum PartnerBusinessTypeEnum {
  UNSPECIFIED = 'UNSPECIFIED',
  PERSONAL = 'PERSONAL',
  TEST = 'TEST',
  PRO = 'PRO',
}

export enum AccountRoleBusinessAccountSubRoleEnum {
  PARTNERSHIP_ADMIN = 'Partnership admin',
  SHOP_ADMIN = 'Shop admin',
  SHOP_MEMBER = 'Shop member',
}
