// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { useApiClient } from './useApiClient';
import { useMutation } from '../shared-components';

/**
 * Deleting a shipment behaves different depending on the shipment's `contractState`.
 *
 * * If the shipment is Completed, it archives the shipment
 * * If the shipment is Draft, it deletes the shipment completely.
 * * If the shipment is in another state it errors.
 *
 * @returns A Mutation to delete a shipment
 */
export function useDeleteShipment() {
  const shipmentApiClient = useApiClient('shipment');

  const mutationFunction = useCallback(
    (shipmentId: string) =>
      shipmentApiClient.delete<unknown>(`api/v2/shipments/${shipmentId}`),
    [shipmentApiClient]
  );

  return useMutation(mutationFunction);
}
