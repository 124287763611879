// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Grid, Stack, Text } from '@livingpackets/design-system-react-next';
import { ButtonV2 as Button } from '@livingpackets/design-system-react-next';
import ErrorSrcDE from 'assets/img/account/partnership/error-de.png';
import ErrorSrcEN from 'assets/img/account/partnership/error-en.png';
import ErrorSrcFR from 'assets/img/account/partnership/error-fr.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const IMG_SRC: any = {
  en: ErrorSrcEN,
  fr: ErrorSrcFR,
  de: ErrorSrcDE,
};

export const Error = () => {
  const { t, i18n } = useTranslation('account');

  // Get correct image source depending on the language
  const src = IMG_SRC[i18n.language] || IMG_SRC.en;

  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: '100vh' }}
    >
      <Grid item sx={{ maxWidth: '42.5625rem !important', width: 'auto' }}>
        <img width="100%" src={src} alt="error" loading="lazy" />
      </Grid>
      <Grid item>
        <Stack alignItems="center" gap=".625rem">
          <Text variant="titleXXL" sx={{ textAlign: 'center ' }}>
            {t('partnership.error')}
          </Text>
          <Text variant="titleM" sx={{ textAlign: 'center ' }}>
            {t('partnership.errorMessage')}
          </Text>
        </Stack>
      </Grid>
      <Grid item mt="1.75rem">
        <Button data-testid="go-back-button" onClick={() => navigate(-1)}>
          {t('goBackBtn')}
        </Button>
      </Grid>
    </Grid>
  );
};
