// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Text, styled } from '@livingpackets/design-system-react-next';
import appLogo from 'assets/appLogo.png';
import { GetOnAppStore } from 'components/atoms/GetOnAppStore';
import { GetOnGooglePlay } from 'components/atoms/GetOnGooglePlay';
import { AnonView } from 'components/containers/AnonView';

const DownloadContainer = styled('div')`
  margin-top: 75px;
  padding-bottom: 20px;
  padding-top: 60px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${({ theme }) => theme.palette.custom.primary[5]};
  position: relative;
  border-radius: 12px;
`;

const DownloadLogo = styled('img')`
  position: absolute;
  top: -50px;
  left: 35%;
`;

const DownloadTextContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.sm}) {
    max-width: initial;
    margin-left: 50px;
  }
`;

const DownloadTextBlock = styled('div')`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: flex-start;
`;

const DownloadTextNumber = styled('div')`
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: ${({ theme }) => theme.palette.custom.primary[100]};
  border-radius: 50%;
  font-family: 'TTNormsPro-Regular';
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  position: absolute;
  left: -40px;
  margin-top: auto;
  margin-bottom: auto;
  top: 50%;
  bottom: 50%;
  text-align: center;
`;

const DownloadTextBlockContainer = styled('div')`
  margin-top: 26px;
  height: 24 px;
  line-height: 24px;
  position: relative;
`;

const DownloadText = styled(Text)`
  text-transform: uppercase;
  text-align: left;
`;

const DownloadButtons = styled('div')`
  margin-top: 35px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
`;

export function DownloadAppsView({
  title,
  description,
  stepList,
}: {
  title: string;
  description: string;
  stepList: Array<{ stepNumber: string; stepText: string }>;
}): JSX.Element {
  return (
    <AnonView title={title} description={description}>
      <DownloadContainer>
        <DownloadLogo alt="The Box by LivingPackets" src={appLogo} />
        <DownloadTextContainer>
          <DownloadTextBlock>
            {stepList.map(step => (
              <DownloadTextBlockContainer key={step.stepNumber}>
                <DownloadTextNumber>{step.stepNumber}</DownloadTextNumber>
                <DownloadText variant="bodyTextM">{step.stepText}</DownloadText>
              </DownloadTextBlockContainer>
            ))}
          </DownloadTextBlock>
        </DownloadTextContainer>
        <DownloadButtons>
          <GetOnAppStore />
          <GetOnGooglePlay />
        </DownloadButtons>
      </DownloadContainer>
    </AnonView>
  );
}
