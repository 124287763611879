// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useState } from 'react';

import {
  AddIcon,
  Box,
  Text,
  Stack,
  styled,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import labelPdfFormatSkeleton from 'assets/img/shipment/pdfFormatSkeleton/label-pdf-format-skeleton.png';
import labelPdfFormatSkeleton2x from 'assets/img/shipment/pdfFormatSkeleton/label-pdf-format-skeleton@2x.png';
import labelPdfFormatSkeleton3x from 'assets/img/shipment/pdfFormatSkeleton/label-pdf-format-skeleton@3x.png';
import labelThermalFormatSkeleton from 'assets/img/shipment/thermalFormatSkeleton/label-thermal-format-skeleton.png';
import labelThermalFormatSkeleton2x from 'assets/img/shipment/thermalFormatSkeleton/label-thermal-format-skeleton@2x.png';
import labelThermalFormatSkeleton3x from 'assets/img/shipment/thermalFormatSkeleton/label-thermal-format-skeleton@3x.png';
import { ShipmentLabelTypeEnum } from 'enums/ShipmentLabelTypeEnum';
import { useDropzone } from 'react-dropzone';
import { useTranslation, Trans } from 'react-i18next';

const LabelChoiceTypeContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<{ isSelected: boolean }>`
  border-radius: 16px;
  border-width: 1.5px;
  border-style: solid;
  border-color: ${({ isSelected, theme }) =>
    isSelected
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.black[4]};
  padding: 24px;
  transition: 200ms;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};

  &:hover {
    border-color: ${({ isSelected, theme }) =>
      isSelected
        ? theme.palette.custom.primary[100]
        : theme.palette.custom.primary[15]};
    cursor: pointer;
  }
`;

const LabelChoiceTypeSubTitle = styled(Text)`
  text-align: center;
`;

LabelChoiceTypeSubTitle.defaultProps = {
  variant: 'bodyTextS',
};
const WrapperDropZone = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  cursor: pointer;
  padding: 32px;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[4]};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23CCCCCCFF' strokeWidth='2' stroke-dasharray='8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 20px;
`;

interface ShipmentDropZoneProps {
  onFileDrop: (file: File, isThermal: boolean) => void;
  labelTypeSelectedByDefault?: ShipmentLabelTypeEnum;
}

const ShipmentDropZone = ({
  onFileDrop,
  labelTypeSelectedByDefault = ShipmentLabelTypeEnum.PDF,
}: ShipmentDropZoneProps) => {
  const { t } = useTranslation('shipments');

  const [labelTypeSelected, setLabelTypeSelected] =
    useState<ShipmentLabelTypeEnum>(labelTypeSelectedByDefault);

  const onDrop = useCallback(
    async (acceptedFile: any) => {
      if (acceptedFile[0]) {
        onFileDrop(
          acceptedFile[0],
          labelTypeSelected === ShipmentLabelTypeEnum.THERMAL
        );
      }
    },
    [labelTypeSelected, onFileDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: { 'application/pdf': [] },
  });

  return (
    <Stack
      sx={{ height: '100%', paddingLeft: '40px', paddingRight: '40px' }}
      gap="1.75rem"
    >
      <Stack direction="row" gap="1.75rem">
        <LabelChoiceTypeContainer
          gap="1.5rem"
          isSelected={labelTypeSelected === ShipmentLabelTypeEnum.PDF}
          onClick={() => setLabelTypeSelected(ShipmentLabelTypeEnum.PDF)}
        >
          <Text
            variant="titleM"
            sx={{ textAlign: 'center' }}
            data-testid="shipmentLabel-importLabel-dropZone-pdf"
          >
            {t('importLabel.dropZone.labelTypeChoice.pdf.title')}
          </Text>
          <Text
            variant="bodyTextS"
            color="custom.neutral.black.50"
            sx={{ textAlign: 'center' }}
          >
            {t('importLabel.dropZone.labelTypeChoice.pdf.subTitle')}
          </Text>
        </LabelChoiceTypeContainer>
        <LabelChoiceTypeContainer
          gap="1.5rem"
          isSelected={labelTypeSelected === ShipmentLabelTypeEnum.THERMAL}
          onClick={() => setLabelTypeSelected(ShipmentLabelTypeEnum.THERMAL)}
        >
          <Text
            variant="titleM"
            sx={{ textAlign: 'center' }}
            data-testid="shipmentLabel-importLabel-dropZone-thermal"
          >
            {t('importLabel.dropZone.labelTypeChoice.thermal.title')}
          </Text>
          <Text
            variant="bodyTextS"
            color="custom.neutral.black.50"
            sx={{ textAlign: 'center' }}
          >
            {t('importLabel.dropZone.labelTypeChoice.thermal.subTitle')}
          </Text>
        </LabelChoiceTypeContainer>
      </Stack>
      <WrapperDropZone {...getRootProps()} data-testid="drop-zone">
        <Stack alignItems="center" sx={{ height: '100%' }}>
          <Box display="flex" pb="16px">
            <Text
              component="div"
              sx={{ display: 'flex', alignItems: 'baseline' }}
            >
              {labelTypeSelected === ShipmentLabelTypeEnum.PDF && (
                <Trans
                  t={t}
                  i18nKey="importLabel.dropZone.labelTypeChoice.pdf.helper"
                  components={{
                    0: (
                      <Text
                        variant="titleS"
                        color="custom.neutral.black.50"
                        sx={{ whiteSpace: 'break-spaces' }}
                      />
                    ),
                    1: (
                      <Text
                        variant="titleS"
                        color="custom.primary.100"
                        ml="0.18rem"
                      />
                    ),
                  }}
                />
              )}
            </Text>

            <Text
              component="div"
              sx={{ display: 'flex', alignItems: 'baseline' }}
            >
              {labelTypeSelected === ShipmentLabelTypeEnum.THERMAL && (
                <Trans
                  t={t}
                  i18nKey="importLabel.dropZone.labelTypeChoice.thermal.helper"
                  components={{
                    0: (
                      <Text
                        variant="titleS"
                        color="custom.neutral.black.50"
                        style={{ whiteSpace: 'break-spaces' }}
                      >
                        {/* eslint-disable-next-line react/jsx-no-literals */}
                        text1
                      </Text>
                    ),
                    1: (
                      <Text
                        variant="titleM"
                        color="custom.primary.100"
                        ml="0.18rem"
                      >
                        {/* eslint-disable-next-line react/jsx-no-literals */}
                        text2
                      </Text>
                    ),
                  }}
                />
              )}
            </Text>
          </Box>
          <Box sx={{ position: 'relative' }}>
            {labelTypeSelected === ShipmentLabelTypeEnum.PDF && (
              <img
                src={labelPdfFormatSkeleton}
                srcSet={`${labelPdfFormatSkeleton2x} 2x, ${labelPdfFormatSkeleton3x} 3x`}
                alt="PDF format skeleton"
              />
            )}
            {labelTypeSelected === ShipmentLabelTypeEnum.THERMAL && (
              <img
                src={labelThermalFormatSkeleton}
                srcSet={`${labelThermalFormatSkeleton2x} 2x, ${labelThermalFormatSkeleton3x} 3x`}
                alt="Thermal Format Skeleton"
              />
            )}
            <Button
              aria-labelledby="drop-label"
              icon={AddIcon}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              data-testid="shipmentLabel-importLabel-drop-label"
            />
          </Box>
        </Stack>
        <input {...getInputProps()} />
      </WrapperDropZone>
    </Stack>
  );
};

export default ShipmentDropZone;
