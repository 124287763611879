// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import {
  AccountTypeEnum,
  PartnerBusinessStatusEnum,
  PartnerBusinessTypeEnum,
} from 'enums/accountTypeEnum';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useToastMessages from 'hooks/useToastMessages';
import {
  ICurrentUserBusinessTypePut,
  ICurrentUserPost,
} from 'models/currentUser';
import { IPartner } from 'models/partner';
import { IUser } from 'models/user';
import { useTranslation } from 'react-i18next';
import useAppState, {
  dispatch,
  userSelector,
} from 'stores/appState/useAppState';
import { IPartnerEntry } from 'stores/useMyPartnersStore';

const dispatchUser = (data: { profile: IUser }) =>
  dispatch({ type: 'SET_USER', payload: data.profile });

const useCustomer = () => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpAccount');
  const { error: toastError, success: toastSuccess } = useToastMessages();
  const appUser = useAppState(userSelector);
  const { t } = useTranslation('navigation');

  const editCurrentUser = useCallback(
    async (currentUser: ICurrentUserPost, displayToast = true) => {
      const { error, data } = await makeRequest({
        path: 'api/v1/me',
        method: 'put',
        body: currentUser,
      });
      if (error) {
        if (displayToast) {
          toastError('messages:contactEditError.message', { wide: true });
        }

        return { success: false };
      }
      if (displayToast) {
        toastSuccess('messages:contactEditSuccess.message', { wide: true });
      }
      dispatchUser(data);

      return { success: true, data };
    },
    [makeRequest, toastSuccess, toastError]
  );

  const editCurrentUserBusinessType = useCallback(
    async (currentUserBusinessType: ICurrentUserBusinessTypePut) => {
      const { error, data } = await makeRequest({
        path: 'api/v1/me',
        method: 'PUT',
        body: currentUserBusinessType,
      });

      if (error) {
        return { success: false, data: null };
      }

      dispatchUser(data);

      return { success: true, data };
    },
    [makeRequest]
  );

  const getBusinessTypeFromAccountType = useCallback(
    (accountTypeEnum: AccountTypeEnum): string => {
      switch (accountTypeEnum) {
        case AccountTypeEnum.TRIAL_ACCOUNT:
          return 'PARTNER_BUSINESS_TYPE_TEST';
        case AccountTypeEnum.PERSONAL_ACCOUNT:
          return 'PARTNER_BUSINESS_TYPE_PERSONAL';
        case AccountTypeEnum.BUSINESS_ACCOUNT:
          return 'PARTNER_BUSINESS_TYPE_PRO';
        case AccountTypeEnum.FULL_VERSION:
          return 'PARTNER_BUSINESS_TYPE_PERSONAL';
        default:
          return 'PARTNER_BUSINESS_TYPE_PERSONAL';
      }
    },
    []
  );

  const getAccountTypeText = useCallback(
    (partner?: IPartner | IPartnerEntry): string | undefined => {
      if (!appUser) {
        return undefined;
      }

      if (appUser.businessType === UserBusinessTypeEnum.PERSONAL) {
        return t('accountSelection.personal');
      }

      if (appUser.businessType === UserBusinessTypeEnum.BUSINESS && partner) {
        const { businessInformation } = partner;

        if (businessInformation.type === PartnerBusinessTypeEnum.TEST) {
          return t('accountSelection.professionalTest');
        }

        if (businessInformation.status === PartnerBusinessStatusEnum.PENDING) {
          return t('accountSelection.professionalProPending');
        }
      }

      return undefined;
    },
    [appUser, t]
  );

  const getPartnerNameInitial = useCallback(
    (partnerName: string): string =>
      partnerName
        .split(' ')
        .map((word: string) => word.slice(0, 1).toUpperCase())
        .join(''),
    []
  );

  return {
    loading,
    editCurrentUser,
    editCurrentUserBusinessType,
    getBusinessTypeFromAccountType,
    getAccountTypeText,
    getPartnerNameInitial,
  };
};

export default useCustomer;
