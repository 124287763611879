// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useEffect } from 'react';

import { loadCrisp } from 'helpers/tracking';
import { isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';

import history from '../helpers/history';

history.listen(location => {
  ReactGA.set({ page: location.location.pathname });
  ReactGA.pageview(location.location.pathname);
});

const useTracking = () => {
  useEffect(() => {
    // Display crisp only Desktop
    if (!isMobile) {
      loadCrisp();
    }
  }, []);
};

export default useTracking;
