// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  AddIcon,
  Text,
  Box,
  ButtonV2 as Button,
  Stack,
} from '@livingpackets/design-system-react-next';
import ContactAddressBlock from 'components/atoms/contactAddress/ContactAddressBlock';
import ContactBasicInformationBlock from 'components/atoms/contactAddress/ContactBasicInformationBlock';
import ContactAddressForm from 'components/molecules/Form/ContactAddressForm';
import ContactForm from 'components/molecules/Form/ContactForm';
import DeleteAddressModal from 'components/molecules/modals/DeleteAddressModal';
import { storeSelector } from 'helpers/paginatedStoreHelpers';
import useContactAddress from 'hooks/useContactAddress';
import {
  IContact,
  IContactAddress,
  IContactAddressForm,
  IContactForm,
  IContactInformationForm,
  INITIAL_STATE_CONTACT_ADDRESS_FORM,
  INITIAL_STATE_CONTACT_INFORMATION_FORM,
} from 'models/contactAddress';
import { useTranslation } from 'react-i18next';
import useContactAddressStore from 'stores/useContactAddressStore';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import { shallow } from 'zustand/shallow';

interface IContactAddressEditType {
  contactInformation: IContact;
  contactAddresses: IContactAddress[];
  onValuesChanged: (values: IContactForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  isDismissTriggered: boolean;
  onContactBasicInformationBlockClick: () => void;
  onContactAddressBlockClick: (address?: IContactAddress) => void;
  isDeleteAddressModalDisplayed?: (
    isDeleteAddressModalDisplayed: boolean
  ) => void;
  displayAddAddressFormOnLoad: boolean;
  onAddressDelete: (addressId: string) => void;
}

const ContactAddressEditForm = ({
  contactInformation,
  contactAddresses,
  isDismissTriggered,
  onContactBasicInformationBlockClick,
  onContactAddressBlockClick,
  onValuesChanged,
  isFormValidChanged,
  isDeleteAddressModalDisplayed,
  displayAddAddressFormOnLoad,
  onAddressDelete,
}: IContactAddressEditType) => {
  const { t } = useTranslation('contactAddress');

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const { dispatch } = useContactAddressStore(storeSelector, shallow);

  const [contactInformationData, setContactInformationData] =
    useState<IContactInformationForm>(INITIAL_STATE_CONTACT_INFORMATION_FORM);
  const [contactAddressData, setContactAddressData] =
    useState<IContactAddressForm>(INITIAL_STATE_CONTACT_ADDRESS_FORM);

  const [addressSelectedForDelete, setAddressSelectedForDelete] =
    useState<IContactAddress>();

  const [isContactInformationFormValid, setIsContactInformationFormValid] =
    useState<boolean>(false);
  const [isContactAddressFormValid, setIsContactAddressFormValid] =
    useState<boolean>(false);

  const [displayBasicInformationForm, setDisplayBasicInformationForm] =
    useState<boolean>(false);
  const [displayAddButton, setDisplayAddButton] = useState<boolean>(true);
  const [displayAddressForm, setDisplayAddressForm] = useState<boolean>(
    displayAddAddressFormOnLoad
  );

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [selectedAddress, setSelectedAddress] = useState<
    IContactAddress | undefined
  >(undefined);

  useEffect(() => {
    onValuesChanged({
      basicInformation: contactInformationData,
      address: contactAddressData,
    });
  }, [contactInformationData, contactAddressData, onValuesChanged]);

  useEffect(() => {
    if (displayBasicInformationForm && displayAddressForm) {
      isFormValidChanged(
        isContactInformationFormValid && isContactAddressFormValid
      );
    } else if (displayBasicInformationForm) {
      isFormValidChanged(isContactInformationFormValid);
    } else if (displayAddressForm) {
      isFormValidChanged(isContactAddressFormValid);
    } else {
      isFormValidChanged(false);
    }
  }, [
    isContactInformationFormValid,
    isContactAddressFormValid,
    displayBasicInformationForm,
    displayAddressForm,
    isFormValidChanged,
  ]);

  // Handle the button dismiss

  useEffect(() => {
    if (isDismissTriggered) {
      setDisplayBasicInformationForm(false);
      setDisplayAddressForm(false);
      isFormValidChanged(false);
    }
  }, [isDismissTriggered, isFormValidChanged]);

  // Handle behavior when the information block appears or not

  useEffect(() => {
    if (displayBasicInformationForm) {
      isFormValidChanged(isContactInformationFormValid);
    }
  }, [
    displayBasicInformationForm,
    isContactInformationFormValid,
    isFormValidChanged,
  ]);

  useEffect(() => {
    if (!displayAddressForm) {
      setSelectedAddress(undefined);
      setIsContactAddressFormValid(false);
    }
  }, [displayAddressForm]);

  // Handle information block click

  const handleContactBasicInformationClick = useCallback(() => {
    setDisplayBasicInformationForm(true);
    onContactBasicInformationBlockClick();
  }, [onContactBasicInformationBlockClick]);

  const handleContactAddressBlockClick = useCallback(
    (address: IContactAddress, event: any) => {
      setDisplayAddressForm(true);
      onContactAddressBlockClick(address);
      setSelectedAddress(address);
      setDisplayAddButton(true);
    },
    [onContactAddressBlockClick]
  );

  const handleContactInformationFormValuesChanged = useCallback(
    (values: IContactInformationForm) => {
      setContactInformationData(values);
    },
    []
  );

  const handleContactInformationFormValidChanged = useCallback(
    (isFormValid: boolean) => {
      setIsContactInformationFormValid(isFormValid);
    },
    []
  );

  const handleAddAddressButtonClick = useCallback(() => {
    setDisplayAddressForm(true);
    setSelectedAddress(undefined);
    onContactAddressBlockClick(undefined);
    setDisplayAddButton(false);
  }, [onContactAddressBlockClick]);

  const handleDeleteClick = useCallback(
    (address: IContactAddress) => {
      setAddressSelectedForDelete(address);
      setShowDeleteModal(true);
      setSelectedAddress(undefined);
      setDisplayAddressForm(false);
      onContactAddressBlockClick(undefined);

      if (isDeleteAddressModalDisplayed) {
        isDeleteAddressModalDisplayed(true);
      }
    },
    [
      isDeleteAddressModalDisplayed,
      setAddressSelectedForDelete,
      setShowDeleteModal,
      setSelectedAddress,
      setDisplayAddressForm,
      onContactAddressBlockClick,
    ]
  );

  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  useEffect(() => {
    if (!showDeleteModal) {
      setAddressSelectedForDelete(undefined);
    }
  }, [setAddressSelectedForDelete, showDeleteModal]);

  const { deleteAddress, loading: deletionInProgress } = useContactAddress(
    activePartner.id
  );

  const handleDelete = useCallback(async () => {
    if (addressSelectedForDelete) {
      const { success } = await deleteAddress(
        contactInformation.id,
        addressSelectedForDelete.id
      );
      if (success) {
        dispatch({
          type: 'removeEntry',
          args: { id: addressSelectedForDelete?.id! },
        });
        setShowDeleteModal(false);
        onAddressDelete(addressSelectedForDelete.id);
      }
    }
  }, [
    deleteAddress,
    contactInformation,
    addressSelectedForDelete,
    setShowDeleteModal,
    dispatch,
    onAddressDelete,
  ]);

  const handleDeleteCancel = useCallback(async () => {
    setShowDeleteModal(false);
    setDisplayAddressForm(false);
  }, []);

  return (
    <>
      <Stack>
        <Text variant="titleM" mb=".5rem">
          {t('create.block.basicInformation.title')}
        </Text>
        <Text variant="bodyTextS" mb="1.5rem">
          {t('create.block.basicInformation.subTitle')}
        </Text>
        {contactInformation && (
          <ContactBasicInformationBlock
            contact={contactInformation}
            displayBasicInformationForm={displayBasicInformationForm}
            onBlockClick={handleContactBasicInformationClick}
            smsEnabled={activePartner.smsEnabled}
          />
        )}
        <Box display={displayBasicInformationForm ? 'block' : 'none'}>
          <ContactForm
            defaultValues={contactInformation}
            onValuesChanged={handleContactInformationFormValuesChanged}
            isFormValidChanged={handleContactInformationFormValidChanged}
          />
        </Box>
      </Stack>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <Stack style={{ height: '48px' }}>
            <Text variant="titleM" mb=".5rem">
              {t('create.block.address.title')}
            </Text>
            <Text variant="bodyTextS" color="custom.neutral.black.50">
              {t('create.block.address.subTitle')}
            </Text>
          </Stack>
          <Stack>
            {displayAddButton && (
              <Button
                data-testid="addAddressBtn"
                icon={AddIcon}
                onClick={handleAddAddressButtonClick}
              />
            )}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          mt="20px"
          justifyContent="space-between"
        >
          {contactAddresses.length !== 0 &&
            contactAddresses.map((address: IContactAddress) => (
              <Stack flex="0 0 360px" key={address.id + '-col'}>
                <ContactAddressBlock
                  address={address}
                  displayAddressForm={displayAddressForm}
                  onBlockClick={(event: any) =>
                    handleContactAddressBlockClick(address, event)
                  }
                  key={address.id}
                  handleDeleteClick={handleDeleteClick}
                  isAddressSelected={
                    selectedAddress ? address.id === selectedAddress.id : false
                  }
                  contactAddressesLength={contactAddresses.length}
                />
              </Stack>
            ))}
        </Stack>
        <Box
          display={displayAddressForm && !showDeleteModal ? 'block' : 'none'}
        >
          <ContactAddressForm
            defaultValues={selectedAddress}
            onValuesChanged={setContactAddressData}
            isFormValidChanged={setIsContactAddressFormValid}
            isDismissTriggered={isDismissTriggered}
          />
        </Box>
      </Stack>
      <DeleteAddressModal
        open={showDeleteModal}
        onSubmit={handleDelete}
        onCancel={handleDeleteCancel}
        loading={deletionInProgress}
        addressId={addressSelectedForDelete?.id}
      />
    </>
  );
};

export default ContactAddressEditForm;
