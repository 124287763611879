// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

export interface ApiFilterModel {
  fieldName: string;
  fieldData: string;
  nextFieldSeparator?: ApiFilterOperatorEnum;
  filterType: ApiFilterTypeEnum;
}

export interface ApiFilterGroupModel {
  list: Array<ApiFilterModel>;
  nextGroupSeparator?: ApiFilterOperatorEnum;
}

export enum ApiFilterOperatorEnum {
  AND = 'AND',
  OR = 'OR',
}

export enum ApiFilterTypeEnum {
  EQUAL = '=',
  INCLUDE = '%>',
}
