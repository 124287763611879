// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Text } from '@livingpackets/design-system-react-next';
import ProfileContributions from 'components/organisms/ProfileContributions';
import { useTranslation } from 'react-i18next';

import useGetCO2Savings from '../../../hooks/useGetCO2Savings';

const LPAccountProfile = () => {
  useGetCO2Savings();
  const { t } = useTranslation(['contribution']);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Text variant="titleXL">{t('contribution:contributions')}</Text>
      </div>
      <ProfileContributions />
    </>
  );
};

export default LPAccountProfile;
