// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import useProductList from 'hooks/useProductList';
import useShipmentList from 'hooks/useShipmentList';

import useShipmentTab from './useShipmentTab';

const useSessionStorage = () => {
  const {
    resetProductListProductTypeSelectedCurrent,
    resetProductListPaginationCurrent,
    resetProductListSearchCurrent,
  } = useProductList();

  const {
    resetShipmentListPaginationCurrent,
    resetShipmentListSearchCurrent,
    resetShipmentListSearchFieldCurrent,
  } = useShipmentList();

  const { resetShipmentListNavTab, resetShipmentNavTab } = useShipmentTab();

  const resetSessionStorage = useCallback(
    (resetProductList = true, resetShipmentList = true) => {
      if (resetShipmentList) {
        resetShipmentListPaginationCurrent();
        resetShipmentListSearchCurrent();
        resetShipmentListSearchFieldCurrent();
        resetShipmentListNavTab();
        resetShipmentNavTab();
      }

      if (resetProductList) {
        resetProductListProductTypeSelectedCurrent();
        resetProductListPaginationCurrent();
        resetProductListSearchCurrent();
      }
    },
    [
      resetShipmentListPaginationCurrent,
      resetShipmentListSearchCurrent,
      resetShipmentListSearchFieldCurrent,
      resetProductListProductTypeSelectedCurrent,
      resetProductListPaginationCurrent,
      resetProductListSearchCurrent,
      resetShipmentNavTab,
      resetShipmentListNavTab,
    ]
  );

  return {
    resetSessionStorage,
  };
};

export default useSessionStorage;
