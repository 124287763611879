// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Text, Stack } from '@livingpackets/design-system-react-next';
import EventInformationLimitDisplayBlock from 'components/atoms/shipment/Timeline/EventInformationLimitDisplayBlock';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import useShipmentDetail from 'hooks/useShipmentDetail';
import { IShipmentTimelineEntry } from 'models/shipment';
import { useTranslation } from 'react-i18next';

interface IEventAirPressureBlockType {
  event: IShipmentTimelineEntry;
}

const EventAirPressureBlock = ({ event }: IEventAirPressureBlockType) => {
  const { t } = useTranslation('shipments');
  const formatTimestamp = useIntlFormatTimestamp();
  const {
    getAlertThreshold,
    getAlertThresholdType,
    getAlertThresholdLimitText,
    getAlertReachedValue,
  } = useShipmentDetail();

  return (
    <Stack
      direction="row"
      marginTop="11px !important"
      justifyContent="space-between"
    >
      <Stack direction="row">
        <Stack>
          <Text variant="bodyTextXS" marginBottom="8px">
            {t('detail.timeline.information.status')}
          </Text>
          <Text
            variant="bodyTextXS"
            marginBottom="8px"
            sx={{ whiteSpace: 'pre' }}
          >
            {t('detail.timeline.information.startOfAlert')}
          </Text>
          {event.closedAt && (
            <Text variant="bodyTextXS">
              {t('detail.timeline.information.endOfAlert')}
            </Text>
          )}
        </Stack>
        <Stack marginLeft="10px !important">
          <Text variant="bodyTextXS" marginBottom="8px">
            {event.closedAt
              ? t('detail.timeline.information.pressure.closed')
              : t('detail.timeline.information.pressure.active')}
          </Text>
          <Text
            variant="bodyTextXS"
            marginBottom="8px"
            sx={{ whiteSpace: 'pre' }}
          >
            {formatTimestamp(event.createdAt, {
              day: 'numeric',
              month: 'long',
              year: undefined,
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Text>
          {event.closedAt && (
            <Text
              variant="bodyTextXS"
              marginBottom="8px"
              sx={{ whiteSpace: 'pre' }}
            >
              {formatTimestamp(event.closedAt, {
                day: 'numeric',
                month: 'long',
                year: undefined,
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Text>
          )}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" marginRight="64px">
        <EventInformationLimitDisplayBlock
          thresholdType={getAlertThresholdType(event)}
          thresholdText={t(
            getAlertThresholdLimitText(
              'detail.timeline.information.pressure',
              getAlertThresholdType(event)
            ) as string,
            {
              value: getAlertThreshold(event),
              defaultValue: '-- (hPa)',
            }
          )}
          currentText={t('detail.timeline.information.pressure.valueReached', {
            value: getAlertReachedValue(event),
          })}
        />
      </Stack>
    </Stack>
  );
};

export default EventAirPressureBlock;
