// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import useImperativeRequest from 'hooks/useImperativeRequest';
import {
  IContact,
  IContactAddress,
  IContactAddressForm,
  IContactAddressPost,
  IContactForm,
  IContactInformationForm,
  IContactPost,
  INITIAL_STATE_CONTACT_ADDRESS,
  INITIAL_STATE_CONTACT_ADDRESS_COMPLETE_FORM,
  ISearchContactAddress,
} from 'models/contactAddress';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import { create } from 'zustand';

import useToastMessages from './useToastMessages';

interface IContactAddressStore {
  contactAddressForm: IContactForm;
  contactAddress: IContact;
  setContactAddressForm: (contactAddressForm: IContactForm) => void;
  setContactAddress: (contactAddress: IContact) => void;
}

export const useContactAddressStore = create<IContactAddressStore>(set => ({
  contactAddressForm: INITIAL_STATE_CONTACT_ADDRESS_COMPLETE_FORM,
  contactAddress: INITIAL_STATE_CONTACT_ADDRESS,
  setContactAddressForm: (contactAddressForm: IContactForm) =>
    set({ contactAddressForm }),
  setContactAddress: (contactAddress: IContact) => set({ contactAddress }),
}));

const postProcessorContactAddressList = (
  contactList: IContact[]
): ISearchContactAddress[] =>
  contactList.map((contact: IContact) => ({
    id: contact.id,
    name: contact.firstName + ' ' + contact.lastName,
    firstName: contact.firstName,
    lastName: contact.lastName,
    email: contact.email,
    phoneNumber: contact.phoneNumber,
    addressList:
      contact.addresses?.map((address: IContactAddress, index: number) => ({
        address: address,
        isAddressSelected: index === 0,
      })) || [],
  }));

const useContactAddress = (id: string) => {
  const [{ loading }, makeRequest] = useImperativeRequest('lpVillage');
  const { error: toastError, success: toastSuccess } = useToastMessages();
  const activePartner = useMyPartnersStore(activePartnerSelector);

  /*  const setContactAddress = useContactAddressStore(
    state => state.setContactAddress
  );*/

  const getContactAddress = useCallback(
    async (contactId: string) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/contacts/${contactId}`,
        method: 'get',
      });
      if (error) {
        toastError('messages:getContactAddressError');

        return { success: false, contact: null };
      }

      //  const processedContactAddress = postProcessorContact(data);
      //    setContactAddress(data);
      return { success: true, contactAddress: data };
    },
    [makeRequest, toastError, activePartner]
  );

  const addContact = useCallback(
    async (contact: IContactPost) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/contacts`,
        method: 'post',
        body: { ...contact },
      });
      if (error) {
        toastError('messages:contactCreateError.message', { wide: true });

        return { success: false };
      }

      toastSuccess('messages:contactCreateSuccess.message', { wide: true });

      return { success: true, data };
    },
    [makeRequest, toastSuccess, toastError, activePartner.id]
  );

  const editContact = useCallback(
    async (contact: IContactPost, contactId: string) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/contacts/${contactId}`,
        method: 'put',
        body: {
          email: contact.email,
          firstName: contact.firstName,
          lastName: contact.lastName,
          phoneNumber: contact.phoneNumber,
        },
      });
      if (error) {
        toastError('messages:contactEditError.message', { wide: true });

        return { success: false };
      }
      toastSuccess('messages:contactEditSuccess.message', { wide: true });

      return { success: true, data };
    },
    [makeRequest, toastSuccess, toastError, activePartner.id]
  );

  const deleteContact = useCallback(
    async (contactId: string) => {
      const { data, error } = await makeRequest({
        path: `api/v1/me/partners/${id}/contacts/${contactId}`,
        method: 'delete',
      });

      if (error) {
        toastError('messages:contactDeleteError.message', {
          wide: true,
        });

        return { success: false };
      }
      toastSuccess('messages:contactDeleteSuccess.message', {
        wide: true,
      });

      return { success: true, data };
    },
    [id, makeRequest, toastError, toastSuccess]
  );

  const fetchContactRoles = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: 'api/v1/me/roles',
      method: 'get',
    });

    if (error) {
      toastError('messages:partnerRoleError', { wide: true });

      return { success: false };
    }

    return { success: true, data };
  }, [makeRequest, toastError]);

  const addAddressToContact = useCallback(
    async (address: IContactAddressPost, contactId: string) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/contacts/${contactId}/addresses`,
        method: 'post',
        body: { ...address },
      });
      if (error) {
        toastError('messages:contactAddressCreateError.message', {
          wide: true,
        });

        return { success: false };
      }

      return { success: true, data };
    },
    [makeRequest, toastError, activePartner.id]
  );

  const editAddress = useCallback(
    async (
      address: IContactAddressPost,
      contactId: string,
      addressId: string
    ) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/contacts/${contactId}/addresses/${addressId}`,
        method: 'put',
        body: { ...address },
      });
      if (error) {
        toastError('messages:contactAddressEditError.message', { wide: true });

        return { success: false };
      }
      toastSuccess('messages:contactAddressEditSuccess.message', {
        wide: true,
      });

      return { success: true, data };
    },
    [makeRequest, toastSuccess, toastError, activePartner.id]
  );

  const deleteAddress = useCallback(
    async (contactId: string, addressId: string) => {
      const { data, error } = await makeRequest({
        path: `api/v1/me/partners/${id}/contacts/${contactId}/addresses/${addressId}`,
        method: 'delete',
      });

      if (error) {
        toastError('messages:contactAddressDeleteError.message', {
          wide: true,
        });

        return { success: false };
      }
      toastSuccess('messages:contactAddressDeleteSuccess.message', {
        wide: true,
      });

      return { success: true, data };
    },
    [id, makeRequest, toastError, toastSuccess]
  );

  const searchContact = useCallback(
    async (contactName: string) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/contacts?offset=0&order=DESC&pageSize=5&sortBy=firstName&search=${contactName}`,
        method: 'get',
      });

      if (error) {
        toastError('messages:getSearchContactError');

        return { success: false, contact: null };
      }

      return { success: true, contactAddressList: data.items };
    },
    [makeRequest, toastError, activePartner]
  );

  const searchContactAddress = useCallback(
    async (contactName: string) => {
      const { error, data } = await makeRequest({
        path: `api/v1/me/partners/${activePartner.id}/contacts?offset=0&order=DESC&pageSize=5&sortBy=firstName&search=${contactName}`,
        method: 'get',
      });

      if (error) {
        toastError('messages:getSearchContactError');

        return { success: false, contact: null };
      }

      const processedContactAddress = postProcessorContactAddressList(
        data.items
      );

      return { success: true, contactAddressList: processedContactAddress };
    },
    [makeRequest, toastError, activePartner]
  );

  const getContactAddressPostFromContactAddressFormData = useCallback(
    (
      contactInformationFormData: IContactInformationForm,
      contactAddressFormData: IContactAddressForm
    ): IContactAddressPost => ({
      type: contactAddressFormData.type,
      firstName: contactInformationFormData.firstName,
      lastName: contactInformationFormData.lastName,
      city: contactAddressFormData.city,
      postalCode: contactAddressFormData.zipCode,
      street: contactAddressFormData.addressInfo,
      houseNumber: '',
      countryCode: contactAddressFormData.countryCode,
      company: contactAddressFormData.companyName
        ? contactAddressFormData.companyName
        : undefined,
      state: '',
      street2: '',
    }),
    []
  );

  return {
    loading,
    addContact,
    getContactAddress,
    editContact,
    deleteContact,
    fetchContactRoles,
    addAddressToContact,
    editAddress,
    deleteAddress,
    searchContact,
    searchContactAddress,
    getContactAddressPostFromContactAddressFormData,
  };
};

export default useContactAddress;
