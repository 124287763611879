// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useMemo } from 'react';

import {
  Text,
  NewTextField as TextField,
  Grid,
} from '@livingpackets/design-system-react-next';
import Dropdown from 'components/atoms/Dropdown';
import useFormTemplate from 'hooks/useFormTemplate';
import { IMemberForm, INITIAL_STATE_MEMBER_FORM, IMember } from 'models/user';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { memberSchema } from 'schemas/memberSchema';

interface IMemberType {
  defaultValues?: IMember;
  onValuesChanged: (values: IMemberForm) => void;
  isFormValidChanged: (isFormValid: boolean) => void;
  isEmailInputDisabled?: boolean;
}

const MemberForm = ({
  defaultValues,
  onValuesChanged,
  isFormValidChanged,
  isEmailInputDisabled = false,
}: IMemberType) => {
  const { t } = useTranslation('userManagement');

  const {
    formState: { isValid },
    getValues,
    setValue,
    watch,
    trigger,
    control,
  } = useFormTemplate<IMemberForm>({
    resolver: memberSchema,
    defaultValues: defaultValues || INITIAL_STATE_MEMBER_FORM,
    formValidationMode: 'onTouched',
  });

  const roleValue = watch('roleId');

  useEffect(() => {
    isFormValidChanged(isValid);
  }, [isValid, isFormValidChanged]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onValuesChanged(getValues() as IMemberForm);
  };

  const usableMemberRoleOptions: Array<{
    value: string | number;
    label: string;
  }> = useMemo(() => {
    const roleList = [];

    if (process.env.REACT_APP_PARTNER_USER_ROLE_BASIC_ID) {
      roleList.push({
        value: process.env.REACT_APP_PARTNER_USER_ROLE_BASIC_ID,
        label: t('form.role.choice.basic'),
      });
    }

    if (process.env.REACT_APP_PARTNER_USER_ROLE_PARTNER_ADMIN_ID) {
      roleList.push({
        value: process.env.REACT_APP_PARTNER_USER_ROLE_PARTNER_ADMIN_ID,
        label: t('form.role.choice.partnerAdmin'),
      });
    }

    return roleList;
  }, [t]);

  const handleRoleChange = async (option: { label: string; value: any }) => {
    setValue('roleId', option.value);
    await trigger('roleId');
    onValuesChanged(getValues() as IMemberForm);
  };

  return (
    <Grid container component="form" spacing="1rem">
      <Grid item mobile={12}>
        <Grid container justifyContent="space-between" gap=".5rem">
          <Grid item>
            <Grid container direction="column" gap=".5rem">
              <Text variant="titleL">
                {t('create.block.basicInformation.title')}
              </Text>
              <Text variant="titleXS" color="custom.neutral.black.50">
                {t('create.block.basicInformation.subTitle')}
              </Text>
            </Grid>
          </Grid>
          <Grid item>
            <Text variant="titleXS" color="custom.neutral.black.50">
              {t('create.block.basicInformation.requiredFields')}
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="firstName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('form.firstName.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('form.lastName.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              required
              label={t('form.email.placeholder')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              disabled={isEmailInputDisabled}
              {...field}
              onChange={e => {
                field.onChange(e);
                handleInputChange(e);
              }}
            />
          )}
        />
      </Grid>
      <Grid item mobile={12} tablet={6} />
      <Grid item mobile={12} mt="2.75rem">
        <Grid container direction="column" gap="0.5rem">
          <Text variant="titleL">{t('create.block.role.title')}</Text>
          <Text variant="titleXS" color="custom.neutral.black.50">
            {t('create.block.role.subTitle')}
          </Text>
        </Grid>
      </Grid>
      <Grid item mobile={12} tablet={6}>
        <Dropdown
          id="roleId"
          options={usableMemberRoleOptions}
          label={t('form.role.placeholder')}
          value={
            usableMemberRoleOptions
              .filter(r => r.value !== undefined)
              .find(r => r.value === roleValue) || null
          }
          width="100%"
          onChange={handleRoleChange}
        />
      </Grid>
    </Grid>
  );
};

export default MemberForm;
