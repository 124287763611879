// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Grid,
  Stack,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import CountrySelector from 'components/organisms/CountrySelector';
import { IAddressForm } from 'models/address';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const AddressForm = ({ register, control, isDisabled }: IAddressForm & any) => {
  const { t, i18n } = useTranslation(['address', 'general']);
  const language = i18n.language;
  const isNumberBeforeStreet = language !== 'de';

  return (
    <>
      <Stack direction="row">
        <Controller
          name="company"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              disabled={isDisabled}
              label={t('address:companyName.label')}
              placeholder={t('general:optional')}
              error={fieldState.invalid}
              helperText={fieldState.error?.message}
              {...field}
            />
          )}
        />
      </Stack>
      <Grid container spacing={2}>
        {isNumberBeforeStreet ? (
          <>
            <Grid item mobile={3}>
              <Controller
                name="houseNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={isDisabled}
                    label={t('address:houseNumber.label')}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item mobile={9}>
              <Controller
                name="street"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={isDisabled}
                    label={t('address:street.label')}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item mobile={3}>
              <Controller
                name="street"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={isDisabled}
                    label={t('address:street.label')}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item mobile={9}>
              <Controller
                name="houseNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    disabled={isDisabled}
                    label={t('address:houseNumber.label')}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item mobile={3}>
          <Controller
            name="postalCode"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                disabled={isDisabled}
                label={t('address:postalCode.label')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item mobile={4.5}>
          <Controller
            name="city"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                disabled={isDisabled}
                label={t('address:city.label')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item mobile={4.5}>
          <CountrySelector
            control={control}
            isDisabled={isDisabled}
            register={register}
            width="100%"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddressForm;
