// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import useShipmentTab, {
  ShipmentListNavTabEnum,
  ShipmentListTabEnum,
} from 'hooks/useShipmentTab';
import { useSearchParams, Navigate } from 'react-router-dom';

const DeepLinkShipmentListRoute = () => {
  const { setShipmentNavTabCurrent, setShipmentTabCurrent } = useShipmentTab();

  const [searchParams] = useSearchParams();

  const partnerId = searchParams.get('partner_id');
  const destination = searchParams.get('destination');

  if (destination === 'send') {
    setShipmentNavTabCurrent(ShipmentListNavTabEnum.ongoing);
    setShipmentTabCurrent(ShipmentListTabEnum.onGoing);
  }

  return <Navigate to={`/partner/${partnerId}/shipments`} />;
};

export default DeepLinkShipmentListRoute;
