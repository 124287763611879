// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useState } from 'react';

import {
  Text,
  Box,
  Checkbox,
  styled,
  Stack,
  ButtonV2 as Button,
  ModalV2 as Modal,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import figuralLogo from 'assets/img/insurance/figural-logo.svg';
import TextArea from 'components/atoms/TextArea';
import CountrySelector from 'components/organisms/CountrySelector';
import useFormTemplate from 'hooks/useFormTemplate';
import { get, set } from 'lodash';
import { IFiguralInsurance } from 'models/figuralInsurance';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { figuralInsuranceSchema } from 'schemas/figuralInsuranceSchema';

const ButtonWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'isFormScrollAtBottom',
})<{ isFormScrollAtBottom: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 100px;
  box-shadow: ${({ isFormScrollAtBottom }) =>
    !isFormScrollAtBottom ? '0px -8px 20px rgb(0 0 0 / 8%)' : '0px'};
  transition: all 200ms ease-in-out;
  padding: 25px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  z-index: 3;
`;

const InsuranceFiguralLogo = styled('div', {
  shouldForwardProp: prop => prop !== 'figuralLogo',
})<{
  figuralLogo: string;
}>`
  min-width: 60px;
  height: 52px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ figuralLogo }) => figuralLogo});
  margin-right: 20px;
`;

const VStackFiguralInsuranceForm = styled(Stack)`
  margin-top: 25px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: ${({ theme }) => theme.palette.custom.neutral.black[20]};
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-track-piece {
    border-radius: 20px;
    background: ${({ theme }) => theme.palette.custom.neutral.black[8]};
  }

  ::-webkit-scrollbar-button {
    height: 100%;
    width: 2rem;
  }
`;

const FieldTitle = styled(Text)`
  margin-bottom: 8px;
`;

FieldTitle.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.50',
};

const FiguralInsuranceFormModal = ({
  isVisible,
  defaultValues,
  isShipmentHasReturnLabel,
  onCancel,
  onSubmit,
}: {
  isVisible: boolean;
  defaultValues: IFiguralInsurance;
  isShipmentHasReturnLabel: boolean;
  onCancel: () => void;
  onSubmit: (FiguralInsuranceFormData: IFiguralInsurance) => void;
}) => {
  const { t } = useTranslation('shipments');

  const [isFormScrollAtBottom, setIsFormScrollAtBottom] =
    useState<boolean>(false);

  const {
    errors,
    register,
    formState: { touchedFields, isValid },
    control,
    setValue,
    handleSubmit,
    trigger,
  } = useFormTemplate<IFiguralInsurance>({
    resolver: figuralInsuranceSchema,
  });

  useEffect(() => {
    if (defaultValues) {
      const inputConfig = {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      };

      if (defaultValues.goodsValue) {
        setValue('goodsValue', defaultValues.goodsValue, inputConfig);
        set(touchedFields, 'goodsValue', true);
        trigger('goodsValue');
      }

      setValue(
        'departureCountryCode',
        defaultValues.departureCountryCode,
        inputConfig
      );

      setValue(
        'arrivalCountryCode',
        defaultValues.arrivalCountryCode,
        inputConfig
      );

      if (defaultValues.note) {
        setValue('note', defaultValues.note, inputConfig);
        trigger('note');
      }

      if (defaultValues.sender.firstName) {
        setValue(
          'sender.firstName',
          defaultValues.sender.firstName,
          inputConfig
        );
        set(touchedFields, 'sender.firstName', true);
        trigger('sender.firstName');
      }

      if (defaultValues.sender.lastName) {
        setValue('sender.lastName', defaultValues.sender.lastName, inputConfig);
        set(touchedFields, 'sender.lastName', true);
        trigger('sender.lastName');
      }

      if (defaultValues.sender.email) {
        setValue('sender.email', defaultValues.sender.email, inputConfig);
        set(touchedFields, 'sender.email', true);
        trigger('sender.email');
      }

      if (defaultValues.sender.address) {
        setValue('sender.address', defaultValues.sender.address, inputConfig);
        set(touchedFields, 'sender.address', true);
        trigger('sender.address');
      }

      if (defaultValues.sender.city) {
        setValue('sender.city', defaultValues.sender.city, inputConfig);
        set(touchedFields, 'sender.city', true);
        trigger('sender.city');
      }

      if (defaultValues.sender.zipCode) {
        setValue('sender.zipCode', defaultValues.sender.zipCode, inputConfig);
        set(touchedFields, 'sender.zipCode', true);
        trigger('sender.zipCode');
      }

      if (defaultValues.sender.countryCode) {
        setValue(
          'sender.countryCode',
          defaultValues.sender.countryCode,
          inputConfig
        );
      }
      setValue(
        'isGoodsReturnInsured',
        defaultValues.isGoodsReturnInsured,
        inputConfig
      );
    }
  }, [defaultValues, touchedFields, setValue, trigger, isVisible]);

  const handleScroll = (e: any) => {
    let element = e.target;

    if (
      element.scrollHeight - element.scrollTop + 0.5 ===
      element.clientHeight
    ) {
      setIsFormScrollAtBottom(true);
    } else {
      setIsFormScrollAtBottom(false);
    }
  };

  return (
    <Modal open={isVisible} setOpen={() => {}} onDismiss={onCancel}>
      <>
        <Text variant="titleM">{t('modal.figuralInsuranceForm.title')}</Text>
        <Stack direction="row" marginTop="20px" alignItems="center">
          <InsuranceFiguralLogo figuralLogo={figuralLogo} />
          <Text variant="bodyTextS" sx={{ whiteSpace: 'pre' }}>
            {t('modal.figuralInsuranceForm.warning')}
          </Text>
        </Stack>
        <VStackFiguralInsuranceForm as="form" onScroll={handleScroll}>
          <Box mb="1rem">
            <FieldTitle>
              {t('form.figuralInsurance.title.goodsValue')}
            </FieldTitle>
            <Controller
              name="goodsValue"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  label={t(
                    'form.figuralInsurance.fields.goodsValue.placeholder'
                  )}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  fullWidth
                  {...field}
                />
              )}
            />
          </Box>
          <Stack direction="row" marginBottom="16px">
            <Box width="50%" marginRight="8px">
              <FieldTitle>
                {t('form.figuralInsurance.title.departureCountryCode')}
              </FieldTitle>
              <CountrySelector
                id="departureCountryCode"
                fieldName="departureCountryCode"
                width="100%"
                control={control}
                register={register}
              />
            </Box>
            <Box width="50%" marginLeft="8px">
              <FieldTitle>
                {t('form.figuralInsurance.title.arrivalCountryCode')}
              </FieldTitle>
              <CountrySelector
                id="arrivalCountryCode"
                fieldName="arrivalCountryCode"
                width="100%"
                control={control}
                register={register}
              />
            </Box>
          </Stack>
          <Box>
            <FieldTitle>{t('form.figuralInsurance.title.note')}</FieldTitle>
            <TextArea
              name="note"
              label={
                get(touchedFields, 'note', false)
                  ? t('form.figuralInsurance.fields.note.placeholder')
                  : undefined
              }
              placeholder={t('form.figuralInsurance.fields.note.placeholder')}
              error={get(errors, 'note', false)}
              isTouched={get(touchedFields, 'note', false)}
              register={register}
              width="100%"
              height="80px"
              characterLimit={255}
            />
          </Box>
          <Stack gap=".5rem">
            <FieldTitle>{t('form.figuralInsurance.title.sender')}</FieldTitle>
            <Stack direction="row">
              <Controller
                name="sender.firstName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    label={t(
                      'form.figuralInsurance.fields.sender.firstName.placeholder'
                    )}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    fullWidth
                    sx={{ width: '50%', marginRight: '.5rem' }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="sender.lastName"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    label={t(
                      'form.figuralInsurance.fields.sender.lastName.placeholder'
                    )}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    fullWidth
                    sx={{ width: '50%', marginLeft: '.5rem' }}
                    {...field}
                  />
                )}
              />
            </Stack>
            <Controller
              name="sender.email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  label={t(
                    'form.figuralInsurance.fields.sender.email.placeholder'
                  )}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  fullWidth
                  {...field}
                />
              )}
            />
          </Stack>
          <Stack mt="1rem" mb="1rem" gap=".5rem">
            <FieldTitle>
              {t('form.figuralInsurance.title.senderAddress')}
            </FieldTitle>
            <Controller
              name="sender.address"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  required
                  label={t(
                    'form.figuralInsurance.fields.sender.address.placeholder'
                  )}
                  error={fieldState.invalid}
                  helperText={fieldState.error?.message}
                  fullWidth
                  {...field}
                />
              )}
            />
            <Stack direction="row">
              <Controller
                name="sender.city"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    label={t(
                      'form.figuralInsurance.fields.sender.city.placeholder'
                    )}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    fullWidth
                    sx={{ width: '70%', marginRight: '.5rem' }}
                    {...field}
                  />
                )}
              />
              <Controller
                name="sender.zipCode"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    label={t(
                      'form.figuralInsurance.fields.sender.zipCode.placeholder'
                    )}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    fullWidth
                    sx={{ width: '70%', marginLeft: '.5rem' }}
                    {...field}
                  />
                )}
              />
            </Stack>
            <Box maxHeight="14rem">
              <CountrySelector
                id="countryCode"
                fieldName="sender.countryCode"
                width="100%"
                control={control}
                register={register}
              />
            </Box>
            {isShipmentHasReturnLabel && (
              <Box marginTop="23px !important">
                <Controller
                  control={control}
                  name="isGoodsReturnInsured"
                  defaultValue={defaultValues.isGoodsReturnInsured}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      label={t(
                        'form.figuralInsurance.fields.isGoodsReturnInsured.label'
                      )}
                    />
                  )}
                />
                <Text
                  variant="bodyTextXS"
                  color="custom.neutral.black.50"
                  mt="5px"
                >
                  {t('form.figuralInsurance.help')}
                </Text>
              </Box>
            )}
          </Stack>
        </VStackFiguralInsuranceForm>
        <ButtonWrapper isFormScrollAtBottom={isFormScrollAtBottom}>
          <Button
            variant="secondary"
            color={defaultValues.isConfirmed ? 'critical' : 'primary'}
            onClick={onCancel}
            data-testid="figural-insurance-form-cancel-button"
          >
            {defaultValues.isConfirmed
              ? t('modal.figuralInsuranceForm.button.delete-figural-insurance')
              : t('modal.figuralInsuranceForm.button.cancel-figural-insurance')}
          </Button>
          <Button
            disabled={!isValid}
            onClick={handleSubmit(onSubmit as any)}
            data-testid="figural-insurance-form-confirm-button"
          >
            {t('modal.figuralInsuranceForm.button.confirm')}
          </Button>
        </ButtonWrapper>
      </>
    </Modal>
  );
};

export default FiguralInsuranceFormModal;
