// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Divider, Grid } from '@livingpackets/design-system-react-next';
import { MoreIcon } from '@livingpackets/icons-react';
import { IconButton } from '@mui/material';
import LivingPacketsLogo from 'assets/living-packets-logo-green-text.svg';
import useLayoutStore, { setOpenNavSelector } from 'stores/useLayoutStore';

const MobileHeader = () => {
  const setOpenNav = useLayoutStore(setOpenNavSelector);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        backgroundColor: 'custom.neutral.white.pure',
        width: '100%',
      }}
    >
      <IconButton
        disableRipple
        aria-label="open menu"
        onClick={() => setOpenNav(true)}
      >
        <MoreIcon />
      </IconButton>
      <Divider
        orientation="vertical"
        sx={{ height: '1.25rem', mr: '1.125rem' }}
      />
      <img src={LivingPacketsLogo} alt="LivingPackets" />
    </Grid>
  );
};

export default MobileHeader;
