// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useState } from 'react';

import { CardBody, CardHeader, Card } from '@livingpackets/design-system-react';
import {
  ArrowLeftIcon,
  Box,
  ButtonV2 as Button,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import { DeployReturnTabs } from 'features/deploy-return';
import useShipmentDetail from 'hooks/useShipmentDetail';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useParams,
  useLocation,
  generatePath,
} from 'react-router-dom';
import { formatLpui } from 'utils/product';

export const STATE_ORIGIN_PRODUCT = 'product';
export const STATE_ORIGIN_SHIPMENT = 'shipment';

export const DeployReturn = () => {
  const { t } = useTranslation('deployReturn');
  const navigate = useNavigate();

  const { shipmentId, partnerId } = useParams<{
    shipmentId: string;
    partnerId: string;
  }>();

  const { getShipmentDetail } = useShipmentDetail();
  const [lpui, setLpui] = useState();

  useEffect(() => {
    if (shipmentId && partnerId) {
      getShipmentDetail(partnerId, shipmentId).then(({ success, shipment }) => {
        if (success && shipment.product) {
          setLpui(shipment.product.lp_ui);
        }
      });
    }
  }, [getShipmentDetail, partnerId, shipmentId]);

  const location = useLocation();

  const { origin, productId } = location.state || {};

  const goToProduct = () => {
    navigate(
      generatePath(PATHS.PRODUCT.DETAIL, {
        partnerId: partnerId,
        lpUi: productId,
      })
    );
  };

  return (
    <Box>
      <Card>
        <CardHeader backgroundColor="black.100" color="white" padding={5}>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Button
                variant="tertiary"
                color="info"
                icon={ArrowLeftIcon}
                onClick={() => navigate(-1)}
              />
              <Text variant="bodyTextXS" color="custom.neutral.black.50">
                {/*  TODO : ATTENTE RETOUR DESIGN POUR HOMOGENITE */}
                {origin === STATE_ORIGIN_PRODUCT
                  ? t('productBreadcrumb')
                  : t('shipmentBreadcrumb')}
              </Text>
            </Stack>
            <Stack direction="column" spacing={0}>
              <Text variant="titleXS">{formatLpui(lpui)}</Text>
              <Text variant="titleXL">{t('titlePage')}</Text>
            </Stack>
          </Stack>
        </CardHeader>
        <CardBody style={{ padding: '1.5rem' }}>
          <DeployReturnTabs />
          <Box display="flex" justifyContent="flex-end" paddingTop="1rem">
            <Button
              onClick={() => {
                origin === STATE_ORIGIN_PRODUCT ? goToProduct() : navigate(-1);
              }}
            >
              {origin === STATE_ORIGIN_PRODUCT
                ? t('ctaLabelProduct')
                : t('ctaLabelShipment')}
            </Button>
          </Box>
        </CardBody>
      </Card>
    </Box>
  );
};
