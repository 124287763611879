// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Stack, Text, styled } from '@livingpackets/design-system-react-next';

import useGetDashboardData from '../../../hooks/useGetDashboardData';
import { SocialMediaArticle } from '../../../models/socialMediaArticle';

const SocialMediaDiv = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  display: flex;
  width: 100%;
  height: 5rem;
  border-radius: 14px;
  padding: 1rem;
  margin-bottom: 0.7rem;
`;

const SocialMediaIcon = styled('div', {
  shouldForwardProp: prop => prop !== 'url',
})<{ url: string }>`
  background-image: url(${({ url }) => url});
  background-size: contain;
  width: 70%;
  height: 70%;
  display: inline-block;
  background-repeat: no-repeat;
  align-self: center;
`;

const TitleLink = styled('a')`
  color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  text-decoration: none;
`;

interface SocialMediaArticleItem {
  article: SocialMediaArticle;
}
const SocialMediaCard = ({ article }: SocialMediaArticleItem) => {
  const { title, url, description, publicated_at_smArticle } =
    useGetDashboardData(article);

  return (
    <SocialMediaDiv>
      <Stack direction="row" width="10%">
        <SocialMediaIcon url={article.url_icon} />
      </Stack>
      <Stack justifyContent="center" width="70%">
        <Text variant="bodyTextM">
          <TitleLink target="_blank" href={url}>
            {title}
          </TitleLink>
        </Text>
        <Text
          variant="bodyTextS"
          sx={{
            marginTop: '0.1rem',
            lineHeight: '1rem',
          }}
        >
          {description}
        </Text>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        width="20%"
        justifyContent="flex-end"
      >
        <Text variant="bodyTextS" color="custom.neutral.black.50">
          {publicated_at_smArticle}
        </Text>
      </Stack>
    </SocialMediaDiv>
  );
};

export default SocialMediaCard;
