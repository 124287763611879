// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useContext, useEffect } from 'react';

import { Grid, ModalV2, Text } from '@livingpackets/design-system-react-next';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import { useTranslation } from 'react-i18next';
import useAppState, { userSelector } from 'stores/appState/useAppState';

import { ModalSizeContext } from '../../../../routes/Personal';
import { ContactUsItem } from '../../../personal/help/contactUs/ContactUsItem';

export const RGPD_MORE_INFO_LINK = 'https://livingpackets.com/privacy/en';

export const CannotDeleteModal = ({ open, setOpen }: any) => {
  const { t } = useTranslation('account');

  // In personal acocunt no context is provided so we need a default {} to avoid errors
  const { width, handleResize } = useContext(ModalSizeContext) || {};

  const { businessType } = useAppState(userSelector)!;

  const isPersonalAccount = businessType === UserBusinessTypeEnum.PERSONAL;

  useEffect(() => {
    if (open && handleResize) {
      handleResize();
    }
  }, [open, handleResize]);

  return (
    <ModalV2
      open={open}
      setOpen={setOpen}
      sx={{
        '& .MuiPaper-root': {
          width,
        },
      }}
    >
      <Grid container direction="column" gap="2rem">
        <Grid item mobile={12}>
          <Text variant="titleXL">{t('personal.deletion.title')}</Text>
        </Grid>
        <Grid item mobile={12}>
          <Grid container direction="column" gap=".875rem">
            <Grid item mobile={12}>
              <Text variant="titleM" color="custom.neutral.black.50">
                {t('personal.deletion.instructions')}
              </Text>
            </Grid>
            <Grid item mobile={12}>
              <Grid
                container
                direction="column"
                gap="1.5rem"
                sx={{
                  bgcolor: 'custom.neutral.black.2',
                  borderRadius: '1.25rem',
                  padding: '1.5rem',
                }}
              >
                <Text variant="bodyTextM">
                  {t('personal.deletion.info1')}
                  <br />
                  <br />
                  {t('personal.deletion.info2')}
                  {!isPersonalAccount && ` ${t('personal.deletion.info3')}`}
                </Text>
                <Text
                  variant="titleS"
                  component="a"
                  color="primary.main"
                  href={RGPD_MORE_INFO_LINK}
                  target="_blank"
                >
                  {t('personal.deletion.gdprLink')}
                </Text>
                <Text variant="titleS">
                  {isPersonalAccount
                    ? t('personal.deletion.cannotDeleteContributionsWarning')
                    : t('personal.deletion.cannotDeleteWarning')}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item mobile={12}>
          <Grid container spacing={1}>
            <Grid item mobile={12} laptop={4}>
              <ContactUsItem prefix="franceOrUK" hideBubble />
            </Grid>
            <Grid item mobile={12} laptop={4}>
              <ContactUsItem prefix="germany" hideBubble />
            </Grid>
            <Grid item mobile={12} laptop={4}>
              <ContactUsItem prefix="switzerland" hideBubble />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalV2>
  );
};
