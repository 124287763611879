// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import {
  useMediaQuery,
  useTheme,
} from '@livingpackets/design-system-react-next';

/**
 * @returns {Object} Information about the responsive layout
 */
const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('laptop'));
  const isLaptop = !isMobile;

  return { isMobile, isLaptop };
};

export default useIsMobile;
