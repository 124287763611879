// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  AddIcon,
  ButtonV2 as Button,
  Grid,
  Stack,
  Text,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import { PhoneNumber } from 'components/form/phoneNumber';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EditableAddress } from '../../../types/address';

export const SpecificContact = ({
  initialValues,
  control,
  disabled,
}: {
  initialValues: Partial<EditableAddress>;
  control: Control<Partial<EditableAddress>, any>;
  disabled?: boolean;
}) => {
  const { t } = useTranslation(['forms', 'account']);

  const [showSpecificContact, setShowSpecificContact] = useState(
    Boolean(initialValues.phoneNumber || initialValues.email)
  );

  if (!showSpecificContact)
    return (
      <Grid item mobile={12} mt=".625rem">
        <Button
          fullWidth
          disabled={disabled}
          variant="secondary"
          onClick={() => setShowSpecificContact(true)}
          data-testid="add-specific-contact"
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap="0.625rem"
          >
            <span>{t('account:address.addSpecificContact')}</span>
            <AddIcon />
          </Stack>
        </Button>
      </Grid>
    );

  return (
    <Grid item mobile={12}>
      <Text mt=".625rem" mb=".875rem" variant="titleS">
        {t('account:partnership.information.contact')}
      </Text>
      <Grid container spacing="0.875rem">
        <Grid item mobile={12} tablet={6}>
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState: { error, invalid } }) => (
              <PhoneNumber
                disabled={disabled}
                label={t('forms:phoneNumber.label')}
                defaultCountryCode="FR"
                error={invalid}
                helperText={error?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item mobile={12} tablet={6}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                disabled={disabled}
                label={t('forms:email.label')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
