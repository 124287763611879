// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import FirstPage from './FirstPage';
import SecondPage from './SecondPage';

type Props = {
  onClose?: () => void;
};

export const Onboarding = ({ onClose = () => {} }: Props) => {
  const [stepsOnboarding, setStepsOnboarding] = useState<number>(0);

  return stepsOnboarding === 0 ? (
    <FirstPage
      setStepsOnboarding={setStepsOnboarding}
      closeOnboarding={onClose}
    />
  ) : stepsOnboarding === 1 ? (
    <SecondPage
      setStepsOnboarding={setStepsOnboarding}
      closeOnboarding={onClose}
    />
  ) : null;
};
