// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import {
  WarningIcon,
  ShipmentOngoingIcon,
  ShipmentCompletedIcon,
  Text,
  Box,
  Stack,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import useIntlFormatTimestamp from 'hooks/useIntlFormatTimestamp';
import useShipmentDetail from 'hooks/useShipmentDetail';
import {
  IShipmentTimelineEntry,
  ShipmentContractStateEnum,
} from 'models/shipment';
import { useTranslation } from 'react-i18next';

const CurrentStatusContainer = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.palette.custom.neutral.black[8]};
  border-left: 1px solid ${({ theme }) => theme.palette.custom.neutral.black[8]};
  border-right: 1px solid
    ${({ theme }) => theme.palette.custom.neutral.black[8]};
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.lg};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.lg};
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  z-index: 1;
`;

const CurrentEventDateText = styled(Text)`
  color: ${({ theme }) => theme.palette.custom.neutral.black[50]};
`;

const CurrentEventNameText = styled(Text)``;

CurrentEventNameText.defaultProps = {
  variant: 'titleXS',
  color: 'custom.neutral.black.90',
};

const CurrentEventNoAlertText = styled(Text)`
  text-align: center;
`;

CurrentEventNoAlertText.defaultProps = {
  variant: 'bodyTextS',
  color: 'custom.neutral.black.50',
};

const CurrentEventAlertClosedText = styled(Text)`
  text-align: center;
`;

CurrentEventAlertClosedText.defaultProps = {
  variant: 'bodyTextXS',
  color: 'custom.neutral.black.100',
};

const CurrentEventAlertContainer = styled(Stack)`
  justify-content: center;
  width: 125px;
  border-left: 1px solid ${({ theme }) => theme.palette.custom.neutral.black[8]};
  margin-right: 20px;
`;

const StateEventContainer = styled('div')`
  width: 48px;
  height: 48px;
  border-radius: 14px;
  background-color: ${({ theme }) => theme.palette.custom.primary[10]};
  text-align: center;
  line-height: 60px;
`;

const ShipmentDetailCurrentStatus = ({
  timelineEvents,
  contractState,
}: {
  timelineEvents: IShipmentTimelineEntry[];
  contractState: ShipmentContractStateEnum;
}) => {
  const theme = useTheme();
  const { t } = useTranslation('shipments');

  const formatTimestamp = useIntlFormatTimestamp();
  const { isShipmentTimelineItemAlert, isAlertClosed } = useShipmentDetail();

  const getNbAlertOpened = useCallback(
    () =>
      timelineEvents.filter(
        (event: IShipmentTimelineEntry) =>
          isShipmentTimelineItemAlert(event) && !isAlertClosed(event)
      ).length,
    [timelineEvents, isAlertClosed, isShipmentTimelineItemAlert]
  );

  const getNbAlertClosed = useCallback(
    () =>
      timelineEvents.filter(
        (event: IShipmentTimelineEntry) =>
          isShipmentTimelineItemAlert(event) && isAlertClosed(event)
      ).length,
    [timelineEvents, isAlertClosed, isShipmentTimelineItemAlert]
  );

  const getLastShipmentEventDateElement = useCallback(
    (timelineDataItems: IShipmentTimelineEntry[]) => {
      let lastEventDate = null;

      const timelineDataItemsReversed = timelineDataItems.slice().reverse();

      timelineDataItemsReversed.forEach((event: IShipmentTimelineEntry) => {
        if (!isShipmentTimelineItemAlert(event)) {
          lastEventDate = event.createdAt;
        }
      });

      if (lastEventDate) {
        return (
          <CurrentEventDateText
            variant="titleXS"
            color="custom.neutral.black.50"
            data-testid="date-delivery-statut"
          >
            {formatTimestamp(lastEventDate, {
              day: 'numeric',
              month: 'long',
              year: undefined,
              hour: '2-digit',
              minute: '2-digit',
            })}
          </CurrentEventDateText>
        );
      } else {
        return;
      }
    },
    [formatTimestamp, isShipmentTimelineItemAlert]
  );

  return (
    <CurrentStatusContainer>
      <Stack direction="row" justifyContent="space-between">
        <Stack>
          <Stack direction="row">
            <StateEventContainer>
              {contractState !== ShipmentContractStateEnum.ARRIVED && (
                <ShipmentOngoingIcon
                  color={theme.palette.custom.primary[100]}
                />
              )}
              {contractState === ShipmentContractStateEnum.ARRIVED && (
                <ShipmentCompletedIcon
                  color={theme.palette.custom.primary[100]}
                />
              )}
            </StateEventContainer>
            <Stack
              marginLeft="16px !important"
              height="100%"
              justifyContent="center"
            >
              {getLastShipmentEventDateElement(timelineEvents)}
              <CurrentEventNameText data-testid="delivery-statut">
                {contractState !== ShipmentContractStateEnum.ARRIVED &&
                  t('detail.contractState.deployed')}
                {contractState === ShipmentContractStateEnum.ARRIVED &&
                  t('detail.contractState.completed')}
              </CurrentEventNameText>
            </Stack>
          </Stack>
        </Stack>
        <CurrentEventAlertContainer>
          {getNbAlertOpened() !== 0 && (
            <Stack direction="row" alignItems="center" justifyContent="center">
              <WarningIcon color={theme.palette.custom.error.red[100]} />
              <Text
                variant="bodyTextS"
                ml=".5625rem"
                color="custom.neutral.black.100"
                data-testid="delivery-alert-count"
              >
                {getNbAlertOpened() === 1 &&
                  t('detail.currentStatus.alertNb', {
                    count: getNbAlertOpened(),
                  })}
                {getNbAlertOpened() > 1 &&
                  t('detail.currentStatus.alertNbPlural', {
                    count: getNbAlertOpened(),
                  })}
              </Text>
            </Stack>
          )}
          {getNbAlertOpened() === 0 && (
            <CurrentEventNoAlertText>
              {t('detail.currentStatus.noAlert')}
            </CurrentEventNoAlertText>
          )}
          {getNbAlertClosed() !== 0 && (
            <CurrentEventAlertClosedText>
              {t(
                getNbAlertClosed() === 1
                  ? 'detail.currentStatus.alertClosed'
                  : 'detail.currentStatus.alertClosedPlural',
                {
                  nbAlertClosed: getNbAlertClosed(),
                }
              )}
            </CurrentEventAlertClosedText>
          )}
        </CurrentEventAlertContainer>
      </Stack>
    </CurrentStatusContainer>
  );
};

export default ShipmentDetailCurrentStatus;
