// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ButtonV2 as Button,
  UploadIcon,
  Box,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import ImportShipmentStepTextBlock from 'components/atoms/importShipment/ImportShipmentStepTextBlock';
import { LabelCropStateNameEnum } from 'components/organisms/ShipmentLabelForm';
import CARRIERS_LIST, { CarrierInfo } from 'models/carriers';
import { Trans, useTranslation } from 'react-i18next';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';

const Wrapper = styled('div')<{ isShipmentFormDisplayed: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${({ isShipmentFormDisplayed }) =>
    isShipmentFormDisplayed ? '1 50%' : '1 100%'};
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  border-radius: 20px;
  height: inherit;
`;

const HeaderWrapper = styled('div')`
  padding-top: 32px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  height: inherit;
`;

export const LogoWrapper = styled('img')`
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0.6875rem;
`;

const WrapperMain = styled('div')<{
  isShipmentFormDisplayed: boolean;
  isConfirmInformationStepDisplayed: boolean;
}>`
  width: ${({ isShipmentFormDisplayed }) =>
    isShipmentFormDisplayed ? '100%' : '100%'};
  height: 100%;
  margin-top: 28px;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  justify-content: ${({ isConfirmInformationStepDisplayed }) =>
    isConfirmInformationStepDisplayed ? 'flex-start' : 'flex-end'};
  align-items: ${({ isShipmentFormDisplayed }) =>
    isShipmentFormDisplayed ? 'center' : 'stretch'};
  flex-wrap: nowrap;
`;

const HeaderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FooterWrapper = styled(Box)`
  padding: 30px 44px 32px 44px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

interface ShipmentLabelContainerProps {
  children: React.ReactNode;
  carrierInfo: CarrierInfo;
  actions?: React.ReactNode;
  labelCropState: string;
  handleLabelReUpload: () => void;
}

const displayShipmentForm = (labelCropState: string): boolean =>
  labelCropState === LabelCropStateNameEnum.shipmentCreationFormDisplay ||
  labelCropState === LabelCropStateNameEnum.showingAutoCropResult;

const displayFixedPage = (labelCropState: string): boolean =>
  displayShipmentForm(labelCropState) ||
  labelCropState === LabelCropStateNameEnum.manuallyCropping ||
  labelCropState === LabelCropStateNameEnum.showingManualCropResult;

const displaySelectCarrier = (labelCropState: string): boolean =>
  labelCropState === LabelCropStateNameEnum.waitingForFile ||
  labelCropState === LabelCropStateNameEnum.processingFile ||
  labelCropState === LabelCropStateNameEnum.autoCropping;

const displayConfirmInformationStep = (labelCropState: string): boolean =>
  displayShipmentForm(labelCropState) ||
  labelCropState === LabelCropStateNameEnum.showingManualCropResult;

export const ShipmentLabelContainer = ({
  children,
  carrierInfo,
  actions,
  labelCropState,
  handleLabelReUpload,
}: ShipmentLabelContainerProps) => {
  const { t } = useTranslation(['general', 'shipments']);

  const activePartner = useMyPartnersStore(activePartnerSelector);

  const hasOneCarrier =
    CARRIERS_LIST.filter(carrier =>
      activePartner.carriers?.includes(carrier.code)
    ).length === 1;

  return (
    <Wrapper isShipmentFormDisplayed={displayShipmentForm(labelCropState)}>
      <Box display="flex" width="100%" flexDirection="column">
        <HeaderWrapper>
          {displayConfirmInformationStep(labelCropState) && (
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <ImportShipmentStepTextBlock
                number="3"
                title={t(
                  'shipments:importLabel.header.steps.confirmInformation.title'
                )}
                subTitle={t(
                  'shipments:importLabel.header.steps.confirmInformation.subTitle'
                )}
              />
            </Box>
          )}
          {!displayShipmentForm(labelCropState) && (
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              {(labelCropState === LabelCropStateNameEnum.waitingForFile ||
                labelCropState === LabelCropStateNameEnum.processingFile ||
                labelCropState === LabelCropStateNameEnum.autoCropping) && (
                <ImportShipmentStepTextBlock
                  number="2"
                  title={t(
                    'shipments:importLabel.header.steps.waitingForFile.title'
                  )}
                  subTitle={t(
                    'shipments:importLabel.header.steps.waitingForFile.subTitle'
                  )}
                />
              )}
              {labelCropState === LabelCropStateNameEnum.pdfPageSelection && (
                <>
                  <ImportShipmentStepTextBlock
                    number="3"
                    title={t(
                      'shipments:importLabel.header.steps.choosePdfPage.title'
                    )}
                    subTitle={t(
                      'shipments:importLabel.header.steps.choosePdfPage.subTitle'
                    )}
                  />
                  <Button
                    variant="tertiary"
                    onClick={handleLabelReUpload}
                    icon={UploadIcon}
                  >
                    {t('general:reupload')}
                  </Button>
                </>
              )}
              {labelCropState ===
                LabelCropStateNameEnum.pdfSpecificPageSelectedManuallyCropping && (
                <>
                  <ImportShipmentStepTextBlock
                    number="4"
                    title={t(
                      'shipments:importLabel.header.steps.cropPdfPage.title'
                    )}
                    subTitle={t(
                      'shipments:importLabel.header.steps.cropPdfPage.subTitle'
                    )}
                  />
                  <Button
                    variant="tertiary"
                    onClick={handleLabelReUpload}
                    icon={UploadIcon}
                  >
                    {t('general:reupload')}
                  </Button>
                </>
              )}
              {labelCropState === LabelCropStateNameEnum.manuallyCropping && (
                <>
                  <ImportShipmentStepTextBlock
                    number="4"
                    title={t(
                      'shipments:importLabel.header.steps.cropPdfPage.title'
                    )}
                    subTitle={t(
                      'shipments:importLabel.header.steps.cropPdfPage.subTitle'
                    )}
                  />
                  <Button
                    variant="tertiary"
                    onClick={handleLabelReUpload}
                    icon={UploadIcon}
                  >
                    {t('general:reupload')}
                  </Button>
                </>
              )}
              {displaySelectCarrier(labelCropState) && (
                <Box display="flex" alignItems="center">
                  <LogoWrapper src={carrierInfo.logo} />
                  <HeaderTextWrapper>
                    <Text variant="titleXS">
                      {hasOneCarrier
                        ? t('shipments:preSelectedCarrier')
                        : t('shipments:selectedCarrier')}
                    </Text>
                    <Text
                      variant="bodyTextS"
                      data-testid="shipmentLabel-carrierInfo-name"
                    >
                      <Trans t={t}>
                        {`shipments:${carrierInfo.code.toLowerCase()}`}
                      </Trans>
                    </Text>
                  </HeaderTextWrapper>
                </Box>
              )}
            </Box>
          )}
          {labelCropState === LabelCropStateNameEnum.showingAutoCropResult && (
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button
                variant="tertiary"
                onClick={handleLabelReUpload}
                icon={UploadIcon}
              >
                {t('general:reupload')}
              </Button>
            </Box>
          )}
          {labelCropState ===
            LabelCropStateNameEnum.shipmentCreationFormDisplay && (
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Button
                variant="tertiary"
                onClick={handleLabelReUpload}
                icon={UploadIcon}
              >
                {t('general:reupload')}
              </Button>
            </Box>
          )}
        </HeaderWrapper>
        <WrapperMain
          isShipmentFormDisplayed={displayFixedPage(labelCropState)}
          isConfirmInformationStepDisplayed={displayConfirmInformationStep(
            labelCropState
          )}
        >
          {children}
        </WrapperMain>
        <FooterWrapper>{actions}</FooterWrapper>
      </Box>
    </Wrapper>
  );
};
