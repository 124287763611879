// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import useImperativeRequest from 'hooks/useImperativeRequest';
import { IUser } from 'models/user';
import useAppState, {
  dispatch,
  userSelector,
} from 'stores/appState/useAppState';

/**
 * /!\ Update user through API v3 but update zustand state in v1 format
 */
export const useUpdateOnboardingWebDone = () => {
  const [, makeRequest] = useImperativeRequest('lpAccountV3');
  const userInfos = useAppState(userSelector);

  const updateOnboardingWebDone = async (value: boolean) => {
    // Update backend
    const { data } = await makeRequest({
      path: '/me',
      method: 'patch',
      body: {
        on_boarding_web_done: value,
      },
    });

    // Update zustand state
    if (data?.on_boarding_web_done) {
      dispatch({
        type: 'SET_USER',
        payload: {
          ...userInfos,
          onBoardingWebDone: data.on_boarding_web_done,
        } as IUser,
      });

      return;
    }
  };

  return { updateOnboardingWebDone };
};
