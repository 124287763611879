// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Stack, Text, styled } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import useGetDashboardData from '../../../hooks/useGetDashboardData';
import { NewsletterItem } from '../../../models/newsletter';

const NewsletterDiv = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  display: flex;
  height: 5rem;
  border-radius: 14px;
  padding: 1rem;
  margin-bottom: 0.7rem;
`;

const DateDiv = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[3]};
  height: 2.5rem;
  border-radius: 10px;
  padding: 0.75rem;
  display: flex;
  align-self: center;
  justify-content: center;
  width: 90%;
`;

const TitleLink = styled('a', {
  shouldForwardProp: prop => prop !== 'newsletterNumber',
})<{ newsletterNumber?: boolean }>`
  color: ${({ theme, newsletterNumber }) =>
    !newsletterNumber ? theme.palette.custom.neutral.black[100] : 'inherit'};
  text-decoration: none;
`;

interface NewsletterListItem {
  newsletter: NewsletterItem;
}

const NewsletterCard = ({ newsletter }: NewsletterListItem) => {
  const { t } = useTranslation('dashboard');

  const { title, url, publicated_at_newsletter } =
    useGetDashboardData(newsletter);

  return (
    <NewsletterDiv>
      <Stack direction="row" width="26%">
        <DateDiv>
          <Text
            sx={{
              display: 'flex',
              alignSelf: 'center',
            }}
          >
            {publicated_at_newsletter}
          </Text>
        </DateDiv>
      </Stack>
      <Stack width="66%" justifyContent="center">
        <Text
          variant="bodyTextS"
          ml="1.5rem"
          sx={{
            lineHeight: '1rem',
          }}
        >
          <TitleLink target="_blank" href={url}>
            {title}
          </TitleLink>
        </Text>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        width="8%"
        justifyContent="flex-end"
      >
        <Text color="custom.primary.100" variant="bodyTextL">
          <TitleLink newsletterNumber target="_blank" href={url}>
            {t('newsletterList.newsletterNumber', {
              number: newsletter.newsletter_number,
            })}
          </TitleLink>
        </Text>
      </Stack>
    </NewsletterDiv>
  );
};
export default NewsletterCard;
