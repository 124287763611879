// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { CountriesArray } from '@livingpackets/design-system-react-next';

/// Correctly formatted (USA got +1 as phone code, + removed countries with empty phone code)
export const countriesData: CountriesArray = [
  {
    translations: {
      de: 'Andorra',
      fr: 'Andorre',
    },
    name: 'Andorra',
    phoneCode: '+376',
    alpha2Code: 'AD',
  },
  {
    translations: {
      de: 'Vereinigte Arabische Emirate',
      fr: 'Émirats arabes unis',
    },
    name: 'United Arab Emirates',
    phoneCode: '+971',
    alpha2Code: 'AE',
  },
  {
    translations: {
      de: 'Afghanistan',
      fr: 'Afghanistan',
    },
    name: 'Afghanistan',
    phoneCode: '+93',
    alpha2Code: 'AF',
  },
  {
    translations: {
      de: 'Antigua und Barbuda',
      fr: 'Antigua-et-Barbuda',
    },
    name: 'Antigua and Barbuda',
    phoneCode: '+1268',
    alpha2Code: 'AG',
  },
  {
    translations: {
      de: 'Anguilla',
      fr: 'Anguilla',
    },
    name: 'Anguilla',
    phoneCode: '+1264',
    alpha2Code: 'AI',
  },
  {
    translations: {
      de: 'Albanien',
      fr: 'Albanie',
    },
    name: 'Albania',
    phoneCode: '+355',
    alpha2Code: 'AL',
  },
  {
    translations: {
      de: 'Armenien',
      fr: 'Arménie',
    },
    name: 'Armenia',
    phoneCode: '+374',
    alpha2Code: 'AM',
  },
  {
    translations: {
      de: 'Angola',
      fr: 'Angola',
    },
    name: 'Angola',
    phoneCode: '+244',
    alpha2Code: 'AO',
  },
  {
    translations: {
      de: 'Argentinien',
      fr: 'Argentine',
    },
    name: 'Argentina',
    phoneCode: '+54',
    alpha2Code: 'AR',
  },
  {
    translations: {
      de: 'Amerikanisch-Samoa',
      fr: 'Samoa américaines',
    },
    name: 'American Samoa',
    phoneCode: '+1684',
    alpha2Code: 'AS',
  },
  {
    translations: {
      de: 'Österreich',
      fr: 'Autriche',
    },
    name: 'Austria',
    phoneCode: '+43',
    alpha2Code: 'AT',
  },
  {
    translations: {
      de: 'Australien',
      fr: 'Australie',
    },
    name: 'Australia',
    phoneCode: '+61',
    alpha2Code: 'AU',
  },
  {
    translations: {
      de: 'Aruba',
      fr: 'Aruba',
    },
    name: 'Aruba',
    phoneCode: '+297',
    alpha2Code: 'AW',
  },
  {
    translations: {
      de: 'Åland',
      fr: 'Ahvenanmaa',
    },
    name: 'Åland Islands',
    phoneCode: '+35818',
    alpha2Code: 'AX',
  },
  {
    translations: {
      de: 'Aserbaidschan',
      fr: 'Azerbaïdjan',
    },
    name: 'Azerbaijan',
    phoneCode: '+994',
    alpha2Code: 'AZ',
  },
  {
    translations: {
      de: 'Bosnien und Herzegowina',
      fr: 'Bosnie-Herzégovine',
    },
    name: 'Bosnia and Herzegovina',
    phoneCode: '+387',
    alpha2Code: 'BA',
  },
  {
    translations: {
      de: 'Barbados',
      fr: 'Barbade',
    },
    name: 'Barbados',
    phoneCode: '+1246',
    alpha2Code: 'BB',
  },
  {
    translations: {
      de: 'Bangladesch',
      fr: 'Bangladesh',
    },
    name: 'Bangladesh',
    phoneCode: '+880',
    alpha2Code: 'BD',
  },
  {
    translations: {
      de: 'Belgien',
      fr: 'Belgique',
    },
    name: 'Belgium',
    phoneCode: '+32',
    alpha2Code: 'BE',
  },
  {
    translations: {
      de: 'Burkina Faso',
      fr: 'Burkina Faso',
    },
    name: 'Burkina Faso',
    phoneCode: '+226',
    alpha2Code: 'BF',
  },
  {
    translations: {
      de: 'Bulgarien',
      fr: 'Bulgarie',
    },
    name: 'Bulgaria',
    phoneCode: '+359',
    alpha2Code: 'BG',
  },
  {
    translations: {
      de: 'Bahrain',
      fr: 'Bahreïn',
    },
    name: 'Bahrain',
    phoneCode: '+973',
    alpha2Code: 'BH',
  },
  {
    translations: {
      de: 'Burundi',
      fr: 'Burundi',
    },
    name: 'Burundi',
    phoneCode: '+257',
    alpha2Code: 'BI',
  },
  {
    translations: {
      de: 'Benin',
      fr: 'Bénin',
    },
    name: 'Benin',
    phoneCode: '+229',
    alpha2Code: 'BJ',
  },
  {
    translations: {
      de: 'Saint-Barthélemy',
      fr: 'Saint-Barthélemy',
    },
    name: 'Saint Barthélemy',
    phoneCode: '+590',
    alpha2Code: 'BL',
  },
  {
    translations: {
      de: 'Bermuda',
      fr: 'Bermudes',
    },
    name: 'Bermuda',
    phoneCode: '+1441',
    alpha2Code: 'BM',
  },
  {
    translations: {
      de: 'Brunei',
      fr: 'Brunei',
    },
    name: 'Brunei',
    phoneCode: '+673',
    alpha2Code: 'BN',
  },
  {
    translations: {
      de: 'Bolivien',
      fr: 'Bolivie',
    },
    name: 'Bolivia',
    phoneCode: '+591',
    alpha2Code: 'BO',
  },
  {
    translations: {
      de: 'Karibische Niederlande',
      fr: 'Pays-Bas caribéens',
    },
    name: 'Caribbean Netherlands',
    phoneCode: '+599',
    alpha2Code: 'BQ',
  },
  {
    translations: {
      de: 'Brasilien',
      fr: 'Brésil',
    },
    name: 'Brazil',
    phoneCode: '+55',
    alpha2Code: 'BR',
  },
  {
    translations: {
      de: 'Bahamas',
      fr: 'Bahamas',
    },
    name: 'Bahamas',
    phoneCode: '+1242',
    alpha2Code: 'BS',
  },
  {
    translations: {
      de: 'Bhutan',
      fr: 'Bhoutan',
    },
    name: 'Bhutan',
    phoneCode: '+975',
    alpha2Code: 'BT',
  },
  {
    translations: {
      de: 'Bouvetinsel',
      fr: 'Île Bouvet',
    },
    name: 'Bouvet Island',
    phoneCode: '+47',
    alpha2Code: 'BV',
  },
  {
    translations: {
      de: 'Botswana',
      fr: 'Botswana',
    },
    name: 'Botswana',
    phoneCode: '+267',
    alpha2Code: 'BW',
  },
  {
    translations: {
      de: 'Weißrussland',
      fr: 'Biélorussie',
    },
    name: 'Belarus',
    phoneCode: '+375',
    alpha2Code: 'BY',
  },
  {
    translations: {
      de: 'Belize',
      fr: 'Belize',
    },
    name: 'Belize',
    phoneCode: '+501',
    alpha2Code: 'BZ',
  },
  {
    translations: {
      de: 'Kanada',
      fr: 'Canada',
    },
    name: 'Canada',
    phoneCode: '+1',
    alpha2Code: 'CA',
  },
  {
    translations: {
      de: 'Kokosinseln',
      fr: 'Îles Cocos',
    },
    name: 'Cocos (Keeling) Islands',
    phoneCode: '+61',
    alpha2Code: 'CC',
  },
  {
    translations: {
      de: 'Kongo (Dem. Rep.)',
      fr: 'Congo (Rép. dém.)',
    },
    name: 'DR Congo',
    phoneCode: '+243',
    alpha2Code: 'CD',
  },
  {
    translations: {
      de: 'Zentralafrikanische Republik',
      fr: 'République centrafricaine',
    },
    name: 'Central African Republic',
    phoneCode: '+236',
    alpha2Code: 'CF',
  },
  {
    translations: {
      de: 'Kongo',
      fr: 'Congo',
    },
    name: 'Republic of the Congo',
    phoneCode: '+242',
    alpha2Code: 'CG',
  },
  {
    translations: {
      de: 'Schweiz',
      fr: 'Suisse',
    },
    name: 'Switzerland',
    phoneCode: '+41',
    alpha2Code: 'CH',
  },
  {
    translations: {
      de: 'Elfenbeinküste',
      fr: "Côte d'Ivoire",
    },
    name: 'Ivory Coast',
    phoneCode: '+225',
    alpha2Code: 'CI',
  },
  {
    translations: {
      de: 'Cookinseln',
      fr: 'Îles Cook',
    },
    name: 'Cook Islands',
    phoneCode: '+682',
    alpha2Code: 'CK',
  },
  {
    translations: {
      de: 'Chile',
      fr: 'Chili',
    },
    name: 'Chile',
    phoneCode: '+56',
    alpha2Code: 'CL',
  },
  {
    translations: {
      de: 'Kamerun',
      fr: 'Cameroun',
    },
    name: 'Cameroon',
    phoneCode: '+237',
    alpha2Code: 'CM',
  },
  {
    translations: {
      de: 'China',
      fr: 'Chine',
    },
    name: 'China',
    phoneCode: '+86',
    alpha2Code: 'CN',
  },
  {
    translations: {
      de: 'Kolumbien',
      fr: 'Colombie',
    },
    name: 'Colombia',
    phoneCode: '+57',
    alpha2Code: 'CO',
  },
  {
    translations: {
      de: 'Costa Rica',
      fr: 'Costa Rica',
    },
    name: 'Costa Rica',
    phoneCode: '+506',
    alpha2Code: 'CR',
  },
  {
    translations: {
      de: 'Kuba',
      fr: 'Cuba',
    },
    name: 'Cuba',
    phoneCode: '+53',
    alpha2Code: 'CU',
  },
  {
    translations: {
      de: 'Kap Verde',
      fr: 'Îles du Cap-Vert',
    },
    name: 'Cape Verde',
    phoneCode: '+238',
    alpha2Code: 'CV',
  },
  {
    translations: {
      de: 'Curaçao',
      fr: 'Curaçao',
    },
    name: 'Curaçao',
    phoneCode: '+599',
    alpha2Code: 'CW',
  },
  {
    translations: {
      de: 'Weihnachtsinsel',
      fr: 'Île Christmas',
    },
    name: 'Christmas Island',
    phoneCode: '+61',
    alpha2Code: 'CX',
  },
  {
    translations: {
      de: 'Zypern',
      fr: 'Chypre',
    },
    name: 'Cyprus',
    phoneCode: '+357',
    alpha2Code: 'CY',
  },
  {
    translations: {
      de: 'Tschechien',
      fr: 'Tchéquie',
    },
    name: 'Czechia',
    phoneCode: '+420',
    alpha2Code: 'CZ',
  },
  {
    translations: {
      de: 'Deutschland',
      fr: 'Allemagne',
    },
    name: 'Germany',
    phoneCode: '+49',
    alpha2Code: 'DE',
  },
  {
    translations: {
      de: 'Dschibuti',
      fr: 'Djibouti',
    },
    name: 'Djibouti',
    phoneCode: '+253',
    alpha2Code: 'DJ',
  },
  {
    translations: {
      de: 'Dänemark',
      fr: 'Danemark',
    },
    name: 'Denmark',
    phoneCode: '+45',
    alpha2Code: 'DK',
  },
  {
    translations: {
      de: 'Dominica',
      fr: 'Dominique',
    },
    name: 'Dominica',
    phoneCode: '+1767',
    alpha2Code: 'DM',
  },
  {
    translations: {
      de: 'Dominikanische Republik',
      fr: 'République dominicaine',
    },
    name: 'Dominican Republic',
    phoneCode: '+1809,829,849',
    alpha2Code: 'DO',
  },
  {
    translations: {
      de: 'Algerien',
      fr: 'Algérie',
    },
    name: 'Algeria',
    phoneCode: '+213',
    alpha2Code: 'DZ',
  },
  {
    translations: {
      de: 'Ecuador',
      fr: 'Équateur',
    },
    name: 'Ecuador',
    phoneCode: '+593',
    alpha2Code: 'EC',
  },
  {
    translations: {
      de: 'Estland',
      fr: 'Estonie',
    },
    name: 'Estonia',
    phoneCode: '+372',
    alpha2Code: 'EE',
  },
  {
    translations: {
      de: 'Ägypten',
      fr: 'Égypte',
    },
    name: 'Egypt',
    phoneCode: '+20',
    alpha2Code: 'EG',
  },
  {
    translations: {
      de: 'Westsahara',
      fr: 'Sahara Occidental',
    },
    name: 'Western Sahara',
    phoneCode: '+2125288,125289',
    alpha2Code: 'EH',
  },
  {
    translations: {
      de: 'Eritrea',
      fr: 'Érythrée',
    },
    name: 'Eritrea',
    phoneCode: '+291',
    alpha2Code: 'ER',
  },
  {
    translations: {
      de: 'Spanien',
      fr: 'Espagne',
    },
    name: 'Spain',
    phoneCode: '+34',
    alpha2Code: 'ES',
  },
  {
    translations: {
      de: 'Äthiopien',
      fr: 'Éthiopie',
    },
    name: 'Ethiopia',
    phoneCode: '+251',
    alpha2Code: 'ET',
  },
  {
    translations: {
      de: 'Finnland',
      fr: 'Finlande',
    },
    name: 'Finland',
    phoneCode: '+358',
    alpha2Code: 'FI',
  },
  {
    translations: {
      de: 'Fidschi',
      fr: 'Fidji',
    },
    name: 'Fiji',
    phoneCode: '+679',
    alpha2Code: 'FJ',
  },
  {
    translations: {
      de: 'Falklandinseln',
      fr: 'Îles Malouines',
    },
    name: 'Falkland Islands',
    phoneCode: '+500',
    alpha2Code: 'FK',
  },
  {
    translations: {
      de: 'Mikronesien',
      fr: 'Micronésie',
    },
    name: 'Micronesia',
    phoneCode: '+691',
    alpha2Code: 'FM',
  },
  {
    translations: {
      de: 'Färöer-Inseln',
      fr: 'Îles Féroé',
    },
    name: 'Faroe Islands',
    phoneCode: '+298',
    alpha2Code: 'FO',
  },
  {
    translations: {
      de: 'Frankreich',
      fr: 'France',
    },
    name: 'France',
    phoneCode: '+33',
    alpha2Code: 'FR',
  },
  {
    translations: {
      de: 'Gabun',
      fr: 'Gabon',
    },
    name: 'Gabon',
    phoneCode: '+241',
    alpha2Code: 'GA',
  },
  {
    translations: {
      de: 'Vereinigtes Königreich',
      fr: 'Royaume-Uni',
    },
    name: 'United Kingdom',
    phoneCode: '+44',
    alpha2Code: 'GB',
  },
  {
    translations: {
      de: 'Grenada',
      fr: 'Grenade',
    },
    name: 'Grenada',
    phoneCode: '+1473',
    alpha2Code: 'GD',
  },
  {
    translations: {
      de: 'Georgien',
      fr: 'Géorgie',
    },
    name: 'Georgia',
    phoneCode: '+995',
    alpha2Code: 'GE',
  },
  {
    translations: {
      de: 'Französisch-Guayana',
      fr: 'Guyane',
    },
    name: 'French Guiana',
    phoneCode: '+594',
    alpha2Code: 'GF',
  },
  {
    translations: {
      de: 'Guernsey',
      fr: 'Guernesey',
    },
    name: 'Guernsey',
    phoneCode: '+44',
    alpha2Code: 'GG',
  },
  {
    translations: {
      de: 'Ghana',
      fr: 'Ghana',
    },
    name: 'Ghana',
    phoneCode: '+233',
    alpha2Code: 'GH',
  },
  {
    translations: {
      de: 'Gibraltar',
      fr: 'Gibraltar',
    },
    name: 'Gibraltar',
    phoneCode: '+350',
    alpha2Code: 'GI',
  },
  {
    translations: {
      de: 'Grönland',
      fr: 'Groenland',
    },
    name: 'Greenland',
    phoneCode: '+299',
    alpha2Code: 'GL',
  },
  {
    translations: {
      de: 'Gambia',
      fr: 'Gambie',
    },
    name: 'Gambia',
    phoneCode: '+220',
    alpha2Code: 'GM',
  },
  {
    translations: {
      de: 'Guinea',
      fr: 'Guinée',
    },
    name: 'Guinea',
    phoneCode: '+224',
    alpha2Code: 'GN',
  },
  {
    translations: {
      de: 'Guadeloupe',
      fr: 'Guadeloupe',
    },
    name: 'Guadeloupe',
    phoneCode: '+590',
    alpha2Code: 'GP',
  },
  {
    translations: {
      de: 'Äquatorialguinea',
      fr: 'Guinée équatoriale',
    },
    name: 'Equatorial Guinea',
    phoneCode: '+240',
    alpha2Code: 'GQ',
  },
  {
    translations: {
      de: 'Griechenland',
      fr: 'Grèce',
    },
    name: 'Greece',
    phoneCode: '+30',
    alpha2Code: 'GR',
  },
  {
    translations: {
      de: 'Südgeorgien und die Südlichen Sandwichinseln',
      fr: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    },
    name: 'South Georgia',
    phoneCode: '+500',
    alpha2Code: 'GS',
  },
  {
    translations: {
      de: 'Guatemala',
      fr: 'Guatemala',
    },
    name: 'Guatemala',
    phoneCode: '+502',
    alpha2Code: 'GT',
  },
  {
    translations: {
      de: 'Guam',
      fr: 'Guam',
    },
    name: 'Guam',
    phoneCode: '+1671',
    alpha2Code: 'GU',
  },
  {
    translations: {
      de: 'Guinea-Bissau',
      fr: 'Guinée-Bissau',
    },
    name: 'Guinea-Bissau',
    phoneCode: '+245',
    alpha2Code: 'GW',
  },
  {
    translations: {
      de: 'Guyana',
      fr: 'Guyana',
    },
    name: 'Guyana',
    phoneCode: '+592',
    alpha2Code: 'GY',
  },
  {
    translations: {
      de: 'Hongkong',
      fr: 'Hong Kong',
    },
    name: 'Hong Kong',
    phoneCode: '+852',
    alpha2Code: 'HK',
  },
  {
    translations: {
      de: 'Honduras',
      fr: 'Honduras',
    },
    name: 'Honduras',
    phoneCode: '+504',
    alpha2Code: 'HN',
  },
  {
    translations: {
      de: 'Kroatien',
      fr: 'Croatie',
    },
    name: 'Croatia',
    phoneCode: '+385',
    alpha2Code: 'HR',
  },
  {
    translations: {
      de: 'Haiti',
      fr: 'Haïti',
    },
    name: 'Haiti',
    phoneCode: '+509',
    alpha2Code: 'HT',
  },
  {
    translations: {
      de: 'Ungarn',
      fr: 'Hongrie',
    },
    name: 'Hungary',
    phoneCode: '+36',
    alpha2Code: 'HU',
  },
  {
    translations: {
      de: 'Indonesien',
      fr: 'Indonésie',
    },
    name: 'Indonesia',
    phoneCode: '+62',
    alpha2Code: 'ID',
  },
  {
    translations: {
      de: 'Irland',
      fr: 'Irlande',
    },
    name: 'Ireland',
    phoneCode: '+353',
    alpha2Code: 'IE',
  },
  {
    translations: {
      de: 'Israel',
      fr: 'Israël',
    },
    name: 'Israel',
    phoneCode: '+972',
    alpha2Code: 'IL',
  },
  {
    translations: {
      de: 'Insel Man',
      fr: 'Île de Man',
    },
    name: 'Isle of Man',
    phoneCode: '+44',
    alpha2Code: 'IM',
  },
  {
    translations: {
      de: 'Indien',
      fr: 'Inde',
    },
    name: 'India',
    phoneCode: '+91',
    alpha2Code: 'IN',
  },
  {
    translations: {
      de: 'Britisches Territorium im Indischen Ozean',
      fr: "Territoire britannique de l'océan Indien",
    },
    name: 'British Indian Ocean Territory',
    phoneCode: '+246',
    alpha2Code: 'IO',
  },
  {
    translations: {
      de: 'Irak',
      fr: 'Irak',
    },
    name: 'Iraq',
    phoneCode: '+964',
    alpha2Code: 'IQ',
  },
  {
    translations: {
      de: 'Iran',
      fr: 'Iran',
    },
    name: 'Iran',
    phoneCode: '+98',
    alpha2Code: 'IR',
  },
  {
    translations: {
      de: 'Island',
      fr: 'Islande',
    },
    name: 'Iceland',
    phoneCode: '+354',
    alpha2Code: 'IS',
  },
  {
    translations: {
      de: 'Italien',
      fr: 'Italie',
    },
    name: 'Italy',
    phoneCode: '+39',
    alpha2Code: 'IT',
  },
  {
    translations: {
      de: 'Jersey',
      fr: 'Jersey',
    },
    name: 'Jersey',
    phoneCode: '+44',
    alpha2Code: 'JE',
  },
  {
    translations: {
      de: 'Jamaika',
      fr: 'Jamaïque',
    },
    name: 'Jamaica',
    phoneCode: '+1876',
    alpha2Code: 'JM',
  },
  {
    translations: {
      de: 'Jordanien',
      fr: 'Jordanie',
    },
    name: 'Jordan',
    phoneCode: '+962',
    alpha2Code: 'JO',
  },
  {
    translations: {
      de: 'Japan',
      fr: 'Japon',
    },
    name: 'Japan',
    phoneCode: '+81',
    alpha2Code: 'JP',
  },
  {
    translations: {
      de: 'Kenia',
      fr: 'Kenya',
    },
    name: 'Kenya',
    phoneCode: '+254',
    alpha2Code: 'KE',
  },
  {
    translations: {
      de: 'Kirgisistan',
      fr: 'Kirghizistan',
    },
    name: 'Kyrgyzstan',
    phoneCode: '+996',
    alpha2Code: 'KG',
  },
  {
    translations: {
      de: 'Kambodscha',
      fr: 'Cambodge',
    },
    name: 'Cambodia',
    phoneCode: '+855',
    alpha2Code: 'KH',
  },
  {
    translations: {
      de: 'Kiribati',
      fr: 'Kiribati',
    },
    name: 'Kiribati',
    phoneCode: '+686',
    alpha2Code: 'KI',
  },
  {
    translations: {
      de: 'Komoren',
      fr: 'Comores',
    },
    name: 'Comoros',
    phoneCode: '+269',
    alpha2Code: 'KM',
  },
  {
    translations: {
      de: 'St. Kitts und Nevis',
      fr: 'Saint-Christophe-et-Niévès',
    },
    name: 'Saint Kitts and Nevis',
    phoneCode: '+1869',
    alpha2Code: 'KN',
  },
  {
    translations: {
      de: 'Nordkorea',
      fr: 'Corée du Nord',
    },
    name: 'North Korea',
    phoneCode: '+850',
    alpha2Code: 'KP',
  },
  {
    translations: {
      de: 'Südkorea',
      fr: 'Corée du Sud',
    },
    name: 'South Korea',
    phoneCode: '+82',
    alpha2Code: 'KR',
  },
  {
    translations: {
      de: 'Kuwait',
      fr: 'Koweït',
    },
    name: 'Kuwait',
    phoneCode: '+965',
    alpha2Code: 'KW',
  },
  {
    translations: {
      de: 'Kaimaninseln',
      fr: 'Îles Caïmans',
    },
    name: 'Cayman Islands',
    phoneCode: '+1345',
    alpha2Code: 'KY',
  },
  {
    translations: {
      de: 'Kasachstan',
      fr: 'Kazakhstan',
    },
    name: 'Kazakhstan',
    phoneCode: '+76,7',
    alpha2Code: 'KZ',
  },
  {
    translations: {
      de: 'Laos',
      fr: 'Laos',
    },
    name: 'Laos',
    phoneCode: '+856',
    alpha2Code: 'LA',
  },
  {
    translations: {
      de: 'Libanon',
      fr: 'Liban',
    },
    name: 'Lebanon',
    phoneCode: '+961',
    alpha2Code: 'LB',
  },
  {
    translations: {
      de: 'St. Lucia',
      fr: 'Sainte-Lucie',
    },
    name: 'Saint Lucia',
    phoneCode: '+1758',
    alpha2Code: 'LC',
  },
  {
    translations: {
      de: 'Liechtenstein',
      fr: 'Liechtenstein',
    },
    name: 'Liechtenstein',
    phoneCode: '+423',
    alpha2Code: 'LI',
  },
  {
    translations: {
      de: 'Sri Lanka',
      fr: 'Sri Lanka',
    },
    name: 'Sri Lanka',
    phoneCode: '+94',
    alpha2Code: 'LK',
  },
  {
    translations: {
      de: 'Liberia',
      fr: 'Liberia',
    },
    name: 'Liberia',
    phoneCode: '+231',
    alpha2Code: 'LR',
  },
  {
    translations: {
      de: 'Lesotho',
      fr: 'Lesotho',
    },
    name: 'Lesotho',
    phoneCode: '+266',
    alpha2Code: 'LS',
  },
  {
    translations: {
      de: 'Litauen',
      fr: 'Lituanie',
    },
    name: 'Lithuania',
    phoneCode: '+370',
    alpha2Code: 'LT',
  },
  {
    translations: {
      de: 'Luxemburg',
      fr: 'Luxembourg',
    },
    name: 'Luxembourg',
    phoneCode: '+352',
    alpha2Code: 'LU',
  },
  {
    translations: {
      de: 'Lettland',
      fr: 'Lettonie',
    },
    name: 'Latvia',
    phoneCode: '+371',
    alpha2Code: 'LV',
  },
  {
    translations: {
      de: 'Libyen',
      fr: 'Libye',
    },
    name: 'Libya',
    phoneCode: '+218',
    alpha2Code: 'LY',
  },
  {
    translations: {
      de: 'Marokko',
      fr: 'Maroc',
    },
    name: 'Morocco',
    phoneCode: '+212',
    alpha2Code: 'MA',
  },
  {
    translations: {
      de: 'Monaco',
      fr: 'Monaco',
    },
    name: 'Monaco',
    phoneCode: '+377',
    alpha2Code: 'MC',
  },
  {
    translations: {
      de: 'Moldawien',
      fr: 'Moldavie',
    },
    name: 'Moldova',
    phoneCode: '+373',
    alpha2Code: 'MD',
  },
  {
    translations: {
      de: 'Montenegro',
      fr: 'Monténégro',
    },
    name: 'Montenegro',
    phoneCode: '+382',
    alpha2Code: 'ME',
  },
  {
    translations: {
      de: 'Saint-Martin',
      fr: 'Saint-Martin',
    },
    name: 'Saint Martin',
    phoneCode: '+590',
    alpha2Code: 'MF',
  },
  {
    translations: {
      de: 'Madagaskar',
      fr: 'Madagascar',
    },
    name: 'Madagascar',
    phoneCode: '+261',
    alpha2Code: 'MG',
  },
  {
    translations: {
      de: 'Marshallinseln',
      fr: 'Îles Marshall',
    },
    name: 'Marshall Islands',
    phoneCode: '+692',
    alpha2Code: 'MH',
  },
  {
    translations: {
      de: 'Nordmazedonien',
      fr: 'Macédoine du Nord',
    },
    name: 'North Macedonia',
    phoneCode: '+389',
    alpha2Code: 'MK',
  },
  {
    translations: {
      de: 'Mali',
      fr: 'Mali',
    },
    name: 'Mali',
    phoneCode: '+223',
    alpha2Code: 'ML',
  },
  {
    translations: {
      de: 'Myanmar',
      fr: 'Birmanie',
    },
    name: 'Myanmar',
    phoneCode: '+95',
    alpha2Code: 'MM',
  },
  {
    translations: {
      de: 'Mongolei',
      fr: 'Mongolie',
    },
    name: 'Mongolia',
    phoneCode: '+976',
    alpha2Code: 'MN',
  },
  {
    translations: {
      de: 'Macao',
      fr: 'Macao',
    },
    name: 'Macau',
    phoneCode: '+853',
    alpha2Code: 'MO',
  },
  {
    translations: {
      de: 'Nördliche Marianen',
      fr: 'Îles Mariannes du Nord',
    },
    name: 'Northern Mariana Islands',
    phoneCode: '+1670',
    alpha2Code: 'MP',
  },
  {
    translations: {
      de: 'Martinique',
      fr: 'Martinique',
    },
    name: 'Martinique',
    phoneCode: '+596',
    alpha2Code: 'MQ',
  },
  {
    translations: {
      de: 'Mauretanien',
      fr: 'Mauritanie',
    },
    name: 'Mauritania',
    phoneCode: '+222',
    alpha2Code: 'MR',
  },
  {
    translations: {
      de: 'Montserrat',
      fr: 'Montserrat',
    },
    name: 'Montserrat',
    phoneCode: '+1664',
    alpha2Code: 'MS',
  },
  {
    translations: {
      de: 'Malta',
      fr: 'Malte',
    },
    name: 'Malta',
    phoneCode: '+356',
    alpha2Code: 'MT',
  },
  {
    translations: {
      de: 'Mauritius',
      fr: 'Île Maurice',
    },
    name: 'Mauritius',
    phoneCode: '+230',
    alpha2Code: 'MU',
  },
  {
    translations: {
      de: 'Malediven',
      fr: 'Maldives',
    },
    name: 'Maldives',
    phoneCode: '+960',
    alpha2Code: 'MV',
  },
  {
    translations: {
      de: 'Malawi',
      fr: 'Malawi',
    },
    name: 'Malawi',
    phoneCode: '+265',
    alpha2Code: 'MW',
  },
  {
    translations: {
      de: 'Mexiko',
      fr: 'Mexique',
    },
    name: 'Mexico',
    phoneCode: '+52',
    alpha2Code: 'MX',
  },
  {
    translations: {
      de: 'Malaysia',
      fr: 'Malaisie',
    },
    name: 'Malaysia',
    phoneCode: '+60',
    alpha2Code: 'MY',
  },
  {
    translations: {
      de: 'Mosambik',
      fr: 'Mozambique',
    },
    name: 'Mozambique',
    phoneCode: '+258',
    alpha2Code: 'MZ',
  },
  {
    translations: {
      de: 'Namibia',
      fr: 'Namibie',
    },
    name: 'Namibia',
    phoneCode: '+264',
    alpha2Code: 'NA',
  },
  {
    translations: {
      de: 'Neukaledonien',
      fr: 'Nouvelle-Calédonie',
    },
    name: 'New Caledonia',
    phoneCode: '+687',
    alpha2Code: 'NC',
  },
  {
    translations: {
      de: 'Niger',
      fr: 'Niger',
    },
    name: 'Niger',
    phoneCode: '+227',
    alpha2Code: 'NE',
  },
  {
    translations: {
      de: 'Norfolkinsel',
      fr: 'Île Norfolk',
    },
    name: 'Norfolk Island',
    phoneCode: '+672',
    alpha2Code: 'NF',
  },
  {
    translations: {
      de: 'Nigeria',
      fr: 'Nigéria',
    },
    name: 'Nigeria',
    phoneCode: '+234',
    alpha2Code: 'NG',
  },
  {
    translations: {
      de: 'Nicaragua',
      fr: 'Nicaragua',
    },
    name: 'Nicaragua',
    phoneCode: '+505',
    alpha2Code: 'NI',
  },
  {
    translations: {
      de: 'Niederlande',
      fr: 'Pays-Bas',
    },
    name: 'Netherlands',
    phoneCode: '+31',
    alpha2Code: 'NL',
  },
  {
    translations: {
      de: 'Norwegen',
      fr: 'Norvège',
    },
    name: 'Norway',
    phoneCode: '+47',
    alpha2Code: 'NO',
  },
  {
    translations: {
      de: 'Nepal',
      fr: 'Népal',
    },
    name: 'Nepal',
    phoneCode: '+977',
    alpha2Code: 'NP',
  },
  {
    translations: {
      de: 'Nauru',
      fr: 'Nauru',
    },
    name: 'Nauru',
    phoneCode: '+674',
    alpha2Code: 'NR',
  },
  {
    translations: {
      de: 'Niue',
      fr: 'Niue',
    },
    name: 'Niue',
    phoneCode: '+683',
    alpha2Code: 'NU',
  },
  {
    translations: {
      de: 'Neuseeland',
      fr: 'Nouvelle-Zélande',
    },
    name: 'New Zealand',
    phoneCode: '+64',
    alpha2Code: 'NZ',
  },
  {
    translations: {
      de: 'Oman',
      fr: 'Oman',
    },
    name: 'Oman',
    phoneCode: '+968',
    alpha2Code: 'OM',
  },
  {
    translations: {
      de: 'Panama',
      fr: 'Panama',
    },
    name: 'Panama',
    phoneCode: '+507',
    alpha2Code: 'PA',
  },
  {
    translations: {
      de: 'Peru',
      fr: 'Pérou',
    },
    name: 'Peru',
    phoneCode: '+51',
    alpha2Code: 'PE',
  },
  {
    translations: {
      de: 'Französisch-Polynesien',
      fr: 'Polynésie française',
    },
    name: 'French Polynesia',
    phoneCode: '+689',
    alpha2Code: 'PF',
  },
  {
    translations: {
      de: 'Papua-Neuguinea',
      fr: 'Papouasie-Nouvelle-Guinée',
    },
    name: 'Papua New Guinea',
    phoneCode: '+675',
    alpha2Code: 'PG',
  },
  {
    translations: {
      de: 'Philippinen',
      fr: 'Philippines',
    },
    name: 'Philippines',
    phoneCode: '+63',
    alpha2Code: 'PH',
  },
  {
    translations: {
      de: 'Pakistan',
      fr: 'Pakistan',
    },
    name: 'Pakistan',
    phoneCode: '+92',
    alpha2Code: 'PK',
  },
  {
    translations: {
      de: 'Polen',
      fr: 'Pologne',
    },
    name: 'Poland',
    phoneCode: '+48',
    alpha2Code: 'PL',
  },
  {
    translations: {
      de: 'St. Pierre und Miquelon',
      fr: 'Saint-Pierre-et-Miquelon',
    },
    name: 'Saint Pierre and Miquelon',
    phoneCode: '+508',
    alpha2Code: 'PM',
  },
  {
    translations: {
      de: 'Pitcairninseln',
      fr: 'Îles Pitcairn',
    },
    name: 'Pitcairn Islands',
    phoneCode: '+64',
    alpha2Code: 'PN',
  },
  {
    translations: {
      de: 'Puerto Rico',
      fr: 'Porto Rico',
    },
    name: 'Puerto Rico',
    phoneCode: '+1787,939',
    alpha2Code: 'PR',
  },
  {
    translations: {
      de: 'Palästina',
      fr: 'Palestine',
    },
    name: 'Palestine',
    phoneCode: '+970',
    alpha2Code: 'PS',
  },
  {
    translations: {
      de: 'Portugal',
      fr: 'Portugal',
    },
    name: 'Portugal',
    phoneCode: '+351',
    alpha2Code: 'PT',
  },
  {
    translations: {
      de: 'Palau',
      fr: 'Palaos (Palau)',
    },
    name: 'Palau',
    phoneCode: '+680',
    alpha2Code: 'PW',
  },
  {
    translations: {
      de: 'Paraguay',
      fr: 'Paraguay',
    },
    name: 'Paraguay',
    phoneCode: '+595',
    alpha2Code: 'PY',
  },
  {
    translations: {
      de: 'Katar',
      fr: 'Qatar',
    },
    name: 'Qatar',
    phoneCode: '+974',
    alpha2Code: 'QA',
  },
  {
    translations: {
      de: 'Réunion',
      fr: 'Réunion',
    },
    name: 'Réunion',
    phoneCode: '+262',
    alpha2Code: 'RE',
  },
  {
    translations: {
      de: 'Rumänien',
      fr: 'Roumanie',
    },
    name: 'Romania',
    phoneCode: '+40',
    alpha2Code: 'RO',
  },
  {
    translations: {
      de: 'Serbien',
      fr: 'Serbie',
    },
    name: 'Serbia',
    phoneCode: '+381',
    alpha2Code: 'RS',
  },
  {
    translations: {
      de: 'Russland',
      fr: 'Russie',
    },
    name: 'Russia',
    phoneCode: '+73,4,5,8,9',
    alpha2Code: 'RU',
  },
  {
    translations: {
      de: 'Ruanda',
      fr: 'Rwanda',
    },
    name: 'Rwanda',
    phoneCode: '+250',
    alpha2Code: 'RW',
  },
  {
    translations: {
      de: 'Saudi-Arabien',
      fr: 'Arabie Saoudite',
    },
    name: 'Saudi Arabia',
    phoneCode: '+966',
    alpha2Code: 'SA',
  },
  {
    translations: {
      de: 'Salomonen',
      fr: 'Îles Salomon',
    },
    name: 'Solomon Islands',
    phoneCode: '+677',
    alpha2Code: 'SB',
  },
  {
    translations: {
      de: 'Seychellen',
      fr: 'Seychelles',
    },
    name: 'Seychelles',
    phoneCode: '+248',
    alpha2Code: 'SC',
  },
  {
    translations: {
      de: 'Sudan',
      fr: 'Soudan',
    },
    name: 'Sudan',
    phoneCode: '+249',
    alpha2Code: 'SD',
  },
  {
    translations: {
      de: 'Schweden',
      fr: 'Suède',
    },
    name: 'Sweden',
    phoneCode: '+46',
    alpha2Code: 'SE',
  },
  {
    translations: {
      de: 'Singapur',
      fr: 'Singapour',
    },
    name: 'Singapore',
    phoneCode: '+65',
    alpha2Code: 'SG',
  },
  {
    translations: {
      de: 'St. Helena, Ascension und Tristan da Cunha',
      fr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    },
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    phoneCode: '+290,47',
    alpha2Code: 'SH',
  },
  {
    translations: {
      de: 'Slowenien',
      fr: 'Slovénie',
    },
    name: 'Slovenia',
    phoneCode: '+386',
    alpha2Code: 'SI',
  },
  {
    translations: {
      de: 'Spitzbergen und Jan Mayen',
      fr: 'Svalbard et Jan Mayen',
    },
    name: 'Svalbard and Jan Mayen',
    phoneCode: '+4779',
    alpha2Code: 'SJ',
  },
  {
    translations: {
      de: 'Slowakei',
      fr: 'Slovaquie',
    },
    name: 'Slovakia',
    phoneCode: '+421',
    alpha2Code: 'SK',
  },
  {
    translations: {
      de: 'Sierra Leone',
      fr: 'Sierra Leone',
    },
    name: 'Sierra Leone',
    phoneCode: '+232',
    alpha2Code: 'SL',
  },
  {
    translations: {
      de: 'San Marino',
      fr: 'Saint-Marin',
    },
    name: 'San Marino',
    phoneCode: '+378',
    alpha2Code: 'SM',
  },
  {
    translations: {
      de: 'Senegal',
      fr: 'Sénégal',
    },
    name: 'Senegal',
    phoneCode: '+221',
    alpha2Code: 'SN',
  },
  {
    translations: {
      de: 'Somalia',
      fr: 'Somalie',
    },
    name: 'Somalia',
    phoneCode: '+252',
    alpha2Code: 'SO',
  },
  {
    translations: {
      de: 'Suriname',
      fr: 'Surinam',
    },
    name: 'Suriname',
    phoneCode: '+597',
    alpha2Code: 'SR',
  },
  {
    translations: {
      de: 'Südsudan',
      fr: 'Soudan du Sud',
    },
    name: 'South Sudan',
    phoneCode: '+211',
    alpha2Code: 'SS',
  },
  {
    translations: {
      de: 'São Tomé und Príncipe',
      fr: 'São Tomé et Príncipe',
    },
    name: 'São Tomé and Príncipe',
    phoneCode: '+239',
    alpha2Code: 'ST',
  },
  {
    translations: {
      de: 'El Salvador',
      fr: 'Salvador',
    },
    name: 'El Salvador',
    phoneCode: '+503',
    alpha2Code: 'SV',
  },
  {
    translations: {
      de: 'Sint Maarten',
      fr: 'Saint-Martin',
    },
    name: 'Sint Maarten',
    phoneCode: '+1721',
    alpha2Code: 'SX',
  },
  {
    translations: {
      de: 'Syrien',
      fr: 'Syrie',
    },
    name: 'Syria',
    phoneCode: '+963',
    alpha2Code: 'SY',
  },
  {
    translations: {
      de: 'Swasiland',
      fr: 'Swaziland',
    },
    name: 'Eswatini',
    phoneCode: '+268',
    alpha2Code: 'SZ',
  },
  {
    translations: {
      de: 'Turks-und Caicosinseln',
      fr: 'Îles Turques-et-Caïques',
    },
    name: 'Turks and Caicos Islands',
    phoneCode: '+1649',
    alpha2Code: 'TC',
  },
  {
    translations: {
      de: 'Tschad',
      fr: 'Tchad',
    },
    name: 'Chad',
    phoneCode: '+235',
    alpha2Code: 'TD',
  },
  {
    translations: {
      de: 'Französische Süd- und Antarktisgebiete',
      fr: 'Terres australes et antarctiques françaises',
    },
    name: 'French Southern and Antarctic Lands',
    phoneCode: '+262',
    alpha2Code: 'TF',
  },
  {
    translations: {
      de: 'Togo',
      fr: 'Togo',
    },
    name: 'Togo',
    phoneCode: '+228',
    alpha2Code: 'TG',
  },
  {
    translations: {
      de: 'Thailand',
      fr: 'Thaïlande',
    },
    name: 'Thailand',
    phoneCode: '+66',
    alpha2Code: 'TH',
  },
  {
    translations: {
      de: 'Tadschikistan',
      fr: 'Tadjikistan',
    },
    name: 'Tajikistan',
    phoneCode: '+992',
    alpha2Code: 'TJ',
  },
  {
    translations: {
      de: 'Tokelau',
      fr: 'Tokelau',
    },
    name: 'Tokelau',
    phoneCode: '+690',
    alpha2Code: 'TK',
  },
  {
    translations: {
      de: 'Osttimor',
      fr: 'Timor oriental',
    },
    name: 'Timor-Leste',
    phoneCode: '+670',
    alpha2Code: 'TL',
  },
  {
    translations: {
      de: 'Turkmenistan',
      fr: 'Turkménistan',
    },
    name: 'Turkmenistan',
    phoneCode: '+993',
    alpha2Code: 'TM',
  },
  {
    translations: {
      de: 'Tunesien',
      fr: 'Tunisie',
    },
    name: 'Tunisia',
    phoneCode: '+216',
    alpha2Code: 'TN',
  },
  {
    translations: {
      de: 'Tonga',
      fr: 'Tonga',
    },
    name: 'Tonga',
    phoneCode: '+676',
    alpha2Code: 'TO',
  },
  {
    translations: {
      de: 'Türkei',
      fr: 'Turquie',
    },
    name: 'Turkey',
    phoneCode: '+90',
    alpha2Code: 'TR',
  },
  {
    translations: {
      de: 'Trinidad und Tobago',
      fr: 'Trinité-et-Tobago',
    },
    name: 'Trinidad and Tobago',
    phoneCode: '+1868',
    alpha2Code: 'TT',
  },
  {
    translations: {
      de: 'Tuvalu',
      fr: 'Tuvalu',
    },
    name: 'Tuvalu',
    phoneCode: '+688',
    alpha2Code: 'TV',
  },
  {
    translations: {
      de: 'Taiwan',
      fr: 'Taïwan',
    },
    name: 'Taiwan',
    phoneCode: '+886',
    alpha2Code: 'TW',
  },
  {
    translations: {
      de: 'Tansania',
      fr: 'Tanzanie',
    },
    name: 'Tanzania',
    phoneCode: '+255',
    alpha2Code: 'TZ',
  },
  {
    translations: {
      de: 'Ukraine',
      fr: 'Ukraine',
    },
    name: 'Ukraine',
    phoneCode: '+380',
    alpha2Code: 'UA',
  },
  {
    translations: {
      de: 'Uganda',
      fr: 'Ouganda',
    },
    name: 'Uganda',
    phoneCode: '+256',
    alpha2Code: 'UG',
  },
  {
    translations: {
      de: 'Kleinere Inselbesitzungen der Vereinigten Staaten',
      fr: 'Îles mineures éloignées des États-Unis',
    },
    name: 'United States Minor Outlying Islands',
    phoneCode: '+268',
    alpha2Code: 'UM',
  },
  {
    translations: {
      de: 'Vereinigte Staaten',
      fr: 'États-Unis',
    },
    name: 'United States',
    phoneCode: '+1',
    alpha2Code: 'US',
  },
  {
    translations: {
      de: 'Uruguay',
      fr: 'Uruguay',
    },
    name: 'Uruguay',
    phoneCode: '+598',
    alpha2Code: 'UY',
  },
  {
    translations: {
      de: 'Usbekistan',
      fr: 'Ouzbékistan',
    },
    name: 'Uzbekistan',
    phoneCode: '+998',
    alpha2Code: 'UZ',
  },
  {
    translations: {
      de: 'Vatikanstadt',
      fr: 'Cité du Vatican',
    },
    name: 'Vatican City',
    phoneCode: '+3906698,79',
    alpha2Code: 'VA',
  },
  {
    translations: {
      de: 'St. Vincent und die Grenadinen',
      fr: 'Saint-Vincent-et-les-Grenadines',
    },
    name: 'Saint Vincent and the Grenadines',
    phoneCode: '+1784',
    alpha2Code: 'VC',
  },
  {
    translations: {
      de: 'Venezuela',
      fr: 'Venezuela',
    },
    name: 'Venezuela',
    phoneCode: '+58',
    alpha2Code: 'VE',
  },
  {
    translations: {
      de: 'Britische Jungferninseln',
      fr: 'Îles Vierges britanniques',
    },
    name: 'British Virgin Islands',
    phoneCode: '+1284',
    alpha2Code: 'VG',
  },
  {
    translations: {
      de: 'Amerikanische Jungferninseln',
      fr: 'Îles Vierges des États-Unis',
    },
    name: 'United States Virgin Islands',
    phoneCode: '+1340',
    alpha2Code: 'VI',
  },
  {
    translations: {
      de: 'Vietnam',
      fr: 'Viêt Nam',
    },
    name: 'Vietnam',
    phoneCode: '+84',
    alpha2Code: 'VN',
  },
  {
    translations: {
      de: 'Vanuatu',
      fr: 'Vanuatu',
    },
    name: 'Vanuatu',
    phoneCode: '+678',
    alpha2Code: 'VU',
  },
  {
    translations: {
      de: 'Wallis und Futuna',
      fr: 'Wallis-et-Futuna',
    },
    name: 'Wallis and Futuna',
    phoneCode: '+681',
    alpha2Code: 'WF',
  },
  {
    translations: {
      de: 'Samoa',
      fr: 'Samoa',
    },
    name: 'Samoa',
    phoneCode: '+685',
    alpha2Code: 'WS',
  },
  {
    translations: {
      de: 'Kosovo',
      fr: 'Kosovo',
    },
    name: 'Kosovo',
    phoneCode: '+383',
    alpha2Code: 'XK',
  },
  {
    translations: {
      de: 'Jemen',
      fr: 'Yémen',
    },
    name: 'Yemen',
    phoneCode: '+967',
    alpha2Code: 'YE',
  },
  {
    translations: {
      de: 'Mayotte',
      fr: 'Mayotte',
    },
    name: 'Mayotte',
    phoneCode: '+262',
    alpha2Code: 'YT',
  },
  {
    translations: {
      de: 'Südafrika',
      fr: 'Afrique du Sud',
    },
    name: 'South Africa',
    phoneCode: '+27',
    alpha2Code: 'ZA',
  },
  {
    translations: {
      de: 'Sambia',
      fr: 'Zambie',
    },
    name: 'Zambia',
    phoneCode: '+260',
    alpha2Code: 'ZM',
  },
  {
    translations: {
      de: 'Simbabwe',
      fr: 'Zimbabwe',
    },
    name: 'Zimbabwe',
    phoneCode: '+263',
    alpha2Code: 'ZW',
  },
];
