// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Box, Text, styled } from '@livingpackets/design-system-react-next';
import useActiveLanguage from 'hooks/useGetActiveLanguage';
import i18n from 'i18next';
import useLayoutStore, {
  openNavSelector,
  setOpenNavSelector,
} from 'stores/useLayoutStore';

interface ILanguageButton {
  item: string;
  activeLang: string;
  children: React.ReactElement;
  onClickButton: () => void;
}

interface IButtonWrapper {
  activelang: string;
  item: string;
}

const ButtonWrapper = styled('span')<IButtonWrapper>`
  margin-right: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
  color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, activelang, item }) =>
    activelang === item
      ? theme.palette.custom.primary[100]
      : theme.palette.custom.neutral.black[100]};
  cursor: pointer;
  border-radius: 7px;

  &:hover {
    color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
    background-color: ${({ theme }) => theme.palette.custom.neutral.black[8]};
  }

  transition: all 200ms ease-in-out;
`;

const LanguageButton = ({
  item,
  activeLang,
  onClickButton,
}: ILanguageButton) => (
  <ButtonWrapper
    item={item}
    activelang={activeLang}
    onClick={onClickButton}
    data-testid={'menu-languageButton-' + item}
  >
    <Text variant="titleS" onClick={() => i18n.changeLanguage(item)}>
      {item}
    </Text>
  </ButtonWrapper>
);

interface ITempLangSwitch {
  languages: string[];
}

const LangSwitch = ({ languages }: ITempLangSwitch) => {
  const activeLang = useActiveLanguage();
  const orderedLanguages = [activeLang].concat(
    languages.filter(el => el !== activeLang)
  );
  const setOpenNav = useLayoutStore(setOpenNavSelector);
  const open = useLayoutStore(openNavSelector);

  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'hidden',
      }}
    >
      {orderedLanguages
        .filter((lang?: string) => lang)
        .map((item: string) => (
          <LanguageButton
            key={item}
            item={item}
            activeLang={activeLang}
            onClickButton={() =>
              open ? i18n.changeLanguage(item) : setOpenNav(true)
            }
          >
            <Text variant="bodyTextS">{item}</Text>
          </LanguageButton>
        ))}
    </Box>
  );
};

export default LangSwitch;
