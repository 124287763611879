// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { isEmpty } from 'lodash/fp';

export const sortAndGroupByDate = (input: any[]) => {
  if (isEmpty(input)) return [];

  const sortedInput = input.sort((a, b) => a.createdAt - b.createdAt);
  const output = [];
  let currentGroup = [sortedInput[0]];

  for (let i = 1; i < sortedInput.length; i++) {
    const currentDate = new Date(
      sortedInput[i].createdAt * 1000
    ).toDateString();
    const previousDate = new Date(
      sortedInput[i - 1].createdAt * 1000
    ).toDateString();

    if (currentDate === previousDate) {
      currentGroup.push(sortedInput[i]);
    } else {
      output.push(currentGroup);
      currentGroup = [sortedInput[i]];
    }
  }

  output.push(currentGroup);

  return output;
};
