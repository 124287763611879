// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  BuyRentIcon,
  Stack,
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import BoxContractsList from 'components/molecules/BoxContractsList';
import { REACT_APP_TRANSACTIONS_BLOCK_DISPLAY } from 'configs';
import useBoxContract from 'hooks/useBoxContract';
import useGetPrimaryAddress from 'hooks/useGetPrimaryAddress';
import { useShipments } from 'hooks/useShipments';
import { useUserShipments } from 'hooks/useUserShipments';
import { useTranslation } from 'react-i18next';

import useFormatTimestamp from '../../hooks/useFormatTimestamp';
import useGetRents from '../../hooks/useGetRents';
import { usePagination } from '../../shared-components';
import useBoxContractsStore, {
  boxContractSelector,
} from '../../stores/useBoxContractsStore';
import {
  getNewsletterDateFormat,
  getSMArticleDateFormat,
} from '../commonFunctions/DateCalculation';
import BalanceCard from '../molecules/BalanceCard';
import BuyAndRentBox from '../molecules/BuyAndRentBox';
import TransactionsList from '../molecules/TransactionsList';

const TabsWrapper = styled('div')`
  display: flex;
  margin-top: 2rem;
  width: 100%;
  border-bottom: 1px solid
    ${({ theme }) => theme.palette.custom.neutral.black[10]};
`;
const TabWrapper = styled('div')`
  margin-left: 3rem;
  height: 2rem;
`;
const ProfileBuyAndRent = () => {
  const theme = useTheme();
  const {
    t,
    i18n: { language },
  } = useTranslation('buyAndRent');

  const { params: paginationParams, setParams: setPaginationParams } =
    usePagination({
      page: 1,
      pageSize: 5,
      orderDirection: 'ASC',
      orderColumn: 'receivedAt',
    });
  const { params: mapPaginationParams } = usePagination({
    page: 1,
    pageSize: 100,
    orderDirection: 'ASC',
    orderColumn: 'receivedAt',
  });

  useBoxContract();
  useGetPrimaryAddress();
  useShipments(mapPaginationParams);

  const fetchShipments = useUserShipments(paginationParams);
  const contracts = useBoxContractsStore(boxContractSelector);
  const { rents } = useGetRents();
  const formatTimestamp = useFormatTimestamp();

  return (
    <Stack>
      <Box display="flex">
        <Box sx={{ width: '70%' }}>
          <BalanceCard
            payoutAvailable={false}
            title={t('buyAndRent')}
            icon={
              <BuyRentIcon color={theme.palette.custom.neutral.white.pure} />
            }
            amount={t('buyNRent.balanceCard.reward', {
              reward: rents.total,
            })}
            payoutAvailability={t(
              'buyAndRent:buyNRent.balanceCard.availableFunds',
              {
                date: rents.available_at
                  ? formatTimestamp(
                      new Date(rents.available_at),
                      getNewsletterDateFormat()
                    )
                  : null,
                defaultValue: '',
              }
            )}
            investments={t('buyNRent.balanceCard.numberBoxesPurchased')}
            investmentAmount={rents.product_count}
            firstLine={
              rents.shipment_count > 1
                ? t('buyNRent.balanceCard.shipmentsCount', {
                    shipmentCount: rents.shipment_count,
                  })
                : t('buyNRent.balanceCard.shipmentsCountSingular', {
                    shipmentCount: rents.shipment_count,
                  })
            }
            initialDate={
              !!rents.activated_at &&
              t('buyAndRent:buyNRent.balanceCard.activationDate', {
                activationDate: rents.activated_at
                  ? formatTimestamp(
                      new Date(rents.activated_at),
                      getSMArticleDateFormat(language)
                    )
                  : null,
                defaultValue: '',
              })
            }
            secondLine={
              rents.contract_count > 1
                ? t('buyNRent.balanceCard.contractCount', {
                    contractCount: rents.contract_count,
                  })
                : t('buyNRent.balanceCard.contractCountSingular', {
                    contractCount: rents.contract_count,
                  })
            }
            cardHeaderColor={theme.colors.secondary.pantone['328C']}
            cardBalanceColor={theme.colors.primary[150]}
          />
        </Box>
        <BuyAndRentBox />
      </Box>
      {contracts.length !== 0 && (
        <Box>
          <TabsWrapper>
            <TabWrapper>
              <Text variant="titleM" color="custom.primary.100">
                {t('contracts')}
              </Text>
            </TabWrapper>
          </TabsWrapper>
          <BoxContractsList
            contracts={contracts}
            pagination={paginationParams}
            onOrderChange={setPaginationParams}
            onPaginationChange={setPaginationParams}
          />
          {REACT_APP_TRANSACTIONS_BLOCK_DISPLAY && (
            <TransactionsList
              shipments={fetchShipments}
              pagination={paginationParams}
              onOrderChange={setPaginationParams}
              onPaginationChange={setPaginationParams}
            />
          )}
        </Box>
      )}
    </Stack>
  );
};

export default ProfileBuyAndRent;
