// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  DotsIcon,
  EditIcon,
  DeleteIcon,
  BulletWrongIcon,
  SearchIcon,
  Chip,
  Box,
  Stack,
  styled,
  useTheme,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import { PATHS } from 'configs';
import { normalizeKey } from 'helpers/i18n';
import { storeSelector } from 'helpers/paginatedStoreHelpers';
import useDefaultAddresses from 'hooks/useDefaultAddresses';
import useFormTemplate from 'hooks/useFormTemplate';
import { queryClient } from 'lib/react-query';
import { size } from 'lodash';
import { isEmpty } from 'lodash/fp';
import {
  ContactAddressType,
  IContact,
  IContactAddress,
  IContactSearchForm,
  INITIAL_STATE_CONTACT_SEARCH_FORM,
} from 'models/contactAddress';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { Row } from 'react-table';
import { contactSearchSchema } from 'schemas/contactAddressSchema';
import { shallow } from 'zustand/shallow';

import useContactAddress from '../../hooks/useContactAddress';
import useContactAddressStore from '../../stores/useContactAddressStore';
import useMyPartnersStore, {
  activePartnerSelector,
} from '../../stores/useMyPartnersStore';
import ContactAddressInfo from '../atoms/contactAddress/ContactAddressInfo';
import DotsAnimated from '../atoms/DotsAnimated';
import NameCard from '../atoms/NameCard';
import { TableDesignTypeEnum } from '../atoms/TableComponents';
import DeleteContactAddressModal from '../molecules/modals/DeleteContactAddressModal';
import ReactTableTable, { TColumn } from '../molecules/ReactTableTable';

const { CONTACT_ADDRESS } = PATHS;

interface IContactTableProps {
  loading: boolean;
  onSortingChange: (input: { sortBy: any; order: 'ASC' | 'DESC' }) => void;
  onPaginationChange: (input: { offset: number; pageSize: number }) => void;
  actionIsSearchMustBeCleared?: number;
}

const IconWrap = styled('div', {
  shouldForwardProp: prop => prop !== 'visible',
})<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'initial' : 'none')};
  cursor: pointer;
`;

const IconsWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ContactInfoText = styled('span')`
  color: ${({ theme }) => theme.palette.custom.neutral.black[100]};
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  font-style: normal;
`;

const BoxElement = styled(Box)`
  height: 56px;
  line-height: 56px;
  margin-top: 0;
`;

const InputContainer = styled('div')`
  position: relative;
`;

const InputIconContainer = styled('div')<{
  displaySearchMagnifierIcon: boolean;
}>`
  position: absolute;
  right: 8px;
  top: ${({ displaySearchMagnifierIcon }) =>
    displaySearchMagnifierIcon ? '4px' : '8px'};
`;

const ClearButton = styled('button')`
  cursor: pointer;
  border: none;
  background: none;
`;

ClearButton.defaultProps = {
  children: <BulletWrongIcon size="1rem" />,
  type: 'button',
};

const PartnerContactsTable = ({
  loading,
  onSortingChange,
  onPaginationChange,
  actionIsSearchMustBeCleared,
}: IContactTableProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(['general', 'contactAddress']);

  const activePartner = useMyPartnersStore(activePartnerSelector);
  const [activeRowEntry, setActiveRowEntry] = useState<IContact>();

  const [displaySearchMagnifierIcon, setDisplaySearchMagnifierIcon] =
    useState<boolean>(true);

  const { control } = useFormTemplate<IContactSearchForm>({
    resolver: contactSearchSchema,
    defaultValues: INITIAL_STATE_CONTACT_SEARCH_FORM,
  });

  const {
    rowData,
    pageSize,
    offset,
    sortBy,
    order,
    total,
    dispatch,
    searchTerm,
  } = useContactAddressStore(storeSelector, shallow);

  const [activeEntry] = useState<IContact>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  useEffect(() => {
    if (!showDeleteModal) {
      setActiveRowEntry(undefined);
    }
  }, [setActiveRowEntry, showDeleteModal]);

  const { deleteContact, loading: deletionInProgress } = useContactAddress(
    activePartner.id
  );

  const handleDelete = useCallback(async () => {
    const { success } = await deleteContact(activeRowEntry?.id!);
    if (success) {
      dispatch({
        type: 'removeEntry',
        args: { id: activeRowEntry?.id! },
      });

      // Update partnership data
      queryClient.refetchQueries({
        queryKey: ['partnership', activePartner.id],
      });

      setShowDeleteModal(false);
    }
  }, [
    deleteContact,
    activeRowEntry,
    setShowDeleteModal,
    dispatch,
    activePartner,
  ]);

  const handleDeleteClick = useCallback(
    (contact: any) => {
      setActiveRowEntry(contact);
      setShowDeleteModal(true);
    },
    [setActiveRowEntry]
  );

  const handleEditClick = useCallback(
    (contactId: string) => {
      let contactAddressEditUrl = generatePath(CONTACT_ADDRESS.EDIT, {
        partnerId: activePartner.id,
        contactId: contactId,
      });
      navigate(contactAddressEditUrl);
    },
    [navigate, activePartner]
  );

  const onSearch = useCallback(
    (event: any) => {
      dispatch({
        type: 'searchByTerm',
        args: { searchTerm: event.currentTarget.value },
      });

      setDisplaySearchMagnifierIcon(event.currentTarget.value === '');
    },
    [dispatch]
  );

  const handleClearSearch = useCallback(() => {
    dispatch({
      type: 'searchByTerm',
      args: { searchTerm: '' },
    });

    setDisplaySearchMagnifierIcon(true);
  }, [dispatch]);

  useEffect(() => {
    if (actionIsSearchMustBeCleared) {
      handleClearSearch();
    }
  }, [handleClearSearch, actionIsSearchMustBeCleared]);

  // Compute default addresses to prepend to the table
  const {
    defaultSenderAddressId,
    defaultRecipientAddressId,
    defaultSenderContact,
    defaultRecipientContact,
  } = useDefaultAddresses();

  let defaultContacts: IContact[] = [];
  if (
    defaultSenderContact &&
    defaultRecipientContact &&
    defaultSenderContact?.id === defaultRecipientContact?.id
  ) {
    // Need to merge same contact but different addresses coming from sender and recipient
    defaultContacts = [
      {
        ...defaultSenderContact,
        addresses: [
          ...defaultSenderContact?.addresses.filter(address =>
            [defaultSenderAddressId, defaultRecipientAddressId].includes(
              address.id
            )
          ),
          ...defaultRecipientContact?.addresses.filter(address =>
            [defaultRecipientAddressId, defaultRecipientAddressId].includes(
              address.id
            )
          ),
        ],
      },
    ];
  } else {
    if (defaultSenderContact) {
      defaultContacts.push({
        ...defaultSenderContact,
        addresses: defaultSenderContact?.addresses.filter(
          address => address.id === defaultSenderAddressId
        ),
      });
    }
    if (defaultRecipientContact) {
      defaultContacts.push({
        ...defaultRecipientContact,
        addresses: defaultRecipientContact?.addresses.filter(
          address => address.id === defaultRecipientAddressId
        ),
      });
    }
  }

  const columns: TColumn<IContact>[] = [
    {
      Header: '',
      id: 'icon',
      width: '3rem',
      Cell: ({ row }: { row: Row<IContact> }) => (
        <Stack>
          {size(row.original.addresses) !== 0 &&
            row.original.addresses.map(
              (address: IContactAddress, index: number) => (
                <BoxElement paddingTop="12px" key={address.id}>
                  {index === 0 && (
                    <NameCard
                      firstName={row.original.firstName}
                      lastName={row.original.lastName}
                      email={row.original.email}
                    />
                  )}
                </BoxElement>
              )
            )}
        </Stack>
      ),
    },
    {
      Header: t('contactAddress:list.header.firstName'),
      accessor: 'firstName',
      width: '10rem',
      headerEmphasis: true,
      sortable: true,
      Cell: ({ row }: { row: Row<IContact> }) => (
        <Stack>
          {size(row.original.addresses) !== 0 &&
            row.original.addresses.map(
              (address: IContactAddress, index: number) => (
                <BoxElement key={address.id}>
                  {index === 0 && (
                    <ContactInfoText>{row.original.firstName}</ContactInfoText>
                  )}
                </BoxElement>
              )
            )}
        </Stack>
      ),
    },
    {
      Header: t('contactAddress:list.header.lastName'),
      accessor: 'lastName',
      width: '10rem',
      sortable: true,
      Cell: ({ row }: { row: Row<IContact> }) => (
        <Stack>
          {size(row.original.addresses) !== 0 &&
            row.original.addresses.map(
              (address: IContactAddress, index: number) => (
                <BoxElement key={address.id}>
                  {index === 0 && (
                    <ContactInfoText>{row.original.lastName}</ContactInfoText>
                  )}
                </BoxElement>
              )
            )}
        </Stack>
      ),
    },
    {
      Header: t('contactAddress:list.header.type'),
      accessor: 'id',
      width: '10rem',
      sortable: false,
      Cell: ({ row }: { row: Row<IContact> }) => {
        let hasDefaultSenderAlreadyBeShown = false;

        return (
          <Stack>
            {size(row.original.addresses) !== 0 &&
              row.original.addresses.map((address: IContactAddress) => {
                let content;

                if (row.index >= defaultContacts.length) {
                  if (address.type !== undefined) {
                    content = (
                      <Chip
                        label={t(
                          normalizeKey(
                            'contactAddress:form.type.choices.' + address.type
                          )
                        )}
                        variant="bodyTextXS"
                        type="tags"
                        state={
                          address.type === ContactAddressType.professional
                            ? 'active'
                            : 'pending'
                        }
                      />
                    );

                    return <BoxElement key={address.id}>{content}</BoxElement>;
                  }

                  return null;
                }

                if (
                  address.id === defaultSenderAddressId &&
                  !hasDefaultSenderAlreadyBeShown
                ) {
                  hasDefaultSenderAlreadyBeShown = true;
                  content = (
                    <Chip
                      label={t('contactAddress:list.defaultSender')}
                      variant="bodyTextXS"
                      type="tags"
                      state="idle"
                    />
                  );
                } else if (address.id === defaultRecipientAddressId) {
                  content = (
                    <Chip
                      label={t('contactAddress:list.defaultRecipient')}
                      variant="bodyTextXS"
                      type="tags"
                      state="idle"
                    />
                  );
                }

                return <BoxElement key={address.id}>{content}</BoxElement>;
              })}
          </Stack>
        );
      },
    },
    {
      Header: t('contactAddress:list.header.address'),
      accessor: 'partnerId',
      width: '10rem',
      sortable: false,
      Cell: ({ row }: { row: Row<IContact> }) => (
        <Stack>
          {size(row.original.addresses) !== 0 &&
            row.original.addresses.map((address: IContactAddress) => (
              <BoxElement key={address.id}>
                <ContactAddressInfo contactAddress={address} key={address.id} />
              </BoxElement>
            ))}
        </Stack>
      ),
    },
    {
      Header: () => (
        <InputContainer>
          <Controller
            name="searchTerm"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                autoFocus={searchTerm !== undefined}
                placeholder={t(
                  'contactAddress:list.header.actions.inputSearch.placeholder'
                )}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                sx={{
                  width: '200px',
                  '& .MuiFilledInput-root': {
                    minHeight: '0',
                    height: '2rem',

                    '& .MuiFilledInput-input': {
                      pb: '1.4375rem',
                    },
                  },
                }}
                {...field}
                onChange={e => {
                  onSearch(e);
                }}
                value={searchTerm}
              />
            )}
          />
          <InputIconContainer
            displaySearchMagnifierIcon={displaySearchMagnifierIcon}
          >
            {displaySearchMagnifierIcon && (
              <SearchIcon color={theme.palette.custom.neutral.black[30]} />
            )}
            {!displaySearchMagnifierIcon && (
              <ClearButton
                aria-label={t('general:clearSearch')}
                title={t('general:clearSearch')}
                onClick={handleClearSearch}
              />
            )}
          </InputIconContainer>
        </InputContainer>
      ),
      id: 'actions',
      hasClickable: false,
      displayHasText: false,
      width: '200px',
      skeleton: <div />,
      // @ts-ignore
      Cell: ({ row, isHovered }: { row: Row<IContact>; isHovered: boolean }) =>
        row.original.id === activeEntry?.id! ? (
          <DotsAnimated />
        ) : isHovered ? (
          <IconsWrapper>
            <IconWrap
              onClick={_ => handleDeleteClick(row.original)}
              visible={true}
              //visible={canDeletePartnerContact}
            >
              <DeleteIcon data-testid={'trash-icon-' + row.original.id} />
            </IconWrap>
            <IconWrap
              onClick={_ => {
                handleEditClick(row.original.id);
              }}
              visible={true}
              //visible={canEditPartnerContact}
            >
              <EditIcon data-testid={'pencil-icon-' + row.original.id} />
            </IconWrap>
          </IconsWrapper>
        ) : (
          <IconsWrapper>
            <DotsIcon />
          </IconsWrapper>
        ),
    },
  ];

  const data = [
    ...defaultContacts,
    // Remove any default address from the contact to avoid duplicates in the list
    ...rowData
      .map(contact => ({
        ...contact,
        addresses: contact.addresses?.filter(
          address =>
            ![defaultSenderAddressId, defaultRecipientAddressId].includes(
              address.id
            )
        ),
      }))
      .filter(contact => contact.addresses?.length),
  ];

  return (
    <>
      <ReactTableTable<IContact>
        dataTestId="contact-address-table"
        loading={loading && !isEmpty(defaultContacts)}
        columns={columns}
        data={data || []}
        pagination={{ pageSize, offset, total }}
        onPaginationChange={onPaginationChange}
        onSortingChange={onSortingChange}
        sorting={{
          sortBy,
          order,
        }}
        tableDesignType={TableDesignTypeEnum.shipmentListTable}
        style={{ height: 'initial' }}
      />
      <DeleteContactAddressModal
        open={showDeleteModal}
        onSubmit={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
        entry={activeRowEntry!}
        loading={deletionInProgress}
      />
    </>
  );
};

export default PartnerContactsTable;
