// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { fromUnixTime, formatDistanceStrict } from 'date-fns';
import { enGB, fr, de } from 'date-fns/locale';
import { SentryCaptureException } from 'helpers/tracking';
import { useTranslation } from 'react-i18next';

const getLocale = (language: any): Locale => {
  switch (language) {
    case 'fr':
      return fr;
    case 'de':
      return de;
    default:
      return enGB;
  }
};

const useIntlDistanceTimestamp = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return useCallback(
    (from: number, to?: number) => {
      try {
        return formatDistanceStrict(
          fromUnixTime(from),
          to ? fromUnixTime(to) : new Date(),
          {
            locale: getLocale(language),
          }
        );
      } catch (e) {
        //FIXME: CHANGE TO DASHBOARDV2 ERROR IMPLEMENTATION
        SentryCaptureException(new Error('Invalid time value ' + from), null);

        return '--';
      }
    },
    [language]
  );
};

export default useIntlDistanceTimestamp;
