// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useState } from 'react';

import {
  ChevronDownIcon,
  Stack,
  Text,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';
import Divider from 'components/atoms/Divider';
import BoxContractListItem from 'components/molecules/BoxContractListItem';
import { IBoxContract } from 'models/boxContract';
import { useTranslation } from 'react-i18next';

import useBoxContract from '../../hooks/useBoxContract';
import {
  OrderDirection,
  PaginationParams,
} from '../../shared-components/usePagination';
import useBoxContractsStore, {
  orderSelector,
  setOrderSelector,
} from '../../stores/useBoxContractsStore';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin-top: 3rem;
  margin-bottom: 1.406rem;
`;
const HeaderWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: 0.75rem;
`;

const StyledSpan = styled(Text)``;

StyledSpan.defaultProps = {
  variant: 'titleS',
  color: 'custom.neutral.black.50',
};

const SortWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.custom.neutral.black[50]};
  background: none;
  border: none;
  display: inline-flex;
  align-items: center;
`;

interface BoxContractListProps {
  isPartner?: boolean;
  contracts?: IBoxContract[];
  pagination: PaginationParams;
  onOrderChange: (order: {
    orderColumn: string;
    orderDirection: OrderDirection;
  }) => void;
  onPaginationChange: (pagination: { page: number }) => void;
}

function BoxContractsList({
  isPartner = false,
  contracts = [],
}: BoxContractListProps) {
  const theme = useTheme();
  const { t } = useTranslation('buyAndRent');

  const order = useBoxContractsStore(orderSelector);
  const setOrder = useBoxContractsStore(setOrderSelector);

  useBoxContract();

  const [sorted, setSorted] = useState(false);
  const setASC = useCallback(() => {
    contracts.sort(
      (a: { totalAmountInCents: number }, b: { totalAmountInCents: number }) =>
        a.totalAmountInCents > b.totalAmountInCents ? 1 : -1
    );
  }, [contracts]);
  const setDESC = useCallback(() => {
    contracts.sort(
      (a: { totalAmountInCents: number }, b: { totalAmountInCents: number }) =>
        b.totalAmountInCents > a.totalAmountInCents ? 1 : -1
    );
  }, [contracts]);
  const handleAmountSort = useCallback(() => {
    setSorted(prevState => !prevState);
    if (sorted) {
      setDESC();
    } else {
      setASC();
    }
  }, [sorted, setSorted, setASC, setDESC]);

  const handleSort = useCallback(() => {
    if (order === 'ASC') {
      setOrder('DESC');

      return;
    } else {
      setOrder('ASC');
    }
  }, [order, setOrder]);

  const renderRows = () =>
    contracts.map((contract: IBoxContract) => (
      <BoxContractListItem
        key={contract.id}
        isPartner={isPartner}
        boxContract={contract}
      />
    ));

  return (
    <Wrapper>
      <HeaderWrapper>
        <Stack direction="row" width="21%">
          <SortWrapper onClick={handleSort}>
            <StyledSpan
              style={{
                marginRight: '0.5rem',
                display: 'flex',
                marginLeft: '2rem',
              }}
            >
              {t('buyNRent.contracts.purchasedDate')}
            </StyledSpan>
            <ChevronDownIcon
              color={theme.palette.custom.primary[100]}
              style={{
                transform: order === 'DESC' ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: '500ms',
              }}
            />
          </SortWrapper>
        </Stack>
        <Stack direction="row" width="20%" alignItems="center">
          <StyledSpan>{t('buyNRent.contracts.paymentMethod')}</StyledSpan>
        </Stack>
        <Stack direction="row" width="17%">
          <SortWrapper>
            <StyledSpan>{t('buyNRent.contracts.bought')}</StyledSpan>
          </SortWrapper>
        </Stack>
        <Stack direction="row" width="20%">
          <SortWrapper onClick={handleAmountSort}>
            <StyledSpan style={{ marginRight: '0.5rem' }}>
              {t('buyNRent.contracts.amount')}
            </StyledSpan>
            <ChevronDownIcon
              color={theme.palette.custom.primary[100]}
              style={{
                transform: !sorted ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: '500ms',
              }}
            />
          </SortWrapper>
        </Stack>
        <Stack direction="row" alignItems="center">
          <StyledSpan>{t('buyNRent.contracts.contractsPDF')}</StyledSpan>
        </Stack>
      </HeaderWrapper>
      <Divider direction="horizontal" />
      {renderRows()}
    </Wrapper>
  );
}

export default BoxContractsList;
