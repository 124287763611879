// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  WalletIcon,
  Text,
  ButtonV2 as Button,
  styled,
  useTheme,
  Box,
  Stack,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

const ContributionDetailInProgress = styled('div')`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 20px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  padding: 12px 14px;
`;

const WalletIconContainer = styled(Box)`
  height: 48px;
  width: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

const WalletFailIconContainer = styled(WalletIconContainer)`
  background-color: ${({ theme }) => theme.palette.custom.error.red[10]};
`;

interface IContributionErrorToFix {
  onClickEdtAccountInformation: () => void;
  isWarningMode?: Boolean;
}

const ContributionErrorToFix = ({
  onClickEdtAccountInformation,
  isWarningMode = false,
}: IContributionErrorToFix) => {
  const { t } = useTranslation('contribution');
  const theme = useTheme();

  return (
    <ContributionDetailInProgress>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <WalletFailIconContainer>
            <WalletIcon color={theme.palette.custom.error.red[100]} />
          </WalletFailIconContainer>
          <Stack paddingLeft="24px">
            <Text variant="titleXS" color="custom.error.red.100">
              {t(
                'contributionsTab.contributionsList.detail.failureToPay.title'
              )}
            </Text>
            <Text variant="bodyTextS" color="custom.error.red.100">
              {t('contributionsTab.contributionsList.detail.failureToPay.text')}
            </Text>
          </Stack>
        </Stack>
        {!isWarningMode && (
          <Button
            variant="primary"
            color="critical"
            onClick={onClickEdtAccountInformation}
            data-testid="contributionList-detail-button-edit-account-information"
          >
            {t(
              'contributionsTab.contributionsList.detail.button.editAccountInformation'
            )}
          </Button>
        )}
      </Stack>
    </ContributionDetailInProgress>
  );
};

export default ContributionErrorToFix;
