// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import ScaleOut from 'components/atoms/ScaleOut';
import FixedLoader from 'components/molecules/FixedLoader';
import { PATHS } from 'configs';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import useAppState, {
  loadingSelector,
  userSelector,
} from 'stores/appState/useAppState';

interface IPrivateRoute {
  component: any;
  isAvailableForCurrentUser?: boolean;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({
  component: Component,
  isAvailableForCurrentUser = true,
  ...rest
}) => {
  const [, loading] = useAppState(loadingSelector);
  const appUser = useAppState(userSelector);
  const { loginWithRedirect } = useAuth0();
  const { i18n } = useTranslation('auth0');
  const location = useLocation();

  if (!loading && !appUser) {
    loginWithRedirect({
      appState: { returnTo: location.pathname },
      ui_locales: i18n.language,
    });
  } else if (!isAvailableForCurrentUser) {
    return <Navigate to={PATHS.PAGE_404} />;
  }

  return appUser ? (
    Component
  ) : (
    <FixedLoader ovBg="#fff" loader={<ScaleOut />} />
  );
};

let privateRouteSecured = withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => <FixedLoader ovBg="#fff" loader={<ScaleOut />} />,
});

let privateRouteAuth0 = window.Cypress ? PrivateRoute : privateRouteSecured;
export default privateRouteAuth0;
