// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { ROUTE_PATHS } from 'configs';
import { Route, Routes } from 'react-router-dom';

import PartnerContactAddressAdd from '../../molecules/contactAddress/PartnerContactAddressAdd';
import PartnerContactAddressEdit from '../../molecules/contactAddress/PartnerContactAddressEdit';
import PartnerContactAddressOverview from '../../molecules/contactAddress/PartnerContactAddressOverview';

const PartnerContactAddressManagement = () => (
  <Routes>
    <Route index element={<PartnerContactAddressOverview />} />
    <Route
      path={ROUTE_PATHS.PARTNER.CHILDREN.CONTACT_ADDRESS.CHILDREN.CREATE.ROOT}
      element={<PartnerContactAddressAdd />}
    />
    <Route path={ROUTE_PATHS.PARTNER.CHILDREN.CONTACT_ADDRESS.CHILDREN.ID.ROOT}>
      <Route
        path={
          ROUTE_PATHS.PARTNER.CHILDREN.CONTACT_ADDRESS.CHILDREN.ID.CHILDREN.EDIT
            .ROOT
        }
        element={<PartnerContactAddressEdit />}
      />
    </Route>
  </Routes>
);

export default PartnerContactAddressManagement;
