// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  CloseIcon,
  PaymentIcon,
  BenefitsIcon,
  Box,
  Text,
  styled,
  useTheme,
  Stack,
} from '@livingpackets/design-system-react-next';
import figuralLogo from 'assets/img/insurance/figural-long-logo.png';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

import Modal from '../../atoms/Modal';

const InsuranceFiguralLogo = styled('div', {
  shouldForwardProp: prop => prop !== 'figuralLogo',
})<{
  figuralLogo: string;
}>`
  width: 110px;
  height: 48px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${({ figuralLogo }) => figuralLogo});
`;

const FiguralInformationModal = ({
  isVisible,
  onDismiss,
}: {
  isVisible: boolean;
  onDismiss: () => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['shipments', 'partnerSettings']);

  const getReadMoreLink = () => (
    <a
      href={t('partnerSettings:modal.figuralInformation.readMoreWebsiteUrl')}
      target="_blank"
      rel="noreferrer"
    >
      <Text variant="bodyTextS" color="custom.primary.100">
        {t('partnerSettings:modal.figuralInformation.readMoreWebsite')}
      </Text>
    </a>
  );

  return (
    <Modal open={isVisible} handleClose={onDismiss}>
      <>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          marginBottom="30px"
        >
          <InsuranceFiguralLogo
            figuralLogo={figuralLogo}
            data-testid="figural-logo-information-modal"
          />
          <CloseIcon
            onClick={() => onDismiss()}
            style={{ cursor: 'pointer' }}
            data-testid="close-modal-icon"
          />
        </Stack>
        <Stack>
          <Stack direction="row" marginBottom="18px !important">
            <BenefitsIcon
              color={theme.palette.custom.primary[100]}
              style={{ marginTop: '-7px' }}
            />
            <Stack marginLeft="11px !important">
              <Text variant="titleXS" color="custom.neutral.black.100">
                {t('partnerSettings:modal.figuralInformation.benefits.title')}
              </Text>
              <Text variant="bodyTextS" color="custom.neutral.black.100">
                <Box
                  marginTop="13px"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t(
                        'partnerSettings:modal.figuralInformation.benefits.description'
                      )
                    ),
                  }}
                />
              </Text>
            </Stack>
          </Stack>
          <Stack direction="row">
            <PaymentIcon
              color={theme.palette.custom.primary[100]}
              style={{ marginTop: '-7px' }}
            />
            <Stack marginLeft="11px !important">
              <Text variant="titleXS" color="custom.neutral.black.100">
                {t('partnerSettings:modal.figuralInformation.avantages.title')}
              </Text>
              <Text variant="bodyTextS" color="custom.neutral.black.100">
                <Box
                  marginTop="10px"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t(
                        'partnerSettings:modal.figuralInformation.avantages.description'
                      )
                    ),
                  }}
                />
              </Text>
              <Text
                variant="bodyTextS"
                color="custom.neutral.black.50"
                sx={{
                  whiteSpace: 'break-spaces',
                  fontStyle: 'italic',
                  lineHeight: '15px',
                }}
              >
                {t('partnerSettings:modal.figuralInformation.help')}
              </Text>
              <Stack direction="row" marginTop="17px !important">
                <Text variant="bodyTextS">
                  {t('partnerSettings:modal.figuralInformation.readMoreLink')}
                </Text>
                {getReadMoreLink()}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </>
    </Modal>
  );
};

export default FiguralInformationModal;
