// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useMemo, useState } from 'react';

import {
  ContactIcon,
  CloseIcon,
  Text,
  Stack,
  NewTextField as TextField,
  ButtonV2,
  ArrowIcon,
  Select,
  Box,
  Grid,
  Grow,
  Collapse,
} from '@livingpackets/design-system-react-next';
import { PhoneNumber } from 'components/form/phoneNumber';
import SearchContactNameForm from 'components/molecules/Form/SearchContactNameForm';
import useFormTemplate from 'hooks/useFormTemplate';
import { TFunction } from 'i18next';
import { IContact } from 'models/contactAddress';
import { IShipment } from 'models/shipment';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { phoneNumber } from 'schemas/common';
import useMyPartnersStore, {
  activePartnerSelector,
} from 'stores/useMyPartnersStore';
import { object, string } from 'yup';

import { useShareTrackingCode } from '../../api/shareTrackingCode';
import { IShareLinkForm, TrackingRoleType } from '../../types/trackingCode';

const languageByDefault = (countryCode: string = 'EN') => {
  switch (countryCode) {
    case 'FR':
      return 'FR';
    case 'DE':
    case 'GE':
      return 'DE';
    default:
      return 'EN';
  }
};

export const TrackingLinkForm = ({
  handleClose,
  shipmentId,
  shipment,
  displaySearchContact,
}: {
  handleClose: () => void;
  shipmentId: string;
  shipment: IShipment;
  displaySearchContact: any;
}) => {
  const { t } = useTranslation(['shipments', 'general']);
  const { mutate: sendTrackingCode } = useShareTrackingCode({
    shipmentId: shipmentId,
  });

  const [displaySearchContactForm, setDisplaySearchContactForm] =
    useState<boolean>(false);
  const activePartner = useMyPartnersStore(activePartnerSelector);

  const shareLinkSchema = (t: TFunction<'general'>) =>
    object().shape({
      role: string().required(t('fieldError')),
      firstName: string().required(t('fieldError')),
      lastName: string().required(t('fieldError')),
      email: string().email(t('emailFieldError')).required(t('fieldError')),
      ...(activePartner.smsEnabled
        ? {
            phoneNumber: string()
              .required(t('fieldError'))
              .concat(phoneNumber()),
          }
        : {}),
    });

  const LANG_OPTIONS = useMemo(
    () => [
      { value: 'EN', label: t('general:english') },
      { value: 'FR', label: t('general:french') },
      { value: 'DE', label: t('general:german') },
    ],
    [t]
  );

  const { handleSubmit, disableSubmit, control, setValue } =
    useFormTemplate<IShareLinkForm>({
      resolver: shareLinkSchema,
      formValidationMode: 'onTouched',
      defaultValues: {
        role: TrackingRoleType.additionalTracker,
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        lang: languageByDefault(shipment.recipient.address.country_code),
      },
    });

  const onSubmit = useCallback(
    (values: any) => {
      sendTrackingCode(values);
      handleClose();
    },
    [handleClose, sendTrackingCode]
  );

  const autofillContactForm = useCallback(
    (contact: IContact) => {
      const inputConfig = {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      };

      setValue('firstName', contact.firstName, inputConfig);
      setValue('lastName', contact.lastName, inputConfig);
      setValue('email', contact.email, inputConfig);
      setValue('phoneNumber', contact.phoneNumber, inputConfig);

      setDisplaySearchContactForm(false);
    },
    [setValue]
  );

  return (
    <Stack
      marginTop="1.625rem"
      marginBottom="2.5rem"
      marginLeft="1.5rem"
      marginRight="1.5rem"
      gap="1.9375rem"
    >
      <Stack flexDirection="row" justifyContent="space-between">
        <Text variant="titleM">
          {shipment.opening_code
            ? t('shipments:shareTracking.shareCodeTitle')
            : t('shipments:shareTracking.shareLinkTitle')}
        </Text>
        <Grow in={!displaySearchContactForm}>
          <Box>
            <ButtonV2
              icon={ContactIcon}
              onClick={() => {
                setDisplaySearchContactForm(true);
                displaySearchContact(true);
              }}
            />
          </Box>
        </Grow>
      </Stack>
      <Grid container spacing="1rem">
        <Grid item mobile={12}>
          <Collapse in={displaySearchContactForm}>
            <Stack
              alignItems="center"
              position="relative"
              zIndex={2}
              paddingRight="3rem"
              height="3.125rem"
            >
              <Box width="100%">
                <SearchContactNameForm
                  onContactSelect={autofillContactForm}
                  isFormDisplayed={displaySearchContactForm}
                  onDismiss={() => {}}
                />
              </Box>
              <Box
                position="absolute"
                top="13px"
                right="5px"
                onClick={() => {
                  setDisplaySearchContactForm(false);
                  displaySearchContact(false);
                }}
                style={{ cursor: 'pointer' }}
              >
                <CloseIcon />
              </Box>
            </Stack>
          </Collapse>
        </Grid>
        <Grid item mobile={12} tablet={6}>
          {/* FIRST_NAME */}
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('shipments:shareTracking.placeholderFirstName')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item mobile={12} tablet={6}>
          {/* LAST_NAME */}
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('shipments:shareTracking.placeholderLastName')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item mobile={12} spacing={2}>
          {/* EMAIL */}
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('shipments:shareTracking.placeholderEmail')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
        {activePartner.smsEnabled && (
          <Grid item mobile={12}>
            {/* PHONE_NUMBER */}
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState }) => (
                <PhoneNumber
                  required
                  label={t('shipments:shareTracking.placeholderPhoneNumber')}
                  error={fieldState.invalid}
                  countrySelectorMaxHeight="11.75rem"
                  defaultCountryCode={
                    shipment.recipient.address.country_code === 'EN'
                      ? 'GB'
                      : shipment.recipient.address.country_code
                  }
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )}
            />
          </Grid>
        )}
        <Grid item mobile={12}>
          {/* LANG */}
          <Controller
            name="lang"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                required
                label={t('shipments:shareTracking.placeholderLanguage')}
                options={LANG_OPTIONS}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between">
        <Grid item>
          <ButtonV2 variant="secondary" color="primary" onClick={handleClose}>
            {t('shipments:shareTracking.cancel')}
          </ButtonV2>
        </Grid>
        <Grid item>
          <ButtonV2
            icon={ArrowIcon}
            disabled={disableSubmit}
            onClick={handleSubmit(onSubmit)}
            variant="primary"
            color="primary"
            data-testid="confirm-share-tracking"
          >
            {shipment.opening_code
              ? t('shipments:shareTracking.ctaCode')
              : t('shipments:shareTracking.ctaLink')}
          </ButtonV2>
        </Grid>
      </Grid>
    </Stack>
  );
};
