// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { ButtonV2 as Button } from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

interface ImportShipmentWaitingForFileActionType {
  onCarrierChange: () => void;
  handlePdfPageSelected: () => void;
  showChangeCarrier: boolean;
}

const ImportShipmentPdfPageSelectionAction = ({
  onCarrierChange,
  handlePdfPageSelected,
  showChangeCarrier,
}: ImportShipmentWaitingForFileActionType) => {
  const { t } = useTranslation('shipments');

  return (
    <>
      {showChangeCarrier && (
        <Button variant="secondary" onClick={onCarrierChange}>
          {t('changeCarrier')}
        </Button>
      )}
      <Button onClick={handlePdfPageSelected}>{t('selectPdfPage')}</Button>
    </>
  );
};

export default ImportShipmentPdfPageSelectionAction;
