// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Grid, Text } from '@livingpackets/design-system-react-next';
import greenDotsLoader from 'assets/lotties/green-dots-loader.json';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';

export const Loading = ({ height = '25.3125rem' }: { height?: string }) => {
  const { t } = useTranslation('account');

  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      height={height}
      gap=".875rem"
      flexWrap="nowrap"
    >
      <Grid
        item
        sx={{
          marginTop: '-1.875rem',
          width: '3.75rem',
          height: '3.75rem',
        }}
      >
        <Lottie
          isClickToPauseDisabled
          options={{
            loop: true,
            autoplay: true,
            animationData: greenDotsLoader,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
        />
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center" gap=".125rem">
          <Grid item>
            <Text color="custom.neutral.black.50" variant="titleM">
              {t('loading')}
            </Text>
          </Grid>
          <Grid item>
            <Text color="custom.neutral.black.50" variant="bodyTextS">
              {t('loadingMessage')}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
