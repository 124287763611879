// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import ShipmentAnonymousMap from 'components/molecules/ShipmentAnonymousMap';
import { IShipmentAnonymousData } from 'models/shipment';

import Modal, { IModalWrapperType } from '../../atoms/Modal';

interface IViewShipmentMapFullScreenModal {
  visible: boolean;
  onDismiss: () => void;
  shipmentData?: IShipmentAnonymousData;
  hideMapFullScreenModal: () => void;
}

const ShipmentAnonymousMapFullScreenModal = ({
  visible,
  onDismiss,
  shipmentData,
  hideMapFullScreenModal,
}: IViewShipmentMapFullScreenModal) => (
  <Modal
    open={visible}
    handleClose={onDismiss}
    wrapperType={IModalWrapperType.fullScreen}
  >
    {shipmentData && (
      <ShipmentAnonymousMap
        shipmentData={shipmentData}
        isFullScreen={true}
        hideMapFullScreenModal={hideMapFullScreenModal}
      />
    )}
  </Modal>
);

export default ShipmentAnonymousMapFullScreenModal;
