// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback } from 'react';

import { HttpRequestMethod } from 'enums/httpRequest';
import useImperativeRequest from 'hooks/useImperativeRequest';
import useProductDetail from 'hooks/useProductDetail';
import { ProductPositionModel } from 'models/product';
import { create } from 'zustand';

interface IProductPositionListStore {
  productPositionList?: ProductPositionModel[];
  setProductPositionList: (productPositionList: ProductPositionModel[]) => void;
}

export const useProductPositionStore = create<IProductPositionListStore>(
  set => ({
    setProductPositionList: (productPositionList: ProductPositionModel[]) =>
      set({ productPositionList }),
  })
);

const useProductPositionList = () => {
  const [, makeRequest] = useImperativeRequest('deviceV3');
  const { getProductType } = useProductDetail();

  const getProductPositionList = useCallback(
    async (activePartnerId: string, articleCodeList?: String[]) => {
      const { error, data } = await makeRequest({
        path: `me/partners/${activePartnerId}/products/-/position`,
        method: HttpRequestMethod.GET,
      });
      if (error) {
        return { success: false, productPositionList: null };
      }

      if (articleCodeList) {
        data.product_positions = data.product_positions.filter(
          (productPosition: ProductPositionModel) =>
            articleCodeList.includes(productPosition.article_code)
        );
      }

      data.product_positions = data.product_positions
        .filter((productPosition: ProductPositionModel) =>
          productPosition.location.latitude === 0 ||
          productPosition.location.longitude === 0
            ? null
            : productPosition
        )
        .map((productPosition: ProductPositionModel) => {
          if (productPosition.contract_state_updated_at) {
            productPosition.contract_state_updated_at =
              new Date(productPosition.contract_state_updated_at).getTime() /
              1000;
          }

          productPosition.productType = getProductType(
            productPosition.article_code
          );

          return productPosition;
        });

      return {
        success: true,
        productPositionList: data.product_positions as ProductPositionModel[],
      };
    },
    [makeRequest, getProductType]
  );

  return {
    getProductPositionList,
  };
};

export default useProductPositionList;
