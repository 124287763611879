// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import {
  ILPVillageAddress,
  INITIAL_STATE_PARTNER_R_ADDRESS,
} from 'models/address';
import { IContact } from 'models/contactAddress';
import { INITIAL_STATE_PARTNER, IPartner } from 'models/partner';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const PAGE_SIZE = 12;

interface ICurrentPartnerStore {
  partner: IPartner;
  contacts: IContact[];
  contactsPagination: { pageSize: number; offset: number; total: number };
  billingAddressesPagination: {
    pageSize: number;
    offset: number;
    total: number;
  };
  registrationAddress: ILPVillageAddress;
  billingAddresses: ILPVillageAddress[];
  setPartner: (partner: IPartner) => void;
  setRegistrationAddress: (registrationAddress: ILPVillageAddress) => void;
  setBillingAddresses: (billingAddresses: ILPVillageAddress[]) => void;
  setContacts: (contacts: IContact[]) => void;
  setContactsPagination: ({
    pageSize,
    offset,
    total,
  }: {
    pageSize?: number;
    offset?: number;
    total?: number;
  }) => void;
  setBillingAddressesPagination: ({
    pageSize,
    offset,
    total,
  }: {
    pageSize?: number;
    offset?: number;
    total?: number;
  }) => void;
}

const useCurrentPartnerStore = create<ICurrentPartnerStore>()(
  devtools(
    persist(
      set => ({
        partner: INITIAL_STATE_PARTNER,
        shops: [],
        shopsPagination: { pageSize: PAGE_SIZE, offset: 0, total: 0 },
        contacts: [],
        contactsPagination: { pageSize: PAGE_SIZE, offset: 0, total: 0 },
        billingAddressesPagination: {
          pageSize: PAGE_SIZE,
          offset: 0,
          total: 0,
        },
        registrationAddress: INITIAL_STATE_PARTNER_R_ADDRESS,
        billingAddresses: [],
        setPartner: partner => set(_state => ({ partner: partner })),
        setRegistrationAddress: address =>
          set(_state => ({ registrationAddress: address })),
        setBillingAddresses: addresses =>
          set(_state => ({ billingAddresses: addresses })),
        setContacts: contacts => {
          set(_state => ({ contacts: contacts }));
        },
        setContactsPagination: ({
          pageSize = PAGE_SIZE,
          offset,
          total = 0,
        }) => {
          set(state => ({
            contactsPagination: {
              ...state.contactsPagination,
              pageSize,
              offset:
                offset !== undefined ? offset : state.contactsPagination.offset,
              total: total ? total : state.contactsPagination.total,
            },
          }));
        },
        setBillingAddressesPagination: ({
          pageSize = PAGE_SIZE,
          offset,
          total = 0,
        }) => {
          set(state => ({
            billingAddressesPagination: {
              ...state.billingAddressesPagination,
              pageSize,
              offset:
                offset !== undefined
                  ? offset
                  : state.billingAddressesPagination.offset,
              total: total ? total : state.billingAddressesPagination.total,
            },
          }));
        },
      }),
      {
        name: 'currentPartner',
      }
    )
  )
);

export const currentPartnerSelector = (state: ICurrentPartnerStore) =>
  state.partner;
export const registrationAddressSelector = (state: ICurrentPartnerStore) =>
  state.registrationAddress;
export const billingAddressesSelector = (state: ICurrentPartnerStore) =>
  state.billingAddresses;
export const contactsSelector = (state: ICurrentPartnerStore) => state.contacts;
export const contactsPaginationSelector = (state: ICurrentPartnerStore) =>
  state.contactsPagination;
export const billingAddressesPaginationSelector = (
  state: ICurrentPartnerStore
) => state.billingAddressesPagination;

export const setCurrentPartnerSelector = (state: ICurrentPartnerStore) =>
  state.setPartner;
export const setRegistrationAddressSelector = (state: ICurrentPartnerStore) =>
  state.setRegistrationAddress;
export const setBillingAddressesSelector = (state: ICurrentPartnerStore) =>
  state.setBillingAddresses;
export const setContactsSelector = (state: ICurrentPartnerStore) =>
  state.setContacts;
export const setContactsPaginationSelector = (state: ICurrentPartnerStore) =>
  state.setContactsPagination;
export const setBillingAddressesPaginationSelector = (
  state: ICurrentPartnerStore
) => state.setBillingAddressesPagination;

export default useCurrentPartnerStore;
