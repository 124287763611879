// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  Text,
  styled,
  Stack,
} from '@livingpackets/design-system-react-next';
import { IShipment } from 'models/shipment';
import { useTranslation } from 'react-i18next';

interface IShipmentNameBlockType {
  shipment: IShipment;
  displayShipmentNameForm: boolean;
  onBlockClick?: () => void;
}

const ShipmentNameContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'displayContainer',
})<{
  displayContainer: boolean;
}>`
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[2]};
  max-width: 400px;
  border-radius: 20px;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: ${({ displayContainer }) => (displayContainer ? 'block' : 'none')};
  transition: 200ms;
  outline: 1.5px solid transparent;
`;

const ShipmentNameBlock = ({
  shipment,
  displayShipmentNameForm,
}: IShipmentNameBlockType) => {
  const { t } = useTranslation('shipments');

  return (
    <ShipmentNameContainer
      displayContainer={!displayShipmentNameForm}
      data-testid="contactBasicInformationContainer"
    >
      <Stack direction="row" justifyContent="space-between">
        <Box>
          <Text
            variant="titleXS"
            color="custom.neutral.black.50"
            marginBottom="4px"
          >
            {t('detail.reference.title')}
          </Text>
          <Text variant="bodyTextS">{shipment.shipment_name}</Text>
        </Box>
      </Stack>
    </ShipmentNameContainer>
  );
};

export default ShipmentNameBlock;
