// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback } from 'react';

import {
  ButtonV2 as Button,
  DeleteIcon,
  Stack,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

interface IDeleteModal {
  title: string;
  ctaText: string;
  open: boolean;
  onAction: () => void;
  text?: string;
  setOpen?: (arg: boolean) => void;
  onClose?: any;
  children?: React.ReactNode;
  loading?: boolean;
}

const Mask = styled('div')`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[30]};
  opacity: 20%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
`;

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
  width: 35rem;
  border-radius: 1.25rem;
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  padding: 2.5rem 1.5rem 1.5rem 1.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
`;

const ActionModal = ({
  open,
  setOpen,
  onClose,
  onAction,
  title,
  text,
  ctaText,
  children,
  loading,
}: IDeleteModal) => {
  const { t } = useTranslation('general');
  const handleClose = useCallback(() => {
    setOpen && setOpen(false);
    onClose && onClose();
  }, [setOpen, onClose]);

  if (!open) return <></>;

  return (
    <>
      <Mask onClick={handleClose} />
      <Wrapper>
        <Text variant="titleM" mb={4}>
          {title}
        </Text>
        {text && (
          <Text variant="bodyTextS" mb="1.5rem">
            {text}
          </Text>
        )}
        {children}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Button variant="tertiary" onClick={handleClose}>
            {t('cancel')}
          </Button>
          <Button
            color="critical"
            icon={DeleteIcon}
            onClick={onAction}
            disabled={loading}
          >
            {ctaText}
          </Button>
        </Stack>
      </Wrapper>
    </>
  );
};

export default ActionModal;
