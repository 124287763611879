// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ChevronUpIcon,
  Text,
  styled,
  Stack,
  Collapse,
} from '@livingpackets/design-system-react-next';

const ImportShipmentConfirmInformationSectionContainer = styled(Stack)`
  background-color: ${({ theme }) => theme.palette.custom.neutral.white.pure};
  border-radius: 16px;
  padding: 24px 32px;
  margin-bottom: 24px;
`;

interface ImportShipmentConfirmInformationSectionType {
  prefix?: React.ReactNode;
  title: string;
  subTitle?: string;
  isOpen: boolean;
  children: React.ReactNode;
  onClick: () => void;
  isDisabled?: boolean;
  dataTestId: string;
}

const ImportShipmentConfirmInformationSection = ({
  prefix,
  title,
  subTitle,
  isOpen,
  children,
  onClick,
  isDisabled = false,
  dataTestId,
}: ImportShipmentConfirmInformationSectionType) => (
  <ImportShipmentConfirmInformationSectionContainer
    sx={{
      opacity: isDisabled ? 0.4 : 1,
      pointerEvents: isDisabled ? 'none' : 'initial',
    }}
  >
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        cursor: isDisabled ? 'default' : 'pointer',
      }}
      onClick={() => {
        if (!isDisabled) {
          onClick();
        }
      }}
      data-testid={dataTestId}
    >
      <Stack direction="row">
        {prefix}
        <Stack direction="column">
          <Text variant="titleL">{title}</Text>
          {subTitle && (
            <Text
              variant="titleXS"
              paddingBottom="12px"
              color="custom.neutral.black.50"
            >
              {subTitle}
            </Text>
          )}
        </Stack>
      </Stack>
      <ChevronUpIcon
        style={{
          transitionProperty: 'transform',
          transitionDuration: '200ms',
          transform: !isOpen ? 'rotate(-0.5turn)' : 'initial',
        }}
      />
    </Stack>
    <Collapse in={isOpen}>{children}</Collapse>
  </ImportShipmentConfirmInformationSectionContainer>
);

export default ImportShipmentConfirmInformationSection;
