// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ChevronRightIcon,
  Stack,
  styled,
  useTheme,
} from '@livingpackets/design-system-react-next';

interface ISmallTabNavigation {
  options: {
    key: any;
    label: string;
    isDisabled?: boolean;
    disabledTooltip?: string;
  }[];
  activeKey?: any;
  setActiveKey: (key: any) => void;
  withSeparator?: boolean;
}

const StyledSpan = styled('span', {
  shouldForwardProp: prop => prop !== 'isActive' && prop !== 'isDisabled',
})<{ isActive: boolean; isDisabled?: boolean }>`
  ${({ theme }) => theme.fontStyles.titleXS}
  text-align: center;
  cursor: pointer;
  color: ${({ theme, isActive, isDisabled }) => {
    if (isActive) {
      return theme.palette.custom.primary[100];
    }

    return isDisabled
      ? theme.palette.custom.neutral.black[20]
      : theme.palette.custom.neutral.black[50];
  }};
  margin-right: 21px;
  padding-bottom: 12px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: 20px;
    height: 2px;
    width: 100%;
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.palette.custom.primary[100] : theme.colors.transparent};
    transition: all 200ms ease-in-out;
  }
`;

const WrapperSeparator = styled('div')`
  display: inline-flex;
  align-items: flex-end;
  vertical-align: middle;
  margin-right: 22px;
`;

const SmallTabNavigation = ({
  activeKey,
  setActiveKey,
  options,
  withSeparator = false,
}: ISmallTabNavigation) => {
  const theme = useTheme();

  return (
    <Stack direction="row">
      {options.map((option, i) => (
        <span key={option.key} data-testid={`shipment-tab-${i}`}>
          <StyledSpan
            onClick={() => setActiveKey(option.key)}
            isActive={option.key === activeKey}
            isDisabled={option.isDisabled}
            data-testid={
              option.isDisabled
                ? `shipment-tab-${i}-disabled`
                : `shipment-tab-${i}-enabled`
            }
          >
            {option.label}
          </StyledSpan>
          {withSeparator && i < options.length - 1 && (
            <WrapperSeparator>
              <ChevronRightIcon
                size="20px"
                color={theme.palette.custom.neutral.black[50]}
              />
            </WrapperSeparator>
          )}
        </span>
      ))}
    </Stack>
  );
};

export default SmallTabNavigation;
