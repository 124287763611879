// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ButtonV2 as Button,
  Text,
  Paper,
  Stack,
  NewTextField as TextField,
} from '@livingpackets/design-system-react-next';
import Dropdown from 'components/atoms/Dropdown';
import LastUpdatedAt from 'components/atoms/LastUpdatedAt';
import AddressForm from 'components/molecules/AddressForm';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useFormTemplate from 'hooks/useFormTemplate';
import useProfileSettings from 'hooks/useProfileSettings';
import pick from 'lodash/pick';
import { ILPAccountAddress } from 'models/address';
import { Language, PreferredLanguage } from 'models/user';
import { Controller, FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { partnerProfileSettingsSchema } from 'schemas/partnerProfileSettingsSchema';
import { profileSettingsSchema } from 'schemas/profileSettingsSchema';
import useAppState, { userSelector } from 'stores/appState/useAppState';

export interface FormInputs {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  company?: string;
  street?: string;
  houseNumber?: string;
  city?: string;
  postalCode?: string;
  countryCode?: string;
  preferredLanguage: Language;
}

const ProfileSettingsForm = ({
  initialState,
}: {
  initialState: ILPAccountAddress;
}) => {
  const { t } = useTranslation(['address', 'profileSettings', 'general']);
  const appUser = useAppState(userSelector);

  const { loading, onSubmit } = useProfileSettings();

  const DEFAULT_VALUES = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    company: '',
    street: '',
    houseNumber: '',
    city: '',
    postalCode: '',
    countryCode: '',
    preferredLanguage: Language.en,
  };

  const preferredLanguageValues = useMemo(
    () =>
      Object.keys(Language).map(k => ({
        value: k,
        label: t(`general:${Language[k as PreferredLanguage]}`),
      })),
    [t]
  );

  const {
    errors,
    register,
    handleSubmit,
    formState: { touchedFields },
    reset,
    control,
    disableSubmit,
    watch,
    setValue,
    trigger,
  } = useFormTemplate<FormInputs>({
    defaultValues: DEFAULT_VALUES,
    resolver:
      appUser?.businessType === UserBusinessTypeEnum.PERSONAL
        ? profileSettingsSchema
        : partnerProfileSettingsSchema,
  });
  const [isBusinessProfil, setIsBusinessProfil] = useState<boolean>(false);
  const [lastUpdatedAt, setLastUpdatedAt] = useState<any>(null);
  const preferredLanguageValue = watch('preferredLanguage');

  const resetValues = useCallback(() => {
    const adresseInfo = !isBusinessProfil
      ? pick(initialState, [
          'company',
          'city',
          'street',
          'houseNumber',
          'postalCode',
          'countryCode',
        ])
      : {};

    reset({
      ...adresseInfo,
      firstName: appUser?.firstName || '',
      lastName: appUser?.lastName || '',
      phoneNumber: appUser?.phoneNumber || '',
      preferredLanguage: appUser?.preferredLanguage || undefined,
    });
  }, [
    appUser?.firstName,
    appUser?.lastName,
    appUser?.phoneNumber,
    appUser?.preferredLanguage,
    initialState,
    isBusinessProfil,
    reset,
  ]);

  const checkProfil = useCallback(() => {
    const isBusiness = appUser?.businessType !== UserBusinessTypeEnum.PERSONAL;
    setIsBusinessProfil(isBusiness);

    const updatedAt = isBusiness
      ? appUser?.updatedAt || 0
      : Math.max(appUser?.updatedAt || 0, initialState?.updatedAt || 0);

    setLastUpdatedAt(updatedAt);
  }, [appUser?.businessType, appUser?.updatedAt, initialState?.updatedAt]);

  useEffect(() => {
    checkProfil();
    resetValues();
  }, [checkProfil, resetValues]);

  useEffect(() => {
    register('preferredLanguage');
  }, [register]);

  const onCancel = useCallback(() => resetValues(), [resetValues]);

  const handleLanguageChange = async (option: {
    label: string;
    value: PreferredLanguage;
  }) => {
    setValue('preferredLanguage', option.value, { shouldDirty: true });
    await trigger('preferredLanguage');
  };

  const onSubmitForm: SubmitHandler<FieldValues> = (data: FieldValues) => {
    onSubmit({
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      company: data.company,
      street: data.street,
      houseNumber: data.houseNumber,
      city: data.city,
      postalCode: data.postalCode,
      countryCode: data.countryCode,
      preferredLanguage: data.preferredLanguage,
    });
  };

  return (
    <Paper
      sx={{ maxWidth: '572px' }}
      headerTitle={t('profileSettings:basicInfo')}
      headerInfo={
        lastUpdatedAt && (
          <LastUpdatedAt m="0 0 0.188rem 0.9rem" timestamp={lastUpdatedAt} />
        )
      }
    >
      <Stack gap="1rem">
        <Stack direction="row" gap="1rem">
          <Controller
            name="firstName"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('address:firstName.label')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('address:lastName.label')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </Stack>
        <Stack direction="row" gap="1rem">
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                required
                label={t('profileSettings:phoneNumber')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                disabled
                label={t('profileSettings:email')}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                {...field}
                value={appUser?.email || ''}
              />
            )}
          />
        </Stack>
        <Stack direction="row">
          <Stack pr="1rem">
            <Dropdown
              name="preferredLanguage"
              label={t('profileSettings:preferredLanguage')}
              value={
                preferredLanguageValues.find(
                  l => l.value === preferredLanguageValue
                ) || null
              }
              options={preferredLanguageValues}
              onChange={handleLanguageChange}
            />
          </Stack>
        </Stack>
        {!isBusinessProfil && (
          <>
            <Text mt="1rem" mb=".5rem" variant="titleM">
              {t('address')}
            </Text>
            <AddressForm
              errors={errors}
              touched={touchedFields}
              register={register}
              rowPadding="0"
              control={control}
            />
          </>
        )}
        <Stack
          direction="row"
          mt="1rem"
          justifyContent="space-between"
          gap="12.4375rem"
        >
          <Button
            variant="secondary"
            onClick={onCancel}
            disabled={disableSubmit || loading}
            fullWidth
          >
            {t('general:dismiss')}
          </Button>
          <Button
            disabled={disableSubmit || loading}
            onClick={handleSubmit(onSubmitForm)}
            fullWidth
          >
            {t('general:save')}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ProfileSettingsForm;
