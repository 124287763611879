// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import {
  ProductArticleCodeEnum,
  ProductClosingStateEnum,
  ProductTypeEnum,
} from 'enums/ProductEnum';
import { ShipmentContractStateEnum } from 'models/shipment';

export interface ProductModel {
  alias: string;
  article_code: string;
  article_description: string;
  battery_level: number;
  closing_state: ProductClosingStateEnum;
  contract_state: ShipmentContractStateEnum;
  contract_state_updated_at: number;
  current_screen: string;
  distance_travelled: number;
  firmware_version: string;
  holder_id: string;
  holder_name: string;
  holder_type: string;
  humidity_external: number;
  humidity_internal: number;
  last_shipment_id: string;
  location: {
    latitude: number;
    longitude: number;
  };
  location_updated_at: number;
  lock_state: boolean;
  lp_ui: string;
  partner_id: string;
  partner_since: number;
  pressure: number;
  product_id: string;
  shipments_operated: number;
  shop_id: string;
  temperature_external: number;
  temperature_internal: number;
  updated_at: number;
  productType: ProductTypeEnum;
}

export interface ProductInfoModel {
  nbProduct: number;
  productType: ProductTypeEnum;
  productImg: string;
  productImg2x: string;
  productImg3x: string;
  productShadowImg: string;
  productShadowImg2x: string;
  productShadowImg3x: string;
}

export interface ProductNotePost {
  noteContent: string;
}

export interface ProductStateModel {
  battery_level?: number;
  humidity_external?: number;
  humidity_internal?: number;
  last_update?: number;
  lock_state?: boolean;
  pressure?: number;
  temperature_external?: number;
  temperature_internal?: number;
}

export interface ProductPositionModel {
  article_code: string;
  productType: ProductTypeEnum;
  contract_state: ShipmentContractStateEnum;
  contract_state_updated_at: number;
  location: {
    latitude: number;
    longitude: number;
  };
  lp_ui: string;
  deployed_shipment_id?: string;
}

export const articleCodeBoxList = [
  ProductArticleCodeEnum.THE_BOX_V2,
  ProductArticleCodeEnum.THE_BOX_V2_PROTO,
];

export const articleCodeBoxProList = [
  ProductArticleCodeEnum.THE_BOX_PRO_V_1_2,
  ProductArticleCodeEnum.THE_BOX_PRO_S_V,
  ProductArticleCodeEnum.THE_BOX_PRO_S_R,
  ProductArticleCodeEnum.THE_BOX_PRO_M,
  ProductArticleCodeEnum.THE_BOX_PRO_L_V,
  ProductArticleCodeEnum.THE_BOX_PRO_L_R,
];

export const articleCodeTabletList = [
  ProductArticleCodeEnum.THE_TABLET_HOLDER_V_1_2,
  ProductArticleCodeEnum.THE_TABLET_HOLDER_PANEL_MOUNTED,
  ProductArticleCodeEnum.FRONT_FACE_V_2_1,
  ProductArticleCodeEnum.FRONT_FACE_V_2_2,
];
