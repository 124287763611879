// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import { Box, Collapse } from '@livingpackets/design-system-react-next';
import LivingPacketsLogo from 'assets/living-packets-logo-green-text.svg';
import useIsMobile from 'hooks/useIsMobile';
import useLayoutStore, { openNavSelector } from 'stores/useLayoutStore';

import PartnershipSelector from './partnerCard/PartnershipSelector';

interface HeaderProps {
  isShipmentAnonymousPage?: boolean;
}

const Header = ({ isShipmentAnonymousPage = false }: HeaderProps) => {
  const { isMobile } = useIsMobile();

  const openNav = useLayoutStore(openNavSelector);

  return (
    <Box
      py="1rem"
      pl="0.75rem"
      gap={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: openNav ? 'visible' : 'hidden',
      }}
    >
      {/* LP Logo */}
      <Collapse
        in={isMobile || openNav}
        orientation="horizontal"
        collapsedSize={32}
      >
        <Box
          sx={{
            width: '11.7414rem',
          }}
        >
          <img
            height="100%"
            width="100%"
            alt="LivingPackets Logo"
            src={LivingPacketsLogo}
          />
        </Box>
      </Collapse>
      {/* Partnership Selector */}
      {!isShipmentAnonymousPage && <PartnershipSelector open={openNav} />}
    </Box>
  );
};

export default Header;
