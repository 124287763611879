// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { useCallback, useEffect, useState } from 'react';

import { IShipmentGeoPathCoordinates } from 'models/shipment';

type Path = { longitude: number; latitude: number }[];
type GMapPath = { lat: number; lng: number }[];

const formatToGMapsPath = (path: Path): GMapPath =>
  path.map(c => ({ lat: c.latitude, lng: c.longitude }));

const useGetShipmentAnonymousGeoPath = (
  shipmentGeoPathCoordinates: IShipmentGeoPathCoordinates[]
) => {
  const [geoPath, setGeoPath] = useState<GMapPath>([]);

  const getGeoPath = useCallback(
    async (shipmentGeoPathCoordinates: IShipmentGeoPathCoordinates[]) =>
      formatToGMapsPath(shipmentGeoPathCoordinates),
    []
  );

  useEffect(() => {
    getGeoPath(shipmentGeoPathCoordinates).then(resp => setGeoPath(resp));
  }, [getGeoPath, shipmentGeoPathCoordinates]);

  return geoPath;
};
export default useGetShipmentAnonymousGeoPath;
