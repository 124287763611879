// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  ArrowIcon,
  Box,
  ButtonV2,
  Grid,
  Text,
  styled,
} from '@livingpackets/design-system-react-next';
import halfCircleBottomWhite from 'assets/half-circle-bottom-white.svg';
import halfCircleTopWhite from 'assets/half-circle-top-white.svg';
import logoOnboarding from 'assets/logoOnboarding.svg';
import { useTranslation } from 'react-i18next';
import useAppState, { userSelector } from 'stores/appState/useAppState';

const LayoutFirstPage = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: ${({ theme }) => theme.palette.custom.neutral.black[3]};
`;

const HalfCircleBottomWhite = styled('img')`
  position: absolute;
  bottom: 0%;
  left: 5%;
`;

const HalfCircleTopWhite = styled('img')`
  position: absolute;
  top: 0%;
  right: 0%;
`;

const ButtonSkip = styled(ButtonV2)`
  margin-right: 0.75rem;
`;

const ButtonGetStarted = styled(ButtonV2)`
  margin-left: 0.75rem;
`;

export type FirstPageProps = {
  setStepsOnboarding: React.Dispatch<React.SetStateAction<number>>;
  closeOnboarding: () => void;
};

const FirstPage = ({ setStepsOnboarding, closeOnboarding }: FirstPageProps) => {
  const { t } = useTranslation('onboarding');

  const userInfos = useAppState(userSelector);

  return (
    <LayoutFirstPage>
      <HalfCircleTopWhite src={halfCircleTopWhite} alt="half-circle" />
      <HalfCircleBottomWhite src={halfCircleBottomWhite} alt="half-circle" />
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        gap="3.3669rem"
      >
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          gap="1.4187rem"
        >
          <img src={logoOnboarding} alt="lplogo" />
          <Text variant="titleM">
            {t('welcome')} {userInfos?.firstName} {userInfos?.lastName}!
          </Text>
        </Grid>
        <Grid item>
          <Grid container>
            <ButtonSkip
              data-testid="onboarding-close-button"
              variant="secondary"
              onClick={closeOnboarding}
            >
              {t('skipOnboarding')}
            </ButtonSkip>
            <ButtonGetStarted
              icon={ArrowIcon}
              onClick={() => setStepsOnboarding(1)}
              data-testid="onboarding-get-started-button"
            >
              {t('getStarted')}
            </ButtonGetStarted>
          </Grid>
        </Grid>
      </Grid>
    </LayoutFirstPage>
  );
};
export default FirstPage;
