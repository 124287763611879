// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useCallback, useEffect, useState } from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from '@livingpackets/design-system-react';
import {
  ArrowLeftIcon,
  ButtonV2 as Button,
  Stack,
  styled,
  Text,
} from '@livingpackets/design-system-react-next';
import PartnerMemberEditForm from 'components/molecules/Form/PartnerMemberEditForm';
import { PATHS } from 'configs';
import usePartnerMemberManagement, {
  usePartnerMemberStore,
} from 'hooks/useParternMemberMangement';
import { IMemberForm, INITIAL_STATE_MEMBER_FORM, IMember } from 'models/user';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import useMyPartnersStore, {
  activePartnerSelector,
} from '../../../stores/useMyPartnersStore';

const CardContact = styled(Card)`
  width: 808px;

  @media (max-width: ${({ theme }) => theme.mediaBreakpoints.md}) {
    width: auto;
  }
`;

const PartnerMemberManagementEdit = () => {
  const { t } = useTranslation(['general', 'userManagement']);
  const activePartner = useMyPartnersStore(activePartnerSelector);
  const {
    loading,
    editMember,
    getPartnerMember,
    getMemberPostFromMemberFormData,
  } = usePartnerMemberManagement();

  const navigate = useNavigate();

  const { memberId } = useParams<{ memberId: string }>();

  const setPartnerMember = usePartnerMemberStore(
    (state: { setPartnerMember: any }) => state.setPartnerMember
  );

  const [isPartnerMemberFormValid, setIsPartnerMemberFormValid] =
    useState<boolean>(true);
  const [partnerMemberData, setPartnerMemberData] = useState<IMember>();
  const [partnerMemberFormData, setPartnerMemberFormData] =
    useState<IMemberForm>(INITIAL_STATE_MEMBER_FORM);

  const backToMemberList = useCallback(() => {
    navigate(
      generatePath(PATHS.USER_MANAGEMENT.LIST, {
        partnerId: activePartner.id,
      })
    );
  }, [navigate, activePartner]);

  useEffect(() => {
    if (memberId) {
      getPartnerMember(memberId).then(({ success, partnerMember }) => {
        if (!success) {
          navigate(PATHS.PAGE_404);
        } else {
          setPartnerMemberData(partnerMember);
        }
      });

      return () => setPartnerMember();
    }
  }, [
    getPartnerMember,
    memberId,
    setPartnerMemberData,
    setPartnerMember,
    navigate,
  ]);

  const onSubmit = useCallback(async () => {
    if (isPartnerMemberFormValid && memberId) {
      const partnerMember = getMemberPostFromMemberFormData(
        partnerMemberFormData
      );

      const { success } = await editMember(partnerMember, memberId);

      if (success) {
        backToMemberList();
      }
    }
  }, [
    partnerMemberFormData,
    memberId,
    isPartnerMemberFormValid,
    backToMemberList,
    editMember,
    getMemberPostFromMemberFormData,
  ]);

  const handleOnMemberValuesChanged = useCallback((values: IMemberForm) => {
    setPartnerMemberFormData(values);
  }, []);

  const handleOnFormValidChanged = useCallback((isFormValid: boolean) => {
    setIsPartnerMemberFormValid(isFormValid);
  }, []);

  return (
    <CardContact>
      <React.Fragment key=".0">
        <CardHeader backgroundColor="black.100" color="white" padding={5}>
          <Stack direction="row" alignItems="center" spacing=".75rem">
            <Button
              variant="tertiary"
              icon={ArrowLeftIcon}
              onClick={backToMemberList}
            />
            {partnerMemberData && (
              <Text variant="bodyTextXS">
                {t('userManagement:edit.breadcrumb', {
                  memberName:
                    partnerMemberData.firstName +
                    ' ' +
                    partnerMemberData.lastName,
                })}
              </Text>
            )}
          </Stack>
          <Text variant="titleXS" mt="1rem">
            {t('userManagement:create.title')}
          </Text>
          {partnerMemberData && (
            <Text variant="titleXL">
              {partnerMemberData.firstName + ' ' + partnerMemberData.lastName}
            </Text>
          )}
        </CardHeader>
        <CardBody paddingLeft="32px" paddingRight="32px" paddingTop="36px">
          {partnerMemberData && (
            <PartnerMemberEditForm
              defaultValues={partnerMemberData}
              onValuesChanged={handleOnMemberValuesChanged}
              isFormValidChanged={handleOnFormValidChanged}
            />
          )}
        </CardBody>
        <CardFooter
          backgroundColor="transparent"
          padding="1.5rem 2rem 2.5rem 2rem"
        >
          <Stack direction="row" justifyContent="space-between">
            <Button
              variant="secondary"
              data-testid="cancelBtn"
              onClick={backToMemberList}
            >
              {t('general:cancel')}
            </Button>
            <Button
              data-testid="submitBtn"
              disabled={!isPartnerMemberFormValid || loading}
              onClick={onSubmit}
            >
              {t('userManagement:form.submit.label')}
            </Button>
          </Stack>
        </CardFooter>
      </React.Fragment>
    </CardContact>
  );
};

export default PartnerMemberManagementEdit;
