// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import {
  Box,
  ButtonV2 as Button,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

interface ImportShipmentManuallyCroppingActionType {
  handlePdfPageSelectedCropped: () => void;
  isButtonActionDisabled: boolean;
}

const ImportShipmentManuallyCroppingAction = ({
  handlePdfPageSelectedCropped,
  isButtonActionDisabled,
}: ImportShipmentManuallyCroppingActionType) => {
  const { t } = useTranslation('shipments');

  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <Button
        onClick={handlePdfPageSelectedCropped}
        disabled={isButtonActionDisabled}
      >
        {t('cropLabel')}
      </Button>
    </Box>
  );
};
export default ImportShipmentManuallyCroppingAction;
