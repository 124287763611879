// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect } from 'react';

import {
  Box,
  Checkbox,
  ModalV2 as Modal,
} from '@livingpackets/design-system-react-next';
import { useTranslation } from 'react-i18next';

import SaveThisAddressModal from './SaveThisAddressModal';

type Props = {
  disabled: boolean;
  isSender: boolean;
  contact: any;
  onSave: (isDefaultAddress: boolean) => void;
  isChecked: boolean;
  setIsChecked: (isChecked: boolean) => void;
};

export const SaveToAddressBook = ({
  disabled,
  isSender,
  contact,
  onSave,
  isChecked,
  setIsChecked,
}: Props) => {
  const { t } = useTranslation(['shipments']);

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (isChecked) {
      setOpen(true);
    }
  }, [isChecked]);

  const onDismiss = async () => {
    setIsChecked(false);
  };

  return (
    <>
      <Box
        my="1rem"
        px="10px"
        flexGrow={1}
        bgcolor="custom.secondary.blue.10"
        sx={{
          borderRadius: '0.25rem',
          opacity: disabled ? '40%' : '100%',
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        <Checkbox
          data-testid={`save-address-to-address-book-${
            isSender ? 'sender' : 'recipient'
          }`}
          label={t('shipments:form.defaultAddressCheckboxLabel')}
          checked={isChecked}
          onChange={event => setIsChecked(event.target.checked)}
        />
      </Box>
      <Modal
        open={open}
        setOpen={setOpen}
        onDismiss={onDismiss}
        maxWidth="560px"
        sx={{ '& .MuiPaper-root': { minWidth: '320px' } }}
      >
        <SaveThisAddressModal
          open={open}
          setOpen={setOpen}
          isSender={isSender}
          contact={contact}
          onSave={onSave}
          onDismiss={onDismiss}
        />
      </Modal>
    </>
  );
};
