// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useRef, useState } from 'react';

import {
  Box,
  Text,
  Paper,
  Stack,
} from '@livingpackets/design-system-react-next';
import { isMobile } from 'react-device-detect';

export const VideoCard = ({
  dataSource,
}: {
  dataSource: {
    title: string;
    videoSource: string;
    description: string;
    poster: string;
  };
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [controlsVisible, setControlsVisible] = useState(false);

  return (
    <Box
      sx={{
        backgroundColor: '#FFF',
        borderRadius: '.625rem',
        marginX: '1rem',
        ...(isMobile
          ? {
              padding: '1rem',
              boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.10)',
            }
          : {}),
      }}
    >
      <Paper
        {...{
          sx: {
            marginTop: '0.25rem !important',

            gap: '0',
            padding: '0 !important',
            background: 'transparent',
          },
        }}
      >
        <Stack direction="column" justifyContent="space-between" pb=".375rem">
          <Text variant="titleL">{dataSource.title}</Text>
          <Text variant="bodyTextM" sx={{ minHeight: '2.25rem' }}>
            {dataSource.description}
          </Text>
        </Stack>

        <Box position="relative">
          <video
            ref={videoRef}
            controls={controlsVisible}
            style={{
              borderRadius: '.625rem',
              width: '100%',
            }}
            poster={dataSource.poster}
            onClick={() => {
              if (videoRef.current) {
                videoRef.current.play();
              }
            }}
            onPlay={() => setControlsVisible(true)}
          >
            <source src={dataSource.videoSource} type="video/mp4" />
          </video>
        </Box>
      </Paper>
    </Box>
  );
};
