// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { FC, ReactElement, ReactNode, useMemo } from 'react';

import ScaleOut from 'components/atoms/ScaleOut';
import FixedLoader from 'components/molecules/FixedLoader';
import { PATHS } from 'configs';
import { UserBusinessTypeEnum } from 'enums/UserBusinessTypeEnum';
import useLDReady from 'hooks/useLDReady';
import useLoadUserAndToken from 'hooks/useLoadUserAndToken';
import useMyPartnerships from 'hooks/useMyPartnerships';
import { Navigate, useLocation } from 'react-router-dom';
import useAppState, {
  loadingSelector,
  userSelector,
} from 'stores/appState/useAppState';
import useMyPartnersStore, {
  partnersSelector,
} from 'stores/useMyPartnersStore';

const AppState: FC<{ children: ReactNode }> = ({ children }) => {
  useLoadUserAndToken();
  useMyPartnerships();
  useLDReady();

  const [, loading] = useAppState(loadingSelector);
  const appUser = useAppState(userSelector);
  const partners = useMyPartnersStore(partnersSelector);
  const location = useLocation();

  const BusinessLogic = useMemo(() => {
    if (loading) {
      return <FixedLoader ovBg="#fff" loader={<ScaleOut />} />;
    }

    /**
     If no appUser, take user through to children
     such that PrivateRoute is loaded, and login occurs **/
    if (!appUser) {
      return children;
    }

    /**
     If users email is not verified...
     a) ...and user is not on /verify-email, redirect them to to /verify-email
     b) ... and user is on /verify-email, render children
     **/
    if (!appUser.emailVerified) {
      if (location.pathname !== PATHS.VERIFY_EMAIL) {
        return <Navigate to={PATHS.VERIFY_EMAIL} />;
      } else {
        return children;
      }
    }

    if (!appUser.firstName || !appUser.lastName || !appUser.phoneNumber) {
      if (
        partners.length !== 0 &&
        appUser.businessType !== UserBusinessTypeEnum.PERSONAL
      ) {
        if (
          location.pathname !== PATHS.AUTHENTICATION.INVITATION_CHECK &&
          location.pathname !== PATHS.AUTHENTICATION.FINALIZE_ACCOUNT
        ) {
          return <Navigate to={PATHS.AUTHENTICATION.INVITATION_CHECK} />;
        } else {
          return children;
        }
      } else {
        if (location.pathname !== PATHS.AUTHENTICATION.FINALIZE_ACCOUNT) {
          return <Navigate to={PATHS.AUTHENTICATION.FINALIZE_ACCOUNT} />;
        } else {
          return children;
        }
      }
    }

    if (
      appUser.businessType === UserBusinessTypeEnum.UNKNOWN &&
      partners.length === 0
    ) {
      if (location.pathname !== PATHS.AUTHENTICATION.ACCOUNT_TYPE_CHOICE) {
        if (
          location.pathname === PATHS.AUTHENTICATION.ADD_PARTNERSHIP_INFORMATION
        ) {
          return children;
        }

        return <Navigate to={PATHS.AUTHENTICATION.ACCOUNT_TYPE_CHOICE} />;
      }
    }

    return children;
  }, [loading, appUser, partners.length, children, location.pathname]);

  return BusinessLogic as ReactElement<any>;
};

export default AppState;
