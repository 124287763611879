// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useState } from 'react';

import {
  Box,
  Paper,
  SenderAddressIcon,
  SenderAddressesIcon,
  Stack,
  Text,
} from '@livingpackets/design-system-react-next';
import { Address } from 'components/molecules/Address';
import ShipmentAnonymousMapFullScreenModal from 'components/molecules/modals/ShipmentAnonymousMapFullScreenModal';
import ShipmentAnonymousMap from 'components/molecules/ShipmentAnonymousMap';
import { IShipmentAnonymousData } from 'models/shipment';
import { useTranslation } from 'react-i18next';

type IShipmentDetailMapAdresses = {
  shipmentData?: IShipmentAnonymousData;
  logo: string | undefined;
};

export const ShipmentDetailMapAdresses = ({
  shipmentData,
  logo,
}: IShipmentDetailMapAdresses) => {
  const { t } = useTranslation(['shipments']);
  const [showMapFull, setShowMapFull] = useState<boolean>(false);

  return (
    <>
      <Paper
        sx={{
          mx: '1rem',
          mt: '0.25rem !important',
          p: '0 !important',
          gap: '0',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p="1rem"
        >
          <Text variant="titleS">
            {t('shipments:anonymous.mobile.mapBlock.title')}
          </Text>
        </Stack>
        <Box height="17.5rem">
          {shipmentData && (
            <ShipmentAnonymousMap
              shipmentData={shipmentData}
              displayMapFullScreenModal={() => {
                setShowMapFull(true);
              }}
            />
          )}
        </Box>
        <Stack pt="1.5rem" px="1rem" direction="row" alignItems="center">
          <img
            src={logo}
            alt="carrier-logo"
            style={{ width: '3rem', height: '3rem' }}
          />
          <Stack p="1rem">
            <Text variant="titleL">
              {shipmentData?.shipment?.contract_state === 'DEPLOYED'
                ? t('shipments:anonymous.mobile.deliveryStatus.inTransit')
                : t('shipments:anonymous.mobile.deliveryStatus.delivered')}
            </Text>
            <Text>
              {shipmentData?.shipment?.contract_state === 'DEPLOYED'
                ? t('shipments:anonymous.mobile.deliveryMessageInTransit')
                : t('shipments:anonymous.mobile.deliveryMessageDelivered')}
            </Text>
          </Stack>
        </Stack>
      </Paper>
      {/* ADDRESS BLOCK */}
      <Box bgcolor="custom.neutral.white.pure" borderRadius=".625rem" mx="1rem">
        <Stack spacing={2} mx="1rem">
          <Text variant="titleM">
            {t('shipments:anonymous.mobile.AdressBlock.title')}
          </Text>
          <Stack gap="1rem">
            <Stack direction="row" spacing={2}>
              <Stack
                width="3rem"
                height="3rem"
                borderRadius="0.625rem"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: 'custom.neutral.black.3',
                }}
              >
                <SenderAddressIcon
                  style={{
                    borderRadius: '0.625rem',
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                />
              </Stack>
              {shipmentData && (
                <Stack width="17.75rem">
                  <Text pb="0.25rem" variant="titleS">
                    {t('shipments:anonymous.mobile.AdressBlock.sender')}{' '}
                    {shipmentData?.shipment.sender.address.first_name}{' '}
                    {shipmentData?.shipment.sender.address.last_name}
                  </Text>
                  <Address
                    address={shipmentData?.shipment.sender.address as any}
                  />
                </Stack>
              )}
            </Stack>
            <Stack direction="row" spacing={2}>
              <Stack
                width="3rem"
                height="3rem"
                borderRadius="0.625rem"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: 'custom.neutral.black.3',
                }}
              >
                <SenderAddressesIcon
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                />
              </Stack>
              {shipmentData && (
                <Stack>
                  <Text variant="titleS" pb="0.25rem">
                    {t('shipments:anonymous.mobile.AdressBlock.recipient')}{' '}
                    {shipmentData?.shipment.recipient.address.first_name}{' '}
                    {shipmentData?.shipment.recipient.address.last_name}
                  </Text>
                  <Text>
                    <Address
                      address={shipmentData?.shipment.recipient.address as any}
                    />
                  </Text>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>

      <ShipmentAnonymousMapFullScreenModal
        visible={showMapFull}
        onDismiss={() => setShowMapFull(false)}
        shipmentData={shipmentData}
        hideMapFullScreenModal={() => setShowMapFull(false)}
      />
    </>
  );
};
