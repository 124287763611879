// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React, { useEffect, useMemo, useState } from 'react';

import { FinancialCard } from '@livingpackets/design-system-react';
import {
  Box,
  ContributionIcon,
  Grid,
  TooltipIcon,
  NewTooltip as Tooltip,
  Text,
  Stack,
} from '@livingpackets/design-system-react-next';
import ContributionTable from 'components/molecules/ContributionTable';
import useGetContribution from 'hooks/useGetContribution';
import { useTranslation } from 'react-i18next';

import useMeContributions from '../../hooks/useContributions';
import { GetMeContributionsParams } from '../../hooks/useGetMeContributions';
import { IContribution } from '../../models/contribution';
import { usePagination } from '../../shared-components';
import useContributionsStore, {
  co2SavingsSelector,
  contributionOverviewSelector,
} from '../../stores/useContributionsStore';
import EstimatedCO2 from '../molecules/EstimatedCO2';

const ProfileContributions = () => {
  const { getContributionOverview } = useGetContribution();
  const contributionOverview = useContributionsStore(
    contributionOverviewSelector
  );

  const co2Savings = useContributionsStore(co2SavingsSelector);
  const { t } = useTranslation('contribution');
  const [accountInformation, setAccountInformation] = useState<undefined>();
  const [contributions, setContributions] = useState<
    IContribution[] | undefined
  >();

  const { params: paginationParams, setParams: setPaginationParams } =
    usePagination({
      pageSize: 100,
      orderDirection: 'DESC',
      orderColumn: 'contributed_at',
      page: 1,
    });

  const contributionsParams: GetMeContributionsParams = useMemo(
    () => ({ ...paginationParams }),
    [paginationParams]
  );

  const fetchContributions = useMeContributions(contributionsParams);

  if (fetchContributions.state === 'success') {
    if (!contributions) {
      setAccountInformation(fetchContributions.resource.account_information);
      setContributions(fetchContributions.resource.contributions);
    }
  }

  useEffect(() => {
    if (contributionOverview.contributions_number === undefined) {
      getContributionOverview();
    }
  }, [contributionOverview, getContributionOverview]);

  return (
    <Stack marginTop="2rem">
      <Stack>
        <Box display="flex">
          <Grid container spacing={2}>
            <Grid item mobile={12} tablet={7}>
              <FinancialCard
                headerBackgroundColor="primary.140"
                titleCard={
                  <>
                    <ContributionIcon size="24px" />
                    <Text variant="titleL">{t('financialCard.title')}</Text>
                  </>
                }
                totalLabelInit={
                  <Text sx={{ textAlign: 'center' }}>
                    {t('financialCard.investmentsTotalLabel')}
                  </Text>
                }
                totalLabelInitAmount={
                  <Text variant="bodyTextXL">
                    {t('financialCard.investmentsTotalAmount', {
                      investmentsTotalAmount:
                        contributionOverview.investments_total_amount,
                    })}
                  </Text>
                }
                totalLabelwin={
                  <Stack direction="row" gap="5px" alignItems="center">
                    <Text sx={{ textAlign: 'center' }}>
                      {t('financialCard.rewardLabel')}
                    </Text>
                    <Tooltip
                      id="reward-tooltip"
                      title={
                        <>
                          <p>{t('financialCard.rewardTooltip.title')}</p>
                          <p>{t('financialCard.rewardTooltip.content')}</p>
                        </>
                      }
                    >
                      <TooltipIcon
                        data-tooltip-id="reward-tooltip"
                        size=".8438rem"
                      />
                    </Tooltip>
                  </Stack>
                }
                totalLabelwinAmount={
                  <Text variant="bodyTextXL">
                    {t('financialCard.rewardAmount', {
                      rewardAmount: contributionOverview.reward_amount,
                    })}
                  </Text>
                }
                summaryTotalLabel={
                  <Text variant="titleL" data-testid="contribSummaryLabel">
                    {t('financialCard.summaryLabel')}
                  </Text>
                }
                summaryTotalAmount={
                  <Text variant="titleL" data-testid="contribSummaryAmount">
                    {t('financialCard.summaryAmount', {
                      summaryAmount:
                        contributionOverview.investments_total_amount,
                    })}
                    {}
                  </Text>
                }
                summaryLabelDetail1={
                  <Text
                    variant="bodyTextL"
                    data-testid="contributionNumberLabel"
                  >
                    {t('financialCard.contributionNumberLabel', {
                      contributionNumber:
                        contributionOverview.contributions_number,
                    })}
                  </Text>
                }
                summaryLabelDetailAmount1={
                  <Text variant="bodyTextL" data-testid="contributionAmount">
                    {t('financialCard.contributionAmount', {
                      contributionAmount:
                        contributionOverview.contributions_amount,
                    })}
                  </Text>
                }
                summaryLabelDetail2={
                  <Text
                    variant="bodyTextL"
                    data-testid="sponsorshipsNumberLabel"
                  >
                    {t('financialCard.sponsorshipsNumberLabel', {
                      sponsorshipsNumberLabel:
                        contributionOverview.sponsorships_number,
                    })}
                  </Text>
                }
                summaryLabelDetailAmount2={
                  <Text variant="bodyTextL" data-testid="sponsorshipsAmount">
                    {t('financialCard.sponsorshipsAmount', {
                      sponsorshipsAmount:
                        contributionOverview.sponsorships_amount,
                    })}
                  </Text>
                }
                IsCompleted={false}
                IsEmpty={contributionOverview.investments_total_amount <= 0}
              />
            </Grid>
            <Grid item mobile={12} tablet={5}>
              <EstimatedCO2
                co2Savings={co2Savings?.co2_savings.global_savings}
              />
            </Grid>
          </Grid>
        </Box>
      </Stack>
      {contributions && (
        <Box mt="1.5625rem">
          <ContributionTable
            contributions={contributions}
            accountInformation={accountInformation}
            onContributionSelect={() => {}}
            pagination={paginationParams}
            onPaginationChange={setPaginationParams}
            onContributionUpdate={(contributionId, rewardStatus) => {
              setContributions(
                contributions.map((contribution: IContribution) => {
                  if (contribution.id === contributionId) {
                    contribution.reward_status = rewardStatus;
                  }

                  return contribution;
                })
              );
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default ProfileContributions;
