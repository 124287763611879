// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import React from 'react';

import useNavRouteMatch from 'hooks/useNavRouteMatch';
import { Helmet } from 'react-helmet';

const HelmetTitle = () => {
  const { context } = useNavRouteMatch();

  const title =
    context === 'partner'
      ? 'LP | Partnership'
      : context === 'shipmentAnonymousDetail'
      ? 'Delivery'
      : 'LP | Private account';

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default HelmetTitle;
