// The information contained in this document are the sole property of LivingPackets. Any disclosure to any third party and any reproduction, in part or whole without the written permission of LivingPackets is prohibited
// Confidential - Copyright LivingPackets: All rights reserved

import { getShopRoles, TApiEnv } from 'configs/helpers';

export const ShopRoles = getShopRoles(process.env.REACT_APP_API_ENV as TApiEnv);

export enum LpVillageServices {
  buyAndRent = 'BuyAndRent',
}

export enum LpVillageScopes {
  readPartner = 'read:partner',
  updatePartner = 'update:partner',

  createPartnerAddress = 'create:partner_address',
  readPartnerAddress = 'read:partner_address',
  updatePartnerAddress = 'update:partner_address',
  deletePartnerAddress = 'delete:partner_address',

  addPartnerUser = 'add:partner_user',
  readPartnerUser = 'read:partner_user',
  updatePartnerUser = 'update:partner_user',
  deletePartnerUser = 'delete:partner_user',

  readRole = 'read:role',
  readAllRoles = 'read:all_roles',

  readApiKey = 'read:apikey',
  rotateApiKey = 'rotate:apikey',
  deleteApiKey = 'delete:apikey',

  readBoxContract = 'read:box-contracts',

  createContact = 'create:contact',
  readContact = 'read:contact',
  readContactUser = 'read:contact_user',

  deletePartnerContact = 'delete:partner_contact',
  updatePartnerContact = 'update:partner_contact',

  readRemoteCommand = 'read:remote-command',
}
